import React from "react";
import { useSelector } from "react-redux";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";

import classNames from "classnames";

import styles from "./Popover.module.scss";

const Popover = (props: {
  id: string;
  title: string;
  children: React.ReactNode | React.ReactNode[];
}) => {
  const user = useSelector(selectCurrentUser);
  return (
    <UncontrolledPopover
      placement="bottom"
      target={props.id}
      trigger="click"
      className={classNames(styles.popover, styles[user.role])}
    >
      <PopoverHeader className={classNames(styles.header, styles[user.role])}>
        {props.title}
      </PopoverHeader>
      <PopoverBody>{props.children}</PopoverBody>
    </UncontrolledPopover>
  );
};

export default Popover;
