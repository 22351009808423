import {
  AppServices,
  AppServicesListing,
  Cart,
  CartCheckout,
  MarketplacePartnerProduct,
  MarketplacePartners,
} from "views/partner";

export const partnerMobileRoutesPath = {
  Marketplace: {
    Index: "/app/marketplace",
    Product: "/app/marketplace/products/:productId",
    Cart: "/app/marketplace/cart",
    CartCheckout: "/app/marketplace/cart/checkout/:supplierId",
  },
  Services: {
    Index: "/app/services",
    Vehicle: "/app/services/:vehicleType",
  },
};

export const partnerMobileRoutes = [
  {
    path: partnerMobileRoutesPath.Marketplace.Index,
    Component: MarketplacePartners,
  },
  {
    path: partnerMobileRoutesPath.Marketplace.Product,
    Component: MarketplacePartnerProduct,
  },
  {
    path: partnerMobileRoutesPath.Marketplace.Cart,
    Component: Cart,
  },
  {
    path: partnerMobileRoutesPath.Marketplace.CartCheckout,
    Component: CartCheckout,
  },
  {
    path: partnerMobileRoutesPath.Services.Index,
    Component: AppServices,
  },
  {
    path: partnerMobileRoutesPath.Services.Vehicle,
    Component: AppServicesListing,
  },
];
