import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { selectCurrentUser } from "app/auth/authSlice";
import { selectMarketplaceIsUsedInApp } from "app/common/commonSlice";
import { useGetCartProductsQuery, useSendCommandMutation } from "app/marketplace/cartApiSlice";

import classNames from "classnames";
import { IsLoading, PageHeader } from "components";
import { errorHandler, formatPrice, GlobalStrings } from "utils";
import { Currency } from "utils/enums";

import ProductsListing from "./ProductsListing/ProductsListing";
import banner from "assets/images/command_sent.webp";

import styles from "./Cart.module.scss";

const CartCheckout = () => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const marketplaceIsUsedInApp = useSelector(selectMarketplaceIsUsedInApp);
  const { supplierId } = useParams();
  const [sendCommand, { isLoading: isLoadingSendCommand }] = useSendCommandMutation();
  const { data, isLoading } = useGetCartProductsQuery(user.id);
  const { refetch } = useGetCartProductsQuery(user?.id, {
    refetchOnMountOrArgChange: false,
  });

  const [cart, setCart] = useState<any>();
  const [commandIsSent, setCommandIsSent] = useState(false);

  useEffect(() => {
    if (data) {
      const cartData = data?.cart?.companies?.find(
        (item: any) => item.id.toString() === supplierId
      );
      if (cartData) {
        setCart(cartData);
      } else if (!commandIsSent) {
        navigate("/marketplace");
      }
    }
  }, [data]);

  const sendCommandHandler = async () => {
    try {
      await sendCommand(supplierId).unwrap();
      setCommandIsSent(true);
      refetch();
    } catch (error) {
      errorHandler(error);
      setCommandIsSent(false);
    }
  };

  return (
    <section
      className={classNames(styles.cart_checkout, {
        [styles.used_for_app]: marketplaceIsUsedInApp,
      })}
    >
      <PageHeader title={GlobalStrings.marketplace.myCart} backRoute="-1" />
      {isLoading || isLoadingSendCommand ? (
        <IsLoading />
      ) : !commandIsSent ? (
        <div className={styles.cart_checkout_container}>
          <div className={styles.products_listing}>
            <div className={styles.cart_products_container}>
              <p className={styles.company_name}>
                <strong>{GlobalStrings.marketplace.productsSellBy}</strong> {cart?.name}
              </p>

              <ProductsListing products={cart?.cartItems} />
            </div>
          </div>
          <div className={classNames("card", styles.summary)}>
            <h4 className="mb-4">
              {GlobalStrings.marketplace.total}: {formatPrice(data?.cart?.total)} {Currency.RON}
            </h4>
            <p className={styles.company_name}>
              <strong>
                {GlobalStrings.formLabels.contact} {cart?.name}
              </strong>
            </p>

            <p className={styles.company_name}>
              <strong>{GlobalStrings.formLabels.email}</strong>
              {": "}
              <Link to={`mailto:${cart?.email}`}>{cart?.email}</Link>
            </p>
            <p className={styles.company_name}>
              <strong>{GlobalStrings.formLabels.phone}</strong>
              {": "}
              <Link to={`tel:${cart?.phone}`}>{cart?.phone}</Link>
            </p>

            <button
              className="btn btn-success"
              onClick={sendCommandHandler}
              disabled={isLoadingSendCommand}
            >
              {GlobalStrings.formLabels.sendCommand}
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.confirmation}>
          {!marketplaceIsUsedInApp && (
            <div className={styles.image_container}>
              <img src={banner} alt="banner" />
            </div>
          )}
          <div>
            <h2>{GlobalStrings.marketplace.commandHasBeenSent}</h2>
          </div>
        </div>
      )}
    </section>
  );
};
export default CartCheckout;
