import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import {
  useContractServiceToPartnerMutation,
  useGetCompanyServicesQuery,
  useGetJuicersQuery,
} from "app/partners/partnersApiSlice";

import { SubmitButton } from "components";
import { errorHandler, GlobalStrings } from "utils";

import ServicesSelect from "./ServicesSelect/ServicesSelect";

export default function GetServicesButtonModal(props: { companyId: string | number | undefined }) {
  const { partnerId } = useParams();
  const { data: services, error } = useGetCompanyServicesQuery(
    {
      companyId: props.companyId,
      partnerId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { refetch } = useGetJuicersQuery(partnerId, {
    refetchOnMountOrArgChange: false,
  });
  const [modal, setModal] = useState(false);

  const [contractServiceToPartner] = useContractServiceToPartnerMutation();
  const toggleModal = () => setModal((prev) => !prev);

  const { handleSubmit, getValues, control, watch } = useForm({
    defaultValues: {
      service: { label: "-", value: "-" },
    },
  });

  const onSubmit = async (_data: any) => {
    if (partnerId) {
      const reqBody = {
        partnersIds: [partnerId.toString()],
      };

      try {
        await contractServiceToPartner({
          body: reqBody,
          serviceId: getValues("service").value,
        });
        refetch();
        setModal(false);
        toast.success(GlobalStrings.activeServices.serviceContracted);
      } catch (error: any) {
        errorHandler(error);
      }
    }
  };

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <div className="d-flex justify-content-center gap-2">
      <Button
        className="btn btn-primary"
        size="sm"
        onClick={() => {
          setModal(true);
        }}
      >
        {GlobalStrings.formLabels.contract}
      </Button>
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggleModal}>{GlobalStrings.activeServices.addJuicers}</ModalHeader>
          <ModalBody>
            <ServicesSelect control={control} name={"service"} services={services || []} />
          </ModalBody>
          <ModalFooter>
            <SubmitButton
              disabled={watch("service").value === "-"}
              isLoading={false}
              color={"primary"}
              label={GlobalStrings.add}
            />
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}
