import React, { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import { useEndTripMutation, useGetTripsQuery } from "app/trips/tripsApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { TableBadge, TableComponent, TableIcon, VehicleType } from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { Currency, TripStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_TRIP } from "utils/types";

import { adminRoutesPath } from "views/admin/routes";

const Trips = (props: { userId: number }) => {
  const user = useSelector(selectCurrentUser);
  const [endTrip] = useEndTripMutation();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const [sorting, setSorting] = useState<SortingState>([]);
  const [modal, setModal] = useState(false);
  const [paymentsModal, setPaymentsModal] = useState(false);
  const [tripToClose, setTripToClose] = useState<I_TRIP>();
  const [trip, setTrip] = useState<I_TRIP>();

  const [endTripButtonDisabled, setEndTripButtonDisabled] = useState(false);

  const toggle = () => setModal((prev) => !prev);
  const togglePaymentsModal = () => setPaymentsModal((prev) => !prev);

  const { data, isFetching, error, refetch } = useGetTripsQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
    userId: props.userId,
  });

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  const columnHelper = createColumnHelper<I_TRIP>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.partners.ID}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.vehicle, {
      id: "vehicle",
      cell: (info) => <VehicleType vehicles={info.getValue()?.type} />,
      header: () => <span>{GlobalStrings.partners.vehicleType}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.vehicle, {
      id: "qrCode",
      cell: (info) => (
        <Link
          to={adminRoutesPath.Vehicles.Vehicle.replace(":vehicleId", info.getValue().id.toString())}
        >
          <p>{info.getValue().qrCode}</p>
        </Link>
      ),
      header: () => <span>{GlobalStrings.formLabels.qrCode}</span>,
      enableSorting: false,
    }),

    columnHelper.accessor(
      (row) => {
        const startDate = new Date(row.startDate);
        const formattedStartDate = `${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()}`;
        return formattedStartDate;
      },
      {
        id: "startDate",
        cell: (info) => <TableBadge text={info.getValue()} color="green" />,
        header: () => <span>{GlobalStrings.partners.startHour}</span>,
        enableSorting: false,
      }
    ),
    columnHelper.accessor(
      (row) => {
        if (row.tripStatus === TripStatus.ACTIVE) {
          return NO_VALUE_SYMBOL;
        }
        const endDate = new Date(row.endDate);
        const formattedStartDate = `${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString()}`;
        return formattedStartDate;
      },
      {
        id: "endDate",
        cell: (info) => <TableBadge text={info.getValue()} color="red" />,
        header: () => <span>{GlobalStrings.partners.finishHour}</span>,
        enableSorting: false,
      }
    ),
    columnHelper.accessor(
      (row) => {
        return !row.durationInMinutes
          ? NO_VALUE_SYMBOL
          : `${row.durationInMinutes} ${GlobalStrings.formLabels.minutes}`;
      },
      {
        id: "duration",
        cell: (info) => <p>{info.getValue()}</p>,
        header: () => <span>{GlobalStrings.partners.duration}</span>,
        enableSorting: false,
      }
    ),
    columnHelper.accessor((row) => row.amount, {
      id: "amount",
      cell: (info) => (
        <p>
          {isNaN(info.getValue()) ? (
            info.getValue()
          ) : (
            <>
              {info.getValue()} {Currency.RON}
            </>
          )}
        </p>
      ),
      header: () => <span>{GlobalStrings.formLabels.totalPaid}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "tripStatus",
      cell: (info) => (
        <div className="d-flex gap-2">
          <TableBadge
            text={GlobalStrings.tripStatus[info.getValue().tripStatus]}
            color={
              info.getValue().tripStatus === TripStatus.ACTIVE
                ? "green"
                : info.getValue().tripStatus === TripStatus.PAUSED
                ? "orange"
                : "red"
            }
          />
          {(info.getValue().tripStatus === TripStatus.ACTIVE ||
            info.getValue().tripStatus === TripStatus.PAUSED) && (
            <Button
              className="btn btn-primary"
              size="sm"
              onClick={() => {
                setTripToClose(info.getValue());
                setModal(true);
              }}
            >
              {GlobalStrings.stop}
            </Button>
          )}
        </div>
      ),
      header: () => <span>{GlobalStrings.partners.tripStatus}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "payment",
      cell: (info) => (
        <div className="edit_delete_container">
          <TableIcon
            type="payment"
            onClickAction={() => {
              setPaymentsModal(true);
              setTrip(info.getValue());
            }}
          />
        </div>
      ),
      header: () => <span>{GlobalStrings.partners.payments}</span>,
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    data: data?.trips || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  const endTripHandler = async () => {
    setEndTripButtonDisabled(true);
    try {
      await endTrip(tripToClose?.id).unwrap();
      toast.success(GlobalStrings.trips.tripClosedSucessfully);
      setTripToClose(undefined);
      setModal(false);
      refetch();
    } catch (error) {
      errorHandler(error, false);
    } finally {
      setEndTripButtonDisabled(false);
    }
  };

  return (
    <section>
      <TableComponent table={table} tableHasData={data?.trips?.length > 0} isLoading={isFetching} />

      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{GlobalStrings.trips.modalHeader}</ModalHeader>
        <ModalBody>
          <p>
            {GlobalStrings.formLabels.firstName}: {tripToClose?.firstName}
          </p>
          <p>
            {GlobalStrings.formLabels.lastName}: {tripToClose?.lastName}
          </p>
          <p>
            {GlobalStrings.formLabels.vehicleID}: {tripToClose?.vehicle.id}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => endTripHandler()} disabled={endTripButtonDisabled}>
            {GlobalStrings.yes}
          </Button>
          <Button color="secondary" onClick={toggle}>
            {GlobalStrings.cancel}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={paymentsModal} toggle={togglePaymentsModal} centered>
        <ModalHeader toggle={togglePaymentsModal}>
          {GlobalStrings.trips.paymentsModalHeader} | {trip?.phone} |{" "}
          {GlobalStrings.partners.tripID}: {trip?.id}
        </ModalHeader>
        <ModalBody>
          <section className={classNames("table_container", user?.role)}>
            <table className="styled_table">
              <thead>
                <tr>
                  <th>{GlobalStrings.formLabels.date}</th>
                  <th>{GlobalStrings.formLabels.panMask}</th>
                  <th>{GlobalStrings.formLabels.paymentStatus}</th>
                </tr>
              </thead>
              <tbody>
                {trip?.payments?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Moment date={item.createdAt} format="DD-MM-YYYY, HH:mm:ss" />
                      </td>
                      <td>{item.panMask}</td>
                      <td>{item.status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePaymentsModal}>
            {GlobalStrings.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    </section>
  );
};
export default Trips;
