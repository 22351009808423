import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import { useAddSupplierMutation } from "app/suppliers/suppliersApiSlice";

import { AlertComponent, PageHeader } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { GlobalStrings, Regex } from "utils";
import { errorHandler } from "utils/globalFunctions";

import { adminRoutesPath } from "views/admin/routes";

type FormData = {
  name: string;
  identifier: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  city: string;
};

const AddSupplierForm = () => {
  const { serverMessage, setServerMessage } = useServerMessage();
  const [addSupplierPartner] = useAddSupplierMutation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      identifier: "",
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      const response = await addSupplierPartner({
        name: data.name,
        identifier: data.identifier,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
      }).unwrap();
      navigate(
        adminRoutesPath.Suppliers.Products.EditSupplier.replace(
          ":supplierId",
          response.data.id.toString()
        )
      );
      setServerMessage({
        text: GlobalStrings.servicesSuppliers.supplierWasAdded,
        type: "success",
      });
    } catch (error) {
      errorHandler(error, true, setServerMessage, GlobalStrings.servicesSuppliers);
    }
  };

  return (
    <section>
      <PageHeader title={`${GlobalStrings.servicesSuppliers.addSupplier}`} backRoute="-1" />

      <Form onSubmit={handleSubmit(onSubmit)} className="card">
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="name"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.companyName}
                  type="text"
                />
              )}
            />
            <Label for="name">{GlobalStrings.formLabels.companyName}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="identifier"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.cifOrCnp,
                  message: GlobalStrings.formValidation.invalidCIForCNP,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.cifCnp}
                  type="text"
                />
              )}
            />

            <Label for="identifier">{GlobalStrings.formLabels.cifCnp}</Label>
          </FormGroup>
        </div>

        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.firstName}
                  type="text"
                />
              )}
            />
            <Label for="firstName">{GlobalStrings.formLabels.firstName}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.lastName}
                  type="text"
                />
              )}
            />
            <Label for="lastName">{GlobalStrings.formLabels.lastName}</Label>
          </FormGroup>
        </div>

        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="email"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.email,
                  message: GlobalStrings.formValidation.invalidEmail,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.email}
                  type="text"
                />
              )}
            />
            <Label for="email">{GlobalStrings.formLabels.email}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="phone"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.phone,
                  message: GlobalStrings.formValidation.invalidPhone,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.phoneNumber}
                  type="text"
                />
              )}
            />

            <Label for="phone">{GlobalStrings.formLabels.phoneNumber}</Label>
          </FormGroup>
        </div>

        <div className="d-flex justify-content-start mb-2">
          <Button color="success" type="submit" className="d-flex align-item-center gap-2 mt-3 ">
            {GlobalStrings.formLabels.addSupplier}
          </Button>
        </div>

        <AlertComponent errors={errors} serverMessage={serverMessage} />
      </Form>
    </section>
  );
};

export default AddSupplierForm;
