import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { useLoginMutation } from "app/auth/authApiSlice";
import { selectCurrentUser, setCredentials } from "app/auth/authSlice";

import { AlertComponent, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { errorHandler, GlobalStrings, GlobalVariables, Regex, roleValidation } from "utils";
import { I_LOGIN } from "utils/types";

import logo from "assets/logo/ecohop-logo.png";
import { adminRoutesPath } from "views/admin/routes";
import { chargingParterRoutesPath } from "views/chargingPartner/routes";
import { supplierRoutesPath } from "views/supplier/routes";

const LoginForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const [login, { isLoading }] = useLoginMutation();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { serverMessage, setServerMessage } = useServerMessage();
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  useEffect(() => {
    if (user) {
      if (roleValidation("supplier", user.roles)) {
        navigate(supplierRoutesPath.Products.Index);
      } else if (roleValidation("admin", user.roles)) {
        navigate(adminRoutesPath.Dashboard);
      } else if (roleValidation("charging_partner", user.roles)) {
        navigate(chargingParterRoutesPath.ContainersAndStations.ContainersMap);
      }
    }
  }, [user]);

  useEffect(() => {
    if (isFormSubmitted) {
      setIsFormSubmitted(false);
      setServerMessage({ type: "", text: "" });
    }
  }, [errors?.email || errors?.password]);

  const onSubmit = async ({ email, password }: I_LOGIN) => {
    try {
      setIsFormSubmitted(true);
      const { data } = await login({ email: email.trim(), password }).unwrap();
      const roles = data?.user?.roles?.map((item: any) => {
        return item?.name;
      });
      let userCopy: any = {};
      if (roles?.length === 1) {
        userCopy = { ...data?.user, roles, role: roles[0] };
      } else {
        userCopy = { ...data?.user, roles };
      }

      dispatch(
        setCredentials({
          user: userCopy,
          accessToken: data.token.access_token,
        })
      );
      if (roleValidation("supplier", userCopy.roles)) {
        navigate(supplierRoutesPath.Products.Index);
      } else {
        navigate(adminRoutesPath.Dashboard);
      }
    } catch (err: any) {
      errorHandler(err, true, setServerMessage, GlobalStrings.auth);
    }
  };

  return (
    <section className="d-flex flex-column gap-4">
      <div className="signin_header">
        <h3 className="title">{GlobalStrings.auth.welcomeMessage}</h3>
        <div style={{ display: "flex", gap: 20 }}>
          <div className="image_container">
            <img src={logo} alt="logo" />
          </div>
          <div className="image_container2">
            <a target="_blank" rel="noreferrer" href="https://european-union.europa.eu/index_ro">
              <img src="/Flag_of_Europe.svg" alt="steagul Uniunii Europene" />
            </a>
          </div>
          <div className="image_container2">
            <a target="_blank" rel="noreferrer" href="https://www.gov.ro/">
              <img src="/Guvernul_Romaniei.svg" alt="logo Guvernul României" />
            </a>
          </div>
          <div className="image_container2">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.fonduri-ue.ro/24-programe-operationale/2014-2020/115-poc-poc"
            >
              <img src="/logo IS-2014-2020.png" alt="logo IS-2014-2020" />
            </a>
          </div>
        </div>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup floating>
          <Controller
            name="email"
            control={control}
            rules={{
              required: GlobalStrings.formValidation.emailIsRequired,
              pattern: {
                value: Regex.loginEmail,
                message: GlobalStrings.formValidation.invalidEmail,
              },
            }}
            render={({ field, fieldState: { invalid } }) => (
              <Input
                {...field}
                invalid={invalid}
                placeholder={GlobalStrings.formLabels.email}
                type="email"
              />
            )}
          />
          <Label for="email">{GlobalStrings.formLabels.email}</Label>
        </FormGroup>
        <FormGroup floating>
          <Controller
            name="password"
            control={control}
            rules={{
              required: GlobalStrings.formValidation.passwordIsRequired,
              pattern: {
                value: Regex.password,
                message: GlobalStrings.formValidation.invalidExistingPassword,
              },
            }}
            render={({ field, fieldState: { invalid } }) => (
              <Input
                {...field}
                invalid={invalid}
                placeholder={GlobalStrings.formLabels.password}
                type={passwordIsVisible ? "text" : "password"}
              />
            )}
          />

          <Label for="password">{GlobalStrings.formLabels.password}</Label>
          {passwordIsVisible ? (
            <AiOutlineEyeInvisible
              className="position-absolute absolute-vertical-center cursor-pointer"
              size="20"
              onClick={() => setPasswordIsVisible(false)}
            />
          ) : (
            <AiFillEye
              className="position-absolute absolute-vertical-center cursor-pointer"
              size="20"
              color={GlobalVariables.redColor}
              onClick={() => setPasswordIsVisible(true)}
            />
          )}
        </FormGroup>
        <div className="d-flex flex-sm-row flex-column align-items-md-center justify-content-between align-items-start gap-md-0 gap-3">
          <SubmitButton isLoading={isLoading} label={GlobalStrings.auth.logInButton} />

          <Link to="/forgot-password" className="mb-0 btn btn-link">
            {GlobalStrings.auth.forgotPassword}
          </Link>
        </div>
      </Form>
      <AlertComponent errors={errors} serverMessage={serverMessage} />
    </section>
  );
};
export default LoginForm;
