import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useLogoutMutation } from "app/auth/authApiSlice";
import { logOut } from "app/auth/authSlice";

import { errorHandler, GlobalStrings } from "utils";

const NotAuthorised = () => {
  const dispatch = useDispatch();
  const [logoutBackend] = useLogoutMutation();
  const navigate = useNavigate();
  const handleLogout = () => {
    try {
      logoutBackend();
    } catch (error) {
      errorHandler(error);
    }
    dispatch(logOut());
    navigate("/");
  };
  return (
    <section className="not_authorized_page">
      <h5>502</h5>
      <h4>{GlobalStrings.auth.notAuthorised}</h4>
      <button className="btn btn-secondary" onClick={handleLogout}>
        {GlobalStrings.auth.backToAuth}
      </button>
    </section>
  );
};
export default NotAuthorised;
