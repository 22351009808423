import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { selectCurrentUser } from "app/auth/authSlice";
import { closeMenu, toggleMenu } from "app/common/commonSlice";

import classNames from "classnames";
import { GlobalStrings } from "utils";
import { I_MENU_ITEM } from "utils/types";

import MenuItem from "../MenuItem/MenuItem";
import adminIcon11 from "assets/icons/admin_icon11.svg";

import styles from "./Menu.module.scss";

const logoutIten = {
  id: -1,
  text: GlobalStrings.menu.logout,
  icon: adminIcon11,
  slug: "/log-out",
};

const Menu = (props: { menuData: I_MENU_ITEM[] }) => {
  const user = useSelector(selectCurrentUser);
  const menuIsOpen = useSelector((state: any) => state.common.menuIsOpen);
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (!ref?.current?.contains(e.target) && menuIsOpen) {
        dispatch(toggleMenu());
      }
    };

    const timeoutId = setTimeout(() => {
      document.addEventListener("click", handleOutsideClick, false);
    }, 0);
    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener("click", handleOutsideClick, false);
    };
  }, [menuIsOpen]);

  useEffect(() => {
    dispatch(closeMenu());
    if (location.pathname === "/dashboard") {
      setActiveIndex(-1);
    } else {
      const menuItem = props.menuData.find(
        (item) => location.pathname.split("/")[1] === item.slug.split("/")[1]
      ) as I_MENU_ITEM;
      setActiveIndex(props.menuData.indexOf(menuItem));
    }
  }, [location.pathname]);

  return (
    <section className={classNames(styles.menu, { [styles.active]: menuIsOpen })}>
      <ul ref={ref}>
        {props.menuData.map((item) => {
          return (
            <MenuItem
              key={item.id}
              item={item}
              setActiveIndex={setActiveIndex}
              activeIndex={activeIndex}
            />
          );
        })}

        <MenuItem
          item={logoutIten}
          type="logout"
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
        />

        <span
          className={classNames(styles.item_line, {
            [styles.active]: activeIndex >= 0,
            [styles[user?.role]]: true,
          })}
          style={{ transform: `translateY(${activeIndex * 55}px)` }}
        ></span>
      </ul>
    </section>
  );
};

export default React.memo(Menu);
