import React, { useState } from "react";
import { GiElectric } from "react-icons/gi";

import { GoogleMap, MarkerClustererF, useLoadScript } from "@react-google-maps/api";
import { StatisticItem } from "components";
import { GlobalStrings, GlobalVariables } from "utils";

import { ElectricityContainers } from "../Containers/ContainersTable";

import ContainerMarker from "./ContainerMarker";

const containerStyle = {
  width: "100%",
  height: "75vh",
};

function ContainersMap(props: { data: ElectricityContainers[]; containers: number }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
  });
  const [active, setActive] = useState();

  return isLoaded ? (
    <>
      <div className="stats d-flex gap-3 flex-column flex-md-row mb-2">
        <div className="w-md-25 w-100">
          <StatisticItem
            icon={<GiElectric size={30} />}
            count={props.containers}
            title={GlobalStrings.containersAndStations.containereENECactive}
          />
        </div>
      </div>
      <GoogleMap mapContainerStyle={containerStyle} center={GlobalVariables.center} zoom={13}>
        <MarkerClustererF averageCenter={false} maxZoom={20} gridSize={50} minimumClusterSize={2}>
          {(clusterer) => (
            <>
              {props.data?.map((item, index) => {
                return (
                  <ContainerMarker
                    clusterer={clusterer}
                    item={item}
                    key={index}
                    active={active}
                    setActive={setActive}
                  />
                );
              })}
            </>
          )}
        </MarkerClustererF>
      </GoogleMap>
    </>
  ) : (
    <>Loading....</>
  );
}

export default React.memo(ContainersMap);
