import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import { Button } from "reactstrap";

import {
  useGetVehicleQuery,
  useLockVehicleMutation,
  useRestartVehicleMutation,
  useStartVehicleMutation,
  useStopVehicleMutation,
  useUnlockVehicleMutation,
} from "app/vehicles/vehiclesApiSlice";

import classNames from "classnames";
import { Battery, IsLoading, TableBadge, VehicleType } from "components";
import moment from "moment";
import { errorHandler, getAddressFromCoordinates, GlobalStrings } from "utils";
import { Currency, VehicleStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

import googleMapsImage from "assets/images/google_maps.svg";
import GetCoordinatesMap from "components/GetCoordinatesMap/GetCoordinatesMap";
import { adminRoutesPath } from "views/admin/routes";

import styles from "./VehicleInfo.module.scss";

const VehicleInfo = () => {
  const { vehicleId } = useParams();
  const { data, error, isLoading, refetch } = useGetVehicleQuery(vehicleId, {
    skip: !vehicleId,
  });
  const [address, setAddress] = useState("");
  const vehicleStatus = data?.status as VehicleStatus;

  useEffect(() => {
    errorHandler(error);
  }, [error]);

  useEffect(() => {
    if (data)
      getAddressFromCoordinates(data?.position?.latitude, data?.position?.longitude, setAddress);
  }, [data]);

  const [startVehicle, { isLoading: isLoadingStart }] = useStartVehicleMutation();
  const [stopVehicle, { isLoading: isLoadingStop }] = useStopVehicleMutation();
  const [lockVehicle, { isLoading: isLoadingLock }] = useLockVehicleMutation();
  const [unlockVehicle, { isLoading: isLoadingUnlock }] = useUnlockVehicleMutation();
  const [restartVehicle, { isLoading: isLoadingRestart }] = useRestartVehicleMutation();

  const startVehicleHandler = async () => {
    try {
      await startVehicle(vehicleId).unwrap();
      toast.success(GlobalStrings.partners.vehicleStarted);
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  const stopVehicleHandler = async () => {
    try {
      await stopVehicle(vehicleId).unwrap();
      toast.success(GlobalStrings.partners.vehicleStoped);
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  const lockVehicleHandler = async () => {
    try {
      await lockVehicle(vehicleId).unwrap();
      toast.success(GlobalStrings.partners.vehicleLocked);
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  const unlockVehicleHandler = async () => {
    try {
      await unlockVehicle(vehicleId).unwrap();
      toast.success(GlobalStrings.partners.vehicleUnocked);
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  const restartVehicleHandler = async () => {
    try {
      await restartVehicle(vehicleId).unwrap();
      toast.success(GlobalStrings.partners.vehicleRestarted);
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  return isLoading ? (
    <IsLoading />
  ) : (
    <section className={classNames(styles.vehicle_info, "d-flex gap-3 flex-column flex-md-row")}>
      <div className="card w-100 w-md-25 h-max">
        <div className={styles.map}>
          {data?.position?.latitude && data?.position?.longitude && (
            <GetCoordinatesMap
              markerPosition={{
                lat: data?.position?.latitude,
                lng: data?.position?.longitude,
              }}
              center={{
                lat: data?.position?.latitude,
                lng: data?.position?.longitude,
              }}
              zoom={15}
            />
          )}
        </div>
        <div className="position-relative">
          <h3 className="w-75">{GlobalStrings.partners.currentLocation}</h3>
          <p>{address}</p>
          <Link
            to={`https://www.google.com/maps/dir/?api=1&destination=${data?.position?.latitude},${data?.position?.longitude}`}
            title={GlobalStrings.dashboard.showRoadToVehicle}
            target="_blank"
            className={styles.google_maps}
          >
            <ReactSVG src={googleMapsImage} width={30} height={30} />
          </Link>
          <p className="mb-0">{GlobalStrings.partners.coordinates}</p>
          <p>
            <strong>
              {data?.position?.latitude}, {data?.position?.longitude}
            </strong>
          </p>
          <hr />
          <p className="mb-2">{GlobalStrings.partners.vehicleType}</p>
          <div className="d-flex align-items-center justify-content-between">
            {data?.type && <VehicleType vehicles={data?.type} />}
            {data?.qrCode && (
              <p className="mb-0">
                <strong>#{data?.qrCode}</strong>
              </p>
            )}
          </div>
          <p className="mb-2 mt-3">{GlobalStrings.partners.batteryLevel}</p>
          <div className="w-100">
            <Battery level={data?.batteryLevel} className="w-100" />
          </div>
          <div className="d-flex gap-3 mt-5">
            <TableBadge
              color={data?.status === VehicleStatus.ACTIVE ? "green" : "red"}
              text={GlobalStrings.vehicleStatus[vehicleStatus]}
            />
            {data?.status === VehicleStatus.ACTIVE && (
              <Button
                color="success"
                outline
                size="sm"
                onClick={startVehicleHandler}
                disabled={isLoadingStart}
                className="w-50"
              >
                {GlobalStrings.start}
              </Button>
            )}
            {data?.status === VehicleStatus.IN_USE && (
              <Button
                color="success"
                outline
                size="sm"
                onClick={stopVehicleHandler}
                disabled={isLoadingStop}
              >
                {GlobalStrings.stop}
              </Button>
            )}
          </div>
          <hr />
          <div className="d-flex gap-3 mt-4 flex-wrap">
            <Button
              color="danger"
              size="sm"
              onClick={lockVehicleHandler}
              disabled={isLoadingLock}
              className="w-auto"
            >
              {GlobalStrings.lock}
            </Button>
            <Button
              color="success"
              size="sm"
              onClick={unlockVehicleHandler}
              disabled={isLoadingUnlock}
              className="w-auto"
            >
              {GlobalStrings.unlock}
            </Button>
            <Button
              color="danger"
              size="sm"
              onClick={restartVehicleHandler}
              disabled={isLoadingRestart}
              className="w-auto"
            >
              {GlobalStrings.restart}
            </Button>
          </div>
        </div>
      </div>
      <div className="card w-100 w-md-75 d-flex flex-md-row flex-column gap-5 h-max">
        <div className="w-100 w-md-50">
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.company}</p>
            <Link
              to={adminRoutesPath.Partners.EditPartner.replace(
                ":partnerId",
                data?.companyId.toString()
              )}
            >
              <p>
                <strong>{data?.companyName}</strong>
              </p>
            </Link>
          </div>
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.customId}</p>
            <p>
              <strong>{data?.customId}</strong>
            </p>
          </div>
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.IMEI}</p>
            <p>
              <strong>{data?.hardware?.imei || NO_VALUE_SYMBOL}</strong>
            </p>
          </div>
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.lastReceivedSignal}</p>
            <p>
              <strong>
                {moment(data?.position?.updatedAt).format("DD.MM.YYYY, HH:mm:ss") ||
                  NO_VALUE_SYMBOL}
              </strong>
            </p>
          </div>
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.locationStatus}</p>
            <p>
              <strong>{data?.locationStatus ? GlobalStrings.yes : GlobalStrings.no}</strong>
            </p>
          </div>
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.vehicleID}</p>
            <p>
              <strong>{data?.id}</strong>
            </p>
          </div>
          <div className={styles.hardware}>
            <h4 className="mt-5">{GlobalStrings.formLabels.hardware}</h4>
            <div className={styles.group}>
              <p>{GlobalStrings.formLabels.lastName}</p>
              <p>
                <strong>{data?.hardware?.name || NO_VALUE_SYMBOL}</strong>
              </p>
            </div>
            <div className={styles.group}>
              <p>{GlobalStrings.formLabels.IMEI}</p>
              <p>
                <strong>{data?.hardware?.imei || NO_VALUE_SYMBOL}</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="w-md-50 w-100">
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.IDIoT}</p>
            <p>
              <strong>{data?.iotId || NO_VALUE_SYMBOL}</strong>
            </p>
          </div>
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.TypeIoT}</p>
            <p>
              <strong>{data?.iotType || NO_VALUE_SYMBOL}</strong>
            </p>
          </div>
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.model}</p>
            <p>
              <strong>{data?.model || NO_VALUE_SYMBOL}</strong>
            </p>
          </div>
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.averageTravelTime}</p>
            <p>
              <strong>{data?.averageTravelTime} {GlobalStrings.formLabels.minutes}</strong>
            </p>
          </div>
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.averageTripsPerDay}</p>
            <p>
              <strong>{data?.averageTripsPerDay || NO_VALUE_SYMBOL} {GlobalStrings.formLabels.trips.toLowerCase()}</strong>
            </p>
          </div>
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.totalAmountSinceLastCharge}</p>
            <p>
              <strong>{data?.totalAmountSinceLastCharge || NO_VALUE_SYMBOL} {Currency.RON}</strong>
            </p>
          </div>
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.totalDistanceInMSinceLastCharge}</p>
            <p>
              <strong>{data?.totalDistanceInMSinceLastCharge || NO_VALUE_SYMBOL}</strong>
            </p>
          </div>
          <div className={styles.group}>
            <p>{GlobalStrings.formLabels.tripsSinceLastCharge}</p>
            <p>
              <strong>{data?.tripsSinceLastCharge || NO_VALUE_SYMBOL} {GlobalStrings.formLabels.trips.toLowerCase()}</strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VehicleInfo;
