import { createSlice } from "@reduxjs/toolkit";
import { UserRoles } from "utils/enums";

import { saveToLocalStorage } from "../../utils";

interface AuthState {
  auth: {
    user: {
      id: string | number;
      firstName: string;
      lastName: string;
      phone: string;
      email: string;
      role: UserRoles;
      roles: UserRoles[];
      refreshToken: string;
      company: {
        id: number;
        name: string;
        phone: string;
        type: string;
        identifier: string;
        email: string;
        juicers: number[];
        juicerForCompanies: number[];
        city: string;
      };
    };
    access_token: string;
  };
}

const authSlice = createSlice({
  name: "auth",
  initialState: { user: null, access_token: null },
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken } = action.payload;
      state.user = user;
      state.access_token = accessToken;
      saveToLocalStorage("user", user);
    },
    logOut: (state) => {
      state.user = null;
      state.access_token = null;
      saveToLocalStorage("user", null);
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: AuthState) => state.auth.user;
export const selectCurrentToken = (state: AuthState) => state.auth.access_token;
