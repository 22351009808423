import React, { useEffect, useMemo, useState } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { UncontrolledTooltip } from "reactstrap";

import {
  useDeleteUserMutation,
  useGetEndUsersQuery,
  useUnblockUserForReservationMutation,
  useUpdateEndUserMutation,
} from "app/users/usersApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ConfirmationPopup, PageHeader, TableBadge, TableComponent, TableIcon } from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { UserStatus } from "utils/enums";

import SearchBar from "components/SearchBar/SearchBar";

import { adminRoutesPath } from "../routes";

type EndUser = {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  phone: string;
  status: UserStatus;
  reasonForBlocking: string;
  transportTypes: {
    id: string;
    name: string;
    type: string;
  }[];
  numberOfTrips: number;
  totalPaid: number;
  isBlockedForReservation: boolean;
};

const EndUsers = () => {
  const [deleteUser] = useDeleteUserMutation();
  const [unblockUserForReservation] = useUnblockUserForReservationMutation();
  const [userToDelete, setUserToDelete] = useState<any>();
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const toggleDeleteModal = () => setDeleteModalIsOpen((prev) => !prev);
  const [updateEndUserStatus] = useUpdateEndUserMutation();
  const [lockModalIsOpen, setLockModalIsOpen] = useState(false);
  const toggleLockModal = () => setLockModalIsOpen((prev) => !prev);
  const [unlockModalIsOpen, setUnlockModalIsOpen] = useState(false);
  const toggleUnlockModal = () => setUnlockModalIsOpen((prev) => !prev);
  const [userToBlock, setUserToBlock] = useState<any>();
  const [blockReason, setBlockReason] = useState("");
  const [userToUnlock, setUserToUnlock] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const buttons = [
    {
      id: 0,
      name: GlobalStrings.personUsers.documents,
      slug: adminRoutesPath.EndUsers.EndUsersWithDocuments,
    },
  ];

  const { data, isFetching, refetch, error } = useGetEndUsersQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
    search,
  });

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setSearch(searchTerm);
    }, 500);
    return () => clearInterval(delay);
  }, [searchTerm]);

  const deleteUserHandler = async () => {
    try {
      await deleteUser(userToDelete.id).unwrap();
      toggleDeleteModal();
      refetch();
      toast.success(GlobalStrings.personUsers.userDeleted);
    } catch (err: any) {
      errorHandler(err);
    }
  };

  const handleStatusChange = async (
    userId: string | number,
    currentStatus: string,
    reasonForBlocking?: string
  ) => {
    if (userToUnlock?.type === "reservation") {
      try {
        await unblockUserForReservation({ id: userId }).unwrap();
        refetch();
        toggleUnlockModal();
        toast.success(GlobalStrings.personUsers.userUnlocked);
      } catch (error) {
        errorHandler(error);
      }
    } else {
      try {
        const newStatus =
          currentStatus === UserStatus.blocked ? UserStatus.active : UserStatus.blocked;

        const payload: any = {
          id: userId,
          status: newStatus,
        };

        if (newStatus === UserStatus.blocked && reasonForBlocking) {
          payload.reasonForBlocking = reasonForBlocking;
        }

        const response = await updateEndUserStatus(payload).unwrap();

        if (response) {
          refetch();
          if (newStatus === UserStatus.blocked) {
            toast.success(GlobalStrings.personUsers.userBlocked);
          } else {
            toast.success(GlobalStrings.personUsers.userUnlocked);
            toggleUnlockModal();
          }
        } else {
          throw new Error(GlobalStrings.personUsers.userStatusUpdateFailed);
        }
      } catch (err: any) {
        errorHandler(err);
      }
    }
  };

  const handleReasonAdd = () => {
    handleStatusChange(userToBlock.id, userToBlock.status, blockReason);
    toggleLockModal();
  };

  const columnHelper = createColumnHelper<EndUser>();

  const columns = [
    columnHelper.accessor((row) => row.lastName, {
      id: "lastName",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.personUsers.lastName}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.firstName, {
      id: "firstName",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.personUsers.firstName}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.email, {
      id: "email",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.personUsers.email}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row.phone, {
      id: "phone",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.personUsers.phone}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row, {
      id: "status",
      cell: (info) => {
        return (
          <div className="d-flex gap-3 justify-content-start align-items-center">
            <div style={{ width: info.getValue().status === UserStatus.blocked ? "75px" : "100%" }}>
              <TableBadge
                color={
                  info.getValue().status === UserStatus.blocked
                    ? "red"
                    : info.getValue().status === UserStatus.inactive
                    ? "orange"
                    : "green"
                }
                text={GlobalStrings.userAccountStatus[info.getValue().status]}
              />
            </div>

            {info.getValue().status === UserStatus.blocked && (
              <>
                <AiFillInfoCircle id={`tooltip-${info.getValue().id}`} />
                <UncontrolledTooltip target={`tooltip-${info.getValue().id}`} placement="right">
                  {info.getValue().reasonForBlocking}
                </UncontrolledTooltip>
              </>
            )}
          </div>
        );
      },
      header: () => <span>{GlobalStrings.personUsers.accountStatus}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),

    columnHelper.accessor((row) => row.numberOfTrips, {
      id: "numberOfTrips",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.personUsers.numberOfTrips}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor(
      (row) => {
        if (row.isBlockedForReservation) {
          return { text: GlobalStrings.userAccountStatus.blocked, ...row };
        }
        return { text: GlobalStrings.userAccountStatus.active, ...row };
      },
      {
        id: "isBlockedForReservation",
        cell: (info) => {
          return (
            <div className="edit_delete_container align-center">
              <TableBadge
                text={info.getValue().text}
                color={
                  info.getValue().text === GlobalStrings.userAccountStatus.blocked ? "red" : "green"
                }
              />
              {info.getValue().text === GlobalStrings.userAccountStatus.blocked && (
                <TableIcon
                  type="unlock"
                  onClickAction={() => {
                    setUserToUnlock({ ...info.getValue(), type: "reservation" });
                    toggleUnlockModal();
                  }}
                />
              )}
            </div>
          );
        },
        header: () => <span>{GlobalStrings.formLabels.reservationStatus}</span>,
        enableSorting: false,
        size: 1,
        minSize: 10,
      }
    ),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <Link
            to={adminRoutesPath.EndUsers.EndUserPayments.replace(
              ":userId",
              info.getValue().id.toString()
            )}
          >
            <TableIcon type="info" />
          </Link>
          {info.getValue().status === UserStatus.blocked ? (
            <TableIcon
              type="unlock"
              onClickAction={() => {
                setUserToUnlock(info.getValue());
                toggleUnlockModal();
              }}
            />
          ) : (
            <TableIcon
              type="lock"
              onClickAction={() => {
                toggleLockModal();
                setUserToBlock(info.getValue());
              }}
            />
          )}

          <TableIcon
            type="delete"
            onClickAction={() => {
              toggleDeleteModal();
              setUserToDelete(info.getValue());
            }}
          />
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
    }),
  ];

  const table = useReactTable({
    data: data?.endUsers,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount: data?.meta?.numberOfPages,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <section>
      <PageHeader title={GlobalStrings.personUsers.personUsersTitle} tabs={buttons} />
      <div className="d-flex justify-content-end">
        <div className="w-md-33 w-100 mb-3">
          <SearchBar
            searchTerm={searchTerm}
            onSearchChange={setSearchTerm}
            placeholder={GlobalStrings.personUsers.namePhoneOrEmail}
          />
        </div>
      </div>

      <TableComponent
        table={table}
        tableHasData={data?.endUsers?.length > 0}
        isLoading={isFetching}
      />

      <ConfirmationPopup
        modalIsOpen={deleteModalIsOpen}
        toggleModal={toggleDeleteModal}
        title={GlobalStrings.personUsers.deleteAccount}
        body={`${GlobalStrings.personUsers.deleteUser} - ${userToDelete?.lastName} ${userToDelete?.firstName}?`}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deleteUserHandler}
      />

      <ConfirmationPopup
        modalIsOpen={lockModalIsOpen}
        toggleModal={toggleLockModal}
        title={GlobalStrings.personUsers.blockAccount}
        body={GlobalStrings.personUsers.blockAccountReason}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={handleReasonAdd}
        setTextareaValue={setBlockReason}
        textareaValue={blockReason}
      />

      <ConfirmationPopup
        modalIsOpen={unlockModalIsOpen}
        toggleModal={toggleUnlockModal}
        title={GlobalStrings.personUsers.unlockAccount}
        body={`${GlobalStrings.personUsers.unlockUser} ${userToUnlock?.phone}?`}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={() => handleStatusChange(userToUnlock.id, userToUnlock.status)}
      />
    </section>
  );
};
export default EndUsers;
