import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import { useGetAllReportsPartnerQuery } from "app/reports/reportsApiSlice";

import { IsLoading, PageHeader, StatisticItem } from "components";
import moment from "moment";
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GlobalStrings } from "utils";

import MapTripsPartner from "./MapTripsPartner/MapTripsPartner";
import bookingIcon from "assets/icons/icon_booking.png";
import distanceIcon from "assets/icons/icon_distance.png";
import moneyIcon from "assets/icons/icon_money.png";
import tripIcon from "assets/icons/icon_trip.png";

const ReportsPartner = (props: {
  partnerId?: string;
  partnerCity?: string;
  hidePageHeader?: boolean;
}) => {
  const user = useSelector(selectCurrentUser);
  const [startDate, setStartDate] = useState(
    moment().clone().subtract(1, "weeks").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const { data, isFetching } = useGetAllReportsPartnerQuery({
    startDate,
    endDate,
    partnerId: props.partnerId || user.company.id,
  });

  const { restOfData, income, tripsPerHour } = data || {};

  if (isFetching) {
    return (
      <div>
        <IsLoading />
      </div>
    );
  }

  return (
    <section>
      {!props.hidePageHeader && <PageHeader title={`${GlobalStrings.partners.reportsPartners}`} />}

      <div className="mt-4">
        <div>
          <div className="d-flex flex-md-row flex-column align-items-center justify-content-end">
            <div className="d-flex align-items-center">
              <Input
                id="startDate"
                name="startDate"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                max={endDate}
              />

              <p className="px-3 mb-0">-</p>

              <Input
                id="endDate"
                name="endDate"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                max={moment().format("YYYY-MM-DD")}
                min={startDate}
              />
            </div>
          </div>
        </div>
        <div className="stats d-flex align-items-stretch justify-content-between gap-3 flex-column flex-md-row mt-3">
          <StatisticItem
            count={restOfData?.averageAmountBetweenCharges}
            title={GlobalStrings.reports.averageAmountBetweenCharges}
            imageURL={moneyIcon}
          />
          <StatisticItem
            count={restOfData?.averageAmountPerVehicle}
            title={GlobalStrings.reports.averageAmountPerVehicle}
            imageURL={moneyIcon}
          />
          <StatisticItem
            count={restOfData?.averageDistnaceBetweenCharges}
            title={GlobalStrings.reports.averageDistnaceBetweenCharges}
            imageURL={distanceIcon}
          />
        </div>
        <div className="stats d-flex align-items-stretch justify-content-between gap-3 flex-column flex-md-row mt-3">
          <StatisticItem
            count={restOfData?.averageNumberOfBookings}
            title={GlobalStrings.reports.averageNumberOfBookings}
            imageURL={bookingIcon}
          />
          <StatisticItem
            count={restOfData?.averageOfNumberOfTripsBetweenCharges}
            title={GlobalStrings.reports.averageOfNumberOfTripsBetweenCharges}
            imageURL={tripIcon}
          />
          <StatisticItem
            count={restOfData?.total}
            title={GlobalStrings.reports.total}
            imageURL={moneyIcon}
          />
        </div>

        <div className="d-flex flex-md-row gap-5 mt-4 card">
          <div className="flex-column w-100">
            <h4 className="text-center mb-5 mt-2">{GlobalStrings.partners.earningsMadeInSmarmy}</h4>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={income} className="w-100">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="value"
                  name={GlobalStrings.analytics.earnings}
                  stroke="#dc2949"
                  activeDot={{ r: 8 }}
                />
                <Brush dataKey="value" height={20} stroke="#dc2949" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="flex-column w-100">
            <h4 className="text-center mb-5 mt-2">
              {GlobalStrings.partners.totalTripsNumberPerTime}
            </h4>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={tripsPerHour} className="w-100">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="count"
                  name={GlobalStrings.analytics.trips}
                  stroke="#0088cc"
                  activeDot={{ r: 8 }}
                />
                <Brush dataKey="count" height={20} stroke="#0088cc" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="card mt-4">
        <MapTripsPartner
          startDate={startDate}
          endDate={endDate}
          partnerId={props.partnerId || user.company.id}
          partnerCity={props.partnerCity}
        />
      </div>
    </section>
  );
};

export default ReportsPartner;
