import React from "react";
import { FieldErrors } from "react-hook-form";
import { Alert } from "reactstrap";

import { returnFirstErrorMessage } from "utils";
import { I_SERVER_MESSAGE } from "utils/types";

const AlertComponent = (props: { serverMessage: I_SERVER_MESSAGE; errors: FieldErrors<any> }) => {
  return (
    <Alert
      color={props.serverMessage?.type || "danger"}
      fade
      isOpen={Object.keys(props.errors)?.length > 0 || props.serverMessage.text !== ""}
      className="mt-3"
    >
      <p style={{ height: "fit-content" }} className="mb-0">
        {returnFirstErrorMessage(props.errors) || props.serverMessage.text}
      </p>
    </Alert>
  );
};

export default AlertComponent;
