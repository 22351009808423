import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap";

import { useCreatePublicTransportPartenersMutation } from "app/publicTransportParteners/publicTransportPartnersApiSlice";

import { CitiesSelect, PageHeader } from "components";
import { GlobalVariables, Regex } from "utils";
import { errorHandler, returnFirstErrorMessage } from "utils/globalFunctions";
import GlobalStrings from "utils/globalStrings";
import { I_FORM_DATA_PUBLIC_TRANSPORT_PARTNER } from "utils/types";

import { adminRoutesPath } from "../routes";

const AddPublicTransportPartners = () => {
  const [createPublicTransport] = useCreatePublicTransportPartenersMutation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<I_FORM_DATA_PUBLIC_TRANSPORT_PARTNER>({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      city: "",
      publicTransportVehicles: "",
      address: "",
      type: "",
    },
  });

  const navigate = useNavigate();

  const onSubmit = async (data: I_FORM_DATA_PUBLIC_TRANSPORT_PARTNER) => {
    try {
      const newPartnerResponse = await createPublicTransport({
        name: data.name,
        phone: data.phone,
        email: data.email,
        city: data.city.value,
        publicTransportVehicles: data.publicTransportVehicles,
        address: data.address,
        type: data.type,
      }).unwrap();

      toast.success(GlobalStrings.PublicTransportParteners.publicTransportPartnerWasSaved);

      const newPartnerId = newPartnerResponse.data.id.toString();

      navigate(adminRoutesPath.PublicTransport.Edit.replace(":id", newPartnerId));
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <section className="d-flex flex-column gap-5">
      <PageHeader
        title={`${GlobalStrings.PublicTransportParteners.pageTitle}`}
        backRoute="/public-transport-partners"
      />
      <div className="card">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-100">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.companyName}
                    type="text"
                  />
                )}
              />
              <Label for="name">{GlobalStrings.formLabels.companyName}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="address"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.address}
                    type="text"
                  />
                )}
              />

              <Label for="type">{GlobalStrings.formLabels.address}</Label>
            </FormGroup>
          </div>

          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-100">
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  pattern: {
                    value: Regex.phone,
                    message: GlobalStrings.formValidation.invalidPhone,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.phoneNumber}
                    type="text"
                  />
                )}
              />
              <Label for="phone">{GlobalStrings.formLabels.phoneNumber}</Label>
            </FormGroup>
            <FormGroup floating className="w-100">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  pattern: {
                    value: Regex.email,
                    message: GlobalStrings.formValidation.invalidEmail,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.email}
                    type="text"
                  />
                )}
              />
              <Label for="email">{GlobalStrings.formLabels.email}</Label>
            </FormGroup>
          </div>

          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-100">
              <CitiesSelect control={control} name="city" />
            </FormGroup>
            <FormGroup floating className="w-100">
              <Controller
                name="publicTransportVehicles"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.publicTransportVehicles}
                    type="select"
                  >
                    <option value="">-</option>
                    {GlobalVariables.publicTransportVehicles.map((item: any) => {
                      return (
                        <option key={item.id} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </Input>
                )}
              />
              <Label for="publicTransportVehicles">
                {GlobalStrings.formLabels.publicTransportVehicle}
              </Label>
            </FormGroup>
          </div>

          <Button color="success" type="submit" className="d-flex align-item-center gap-2 mt-3">
            {GlobalStrings.formLabels.add}
          </Button>

          <Alert
            color="danger"
            fade={true}
            isOpen={Object.keys(errors).length > 0}
            className="mt-3"
          >
            {returnFirstErrorMessage(errors) || GlobalStrings.formValidation.fieldsRequired}
          </Alert>
        </Form>
      </div>
    </section>
  );
};

export default AddPublicTransportPartners;
