import React from "react";
import { SyncLoader } from "react-spinners";

import { GlobalVariables } from "utils";

const IsLoading = () => {
  return (
    <div className="d-flex align-items-center justify-content-center p-5">
      <SyncLoader color={GlobalVariables.blackColor} margin={10} size={20} />
    </div>
  );
};

export default IsLoading;
