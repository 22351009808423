import React, { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import {
  I_CHARGED_VEHICLES,
  useGetChargedVehiclesQuery,
  useRefundBookingMutation,
} from "app/chargedVehicles/chargedVehiclesApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { TableBadge, TableComponent, TableIcon } from "components";
import moment from "moment";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { BookingStatus, Currency } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

import styles from "../EndUser.module.scss";

const ChargeVehicles = () => {
  const { userId } = useParams();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const [sorting, setSorting] = useState<SortingState>([]);
  const [refundModal, setRefundModal] = useState(false);
  const [paymentsModal, setPaymentsModal] = useState(false);
  const togglePaymentsModal = () => setPaymentsModal((prev) => !prev);
  const user = useSelector(selectCurrentUser);
  const [chargedVehicleToRefund, setChargedVehicleToRefund] = useState<I_CHARGED_VEHICLES>();
  const toggleRefundModal = () => setRefundModal((prev) => !prev);
  const [refundBooking] = useRefundBookingMutation();

  const { data, isFetching, error, refetch } = useGetChargedVehiclesQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
    userId,
  });

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  const columnHelper = createColumnHelper<I_CHARGED_VEHICLES>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.partners.ID}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.bookType, {
      id: "bookType",
      cell: (info) => <TableBadge text={GlobalStrings.bookingType[info.getValue()]} color="gray" />,
      header: () => <span>{GlobalStrings.formLabels.bookType}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.startDate, {
      id: "startDate",
      cell: (info) =>
        info.getValue() !== NO_VALUE_SYMBOL ? (
          <TableBadge text={moment(info.getValue()).format("DD.MM.YYYY, HH:mm:ss")} color="green" />
        ) : (
          info.getValue()
        ),
      header: () => <span>{GlobalStrings.partners.startHour}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.endDate, {
      id: "endDate",
      cell: (info) =>
        info.getValue() !== NO_VALUE_SYMBOL ? (
          <TableBadge text={moment(info.getValue()).format("DD.MM.YYYY, HH:mm:ss")} color="red" />
        ) : (
          info.getValue()
        ),
      header: () => <span>{GlobalStrings.partners.finishHour}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.amount, {
      id: "amount",
      cell: (info) => (
        <p>
          {isNaN(Number(info.getValue())) ? (
            info.getValue()
          ) : (
            <>
              {info.getValue()} {Currency.RON}
            </>
          )}
        </p>
      ),
      header: () => <span>{GlobalStrings.partners.invoiceAmount}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor(
      (row) => {
        return isNaN(row.durationInMinutes)
          ? row.durationInMinutes
          : `${row.durationInMinutes} ${GlobalStrings.formLabels.minutes}`;
      },
      {
        id: "duration",
        cell: (info) => <p>{info.getValue()}</p>,
        header: () => <span>{GlobalStrings.partners.duration}</span>,
        enableSorting: false,
      }
    ),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => (
        <TableBadge
          text={GlobalStrings.bookingStatus[info.getValue()]}
          color={
            info.getValue() === BookingStatus.ACTIVE || info.getValue() === BookingStatus.CHARGING
              ? "green"
              : info.getValue() === BookingStatus.FINISHED
              ? "orange"
              : "red"
          }
        />
      ),
      header: () => <span>{GlobalStrings.formLabels.status}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 7,
    }),
    columnHelper.accessor((row) => row, {
      id: "vehicle",
      cell: (info) => info.getValue().vehicleInfo?.model,
      header: () => <span>{GlobalStrings.partners.vehicleType}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "payment",
      cell: (info) => (
        <div className="edit_delete_container">
          <TableIcon
            type="payment"
            onClickAction={() => {
              setPaymentsModal(true);
              setChargedVehicleToRefund(info.getValue());
            }}
          />
        </div>
      ),
      header: () => <span>{GlobalStrings.partners.payments}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "chargedVehicleRefund",
      cell: (info) => (
        <div className="d-flex justify-content-center gap-2">
          <Button
            className="btn btn-primary"
            size="sm"
            disabled={info.getValue().refunded === true || info.getValue().amount === 0}
            onClick={() => {
              setChargedVehicleToRefund(info.getValue());
              setRefundModal(true);
            }}
          >
            {GlobalStrings.formLabels.refund}
          </Button>
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    data: data?.chargedVehicles || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount: data?.meta?.numberOfPages,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  const refundChargedVehicleHandler = async () => {
    if (chargedVehicleToRefund) {
      try {
        await refundBooking(chargedVehicleToRefund?.id).unwrap();
        toast.success(GlobalStrings.containersAndStations.refundCompleted);
        setChargedVehicleToRefund(undefined);
        setRefundModal(false);
        refetch();
      } catch (error) {
        errorHandler(error, false);
      }
    }
  };

  return (
    <div className={styles.payment}>
      <TableComponent
        table={table}
        tableHasData={data?.chargedVehicles?.length ? data?.chargedVehicles?.length > 0 : false}
        isLoading={isFetching}
      />
      <Modal isOpen={refundModal} toggle={toggleRefundModal} centered>
        <ModalHeader toggle={toggleRefundModal}>
          {GlobalStrings.trips.refundChargingModalHeader}
        </ModalHeader>
        <ModalBody>
          <p>
            {GlobalStrings.formLabels.ID}: {chargedVehicleToRefund?.id}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => refundChargedVehicleHandler()}>
            {GlobalStrings.yes}
          </Button>
          <Button color="secondary" onClick={toggleRefundModal}>
            {GlobalStrings.cancel}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={paymentsModal} toggle={togglePaymentsModal} centered>
        <ModalHeader toggle={togglePaymentsModal}>
          {GlobalStrings.trips.paymentsModalHeader} | {GlobalStrings.partners.reservationID}:{" "}
          {chargedVehicleToRefund?.id}
        </ModalHeader>
        <ModalBody>
          <section className={classNames("table_container", user?.role)}>
            <table className="styled_table">
              <thead>
                <tr>
                  <th>{GlobalStrings.formLabels.ID}</th>
                  <th>{GlobalStrings.formLabels.date}</th>
                  <th>{GlobalStrings.formLabels.panMask}</th>
                  <th>{GlobalStrings.formLabels.paymentStatus}</th>
                  <th>{GlobalStrings.formLabels.totalPaid}</th>
                </tr>
              </thead>
              <tbody>
                {chargedVehicleToRefund?.payments?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>
                        <Moment date={item.createdAt} format="DD-MM-YYYY, HH:mm:ss" />
                      </td>
                      <td>{item.panMask}</td>
                      <td>{item.status}</td>
                      <td>
                        {parseFloat(item?.amount).toFixed(2)} {Currency.RON}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePaymentsModal}>
            {GlobalStrings.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default ChargeVehicles;
