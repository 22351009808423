import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { selectCurrentToken } from "app/auth/authSlice";
import { useGetInvoicesQuery } from "app/invoices/invoicesApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import { TableComponent, TableIcon } from "components";
import { errorHandler, getSortDetails, GlobalStrings, GlobalVariables } from "utils";
import { Currency } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

export type Invoice = {
  id: string | number;
  firstName?: string;
  lastName?: string;
  CIF?: string;
  name?: string;
  email: string;
  phone: string;
  currency: Currency;
  amount: number;
  tripId: number;
  containerBookingId: number;
  paymentId: number;
};

const InvoicesList = () => {
  const token = useSelector(selectCurrentToken);
  const location = useLocation();
  const type = location.pathname.split("/")[2];
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const columnHelper = createColumnHelper<Invoice>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { data, isFetching, error } = useGetInvoicesQuery(
    {
      pageIndex,
      pageSize,
      sort: getSortDetails(sorting[0]),
      type,
    },
    { skip: !type }
  );

  useEffect(() => {
    errorHandler(error);
  }, [error]);

  useEffect(() => {
    setPagination((prev) => {
      return { pageIndex: 0, pageSize: prev.pageSize };
    });
  }, [location.pathname]);

  const downloadInvoiceHandler = async (invoice: Invoice) => {
    try {
      let url = "";
      if (invoice?.tripId) {
        url = `${process.env.REACT_APP_PAYMENT_API_URL}/invoices/pdf-invoice/generate?tripId=${invoice.tripId}`;
      } else if (invoice?.containerBookingId) {
        url = `${process.env.REACT_APP_PAYMENT_API_URL}/invoices/pdf-invoice/generate?containerBookingId=${invoice.containerBookingId}`;
      } else if (invoice?.paymentId) {
        url = `${process.env.REACT_APP_PAYMENT_API_URL}/invoices/pdf-invoice/generate?walletPaymentId=${invoice.paymentId}`;
      }

      if (!url) {
        toast.error(GlobalStrings.invoices.invoiceDataErrorMessage);
        return;
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      if (type === "user") {
        link.download = `Invoice_${invoice.id}_${invoice.firstName}_${invoice.lastName}.pdf`;
      } else {
        link.download = `Invoice_${invoice.id}_${invoice.name}.pdf`;
      }

      link.click();
    } catch (error: any) {
      toast.error(GlobalStrings.invoices.errorMessage);
    }
  };

  const columns = [
    columnHelper.accessor((row) => row, {
      id: type === "user" ? "firstName" : "name",
      cell: (info) => <p>{type === "user" ? info.getValue().firstName : info.getValue().name}</p>,
      header: () => (
        <span>
          {type === "user"
            ? GlobalStrings.formLabels.firstName
            : GlobalStrings.formLabels.companyName}
        </span>
      ),
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row, {
      id: type === "user" ? "lastName" : "CIF",
      cell: (info) => <p>{type === "user" ? info.getValue().lastName : info.getValue().CIF}</p>,
      header: () => (
        <span>
          {type === "user" ? GlobalStrings.formLabels.lastName : GlobalStrings.formLabels.CIF}
        </span>
      ),
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row.email, {
      id: "email",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.email}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 17,
    }),
    columnHelper.accessor((row) => row.phone, {
      id: "phone",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.phoneNumber}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row, {
      id: "type",
      cell: (info) => (
        <p>{info.getValue().amount + " " + GlobalVariables.currency[info.getValue().currency]}</p>
      ),
      header: () => <span>{GlobalStrings.invoices.amount}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 13,
    }),
    columnHelper.accessor(
      (row) => {
        if (row.tripId) return GlobalStrings.invoices.trip;
        if (row.containerBookingId) return GlobalStrings.invoices.booking;
        return NO_VALUE_SYMBOL;
      },
      {
        id: "amount",
        cell: (info) => <p>{info.getValue()}</p>,
        header: () => <span>{GlobalStrings.invoices.type}</span>,
        enableSorting: false,
        size: 1,
        minSize: 13,
      }
    ),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <TableIcon
            type="download"
            onClickAction={() => downloadInvoiceHandler(info.getValue())}
          />
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
    }),
  ];

  const table = useReactTable({
    data: data?.invoices || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <>
      <TableComponent
        table={table}
        tableHasData={data?.invoices?.length > 0}
        isLoading={isFetching}
      />
    </>
  );
};
export default InvoicesList;
