import React from "react";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "app/auth/authSlice";

import { PageHeader } from "components";
import { GlobalStrings } from "utils";

import ProductsTable from "./ProductsTable/ProductsTable";

import { supplierRoutesPath } from "../routes";

const Products = () => {
  const user = useSelector(selectCurrentUser);

  const tabs = [
    {
      id: 0,
      name: GlobalStrings.marketplace.addProduct,
      slug: supplierRoutesPath.Products.AddProduct,
    },
  ];
  return (
    <section>
      <PageHeader title={GlobalStrings.menuProviders.activeProducts} tabs={tabs} />
      <ProductsTable id={user.company.id} />
    </section>
  );
};

export default Products;
