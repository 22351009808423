import { apiSlice } from "app/apiSlice";

import { I_INCIDENT } from "utils/types";

export interface IUpdateWalletResponse {
  success: boolean;
  message: string;
}

export const incidentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateWallet: builder.mutation<IUpdateWalletResponse, I_INCIDENT>({
      query: ({ userId, amount, description }) => ({
        url: `${process.env.REACT_APP_API_URL}/users/incidents/resolve/${userId}`,
        method: "POST",
        body: {
          amount,
          description,
        },
      }),
    }),
  }),
});

export const { useUpdateWalletMutation } = incidentsApiSlice;
