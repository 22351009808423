import React, { useEffect, useState } from "react";
import Select from "react-select";

import { Table } from "@tanstack/react-table";

import { GlobalStrings, GlobalVariables } from "../../utils";

const TablePagination = ({ table }: { table: Table<any> }) => {
  const [pages, setPages] = useState<{ value: number; label: number }[]>([]);

  useEffect(() => {
    const pageNumbers: any = [];
    for (let i = 1; i <= table.getPageCount(); i++) pageNumbers.push({ value: i, label: i });
    setPages(pageNumbers);
  }, [table.getPageCount()]);

  return (
    <div className="table_pagination">
      <div className="d-flex gap-2 align-items-center">
        <span>{GlobalStrings.pagination.page}</span>
        <strong>
          {table.getState().pagination.pageIndex + 1} {GlobalStrings.pagination.of}{" "}
          {table.getPageCount()}
        </strong>
      </div>
      <div className="d-flex">
        <div className="d-flex">
          <button
            className="btn btn-pagination"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className="btn btn-pagination"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
        </div>
        <Select
          menuPlacement="top"
          className="page_number"
          options={pages}
          value={{
            value: table.getState().pagination.pageIndex + 1,
            label: table.getState().pagination.pageIndex + 1,
          }}
          captureMenuScroll
          onChange={(newValue) => table.setPageIndex(Number(newValue?.value) - 1)}
          isSearchable={false}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />

        <div className="d-flex">
          <button
            className="btn btn-pagination"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>

          <button
            className="btn btn-pagination"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
        </div>

        <Select
          className="page_number"
          menuPlacement="top"
          options={GlobalVariables.paginationRows}
          captureMenuScroll
          value={{
            value: table.getState().pagination.pageSize,
            label: table.getState().pagination.pageSize,
          }}
          onChange={(newValue) => table.setPageSize(Number(newValue?.value))}
          isSearchable={false}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </div>
    </div>
  );
};

export default TablePagination;
