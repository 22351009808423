import React, { useEffect, useState } from "react";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { useGetTripsQuery } from "app/trips/tripsApiSlice";
import { useWebSocket } from "app/websocket/WebSocketContext";

import { IsLoading, PageHeader } from "../../../components";
import { GlobalStrings, GlobalVariables } from "../../../utils";
import AiControllerMap from "./AiControllerMap/AiControllerMap";

import styles from "./AiController.module.scss";

const colors = ["#0000ff", "#000", "#00aaff", "#525207", "#1a542e", "#07423c", "#a15f08"];

export interface AIVehicleLocation {
  imei: string;
  location: [number, number];
  speed: number;
  speedLimit: number;
  segmentStart: {
    lat: number;
    lon: number
  };
  segmentEnd: {
    lat: number;
    lon: number
  }
}


const AiController = () => {
  const socket = useWebSocket();

  const [filteredData, setFilteredData] = useState([]);
  const [decodedPolylines, setDecodedPolylines] = useState<any>([]);

  const { data, isFetching } = useGetTripsQuery({
    pageIndex: 0,
    pageSize: 200,
  });

  useEffect(() => {
    if (data?.trips) {
      setFilteredData(data?.trips?.filter((item: any) => item.path && item.type === "car"));
    }
  }, [data]);

  // const [currentLocation, setCurrentLocation] = useState<[number, number] | null>(null);
  const [vehicleLocations, setVehicleLocations] = useState<AIVehicleLocation[]>([]);


  useEffect(() => {
    console.log("New location received:", socket?.data);
    if (socket?.data) {
      const newLocation: AIVehicleLocation = {
        imei: socket.data.imei,
        location: [socket.data.latitude, socket.data.longitude],
        speed: socket.data.speed,
        speedLimit: socket.data.speedLimit,
        segmentStart: {
          lat: socket.data.segmentStart.lat,
          lon: socket.data.segmentStart.lon
        },
        segmentEnd: {
          lat: socket.data.segmentEnd.lat,
          lon: socket.data.segmentEnd.lon
        }
      };
      setVehicleLocations(prevLocations => {
        const otherVehicles = prevLocations.filter(v => v.imei !== newLocation.imei);
        return [...otherVehicles, newLocation];
      });
    }
  }, [socket?.data]);

  return (
    <section>
      <PageHeader title={GlobalStrings.aiController.aiController} />

      <div className={styles.aiControllerContainer}>
        <div className="card">
          <AiControllerMap
            liveLocation={vehicleLocations}
            socketData={socket?.data}
            encodedPolylines={decodedPolylines}
          />
          <div className={styles.sideBar}>
            <div className={styles.sideBarHeader}>
              <p>Status conexiune: {socket?.isConnected ? "Conectat" : "Deconectat"}</p>
              {socket?.data && <p>Ultimul mesaj: {JSON.stringify(socket.data)}</p>}
              {socket?.error && <p>Error: {socket.error.message}</p>}
              <div className={styles.sideBarSeparator}></div>
            </div>
            <div>
              <p>
                <strong>Curse cu mașina</strong>{" "}
              </p>
              {isFetching && <IsLoading />}
              {filteredData?.map((item: any, index: number) => {
                return (
                  <div key={index} className={styles.item}>
                    <p>ID cursa: {item?.id}</p>
                    <div className={styles.icon}>
                      <div className="edit_delete_container">
                        {decodedPolylines?.includes(item.path?.replace(/\\\\/g, "\\")) ? (
                          <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                            <div
                              style={{
                                width: 10,
                                height: 10,
                                backgroundColor:
                                  colors[
                                    decodedPolylines.indexOf(item.path?.replace(/\\\\/g, "\\")) % 7
                                  ],
                                borderRadius: "50%",
                              }}
                            />
                            <AiOutlineEyeInvisible
                              className="cursor-pointer"
                              size="20"
                              onClick={() => {
                                setDecodedPolylines((prev: any) =>
                                  prev?.filter(
                                    (path: any) => path !== item.path?.replace(/\\\\/g, "\\")
                                  )
                                );
                              }}
                            />
                          </div>
                        ) : (
                          <AiFillEye
                            className="cursor-pointer"
                            size="20"
                            color={GlobalVariables.redColor}
                            onClick={() =>
                              setDecodedPolylines((prev: any) => [
                                ...prev,
                                item.path?.replace(/\\\\/g, "\\"),
                              ])
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

AiController.displayName = "AiController";

export default AiController;
