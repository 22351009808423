import React from "react";

import { PageHeader } from "components";
import { GlobalStrings } from "utils";

import AddJuicersButtonModal from "views/common/AddJuicersButtonModal/AddJuicersButtonModal";
import JuicersListing from "views/common/JuicersListing/JuicersListing";
import JuicersServicesListing from "views/common/JuicersServicesListing/JuicersServicesListing";

const Juicers = () => {
  return (
    <section>
      <div className="card ">
        <PageHeader title={GlobalStrings.activeServices.juicersTitle} />
        <AddJuicersButtonModal />
        <JuicersServicesListing />
        <JuicersListing />
      </div>
    </section>
  );
};
export default Juicers;
