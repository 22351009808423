import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { PageHeader } from "components";
import { GlobalStrings } from "utils";

import ServicesListing from "./ServicesListing/ServicesListing";

import { partnerRoutesPath } from "../routes";

const ServicesPartners = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const ServicesTabs = [
    {
      id: 0,
      name: GlobalStrings.servicesPartners.electricCars,
      slug: partnerRoutesPath.Services.Cars,
    },
    {
      id: 1,
      name: GlobalStrings.servicesPartners.electricScooters,
      slug: partnerRoutesPath.Services.Scooters,
    },
    {
      id: 2,
      name: GlobalStrings.servicesPartners.electricBicycles,
      slug: partnerRoutesPath.Services.Bicycles,
    },
  ];

  const ServicesScooters = [
    {
      id: 0,
      name: GlobalStrings.servicesPartners.juicers,
      slug: partnerRoutesPath.Services.Juicers,
    },
  ];

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes(ServicesTabs[0]?.slug)) {
      setActiveIndex(0);
    } else if (location.pathname.includes(ServicesTabs[1]?.slug)) {
      setActiveIndex(1);
    } else if (location.pathname.includes(ServicesTabs[2]?.slug)) {
      setActiveIndex(2);
    }
  }, [location.pathname]);

  return (
    <section>
      <PageHeader
        title={GlobalStrings.servicesSuppliers.serviceSupplierManagement}
        tabs={ServicesScooters}
        tabsCurrentview={ServicesTabs}
        tabsCurrentviewActiveIndex={activeIndex}
      />
      <ServicesListing
        vehicleType={activeIndex === 0 ? "car" : activeIndex === 1 ? "scooter" : "bicycle"}
      />
    </section>
  );
};

export default ServicesPartners;
