import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { InvoicesList, PageHeader } from "components";
import { GlobalStrings } from "utils";

import { adminRoutesPath } from "../routes";

const Invoices = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  const invoicesTabs = [
    {
      id: 0,
      name: GlobalStrings.invoices.users,
      slug: adminRoutesPath.Invoices.EndUsers,
    },
    {
      id: 1,
      name: GlobalStrings.invoices.partners,
      slug: adminRoutesPath.Invoices.Partners,
    },
    {
      id: 2,
      name: GlobalStrings.invoices.supplier,
      slug: adminRoutesPath.Invoices.Suppliers,
    },
  ];

  useEffect(() => {
    if (location.pathname.includes(invoicesTabs[0].slug)) {
      setActiveIndex(0);
    } else if (location.pathname.includes(invoicesTabs[1].slug)) {
      setActiveIndex(1);
    } else if (location.pathname.includes(invoicesTabs[2].slug)) {
      setActiveIndex(2);
    }
  }, [location.pathname]);
  return (
    <section>
      <PageHeader
        title={GlobalStrings.invoices.invoicesTitle}
        tabsCurrentview={invoicesTabs}
        tabsCurrentviewActiveIndex={activeIndex}
      />
      <InvoicesList />
    </section>
  );
};
export default Invoices;
