import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";

import { I_STATION, useDeleteStationMutation } from "app/containersAndStations/stationsApiSlice";

import classNames from "classnames";
import { ConfirmationPopup, NoResults, PageHeader, TableIcon } from "components";
import { errorHandler, GlobalStrings } from "utils";
import { UserRoles } from "utils/enums";
import { I_ELECTRICITY_CONTAINER } from "utils/types";

import { adminRoutesPath } from "views/admin/routes";

import AddStation from "./AddStation";

const Stations = (props: { data: I_ELECTRICITY_CONTAINER; refetch: Function }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [stationToDelete, setStationToDelete] = useState<I_STATION>();
  const [deletStation] = useDeleteStationMutation();

  const toggle = () => setIsOpen((prev) => !prev);
  const toggleDeleteModal = () => setIsOpenDeleteModal((prev) => !prev);

  const deletStationHandler = async () => {
    if (stationToDelete) {
      try {
        await deletStation(stationToDelete.id).unwrap();
        toggleDeleteModal();
        props.refetch();
        toast.success(GlobalStrings.containersAndStations.stationDeleted);
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  return (
    <section>
      <PageHeader title={GlobalStrings.formLabels.stations} />
      <Button className="btn btn-success right mb-2" onClick={toggle}>
        {GlobalStrings.add}
      </Button>

      <section className={classNames("table_container", UserRoles.charging_station_partner_admin)}>
        <table className="styled_table">
          <thead>
            <tr>
              <th>{GlobalStrings.formLabels.company}</th>
              <th>{GlobalStrings.formLabels.status}</th>
              <th>{GlobalStrings.formLabels.power}</th>
              <th>{GlobalStrings.formLabels.connectors}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.data?.stations?.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.companyName}</td>
                  <td>{GlobalStrings.stationsStatus?.[item.status]}</td>
                  <td>{item.power}</td>
                  <td>{item.connectors?.length}</td>
                  <td>
                    <div className="edit_delete_container">
                      <Link
                        to={adminRoutesPath.ContainersAndStations.EditStation.replace(
                          ":id",
                          item.id.toString()
                        )}
                      >
                        <TableIcon type="edit" />
                      </Link>

                      <TableIcon
                        key={item.id + "delete"}
                        type="delete"
                        onClickAction={() => {
                          toggleDeleteModal();
                          setStationToDelete(item);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {!props?.data?.stations?.length && <NoResults />}
      </section>

      <AddStation isOpen={isOpen} toggle={toggle} container={props.data} refetch={props.refetch} />

      <ConfirmationPopup
        modalIsOpen={isOpenDeleteModal}
        toggleModal={toggleDeleteModal}
        title={GlobalStrings.containersAndStations.deleteStationModalTitle}
        body={GlobalStrings.containersAndStations.deleteStationModalBody}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deletStationHandler}
      />
    </section>
  );
};

export default Stations;
