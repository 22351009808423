import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import { useUpdateWalletMutation } from "app/incidents/incidentsApiSlice";

import { AlertComponent, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { errorHandler, GlobalStrings } from "utils";
import { I_INCIDENT } from "utils/types";

const Incidents = ({ refetch }: { refetch: Function }) => {
  const { userId } = useParams();
  const userIdNumber = Number(userId);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      userId: userIdNumber,
      amount: "",
      description: "",
    },
  });

  const [updateWallet, { isLoading }] = useUpdateWalletMutation();
  const { serverMessage, setServerMessage } = useServerMessage();

  const clearFormHandler = () => {
    reset();
  };

  const onSubmit = async ({ userId, amount, description }: I_INCIDENT) => {
    if (amount !== "" && amount !== "0") {
      try {
        await updateWallet({ userId, amount, description }).unwrap();
        setServerMessage({
          type: "success",
          text: GlobalStrings.incidents.walletUpdated,
        });
        clearFormHandler();
        refetch();
      } catch (error: any) {
        errorHandler(error, true, setServerMessage, GlobalStrings.server);
      }
    }
  };

  return (
    <section className="d-flex flex-column gap-5 justify-content-center">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex gap-2 flex-column flex-md-column justify-content-center align-items-center">
          <p>{GlobalStrings.incidents.adjustAmount}</p>
          <p className="text-muted small">{GlobalStrings.incidents.negativeBalance}</p>
        </div>
        <div className="d-flex gap-2 flex-column align-items-center">
          <FormGroup floating className="col-12 col-md-3 mt-3">
            <Controller
              name="amount"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.invalidNumber,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input {...field} invalid={invalid} placeholder="null" type="number" />
              )}
            />
            <Label for="amount">{GlobalStrings.incidents.addAmount}</Label>
          </FormGroup>

          <FormGroup floating className="col-12 col-md-7 mt-3">
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.incidents.textNotification}
                  type="textarea"
                  style={{ height: "200px" }}
                />
              )}
            />
            <Label for="lastName">{GlobalStrings.incidents.textNotification}</Label>
          </FormGroup>
        </div>
        <div className="mt-1 d-flex  flex-column flex-md-row justify-content-around align-items-center">
          <SubmitButton color="primary" isLoading={isLoading} label="Trimite" />
          <Button color="secondary" onClick={() => clearFormHandler()}>
            {GlobalStrings.incidents.cancel}
          </Button>
        </div>
      </Form>

      <AlertComponent errors={errors} serverMessage={serverMessage} />
    </section>
  );
};

export default Incidents;
