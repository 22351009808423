import React from "react";

import classNames from "classnames";

import styles from "./Battery.module.scss";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

const Battery = (props: { level: number; className?: string }) => {
  const batteryStatus =
    props.level < 10
      ? "alert"
      : props.level < 60
      ? "warn"
      : props.level < 90
      ? "more_than_half"
      : "full";
  return (
    <div className={classNames(styles.battery, props.className)}>
      <div
        className={classNames(styles["battery-level"], styles[batteryStatus])}
        style={{ width: props.level + "%" }}
      ></div>
      <div className={classNames(styles.battery_label, styles[batteryStatus])}>
        {!isNaN(props.level) ? props.level + "%" : NO_VALUE_SYMBOL}
      </div>
    </div>
  );
};

export default Battery;
