import { GlobalStrings } from "utils";
import { AddSupplierUserForm, RequestsOffers } from "views/common";
import {
  AddEditProduct,
  AddEditSupplierService,
  DeclinedProducts,
  Juicer,
  Products,
  SuppliersAccounts,
  SupplierServices,
} from "views/supplier";

export const supplierRoutesPath = {
  Products: {
    Index: "/products",
    AddProduct: "/products/add",
    Product: "/products/:productId/edit",
  },
  Services: {
    Index: "/services",
    AddService: "/services/add",
    Service: "/services/:serviceId/edit",
    VehiclesMap: "/services/vehicles/map",
    VehiclesCharges: "/services/vehicles/charges",
  },
  RequestOffers: "/requests-offers",
  DeclinedProducts: "/declined-products",
  Accounts: {
    Index: "/accounts",
    CreateAccount: "/accounts/:supplierId/add",
  },
};

export const supplierRoutes = [
  {
    path: supplierRoutesPath.Products.Index,
    Component: Products,
    metaTitle: GlobalStrings.SEO.supplier.products,
  },
  {
    path: supplierRoutesPath.Products.AddProduct,
    Component: AddEditProduct,
    metaTitle: GlobalStrings.SEO.supplier.addProduct,
  },
  {
    path: supplierRoutesPath.Products.Product,
    Component: AddEditProduct,
    metaTitle: GlobalStrings.SEO.supplier.editProduct,
  },
  {
    path: supplierRoutesPath.Services.Index,
    Component: SupplierServices,
    metaTitle: GlobalStrings.SEO.supplier.services,
  },
  {
    path: supplierRoutesPath.Services.AddService,
    Component: AddEditSupplierService,
    metaTitle: GlobalStrings.SEO.supplier.addService,
  },
  {
    path: supplierRoutesPath.Services.Service,
    Component: AddEditSupplierService,
    metaTitle: GlobalStrings.SEO.supplier.editService,
  },
  {
    path: supplierRoutesPath.Services.VehiclesMap,
    Component: Juicer,
    metaTitle: GlobalStrings.SEO.supplier.vehiclesMap,
  },
  {
    path: supplierRoutesPath.Services.VehiclesCharges,
    Component: Juicer,
    metaTitle: GlobalStrings.SEO.supplier.vehiclesMap,
  },
  {
    path: supplierRoutesPath.RequestOffers,
    Component: RequestsOffers,
    metaTitle: GlobalStrings.SEO.supplier.requestsOffers,
  },
  {
    path: supplierRoutesPath.DeclinedProducts,
    Component: DeclinedProducts,
    metaTitle: GlobalStrings.SEO.supplier.declinedProducts,
  },
  {
    path: supplierRoutesPath.Accounts.Index,
    Component: SuppliersAccounts,
    metaTitle: GlobalStrings.SEO.supplier.accounts,
  },
  {
    path: supplierRoutesPath.Accounts.CreateAccount,
    Component: AddSupplierUserForm,
    metaTitle: GlobalStrings.SEO.supplier.addAccount,
  },
];
