import * as React from "react";

import classNames from "classnames";

import styles from "./StatisticItem.module.scss";

const StatisticItem = (props: {
  title: string;
  count: number | undefined;
  icon?: React.ReactElement;
  imageURL?: string;
}) => {
  return (
    <div className={classNames(styles.statistic_item, "card")}>
      {props.icon && <div className={styles.icon}>{props.icon}</div>}
      {props.imageURL && (
        <div className={styles.icon}>
          <img src={props.imageURL} />
        </div>
      )}
      <div className={styles.text}>
        <h3>{props.count}</h3>
        <p>{props.title}</p>
      </div>
    </div>
  );
};

export default StatisticItem;
