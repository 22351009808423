import { NO_VALUE_SYMBOL } from "utils/globalStrings";

import { apiSlice } from "../apiSlice";

export interface I_ORDER {
  supplierName: string;
  email: string;
  phone: string;
  amount: number;
  date: string;
  products: {
    name: string;
    quantity: number;
    price: number;
  }[];
}

export const marketplaceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<any, any>({
      query: ({
        pageIndex,
        pageSize,
        sort,
        acceptanceStatus,
        companyId,
        subcategoryId,
        characteristicsIds,
        categoriesIds,
        name,
        inStock,
      }) => {
        let url = `${process.env.REACT_APP_MARKETPLACE_API_URL}/products?pagination=true`;
        if (pageIndex >= 0 && pageSize) {
          url += `&page=${pageIndex + 1}&limit=${pageSize}`;
        }
        if (sort) {
          url += `&sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}`;
        }
        if (acceptanceStatus) {
          url += `&acceptanceStatus=${acceptanceStatus}`;
        }
        if (companyId) {
          url += `&companyId=${companyId}`;
        }
        if (subcategoryId) {
          url += `&subCategoryId=${subcategoryId}`;
        }
        if (categoriesIds?.length) {
          url += `&categoriesIds=[${categoriesIds.join(",")}]`;
        }
        if (characteristicsIds?.length) {
          url += `&characteristicsIds=[${characteristicsIds.join(",")}]`;
        }
        if (name) {
          url += `&name=contains,${name}`;
        }
        if (inStock) {
          url += `&inStock=true`;
        }
        return url;
      },
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const products = response.data?.pageData.map((item: any) => {
          return {
            ...item,
            imagesKeys: item.imagesKeys.map(
              (item: any) =>
                `${process.env.REACT_APP_MARKETPLACE_API_URL}/products/image/from-bucket?imageKey=${item}`
            ),
          };
        });

        return {
          products,
          meta,
        };
      },
    }),
    getProductCategories: builder.query<any, any>({
      query: () => `${process.env.REACT_APP_MARKETPLACE_API_URL}/categories`,
      transformResponse: (response: any) => {
        const { data } = response;
        const categories = data?.pageData?.map((category: any) => {
          return {
            id: category.id,
            name: category.name,
            label: category.name,
            value: category.id,
          };
        });
        return categories;
      },
    }),
    getProductSubcategories: builder.query<any, any>({
      query: () => `${process.env.REACT_APP_MARKETPLACE_API_URL}/subcategories`,
      transformResponse: (response: any) => {
        const { data } = response;
        const subcategories = data?.pageData?.map((category: any) => {
          return {
            id: category.id,
            name: category.name,
            value: category.id,
          };
        });
        return subcategories;
      },
    }),
    getAttributtesBySubcategory: builder.query<any, any>({
      query: (subcategoryId: number) =>
        `${process.env.REACT_APP_MARKETPLACE_API_URL}/attributes/by-subcategory/${subcategoryId}`,
      transformResponse: (response: any) => {
        const { data } = response;
        const attributtes = data?.map((attribute: any) => {
          return {
            id: attribute.id,
            name: attribute.name,
            value: attribute.id,
            characteristics: attribute.characteristics.map((characteristic: any) => {
              return {
                id: characteristic.id,
                name: characteristic.value,
                value: characteristic.id,
              };
            }),
          };
        });
        return attributtes;
      },
    }),

    getAllAttributes: builder.query<any, any>({
      query: () => `${process.env.REACT_APP_MARKETPLACE_API_URL}/attributes`,
      transformResponse: (response: any) => {
        const { data } = response;
        const attributtes = data?.pageData?.map((attribute: any) => {
          return {
            id: attribute.id,
            name: attribute.name,
            value: attribute.id,
            characteristics: attribute.characteristics.map((characteristic: any) => {
              return {
                id: characteristic.id,
                name: characteristic.value,
                value: characteristic.id,
              };
            }),
          };
        });
        return attributtes;
      },
    }),
    getProduct: builder.query<any, any>({
      query: (productId: number) =>
        `${process.env.REACT_APP_MARKETPLACE_API_URL}/products/${productId}`,
      transformResponse: (response: any) => {
        const { data } = response;
        return {
          ...data,
          imagesKeys: data.imagesKeys.map(
            (item: any) =>
              `${process.env.REACT_APP_MARKETPLACE_API_URL}/products/image/from-bucket?imageKey=${item}`
          ),
        };
      },
    }),
    getOrders: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, supplierId, partnerId }) => {
        let url = `${process.env.REACT_APP_MARKETPLACE_API_URL}/order-histories?`;
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (supplierId) {
          url += `supplierId=${supplierId}&`;
        }
        if (partnerId) {
          url += `partnerId=${partnerId}&`;
        }

        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const orders: I_ORDER[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          orders.push({
            supplierName: item.supplier || NO_VALUE_SYMBOL,
            email: item?.contact?.email || NO_VALUE_SYMBOL,
            phone: item?.contact?.phone || NO_VALUE_SYMBOL,
            amount: item?.amount || NO_VALUE_SYMBOL,
            date: item.createdAt,
            products: item.products,
          });
        });
        return {
          orders,
          meta,
        };
      },
    }),

    addProduct: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_MARKETPLACE_API_URL}/products`,
        method: "POST",
        body,
      }),
    }),
    updateProduct: builder.mutation({
      query: (data: { body: any; productId: number | string }) => ({
        url: `${process.env.REACT_APP_MARKETPLACE_API_URL}/products/${data.productId}`,
        method: "PUT",
        body: data.body,
      }),
    }),
    acceptProduct: builder.mutation({
      query: (productId: number | string) => ({
        url: `${process.env.REACT_APP_MARKETPLACE_API_URL}/products/${productId}/accept-product`,
        method: "PUT",
      }),
    }),
    declineProduct: builder.mutation({
      query: (data: { productId: number | string; reasonForDeclining: string }) => ({
        url: `${process.env.REACT_APP_MARKETPLACE_API_URL}/products/${data.productId}/decline-product`,
        method: "PUT",
        body: {
          reasonForDeclining: data.reasonForDeclining,
        },
      }),
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductCategoriesQuery,
  useGetProductSubcategoriesQuery,
  useGetAttributtesBySubcategoryQuery,
  useGetAllAttributesQuery,
  useGetProductQuery,
  useGetOrdersQuery,
  useAddProductMutation,
  useUpdateProductMutation,
  useAcceptProductMutation,
  useDeclineProductMutation,
} = marketplaceApiSlice;
