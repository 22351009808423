import React, { useMemo, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import { useGetTripsQuery } from "app/trips/tripsApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { PageHeader, TableBadge, TableComponent, TableIcon, VehicleType } from "components";
import { getSortDetails, GlobalStrings, GlobalVariables } from "utils";
import { Currency, Selection, TripStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_TRIP } from "utils/types";

import styles from "./TripsTable.module.scss";

const TripsTable = (props: { id: string | undefined | number; hidePageHeader?: boolean }) => {
  const user = useSelector(selectCurrentUser);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filterType, setFilterType] = useState<string>(Selection.all);
  const [paymentsModal, setPaymentsModal] = useState(false);
  const [trip, setTrip] = useState<I_TRIP>();
  const togglePaymentsModal = () => setPaymentsModal((prev) => !prev);

  const { data, isFetching } = useGetTripsQuery({
    companyId: props.id || "",
    type: filterType === Selection.all ? undefined : filterType,
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
  });

  const columnHelper = createColumnHelper<I_TRIP>();

  const columns = [
    columnHelper.accessor((row) => row, {
      id: "lastName",
      cell: (info) => (
        <p>
          {(info.getValue().firstName !== NO_VALUE_SYMBOL ||
            info.getValue().lastName !== NO_VALUE_SYMBOL) && (
            <>
              {info.getValue().firstName} {info.getValue().lastName} <br />
            </>
          )}

          {info.getValue().phone}
        </p>
      ),
      header: () => <span>{GlobalStrings.partners.client}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.vehicle, {
      id: "vehicle",
      cell: (info) => <VehicleType vehicles={info.getValue()?.type} />,
      header: () => <span>{GlobalStrings.partners.vehicleType}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.vehicle.qrCode, {
      id: "qrCode",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.qrCode}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.id, {
      id: "id",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.partners.tripID}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor(
      (row) => {
        const startDate = new Date(row.startDate);
        const formattedStartDate = `${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()}`;
        return formattedStartDate;
      },
      {
        id: "startDate",
        cell: (info) => <TableBadge text={info.getValue()} color="green" />,
        header: () => <span>{GlobalStrings.partners.startHour}</span>,
        enableSorting: false,
      }
    ),
    columnHelper.accessor(
      (row) => {
        if (row.tripStatus === TripStatus.ACTIVE) {
          return NO_VALUE_SYMBOL;
        }
        const endDate = new Date(row.endDate);
        const formattedStartDate = `${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString()}`;
        return formattedStartDate;
      },
      {
        id: "endDate",
        cell: (info) => <TableBadge text={info.getValue()} color="red" />,
        header: () => <span>{GlobalStrings.partners.finishHour}</span>,
        enableSorting: false,
      }
    ),
    columnHelper.accessor((row) => row.durationInMinutes, {
      id: "duration",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.partners.duration}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row.amount, {
      id: "amount",
      cell: (info) => (
        <p>
          {isNaN(info.getValue()) ? (
            info.getValue()
          ) : (
            <>
              {info.getValue()} {Currency.RON}
            </>
          )}
        </p>
      ),
      header: () => <span>{GlobalStrings.partners.invoiceAmount}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "tripStatus",
      cell: (info) => (
        <div className="d-flex gap-2">
          <TableBadge
            text={GlobalStrings.tripStatus[info.getValue().tripStatus]}
            color={
              info.getValue().tripStatus === TripStatus.ACTIVE
                ? "green"
                : info.getValue().tripStatus === TripStatus.PAUSED
                ? "orange"
                : "red"
            }
          />
        </div>
      ),
      header: () => <span>{GlobalStrings.partners.tripStatus}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "payment",
      cell: (info) => (
        <div className="edit_delete_container">
          <TableIcon
            type="payment"
            onClickAction={() => {
              setPaymentsModal(true);
              setTrip(info.getValue());
            }}
          />
        </div>
      ),
      header: () => <span>{GlobalStrings.partners.payments}</span>,
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    data: data?.trips || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  const handleFilterChange = (selectedOption: any) => {
    setFilterType(selectedOption?.value || "");
  };

  const selectOptions = [
    { value: Selection.all, label: Selection.all },
    ...GlobalVariables.vehicleTypes.map((type) => ({
      value: type.value,
      label: type.label,
    })),
  ];

  return (
    <section>
      {!props.hidePageHeader && <PageHeader title={GlobalStrings.partners.trips} />}
      <div className={styles.select_container}>
        <Select
          id="filterType"
          options={selectOptions}
          value={selectOptions.find((option) => option.value === filterType)}
          onChange={handleFilterChange}
          isSearchable={false}
        />
      </div>

      <TableComponent table={table} tableHasData={data?.trips?.length > 0} isLoading={isFetching} />

      <Modal isOpen={paymentsModal} toggle={togglePaymentsModal} centered>
        <ModalHeader toggle={togglePaymentsModal}>
          {GlobalStrings.trips.paymentsModalHeader} | {trip?.phone} |{" "}
          {GlobalStrings.partners.tripID}: {trip?.id}
        </ModalHeader>
        <ModalBody>
          <section className={classNames("table_container", user?.role)}>
            <table className="styled_table">
              <thead>
                <tr>
                  <th>{GlobalStrings.formLabels.date}</th>
                  <th>{GlobalStrings.formLabels.panMask}</th>
                  <th>{GlobalStrings.formLabels.paymentStatus}</th>
                </tr>
              </thead>
              <tbody>
                {trip?.payments?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Moment date={item.createdAt} format="DD-MM-YYYY, HH:mm:ss" />
                      </td>
                      <td>{item.panMask}</td>
                      <td>{item.status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePaymentsModal}>
            {GlobalStrings.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    </section>
  );
};

export default TripsTable;
