import React from "react";
import { AiOutlineClose } from "react-icons/ai";

import styles from "./FiltersBadge.module.scss";

const FiltersBadge = (props: { attribute: string; characteristic: string; action?: Function }) => {
  return (
    <div className={styles.attribute}>
      <p className={styles.category}>{props.attribute}</p>
      <p>{props.characteristic}</p>
      {props.action && (
        <AiOutlineClose
          size={20}
          className={styles.close_icon}
          onClick={() => (props.action ? props.action() : undefined)}
        />
      )}
    </div>
  );
};

export default FiltersBadge;
