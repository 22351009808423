import React from "react";

import { PageHeader } from "components";
import { GlobalStrings } from "utils";

import VehicleInfo from "./VehicleInfo/VehicleInfo";

const Vehicle = () => {
  return (
    <section>
      <PageHeader title={GlobalStrings.partners.vehiclePage} backRoute="-1" />

      <VehicleInfo />
    </section>
  );
};

export default Vehicle;
