// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmationPopup_textarea__Cx71J {
  width: 100%;
  resize: vertical;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/ConfirmationPopup/ConfirmationPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;AACF","sourcesContent":[".textarea {\n  width: 100%;\n  resize: vertical;\n  padding: 8px;\n  font-size: 14px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `ConfirmationPopup_textarea__Cx71J`
};
export default ___CSS_LOADER_EXPORT___;
