import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";

import { useDeleteConnectorMutation } from "app/containersAndStations/containersAndStationsApiSlice";

import classNames from "classnames";
import { ConfirmationPopup, NoResults, PageHeader, TableIcon } from "components";
import { errorHandler, GlobalStrings } from "utils";
import { I_CONNECTOR } from "utils/types";

import AddEditConector from "./AddEditConnector";

const Connectors = (props: {
  connectors?: I_CONNECTOR[];
  companyId: number;
  containerId?: number;
  stationId?: number;
  refetch: Function;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [connectorToDelete, setConnectorToDelete] = useState<I_CONNECTOR>();
  const [connectorToEdit, setConnectorToEdit] = useState<I_CONNECTOR>();
  const [deleteConnector] = useDeleteConnectorMutation();

  const toggle = () => setIsOpen((prev) => !prev);
  const toggleDeleteModal = () => setIsOpenDeleteModal((prev) => !prev);

  const deleteConnectorHandler = async () => {
    if (connectorToDelete) {
      try {
        await deleteConnector(connectorToDelete?.id);
        toast.success(GlobalStrings.addElectricityContainer.connectorDeleted);
        setConnectorToDelete(undefined);
        toggleDeleteModal();
        props.refetch();
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  return (
    <section>
      <PageHeader title={`Conectori`} />
      <Button
        className="btn btn-success right mb-2"
        onClick={() => {
          toggle();
          setConnectorToEdit(undefined);
        }}
      >
        {GlobalStrings.add}
      </Button>

      <section className={classNames("table_container", "admin")}>
        <table className="styled_table">
          <thead>
            <tr>
              <th>{GlobalStrings.addElectricityContainer.connectorType}</th>
              <th>{GlobalStrings.formLabels.status}</th>
              <th>{GlobalStrings.formLabels.label}</th>
              <th>{GlobalStrings.formLabels.power}</th>
              <th>{GlobalStrings.formLabels.internalId}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.connectors?.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.type}</td>
                  <td>{GlobalStrings.connectorStatus[item.status]}</td>
                  <td>{item.label}</td>
                  <td>{item.power}</td>
                  <td>{item.internalId}</td>
                  <td>
                    <div className="edit_delete_container">
                      <TableIcon
                        key={item.id + "edit"}
                        type="edit"
                        onClickAction={() => {
                          toggle();
                          setConnectorToEdit(item);
                        }}
                      />

                      <TableIcon
                        key={item.id + "delete"}
                        type="delete"
                        onClickAction={() => {
                          toggleDeleteModal();
                          setConnectorToDelete(item);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {!props?.connectors?.length && <NoResults />}
      </section>
      <AddEditConector
        isOpen={isOpen}
        toggle={toggle}
        containerId={props.containerId}
        stationId={props.stationId}
        refetch={props.refetch}
        data={connectorToEdit}
      />
      <ConfirmationPopup
        modalIsOpen={isOpenDeleteModal}
        toggleModal={toggleDeleteModal}
        title={GlobalStrings.addElectricityContainer.connectorModalTitle}
        body={GlobalStrings.addElectricityContainer.connectorModalBody}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deleteConnectorHandler}
      />
    </section>
  );
};

export default Connectors;
