import React from "react";
import { useParams } from "react-router-dom";

import ReportsPartner from "views/common/ReportsPartner/ReportsPartner";

const Reports = (props: { city: string }) => {
  const { partnerId } = useParams();

  return (
    <section>
      <ReportsPartner partnerId={partnerId} partnerCity={props.city} hidePageHeader />
    </section>
  );
};
export default Reports;
