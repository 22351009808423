import React from "react";

import { useGetPaymentsReportsOverallQuery } from "app/reports/reportsApiSlice";

import { IsLoading } from "components";
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GlobalStrings } from "utils";

export default function PaymentTab({ startDate, endDate }: { startDate: string; endDate: string }) {
  const { data: paymentsOverallData, isFetching } = useGetPaymentsReportsOverallQuery({
    startDate,
    endDate,
  });

  if (isFetching) {
    return (
      <div>
        <IsLoading />
      </div>
    );
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={paymentsOverallData} className="w-100">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="bikes"
            stroke="#d82c2c"
            activeDot={{ r: 8 }}
            name={GlobalStrings.analytics.bikes}
          />
          <Line
            type="monotone"
            dataKey="scooters"
            stroke="#8A2BE2"
            name={GlobalStrings.analytics.scooters}
          />
          <Line
            type="monotone"
            dataKey="cars"
            stroke="#FFA500"
            name={GlobalStrings.analytics.cars}
          />
          <Line
            type="monotone"
            dataKey="containers"
            stroke="#82ca9d"
            name={GlobalStrings.analytics.containers}
          />
          <Brush dataKey="name" height={20} stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
