import React from "react";

import { flexRender, Table } from "@tanstack/react-table";
import { useResponsive } from "ahooks";

const TableBody = ({ table }: { table: Table<any> }) => {
  const responsive = useResponsive();
  return (
    <tbody>
      {responsive.md &&
        table.getRowModel().rows.map((row: any) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell: any) => (
              <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
            ))}
          </tr>
        ))}

      {!responsive.md &&
        table.getRowModel().rows.map((row: any) => (
          <div key={row.id} className="table_card">
            {row.getVisibleCells().map((cell: any, index: number) => (
              <div key={cell.id} className="card_row">
                <p className="mb-0">
                  {flexRender(
                    table.getHeaderGroups()[0].headers[index]?.column.columnDef.header,
                    table.getHeaderGroups()[0].headers[index]?.getContext()
                  )}
                </p>
                <p className="mb-0">{flexRender(cell.column.columnDef.cell, cell.getContext())}</p>
              </div>
            ))}
          </div>
        ))}
    </tbody>
  );
};

export default TableBody;
