import React from "react";
// import styles from "../EndUser.module.scss";
import { GrValidate } from "react-icons/gr";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Moment from "react-moment";

import { useGetDocumentsQuery } from "app/users/usersApiSlice";

import classNames from "classnames";
import { NoResults } from "components";
import { GlobalStrings } from "utils";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

const Documents = (props: { userId: number }) => {
  const { data: documents } = useGetDocumentsQuery({
    id: props.userId,
  });

  return (
    <div>
      <div>
        <section className={classNames("table_container", "admin")}>
          <table className="styled_table">
            <thead>
              <tr>
                <th>{GlobalStrings.documents.document}</th>
                <th className="text-center">{GlobalStrings.documents.front}</th>
                <th className="text-center">{GlobalStrings.documents.back}</th>
                <th>{GlobalStrings.documents.expiringDate}</th>
                <th>{GlobalStrings.documents.updatedAt}</th>
              </tr>
            </thead>

            {(documents?.driverLicence?.id || documents?.identityCard?.id) && (
              <tbody>
                <tr>
                  <td>{GlobalStrings.documents.identityCard}</td>
                  <td className="text-center">
                    {documents?.identityCard.isFaceValid ? (
                      <GrValidate color="green" size={30} />
                    ) : (
                      <IoMdCloseCircleOutline color="red" size={30} />
                    )}
                  </td>
                  <td className="text-center">
                    {documents?.identityCard.isBackValid ? (
                      <GrValidate color="green" size={30} />
                    ) : (
                      <IoMdCloseCircleOutline color="red" size={30} />
                    )}
                  </td>
                  <td>
                    {documents?.identityCard.expiresAt ? (
                      <Moment date={documents?.identityCard.expiresAt} format="DD.MM.YYYY" />
                    ) : (
                      NO_VALUE_SYMBOL
                    )}
                  </td>
                  <td>
                    {documents?.identityCard.updatedAt ? (
                      <Moment
                        date={documents?.identityCard.updatedAt}
                        format="DD.MM.YYYY, HH:mm:ss"
                      />
                    ) : (
                      NO_VALUE_SYMBOL
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{GlobalStrings.documents.driverLicense}</td>
                  <td className="text-center">
                    {documents?.driverLicence.isFaceValid ? (
                      <GrValidate color="green" size={30} />
                    ) : (
                      <IoMdCloseCircleOutline color="red" size={30} />
                    )}
                  </td>
                  <td className="text-center">
                    {documents?.driverLicence.isBackValid ? (
                      <GrValidate color="green" size={30} />
                    ) : (
                      <IoMdCloseCircleOutline color="red" size={30} />
                    )}
                  </td>
                  <td>
                    {documents?.identityCard.expiresAt ? (
                      <Moment date={documents?.driverLicence.expiresAt} format="DD.MM.YYYY" />
                    ) : (
                      NO_VALUE_SYMBOL
                    )}
                  </td>
                  <td>
                    {documents?.driverLicence.updatedAt ? (
                      <Moment
                        date={documents?.driverLicence.updatedAt}
                        format="DD.MM.YYYY, HH:mm:ss"
                      />
                    ) : (
                      NO_VALUE_SYMBOL
                    )}
                  </td>
                </tr>{" "}
              </tbody>
            )}
          </table>
          {!documents?.driverLicence?.id && !documents?.identityCard?.id && <NoResults />}
        </section>
      </div>
    </div>
  );
};
export default Documents;
