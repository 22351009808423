import * as React from "react";
import { GiShoppingCart } from "react-icons/gi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { selectCurrentUser } from "app/auth/authSlice";
import { selectMarketplaceIsUsedInApp } from "app/common/commonSlice";

import classNames from "classnames";
import { Currency, ProductUM } from "utils/enums";

import { Price } from "../../../components";
import { GlobalStrings, roleValidation } from "../../../utils";
import placeholder from "assets/images/product_placeholder.jpg";

import styles from "./ProductTeaser.module.scss";

const ProductTeaser = (props: {
  id?: number;
  productName: string;
  productImage: string;
  price: number;
  unit: ProductUM;
  inStock: boolean;
  type: "admin" | "partner" | "supplier";
  reasonForDeclining?: string;
  acceptFunction?: React.MouseEventHandler<HTMLButtonElement>;
  declineFunction?: React.MouseEventHandler<HTMLButtonElement>;
  addToCartFunction?: Function;
  slug: string;
}) => {
  const user = useSelector(selectCurrentUser);
  const marletplaceIsUsedInApp = useSelector(selectMarketplaceIsUsedInApp);
  return (
    <div
      className={classNames(styles.product_teaser, {
        [styles.used_for_app]: marletplaceIsUsedInApp || roleValidation("admin", user?.roles),
      })}
    >
      <p className={styles.product_status}>
        {props.inStock ? GlobalStrings.marketplace.inStock : GlobalStrings.marketplace.outOfStock}
      </p>

      <div>
        <Link to={`${props.slug}/${props.id}`}>
          <div className={styles.image}>
            <img src={props.productImage || placeholder} alt={props.productName} />
          </div>
        </Link>
        <div className={styles.product_info}>
          <Link to={`${props.slug}/${props.id}`}>{props.productName}</Link>
          {props.reasonForDeclining && (
            <p>
              {GlobalStrings.marketplace.reasonForDeclining}
              <br /> {props.reasonForDeclining}
            </p>
          )}
        </div>
      </div>
      <div>
        <Price
          value={parseFloat(props.price.toFixed(2))}
          unit={props.unit}
          currency={Currency.RON}
          className={styles.product_price}
        />
        <div className={styles.buttons_container}>
          {props.type === "admin" && (
            <>
              <button className={styles.accept} onClick={props?.acceptFunction || undefined}>
                {GlobalStrings.marketplace.accept}
              </button>
              <button className={styles.decline} onClick={props?.declineFunction || undefined}>
                {GlobalStrings.marketplace.decline}
              </button>
            </>
          )}
          {props.type === "supplier" && (
            <>
              <Link to={`${props.slug}/${props.id}`} className={styles.accept}>
                {GlobalStrings.edit}
              </Link>

              <button className={styles.decline} onClick={props?.declineFunction || undefined}>
                {GlobalStrings.delete}
              </button>
            </>
          )}
          {!roleValidation("admin", user?.roles) && props.type === "partner" ? (
            <>
              <button
                className={styles.add_to_cart}
                onClick={() =>
                  props.addToCartFunction ? props.addToCartFunction(props.id) : undefined
                }
              >
                <GiShoppingCart size={30} /> {GlobalStrings.marketplace.addToCart}
              </button>
            </>
          ) : (
            <>
              <Link className={styles.add_to_cart} to={`${props.slug}/${props.id}`}>
                {GlobalStrings.marketplace.showProduct}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductTeaser;
