import DeclinedProducts from "./DeclinedProducts/DeclinedProducts";
import AddEditProduct from "./Products/AddEditProduct/AddEditProduct";
import Products from "./Products/Products";
import SuppliersAccounts from "./SuppliersAccounts/SuppliersAccounts";
import AddEditSupplierService from "./SupplierServices/AddEditSupplierService/AddEditSupploerService";
import Juicer from "./SupplierServices/Juicer/Juicer";
import SupplierServices from "./SupplierServices/SupplierServices";

export {
  Products,
  AddEditProduct,
  SupplierServices,
  AddEditSupplierService,
  DeclinedProducts,
  SuppliersAccounts,
  Juicer,
};
