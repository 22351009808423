import React, { useEffect, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { selectCurrentUser } from "app/auth/authSlice";
import { selectMarketplaceIsUsedInApp } from "app/common/commonSlice";
import { useGetCartProductsQuery } from "app/marketplace/cartApiSlice";
import {
  useGetAllAttributesQuery,
  useGetProductCategoriesQuery,
  useGetProductsQuery,
  useGetProductSubcategoriesQuery,
} from "app/marketplace/marketplaceApiSlice";

import { useResponsive } from "ahooks";
import classNames from "classnames";
import { Filters, IsLoading, NoResults, Pagination } from "components";
import { GlobalStrings, roleValidation } from "utils";

import Categories from "./Categories/Categories";
import ProductsListing from "./ProductsListing/ProductsListing";
import SearchBar from "components/SearchBar/SearchBar";

import styles from "./MarketplacePartner.module.scss";

const MarketplacePartner = () => {
  const responsive = useResponsive();
  const marketplaceIsUsedInApp = useSelector(selectMarketplaceIsUsedInApp);

  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [filtersAreActive, setFiltersAreActive] = useState(false);

  const { data: cartProducts } = useGetCartProductsQuery(user.id);
  const { data: categories } = useGetProductCategoriesQuery(undefined);
  const { data: subcategories } = useGetProductSubcategoriesQuery(undefined);
  const { data: attributes } = useGetAllAttributesQuery(undefined);
  const { data: products, isLoading } = useGetProductsQuery({
    pageIndex,
    pageSize,
    acceptanceStatus: "accepted",
    subcategoryId: searchParams.get("subcategory"),
    categoriesIds: searchParams.getAll("categories"),
    name: searchParams.get("name"),
    characteristicsIds: searchParams.getAll("characteristics"),
    inStock: true,
  });

  useEffect(() => {
    const delay = setTimeout(() => {
      if (searchTerm) {
        searchParams.set("name", searchTerm);
      } else {
        searchParams.delete("name");
      }
      setSearchParams(searchParams);
    }, 500);

    return () => clearInterval(delay);
  }, [searchTerm]);

  return (
    <section className={styles.marketplace}>
      <div className={styles.flex_container}>
        <div className={styles.filters}>
          {categories && <Categories categories={categories} />}
          {!roleValidation("admin", user?.roles) && !responsive.md && (
            <div className="d-flex">
              <button
                className={classNames("btn", styles.btn_filters, {
                  [styles.used_for_app]: marketplaceIsUsedInApp,
                })}
                onClick={() => setFiltersAreActive((prev) => !prev)}
              >
                {GlobalStrings.marketplace.filters}
              </button>
              <div
                className={classNames(styles.add_to_cart, {
                  [styles.used_for_app]: marketplaceIsUsedInApp,
                })}
              >
                <div className={styles.button} onClick={() => navigate("cart")}>
                  <div className={styles.icon_container}>
                    <AiOutlineShoppingCart size={22} />
                    {cartProducts?.cart?.productsCount ? (
                      <div className={styles.number_of_products}>
                        {cartProducts?.cart?.productsCount < 100
                          ? cartProducts?.cart?.productsCount
                          : "99+"}
                      </div>
                    ) : null}
                  </div>
                  <p>{GlobalStrings.marketplace.myCart}</p>
                </div>
              </div>
            </div>
          )}
          {attributes && (
            <Filters
              attributes={attributes}
              subcategories={subcategories}
              isActive={filtersAreActive}
              closeFilters={setFiltersAreActive}
            />
          )}
        </div>
        <div className={styles.products}>
          <div className={styles.products_header}>
            <div className={styles.search_container}>
              <SearchBar
                searchTerm={searchTerm}
                onSearchChange={setSearchTerm}
                placeholder={GlobalStrings.marketplace.searchProduct}
              />
            </div>
            {!roleValidation("admin", user?.roles) && responsive.md && (
              <div className={styles.add_to_cart}>
                <div className={styles.button} onClick={() => navigate("cart")}>
                  <div className={styles.icon_container}>
                    <AiOutlineShoppingCart size={22} />
                    {cartProducts?.cart?.productsCount ? (
                      <div className={styles.number_of_products}>
                        {cartProducts?.cart?.productsCount < 100
                          ? cartProducts?.cart?.productsCount
                          : "99+"}
                      </div>
                    ) : null}
                  </div>
                  <p>{GlobalStrings.marketplace.myCart}</p>
                </div>
              </div>
            )}
          </div>
          {isLoading ? <IsLoading /> : <ProductsListing products={products?.products} />}
          {products?.products?.length === 0 && <NoResults />}
          {products?.products?.length > 0 && (
            <div className={styles.pagination}>
              <Pagination
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPageIndex={setPageIndex}
                setPageSize={setPageSize}
                numberOfPages={products?.meta?.numberOfPages}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default MarketplacePartner;
