import React, { useState } from "react";
import { Button, ButtonGroup } from "reactstrap";

import { useGetMapReportsOverallQuery } from "app/reports/reportsApiSlice";

import { GoogleMap, HeatmapLayer, useLoadScript } from "@react-google-maps/api";
import { IsLoading } from "components";
import { GlobalStrings, GlobalVariables } from "utils";

const containerStyle = {
  width: "100%",
  height: "75vh",
};

const libraries = ["visualization"] as any;

const MapTab = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
  const google = window.google;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
    libraries,
  });
  const [filter, setFilter] = useState<"startPositions" | "finishPositions">("startPositions");

  const { data, isFetching } = useGetMapReportsOverallQuery({
    startDate,
    endDate,
  });

  if (!isLoaded || isFetching) {
    return (
      <div>
        <IsLoading />
      </div>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <ButtonGroup className="w-25">
          <Button
            color="danger"
            outline
            onClick={() => {
              setFilter("startPositions");
            }}
            active={filter === "startPositions"}
          >
            {GlobalStrings.analytics.startTrip}
          </Button>
          <Button
            color="danger"
            outline
            onClick={() => {
              setFilter("finishPositions");
            }}
            active={filter === "finishPositions"}
          >
            {GlobalStrings.analytics.endTrip}
          </Button>
        </ButtonGroup>
      </div>

      <GoogleMap mapContainerStyle={containerStyle} center={GlobalVariables.center} zoom={13}>
        <HeatmapLayer
          key={`${filter}-${Math.random()}`}
          data={
            data?.[filter]?.map((item: any) => {
              return new google.maps.LatLng(item?.lat, item?.lng);
            }) || []
          }
          options={{
            opacity: 0.8,
          }}
        />
      </GoogleMap>
    </>
  );
};

export default MapTab;
