import React from "react";
import { useParams } from "react-router-dom";

import { useGetProductQuery } from "app/marketplace/marketplaceApiSlice";

import { AddEditProductFrom, IsLoading, PageHeader, SEO } from "components";
import { GlobalStrings } from "utils";
import { Page404 } from "views/common";

const AddEditProduct = () => {
  const { productId } = useParams();
  const {
    data: productData,
    isLoading,
    refetch,
    error,
  } = useGetProductQuery(productId, {
    skip: !productId,
  });

  if (productId && !isLoading && error) {
    return <Page404 />;
  } else
    return (
      <section>
        {productData && <SEO title={productData?.name + GlobalStrings.SEO.supplier.editProduct} />}
        <PageHeader
          title={
            productId
              ? GlobalStrings.marketplace.productDetails
              : GlobalStrings.marketplace.addProduct
          }
          backRoute="/products"
        />
        <div className="card">
          {isLoading ? (
            <IsLoading />
          ) : (
            <AddEditProductFrom productData={productData} refetch={refetch} />
          )}
        </div>
      </section>
    );
};

export default AddEditProduct;
