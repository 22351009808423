import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useGetPartnerQuery } from "app/partners/partnersApiSlice";

import { PageHeader, SEO } from "components";
import { errorHandler, GlobalStrings } from "utils";

import { adminRoutesPath } from "views/admin/routes";

import Accounts from "./Accounts";
import GeneralInfo from "./GeneralInfo";
import Juicers from "./Juicers";
import Prices from "./Prices";
import Reports from "./Reports";
import Trips from "./Trips";

const PartnerEdit = () => {
  const { partnerId } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const { data: partner, refetch, isFetching, error } = useGetPartnerQuery(partnerId);

  const tabs = [
    {
      id: 0,
      name: GlobalStrings.formLabels.generalData,
      slug: adminRoutesPath.Partners.EditPartner.replace(":partnerId", partnerId?.toString() || ""),
    },
    {
      id: 1,
      name: GlobalStrings.formLabels.trips,
      slug: adminRoutesPath.Partners.EditPartnerTrips.replace(
        ":partnerId",
        partnerId?.toString() || ""
      ),
    },
    {
      id: 2,
      name: GlobalStrings.formLabels.accounts,
      slug: adminRoutesPath.Partners.EditPartnerAccounts.replace(
        ":partnerId",
        partnerId?.toString() || ""
      ),
    },
    {
      id: 3,
      name: GlobalStrings.formLabels.reports,
      slug: adminRoutesPath.Partners.EditPartnerReports.replace(
        ":partnerId",
        partnerId?.toString() || ""
      ),
    },
    {
      id: 4,
      name: GlobalStrings.formLabels.prices,
      slug: adminRoutesPath.Partners.EditPartnerPrices.replace(
        ":partnerId",
        partnerId?.toString() || ""
      ),
    },
    {
      id: 5,
      name: GlobalStrings.formLabels.juicers,
      slug: adminRoutesPath.Partners.EditPartnerJuicers.replace(
        ":partnerId",
        partnerId?.toString() || ""
      ),
    },
  ];

  useEffect(() => {
    errorHandler(error);
  }, [error]);

  useEffect(() => {
    if (location.pathname.includes(tabs[0].slug)) {
      setActiveIndex(0);
    } else if (location.pathname.includes(tabs[1].slug)) {
      setActiveIndex(1);
    } else if (location.pathname.includes(tabs[2].slug)) {
      setActiveIndex(2);
    } else if (location.pathname.includes(tabs[3].slug)) {
      setActiveIndex(3);
    } else if (location.pathname.includes(tabs[4].slug)) {
      setActiveIndex(4);
    } else if (location.pathname.includes(tabs[5].slug)) {
      setActiveIndex(5);
    }
  }, [location.pathname]);

  return (
    <section>
      <PageHeader
        title={`${GlobalStrings.partners.partnersEditTitle} - ${partner?.name}`}
        backRoute="-1"
        tabsCurrentview={tabs}
        tabsCurrentviewActiveIndex={activeIndex}
      />
      <SEO title={partner?.name + GlobalStrings.SEO.admin.editPartner} />
      {activeIndex === 0 && (
        <GeneralInfo data={partner} refetch={refetch} isFetching={isFetching} />
      )}
      {activeIndex === 1 && <Trips />}
      {activeIndex === 2 && <Accounts />}
      {activeIndex === 3 && <Reports city={partner?.city} />}
      {activeIndex === 4 && (
        <Prices
          identifier={partner?.identifier}
          individualTransportVehicles={partner?.individualTransportVehicles}
        />
      )}
      {activeIndex === 5 && <Juicers />}
    </section>
  );
};
export default PartnerEdit;
