import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { PageHeader } from "components";
import { GlobalStrings } from "utils";

import VehiclesCharges from "../VehiclesCharges/VehiclesCharges";
import VehiclesMapView from "../VehiclesMapView/VehiclesMapView";
import { supplierRoutesPath } from "views/supplier/routes";

function Juicer() {
  const invoicesTabs = [
    {
      id: 0,
      name: GlobalStrings.servicesSuppliers.map,
      slug: supplierRoutesPath.Services.VehiclesMap,
    },
    {
      id: 1,
      name: GlobalStrings.servicesSuppliers.chargesHistory,
      slug: supplierRoutesPath.Services.VehiclesCharges,
    },
  ];
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (location.pathname.includes(invoicesTabs[0].slug)) {
      setActiveIndex(0);
    } else if (location.pathname.includes(invoicesTabs[1].slug)) {
      setActiveIndex(1);
    }
  }, [location.pathname]);

  return (
    <div>
      <PageHeader
        title={GlobalStrings.servicesSuppliers.managePartnerVehicles}
        backRoute="-1"
        tabsCurrentview={invoicesTabs}
        tabsCurrentviewActiveIndex={activeIndex}
      />

      {activeIndex === 0 && <VehiclesMapView />}
      {activeIndex === 1 && <VehiclesCharges />}
    </div>
  );
}

export default Juicer;
