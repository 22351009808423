import React, { useState } from "react";
import Slider from "react-slick";

import styles from "./Gallery.module.scss";

const Gallery = (props: { images: string[] }) => {
  const [nav1, setNav1] = useState<any>();
  const [nav2, setNav2] = useState<any>();
  return (
    <div className={styles.gallery}>
      <Slider
        asNavFor={nav2}
        ref={(slider1) => setNav1(slider1)}
        className={styles.first_slider}
        arrows={false}
        dots={true}
        fade
      >
        {props.images?.map((item, index) => {
          return (
            <div key={index} className={styles.image}>
              <img src={item} alt="image" />
            </div>
          );
        })}
      </Slider>
      <Slider
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        slidesToShow={props.images?.length >= 3 ? 3 : props.images?.length}
        swipeToSlide={true}
        focusOnSelect={true}
        className={styles.second_slider}
        arrows={false}
      >
        {props.images?.map((item, index) => {
          return (
            <div key={index} className={styles.image}>
              <img src={item} alt="image" />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Gallery;
