import { StationStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_CONNECTOR, I_ELECTRICITY_CONTAINER } from "utils/types";

import { apiSlice } from "../apiSlice";

export interface I_STATION {
  id: number;
  name: string;
  identity: string;
  status: StationStatus;
  companyId: number;
  companyName: string;
  power: number;
  online: boolean;
  latitude: number;
  longitude: number;
  connectors: I_CONNECTOR[];
  container: I_ELECTRICITY_CONTAINER;
  address: string;
}

export const stationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNumberOfStationsInUse: builder.query<any, any>({
      query: (companyId: number) => {
        let url = `${process.env.REACT_APP_CONTAINERS_API_URL}/charging-stations/count/number-of-stations-in-use`;
        if (companyId) {
          url += `?companyId=${companyId}`;
        }

        return url;
      },
      transformResponse: (response: any) => {
        return response.data.total;
      },
    }),
    getStations: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, companyId }) => {
        let url = `${process.env.REACT_APP_CONTAINERS_API_URL}/charging-stations?`;
        if (pageIndex >= 0 && pageSize > 0) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (companyId) {
          url += `companyId=${companyId}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const stations: I_STATION[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          stations.push({
            id: item.id,
            name: item.name || NO_VALUE_SYMBOL,
            identity: item.identity || NO_VALUE_SYMBOL,
            status: item.status || NO_VALUE_SYMBOL,
            companyId: item.companyId,
            companyName: item.companyName || NO_VALUE_SYMBOL,
            power: item.power || NO_VALUE_SYMBOL,
            online: item.online,
            latitude: item.latitude,
            longitude: item.longitude,
            connectors: item.connectors,
            container: item.container,
            address: item.address,
          });
        });
        return {
          stations,
          meta,
        };
      },
    }),
    getStation: builder.query<any, any>({
      query: (id: string) => `${process.env.REACT_APP_CONTAINERS_API_URL}/charging-stations/${id}`,
      transformResponse: (response: any) => {
        const station: I_STATION = {
          id: response.data.id,
          power: response.data?.power,
          name: response.data?.name,
          latitude: response.data.latitude,
          online: response.data.online,
          longitude: response.data.longitude,
          address: response.data.address,
          status: response.data.status,
          companyId: response.data.companyId,
          companyName: response.data.companyName,
          connectors: response.data?.connectors,
          container: response.data?.container,
          identity: response.data?.identity,
        };
        return {
          station,
        };
      },
    }),
    deleteStation: builder.mutation({
      query: (id: string | number) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/charging-stations/${id}`,
        method: "DELETE",
      }),
    }),
    createStation: builder.mutation<any, Partial<any>>({
      query: (body) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/charging-stations`,
        method: "POST",
        body,
      }),
    }),
    updateStation: builder.mutation<any, any>({
      query: (data: { body: any; id: number }) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/charging-stations/${data.id}`,
        method: "PUT",
        body: data.body,
      }),
    }),
  }),
});

export const {
  useGetStationsQuery,
  useGetNumberOfStationsInUseQuery,
  useCreateStationMutation,
  useDeleteStationMutation,
  useGetStationQuery,
  useUpdateStationMutation,
} = stationsApiSlice;
