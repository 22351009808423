import { ChargeStatus, VehicleStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

import { I_VEHICLE } from "views/partner/Vehicles/VehiclesListing/VehiclesListing";

import { apiSlice } from "../apiSlice";

export interface I_VEHICLE_CHARGE {
  id: number;
  batteryLevelAtStart: number;
  batteryLevelAtEnd: number;
  startDate: string;
  endDate: string;
  tariff: number;
  status: ChargeStatus;
  vehicle: {
    id: number;
    qrCode: string;
    companyName: string;
    status: VehicleStatus;
    batteryLevel: number;
  };
  supplierName?: string;
}

export const vehiclesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNearbyVehicles: builder.query<any, any>({
      query: ({
        northLatitude,
        southLatitude,
        eastLongitude,
        westLongitude,
        agencyId,
        withScooters,
        withCars,
        withBicycles,
        withContainers,
        withBusStops,
        withChargingStations,
      }) =>
        `${process.env.REACT_APP_ROUTING_API_URL}/objects/nearby?northLatitude=${northLatitude}&southLatitude=${southLatitude}&eastLongitude=${eastLongitude}&westLongitude=${westLongitude}&agencyId=${agencyId}&withScooters=${withScooters}&withCars=${withCars}&withBicycles=${withBicycles}&withContainers=${withContainers}&withBusStops=${withBusStops}&withChargingStations=${withChargingStations}`,
      transformResponse: (response: any) => {
        return [
          ...response?.vehicles,
          ...response?.containers,
          ...response?.stops,
          ...response?.chargingStations,
        ];
      },
    }),
    getVehicles: builder.query<any, any>({
      query: ({ partnerId, pageIndex, pageSize, sort }) => {
        let url = `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles?`;
        if (partnerId) {
          url += `companyId=${partnerId}&`;
        }
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      keepUnusedDataFor: 0,
      transformResponse: (response: any) => {
        const { data } = response;
        const meta = {
          page: data?.page,
          numberOfPages: data?.numberOfPages,
          pageSize: data?.pageSize,
          totalRows: data?.totalRows,
        };
        const vehicles: I_VEHICLE[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          vehicles.push({
            id: item?.id,
            iotId: item?.iotId || NO_VALUE_SYMBOL,
            iotType: item?.iotType || NO_VALUE_SYMBOL,
            model: item?.model || NO_VALUE_SYMBOL,
            position: item?.position || NO_VALUE_SYMBOL,
            status: item?.status || NO_VALUE_SYMBOL,
            tripsSinceLastCharge: item?.tripsSinceLastCharge || NO_VALUE_SYMBOL,
            type: item?.type || NO_VALUE_SYMBOL,
            averageTravelTime: item?.averageTravelTime || NO_VALUE_SYMBOL,
            averageTripsPerDay: item?.averageTripsPerDay || NO_VALUE_SYMBOL,
            batteryLevel: item?.batteryLevel || NO_VALUE_SYMBOL,
            customId: item?.customId || NO_VALUE_SYMBOL,
            qrCode: item?.qrCode || NO_VALUE_SYMBOL,
            companyName: item?.companyName || NO_VALUE_SYMBOL,
            companyId: item?.companyId || NO_VALUE_SYMBOL,
          });
        });
        return {
          meta,
          vehicles,
        };
      },
    }),
    getVehiclesByPartners: builder.query<any, any>({
      query: (partnerIds) => {
        let url = `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/all/by-partners?`;
        partnerIds.forEach((id: number) => {
          url += `partnersIds=${id}&`;
        });
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      keepUnusedDataFor: 0,
      transformResponse: (response: any) => {
        const vehicles: I_VEHICLE[] = [];
        response?.data?.forEach((item: any) => {
          vehicles.push({
            id: item?.id,
            iotId: item?.iotId || NO_VALUE_SYMBOL,
            iotType: item?.iotType || NO_VALUE_SYMBOL,
            model: item?.model || NO_VALUE_SYMBOL,
            position: item?.position || NO_VALUE_SYMBOL,
            status: item?.status || NO_VALUE_SYMBOL,
            companyName: item?.companyName || NO_VALUE_SYMBOL,
            tripsSinceLastCharge: item?.tripsSinceLastCharge || NO_VALUE_SYMBOL,
            type: item?.type || NO_VALUE_SYMBOL,
            averageTravelTime: item?.averageTravelTime || NO_VALUE_SYMBOL,
            averageTripsPerDay: item?.averageTripsPerDay || NO_VALUE_SYMBOL,
            batteryLevel: item?.batteryLevel || NO_VALUE_SYMBOL,
            customId: item?.customId || NO_VALUE_SYMBOL,
          });
        });
        return vehicles;
      },
    }),
    getVehicle: builder.query<any, any>({
      query: (vehicleId: number) =>
        `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/by-id/${vehicleId}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    getVehiclesChargesForJuicer: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, companyId }) => {
        let url = `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/charges/for-juicer?`;
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (companyId) {
          url += `companyId=${companyId}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      keepUnusedDataFor: 0,
      transformResponse: (response: any) => {
        const { data } = response;
        const meta = {
          page: data?.page,
          numberOfPages: data?.numberOfPages,
          pageSize: data?.pageSize,
          totalRows: data?.totalRows,
        };
        const vehicles: I_VEHICLE_CHARGE[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          vehicles.push({
            id: item?.id,
            batteryLevelAtStart: item?.batteryLevelAtStart,
            batteryLevelAtEnd:
              item?.batteryLevelAtEnd!==null && item?.batteryLevelAtEnd >= 0
                ? item?.batteryLevelAtEnd
                : NO_VALUE_SYMBOL,
            startDate: item?.startDate || NO_VALUE_SYMBOL,
            endDate: item?.endDate || NO_VALUE_SYMBOL,
            status: item?.status || NO_VALUE_SYMBOL,
            tariff: item?.tariff || NO_VALUE_SYMBOL,
            supplierName: item?.supplierName || NO_VALUE_SYMBOL,
            vehicle: {
              id: item?.vehicle.id,
              qrCode: item?.vehicle.qrCode,
              companyName: item?.vehicle.companyName,
              status: item?.vehicle.status,
              batteryLevel: item?.vehicle.batteryLevel,
            },
          });
        });
        return {
          meta,
          vehicles,
        };
      },
    }),
    getVehiclesCharges: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, companyId }) => {
        let url = `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/charges-paginated?`;
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (companyId) {
          url += `companyId=${companyId}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        const { data } = response;
        const meta = {
          page: data?.page,
          numberOfPages: data?.numberOfPages,
          pageSize: data?.pageSize,
          totalRows: data?.totalRows,
        };
        const vehicles: I_VEHICLE_CHARGE[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          vehicles.push({
            id: item?.id,
            batteryLevelAtStart: item?.batteryLevelAtStart || NO_VALUE_SYMBOL,
            batteryLevelAtEnd: item?.batteryLevelAtEnd || NO_VALUE_SYMBOL,
            startDate: item?.startDate || NO_VALUE_SYMBOL,
            endDate: item?.endDate || NO_VALUE_SYMBOL,
            status: item?.status || NO_VALUE_SYMBOL,
            tariff: item?.tariff || NO_VALUE_SYMBOL,
            supplierName: item?.juicerName || NO_VALUE_SYMBOL,
            vehicle: {
              id: item?.vehicle.id,
              qrCode: item?.vehicle.qrCode,
              companyName: item?.vehicle.companyName,
              status: item?.vehicle.status,
              batteryLevel: item?.vehicle.batteryLevel,
            },
          });
        });
        return {
          meta,
          vehicles,
        };
      },
    }),
    addVehicle: builder.mutation<any, any>({
      query: (body) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles`,
        method: "POST",
        body,
      }),
    }),
    updateVehicle: builder.mutation<any, any>({
      query: (data: { body: any; vehicleId: number }) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/${data.vehicleId}`,
        method: "PUT",
        body: data.body,
      }),
    }),
    deleteVehicle: builder.mutation<any, any>({
      query: (vehicleId) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/${vehicleId}`,
        method: "DELETE",
      }),
    }),
    setChargingMode: builder.mutation<any, any>({
      query: (id) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/set-charging-mode/${id}`,
        method: "POST",
      }),
    }),
    stopChargingMode: builder.mutation<any, any>({
      query: (id) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/stop-charging-mode/${id}`,
        method: "POST",
      }),
    }),
    startVehicle: builder.mutation<any, any>({
      query: (id) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/start-vehicle-admin/${id}`,
        method: "POST",
      }),
    }),
    stopVehicle: builder.mutation<any, any>({
      query: (id) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/stop-vehicle-admin/${id}`,
        method: "POST",
      }),
    }),
    lockVehicle: builder.mutation<any, any>({
      query: (id) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/lock-vehicle-admin/${id}`,
        method: "POST",
      }),
    }),
    unlockVehicle: builder.mutation<any, any>({
      query: (id) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/unlock-vehicle-admin/${id}`,
        method: "POST",
      }),
    }),
    restartVehicle: builder.mutation<any, any>({
      query: (id) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/restart-vehicle-admin/${id}`,
        method: "POST",
      }),
    }),
    markChargeAsPaid: builder.mutation<any, any>({
      query: (id) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/charge/set-paid/${id}`,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useGetNearbyVehiclesQuery,
  useGetVehiclesQuery,
  useGetVehiclesByPartnersQuery,
  useGetVehicleQuery,
  useAddVehicleMutation,
  useUpdateVehicleMutation,
  useDeleteVehicleMutation,
  useSetChargingModeMutation,
  useStopChargingModeMutation,
  useStartVehicleMutation,
  useStopVehicleMutation,
  useGetVehiclesChargesForJuicerQuery,
  useGetVehiclesChargesQuery,
  useLockVehicleMutation,
  useUnlockVehicleMutation,
  useRestartVehicleMutation,
  useMarkChargeAsPaidMutation,
} = vehiclesApiSlice;
