import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { useGetVehiclesQuery } from "app/vehicles/vehiclesApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Battery, TableBadge, TableComponent, TableIcon, VehicleType } from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { VehicleStatus } from "utils/enums";

import { adminRoutesPath } from "views/admin/routes";
import { I_VEHICLE } from "views/partner/Vehicles/VehiclesListing/VehiclesListing";

const VehiclesListing = () => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const columnHelper = createColumnHelper<I_VEHICLE>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { data, isFetching, error } = useGetVehiclesQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
  });

  useEffect(() => {
    errorHandler(error);
  }, [error]);

  const columns = [
    columnHelper.accessor((row) => row, {
      id: "companyName",
      cell: (info) => (
        <Link
          to={adminRoutesPath.Partners.EditPartner.replace(
            ":partnerId",
            info.getValue()?.companyId?.toString() || ""
          )}
        >
          <p>{info.getValue().companyName}</p>
        </Link>
      ),
      header: () => <span>{GlobalStrings.formLabels.company}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.customId, {
      id: "customId",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.partners.identifier}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.type, {
      id: "type",
      cell: (info) => <VehicleType vehicles={info.getValue()} />,
      header: () => <span>{GlobalStrings.partners.vehicleType}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row.iotId, {
      id: "iotId",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.IDIoT}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.qrCode, {
      id: "qrCode",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.qrCode}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => (
        <TableBadge
          color={info.getValue() === VehicleStatus.ACTIVE ? "green" : "red"}
          text={GlobalStrings.vehicleStatus[info.getValue()]}
        />
      ),
      header: () => <span>{GlobalStrings.formLabels.status}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.batteryLevel, {
      id: "batteryLevel",
      cell: (info) => <Battery level={info.getValue()} />,
      header: () => <span>{GlobalStrings.formLabels.batteryLevel}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <Link
            to={adminRoutesPath.Vehicles.Vehicle.replace(
              ":vehicleId",
              info.getValue().id.toString()
            )}
          >
            <TableIcon type="info" />
          </Link>
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
    }),
  ];

  const table = useReactTable({
    data: data?.vehicles || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount: data?.meta?.numberOfPages,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <>
      <TableComponent
        table={table}
        tableHasData={data?.vehicles?.length > 0}
        isLoading={isFetching}
      />
    </>
  );
};
export default VehiclesListing;
