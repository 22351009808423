import React, { useState } from "react";
import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap";

import { GlobalStrings } from "utils";

export default function ConfirmationModal(props: {
  handler: any;
  buttonText: string;
  icon?: any;
  title: string;
}) {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal((prev) => !prev);

  const modalHandler = () => {
    props.handler();
    setModal(false);
  };

  return (
    <div className="d-flex justify-content-center gap-2">
      {props.icon ? (
        <div
          onClick={() => {
            setModal(true);
          }}
          className="edit_delete_container"
        >
          <span>{props.icon}</span>
        </div>
      ) : (
        <Button
          className="btn btn-primary"
          size="sm"
          onClick={() => {
            setModal(true);
          }}
        >
          {props.buttonText}
        </Button>
      )}
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>{props.title}</ModalHeader>
        <ModalFooter>
          <Button color="primary" onClick={modalHandler}>
            {GlobalStrings.yes}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            {GlobalStrings.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
