import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { selectCurrentUser } from "app/auth/authSlice";
import { selectMarketplaceIsUsedInApp } from "app/common/commonSlice";

import classNames from "classnames";
import { GlobalStrings, roleValidation } from "utils";

import styles from "./Categories.module.scss";

const Categories = (props: { categories: any }) => {
  const marketplaceIsUsedInApp = useSelector(selectMarketplaceIsUsedInApp);
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(selectCurrentUser);
  const [categoriesAreOpen, setCategoriesAreOpen] = useState(false);

  const categoriesHandler = (category: any) => {
    const categories = searchParams.getAll("categories");
    if (categories?.includes(category.id.toString())) {
      const filteredCategories = categories?.filter((item: any) => item !== category.id.toString());
      if (filteredCategories.length > 0) {
        searchParams.delete("categories");
        for (const filteredCategory of filteredCategories) {
          searchParams.append("categories", filteredCategory);
        }
        setSearchParams(searchParams);
      } else {
        searchParams.delete("categories");
        setSearchParams(searchParams);
      }
    } else {
      searchParams.append("categories", category.id);
      setSearchParams(searchParams);
    }
  };

  return (
    <div
      className={classNames(styles.categories_container, {
        [styles.used_for_app]: marketplaceIsUsedInApp || roleValidation("admin", user?.roles),
      })}
    >
      <p
        className={classNames(styles.categories_button)}
        onClick={() => setCategoriesAreOpen((prev) => !prev)}
      >
        {GlobalStrings.formLabels.categories}
      </p>
      <div
        className={classNames(styles.categories_list, {
          [styles.active]: categoriesAreOpen === true,
        })}
      >
        {props.categories?.map((item: any, index: number) => {
          return (
            <p
              key={index}
              className={classNames(styles.category, {
                [styles.active]: searchParams.getAll("categories").includes(item.id.toString()),
              })}
              onClick={() => categoriesHandler(item)}
            >
              {item.name}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default Categories;
