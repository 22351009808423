import React from "react";
import { ClipLoader } from "react-spinners";
import { LengthType } from "react-spinners/helpers/props";
import { Button } from "reactstrap";

import { GlobalVariables } from "../../utils";

const SubmitButton = (props: {
  isLoading: boolean;
  label: string;
  className?: string;
  clipLoaderSize?: LengthType;
  clipLoaderColor?: string;
  disabled?: boolean;
  color?: string;
}) => {
  return (
    <Button
      color={props.color || "success"}
      type="submit"
      className={`d-flex align-item-center gap-2 ${props.className}`}
      disabled={props.isLoading || props.disabled}
    >
      {props.label}
      <ClipLoader
        size={props.clipLoaderSize || "20px"}
        color={props.clipLoaderColor || GlobalVariables.whiteColor}
        loading={props.isLoading}
      />
    </Button>
  );
};

export default SubmitButton;
