import { I_ACTIVE_PRODUCTS } from "utils/types";

import { apiSlice } from "../apiSlice";

export const activeProductsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActiveProducts: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, companyId }) =>
        `${process.env.REACT_APP_MARKETPLACE_API_URL}/products?pagination=true&page=${
          pageIndex + 1
        }&limit=${pageSize}${
          sort ? `&sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}` : ""
        }${companyId ? `&companyId=${companyId}` : ""}`,
      keepUnusedDataFor: 3,
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const activeProducts: I_ACTIVE_PRODUCTS[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          activeProducts.push({
            id: item.id,
            acceptanceStatus: item.acceptanceStatus,
            name: item?.name || "",
            categories: item.categories?.map((category: any) => {
              return {
                id: category.id,
                name: category.name,
              };
            }),

            subCategory: item?.subCategory?.name || "",
            inStock: item?.inStock || "",
            price: item?.price || "",
            companyId: item?.companyId || "",
          });
        });
        return {
          activeProducts,
          meta,
        };
      },
    }),

    getActiveProductsId: builder.query<any, any>({
      query: (id: number) => `${process.env.REACT_APP_MARKETPLACE_API_URL}/products/${id}`,
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),

    updateActiveProducts: builder.mutation({
      query: (data: {
        body: {
          productName: string;
          for: string;
          category: string;
          brand: string;
          inStock: string;
          price: number;
        };
        id?: string | number;
      }) => ({
        url: `${process.env.REACT_APP_MARKETPLACE_API_URL}/products/${data.id}`,
        method: "PUT",
        body: { ...data.body },
      }),
    }),

    deleteActiveProducts: builder.mutation({
      query: (productId) => ({
        url: `${process.env.REACT_APP_MARKETPLACE_API_URL}/products/${productId}`,
        method: "DELETE",
      }),
    }),

    createActiveProducts: builder.mutation<any, Partial<any>>({
      query: (newActiveProducts) => ({
        url: `${process.env.REACT_APP_MARKETPLACE_API_URL}/products`,
        method: "POST",
        body: { ...newActiveProducts },
      }),
    }),
  }),
});

export const {
  useGetActiveProductsQuery,
  useGetActiveProductsIdQuery,
  useUpdateActiveProductsMutation,
  useDeleteActiveProductsMutation,
  useCreateActiveProductsMutation,
} = activeProductsApiSlice;
