import { NO_VALUE_SYMBOL } from "utils/globalStrings";

import { I_ELECTRICITY_CONTAINER } from "../../utils/types";

import { apiSlice } from "../apiSlice";

export const containersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableElectricityContainersCount: builder.query<any, void>({
      query: () => `${process.env.REACT_APP_CONTAINERS_API_URL}/containers?status=available`,
      keepUnusedDataFor: 3,
      transformResponse: (response: any) => {
        return response.data.pageData.length;
      },
    }),
    getNumberOfUsedContainers: builder.query<any, any>({
      query: (companyId: number) =>
        `${process.env.REACT_APP_CONTAINERS_API_URL}/containers/count/number-of-containers-in-use-with-filter?companyId=${companyId}`,
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    getAllElectricityContainers: builder.query<any, any>({
      query: ({ companyId }) => {
        let url = `${process.env.REACT_APP_CONTAINERS_API_URL}/containers?`;

        if (companyId) {
          url += `companyId=${companyId}`;
        }

        return url;
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => response.data?.pageData,
    }),
    getSortedElectricityContainer: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, companyId }) => {
        let url = `${process.env.REACT_APP_CONTAINERS_API_URL}/containers?`;
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (companyId) {
          url += `companyId=${companyId}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      keepUnusedDataFor: 3,
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const electricityContainers: I_ELECTRICITY_CONTAINER[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          electricityContainers.push({
            id: item.id,
            companyId: item?.companyId,
            name: item?.name,
            status: item?.status || NO_VALUE_SYMBOL,
            totalCapacity: item?.totalCapacity || NO_VALUE_SYMBOL,
            levelOfBattery: item?.levelOfBattery || NO_VALUE_SYMBOL,
            companyName: item?.companyName || NO_VALUE_SYMBOL,
            remainingEnergyCapacity: item.remainingEnergyCapacity || NO_VALUE_SYMBOL,
            numberOfDailyCharges: item.numberOfDailyCharges || NO_VALUE_SYMBOL,
            statusInvertor: item.statusInvertor,
            connectors: item.connectors,
            stations: item.stations,
          });
        });
        return {
          electricityContainers,
          meta,
        };
      },
    }),
    getElectricityContainer: builder.query<any, any>({
      query: (id: string) => `${process.env.REACT_APP_CONTAINERS_API_URL}/containers/${id}`,
      keepUnusedDataFor: 3,
      transformResponse: (response: any) => {
        const electricityContainer: any = {
          id: response.data.id,
          totalCapacity: response.data?.totalCapacity,
          lat: response.data.latitude,
          lng: response.data.longitude,
          address: response.data.address,
          type: response.data.type,
          numberOfPorts: response.data.numberOfPorts,
          status: response.data.status,
          name: response.data.name,
          companyId: response.data.companyId,
          companyName: response.data.companyName,
          statusInvertor: response.data.statusInvertor,
          connectors: response.data?.connectors,
          stations: response.data?.stations,
        };
        return {
          electricityContainer,
        };
      },
    }),
    deleteElectricityContainer: builder.mutation({
      query: (id: string | number) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/containers/${id}`,
        method: "DELETE",
      }),
    }),
    createElectricityContainer: builder.mutation<any, Partial<any>>({
      query: (body) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/containers`,
        method: "POST",
        body,
      }),
    }),
    updateElectricityContainer: builder.mutation<any, any>({
      query: (data: { body: any; id: number }) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/containers/${data.id}`,
        method: "PUT",
        body: data.body,
      }),
    }),
  }),
});

export const {
  useGetAvailableElectricityContainersCountQuery,
  useGetNumberOfUsedContainersQuery,
  useCreateElectricityContainerMutation,
  useGetAllElectricityContainersQuery,
  useGetSortedElectricityContainerQuery,
  useDeleteElectricityContainerMutation,
  useGetElectricityContainerQuery,
  useUpdateElectricityContainerMutation,
} = containersApiSlice;
