import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { selectCurrentUser } from "app/auth/authSlice";
import { useGetPartnerQuery } from "app/partners/partnersApiSlice";

import { IsLoading, PageHeader, PartnerForm } from "components";
import { GlobalStrings } from "utils";

import PartnerPrices from "./PartnerPrices/PartnerPrices";
import PartnerVehiclesMap from "./PartnerVehiclesMap/PartnerVehiclesMap";
import VehiclesBatteryLevels from "./VehiclesBatteryLevels/VehiclesBatteryLevels";
import TripsTable from "views/common/Partners/TripsTable/TripsTable";

import { partnerRoutesPath } from "../routes";

const PartnerDashboard = () => {
  const user = useSelector(selectCurrentUser);
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const {
    data: partner,
    isLoading,
    refetch,
  } = useGetPartnerQuery(user.company.id, { skip: !user.company.id });

  const tabs = [
    {
      id: 0,
      name: GlobalStrings.partners.map,
      slug: partnerRoutesPath.Dashboard.Index,
    },
    {
      id: 1,
      name: GlobalStrings.partners.tripsAndReports,
      slug: partnerRoutesPath.Dashboard.TripsAndReports,
    },
    {
      id: 2,
      name: GlobalStrings.partners.generalInfo,
      slug: partnerRoutesPath.Dashboard.GeneralInfo,
    },
    {
      id: 3,
      name: GlobalStrings.partners.prices,
      slug: partnerRoutesPath.Dashboard.Prices,
    },
  ];

  useEffect(() => {
    if (location.pathname === tabs[0].slug) {
      setActiveIndex(0);
    } else if (location.pathname === tabs[1].slug) {
      setActiveIndex(1);
    } else if (location.pathname === tabs[2].slug) {
      setActiveIndex(2);
    } else if (location.pathname === tabs[3].slug) {
      setActiveIndex(3);
    }
  }, [location.pathname]);

  const content = (
    <section className="dashboard">
      <PageHeader
        title={GlobalStrings.partners.mainPage}
        tabsCurrentview={tabs}
        tabsCurrentviewActiveIndex={activeIndex}
      />
      {location.pathname === tabs[0].slug && (
        <div className="mt-3">
          <PartnerVehiclesMap />
        </div>
      )}

      {location.pathname === tabs[1].slug && (
        <div className="mt-3">
          <TripsTable id={user.company.id} hidePageHeader />
        </div>
      )}

      {location.pathname === tabs[2].slug && (
        <div className="mt-3">
          {isLoading ? (
            <IsLoading />
          ) : (
            <div className="card">
              <PartnerForm
                data={partner}
                refetch={refetch}
                id={user.company.id as any}
                hasFiedlsDisabled
              />
              <VehiclesBatteryLevels data={partner} refetch={refetch} />
            </div>
          )}
        </div>
      )}

      {location.pathname === tabs[3].slug && (
        <div className="mt-3">
          {isLoading ? (
            <IsLoading />
          ) : (
            <div className="card">
              <PartnerPrices
                identifier={partner.identifier}
                individualTransportVehicles={partner.individualTransportVehicles}
              />
            </div>
          )}
        </div>
      )}
    </section>
  );

  return content;
};
export default PartnerDashboard;
