import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  useSetChargingModeMutation,
  useStopChargingModeMutation,
} from "app/vehicles/vehiclesApiSlice";

import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { Battery } from "components";
import { errorHandler, GlobalStrings } from "utils";
import { VehicleStatus } from "utils/enums";
import { I_VEHICLE } from "utils/types";

import bikePin from "assets/icons/bike_pin.svg";
import carPin from "assets/icons/car_pin.svg";
import scooterPin from "assets/icons/scooter_pin.svg";

const VehiclesMarker = (props: {
  clusterer: any;
  item: any;
  active: I_VEHICLE | undefined;
  setActive: any;
  refetch: Function;
}) => {
  const [setChargingMode] = useSetChargingModeMutation();
  const [stopChargingMode] = useStopChargingModeMutation();
  const google = window.google;
  const [MVCObject, setMVCObject] = useState(undefined);
  const handleOnLoad = useCallback((marker: any) => {
    setMVCObject(marker);
  }, []);

  const [image, setImage] = useState("");
  useEffect(() => {
    let image;
    switch (true) {
      case props.item?.type && props.item?.type === "car":
        image = carPin;
        break;
      case props.item?.type && props.item?.type === "bicycle":
        image = bikePin;
        break;
      case props.item?.type && props.item?.type === "scooter":
        image = scooterPin;
        break;
      default:
        image = scooterPin;
    }
    setImage(image);
  }, [props.item]);

  const setChargingModeHandler = async () => {
    try {
      const response: any = await setChargingMode(props?.active?.id);
      props.setActive(response.data.data);
      toast.success("Starea vehiculului a fost schimbata cu succes!");
    } catch (error) {
      errorHandler(error);
    }
  };

  const stopChargingModeHandler = async () => {
    try {
      const response: any = await stopChargingMode(props?.active?.id);
      props.setActive(response.data.data);
      props.refetch();
      toast.success("Starea vehiculului a fost schimbata cu succes!");
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      {image && (
        <MarkerF
          onLoad={handleOnLoad}
          icon={{
            url: image,
            scaledSize: new google.maps.Size(50, 50),
          }}
          position={{
            lat: parseFloat(props.item?.position?.latitude) || parseFloat(props.item?.latitude),
            lng: parseFloat(props.item?.position?.longitude) || parseFloat(props.item?.longitude),
          }}
          clusterer={props.clusterer}
          clickable
          onClick={() => props.setActive(props.item)}
        >
          {props.active && props.active?.id === props.item?.id && (
            <InfoWindowF
              anchor={MVCObject}
              onCloseClick={() => {
                setMVCObject(undefined);
                props.setActive(undefined);
              }}
              position={{
                lat: +props.item.lat,
                lng: +props.item.lng,
              }}
            >
              <div className="map_pin">
                <h4>{GlobalStrings.vehicleTypes[props.active.type]}</h4>
                <h6 className="text-center mb-4">{props.active.companyName}</h6>

                <p>
                  <span>{GlobalStrings.formLabels.status}</span>
                  <span>{GlobalStrings.vehicleStatus[props.active.status]}</span>
                </p>
                <p>
                  <span>{GlobalStrings.formLabels.model}</span>
                  <span>{props.active.model}</span>
                </p>

                <Battery level={props.active.batteryLevel} />
                <div className="buttons d-flex gap-2 mt-4 flex-column flex-md-row">
                  <button
                    className="btn btn-primary flex-fill"
                    onClick={setChargingModeHandler}
                    disabled={props.active.status === VehicleStatus.CHARGING}
                  >
                    {GlobalStrings.formLabels.block}
                  </button>
                  <button
                    className="btn btn-success flex-fill"
                    onClick={stopChargingModeHandler}
                    disabled={props.active.status === VehicleStatus.ACTIVE}
                  >
                    {GlobalStrings.formLabels.activate}
                  </button>
                </div>
              </div>
            </InfoWindowF>
          )}
        </MarkerF>
      )}
    </>
  );
};

export default VehiclesMarker;
