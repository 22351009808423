import React from "react";

import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export default function BarChartComponent({
  data,
  fillColor = "#dc2949",
}: {
  data: { name: string; count: number }[];
  fillColor?: string;
}) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data} width={100}>
        <CartesianGrid strokeDasharray="4" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="count" fill={fillColor} barSize={40} />
      </BarChart>
    </ResponsiveContainer>
  );
}
