/* eslint-disable no-unused-vars */
export enum Currency {
  RON = "RON",
  EURO = "EURO",
  LEI = "LEI",
}

export enum ProductStatus {
  IN_STOCK = "inStock",
  OUT_OF_STOCK = "outOfStock",
}

export enum TaskStatus {
  Active = "active",
  Inactive = "inactive",
  Pending = "pending",
}

export enum Selection {
  all = "All",
}

export enum PaymentType {
  CARD = "card",
  CASH = "cash",
}

export enum TransportType {
  SCOOTER = "scooter",
  CAR = "car",
  BICYCLE = "bicycle",
  RIDESHARING = "rideSharing",
  TRAM = "tram",
  BUS = "bus",
  BUSTRAM = "bus-tram",
}

export enum UserStatus {
  blocked = "blocked",
  active = "active",
  inactive = "inactive",
}

export enum AccountStatus {
  INNACTIVE = "inactive",
  ACTIVE = "active",
  BLOCKED = "blocked",
  UNVERIFIED = "unverified",
}

export enum ElectricityContainerStatus {
  CHARGING = "charging",
  OUTOFUSE = "outofuse",
  AVAILABLE = "available",
}

export enum ElectricityContainerType {
  FIXED = "fixed",
  MOBILE = "mobile",
}
export enum AdminUserRoles {
  admin = "admin",
  partial_admin = "partial_admin",
}

export enum ProductUM {
  PIECE = "pc",
  HOUR = "hour",
  KG = "kg",
  L = "l",
}

export enum UserRoles {
  admin = "admin",
  partial_admin = "partial_admin",
  supplier = "supplier",
  supplier_admin = "supplier_admin",
  partner = "partner",
  partner_admin = "partner_admin",
  charging_station_partner_admin = "charging_station_partner_admin",
}

export enum ProductAcceptanceStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  DECLINED = "declined",
}

export enum CompanyType {
  SUPPLIER = "supplier",
  PARTNER = "partner",
  PUBLIC_TRANSPORT = "public_transport",
  CHARGING_STATION_PARTNER = "charging_station_partner",
}

export enum ManagedVehicles {
  BUS = "bus",
  TRAM = "tram",
  BUSTRAM = "bus/tram",
}

export enum ServiceType {
  RECHARGEBATTERY = "recharge battery",
  REPAIR = "repair",
  VULCANIZATION = "vulcanization",
}

export enum VehicleStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DAMAGED = "damaged",
  STOLEN = "stolen",
  CHARGING = "charging",
  IN_USE = "in_use",
  RESERVED = "reserved",
  UNAVAILABLE = "unavailable",
  LOCATION_UNAVAILABLE = "location_unavailable",
}

export enum Connector {
  CCS = "CCS",
  CHAdeMO = "CHAdeMO",
  AC = "AC",
  OUTLET_220 = "220",
}

export enum StationStatus {
  ACTIVE = "active",
  OUTOFUSE = "outofuse",
  MAINTENANCE = "maintenance",
}

export enum ConnectorStatus {
  CHARGING = "charging",
  OUTOFUSE = "outofuse",
  AVAILABLE = "available",
  ACTIVE = "active",
  FINISHED = "finished",
  CANCELED = "canceled",
  IN_USE = "in_use",
}

export enum TripStatus {
  RESERVED = "RESERVED",
  ACTIVE = "ACTIVE",
  FINISHED = "FINISHED",
  CANCELED = "CANCELED",
  PAUSED = "PAUSED",
}

export enum DocumentStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  EXPIRED = "expired",
}

export enum TypeOfDocument {
  ID = "identity-card",
  DRIVER_LICENCE = "driver-licence",
}

export enum PartOfDocuemnt {
  FACE = "face",
  BACK = "back",
}

export enum PaymentStatus {
  PAYMENT_STATUS_OK = "complete-ok",
  PAYMENT_STATUS_FAILED = "complete-failed",
  PAYMENT_STATUS_REFUND_OK = "refund-ok",
  PAYMENT_STATUS_REFUND_FAILED = "refund-failed",
  PAYMENT_STATUS_IN_PROGRESS = "in-progress",
  PAYMENT_STATUS_IN_PROGRESS_AUTHORIZATION = "in-progress-authorization",
  PAYMENT_STATUS_CANCEL = "cancel-ok",
  PAYMENT_STATUS_AUTHORIZED = "authorized",
}

export enum ChargeStatus {
  PENDING = "pending",
  PAID = "paid",
  SUSPENDED = "suspended",
}

export enum BookingStatus {
  CHARGING = "charging",
  OUTOFUSE = "outofuse",
  AVAILABLE = "available",
  ACTIVE = "active",
  FINISHED = "finished",
  CANCELED = "canceled",
  UNFULFILLED = "unfulfilled",
}

export enum BookingType {
  FIXED = "fixed",
  MOBILE = "mobile",
  FAST_CHARGE = "fastCharge",
}

export enum VoucherType {
  PERCENTAGE = "percentage",
  MINUTES = "minutes",
  VALUE = "value",
}
