import React, { useEffect, useState } from "react";

import { useGetNearbyVehiclesQuery } from "app/vehicles/vehiclesApiSlice";

import { GoogleMap, MarkerClustererF, useLoadScript } from "@react-google-maps/api";
import { IsLoading } from "components";
import { GlobalVariables } from "utils";

import VehiclesMarker from "./VehiclesMarker";

const containerStyle = {
  width: "100%",
  height: "75vh",
};

function VehiclesMapView() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
  });
  const [active, setActive] = useState();
  const [map, setMap] = useState<any>();
  const [bounds, setBounds] = useState<any>(null);
  const [boundsCopy, setBoundsCopy] = useState<any>(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { data, isLoading, refetch } = useGetNearbyVehiclesQuery(
    {
      westLongitude: bounds?.getSouthWest().lng(),
      eastLongitude: bounds?.getNorthEast().lng(),
      northLatitude: bounds?.getNorthEast().lat(),
      southLatitude: bounds?.getSouthWest().lat(),
      agencyId: 1,
      withScooters: true,
      withCars: false,
      withBicycles: true,
      withContainers: false,
      withBusStops: false,
    },
    { skip: !bounds }
  );

  const onBoundsChanged = () => {
    if (map) {
      const bounds = map.getBounds();
      setBoundsCopy(bounds);
    }
  };

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    const delay = setTimeout(
      () => {
        setBounds(boundsCopy);
      },
      isFirstLoad ? 0 : 1000
    );
    return () => clearTimeout(delay);
  }, [boundsCopy]);

  return (
    <div>
      {isLoading ? (
        <IsLoading />
      ) : isLoaded ? (
        <div className="position-relative">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={GlobalVariables.center}
            onLoad={(mapInstance) => setMap(mapInstance)}
            onBoundsChanged={onBoundsChanged}
            zoom={13}
          >
            <MarkerClustererF
              averageCenter={false}
              maxZoom={20}
              gridSize={50}
              minimumClusterSize={5}
            >
              {(clusterer) => (
                <>
                  {data?.map((item: any, index: any) => {
                    return (
                      <VehiclesMarker
                        clusterer={clusterer}
                        item={item}
                        key={index}
                        active={active}
                        setActive={setActive}
                        refetch={refetch}
                      />
                    );
                  })}
                </>
              )}
            </MarkerClustererF>
          </GoogleMap>
        </div>
      ) : (
        <>Loading....</>
      )}
    </div>
  );
}

export default React.memo(VehiclesMapView);
