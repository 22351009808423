import React from "react";

import { PageHeader } from "components";
import { GlobalStrings } from "utils";

import VehiclesListing from "./VehiclesListing/VehiclesListing";

const AdminVehicles = () => {
  return (
    <section>
      <PageHeader title={GlobalStrings.partners.partnersVehicles} />

      <VehiclesListing />
    </section>
  );
};
export default AdminVehicles;
