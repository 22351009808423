import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useGetServicesQuery } from "app/services/servicesApiSlice";

import classNames from "classnames";
import { IsLoading, NoResults, Pagination } from "components";
import { GlobalStrings, GlobalVariables } from "utils";
import { Currency, TransportType } from "utils/enums";
import { I_SERVICE } from "utils/types";

import ContactDetailsPopup from "./ContactDetailsPopup/ContactDetailsPopup";

import styles from "./AppServicesListing.module.scss";

type ParamsType = {
  vehicleType: TransportType;
};

const AppServicesListing = () => {
  const { vehicleType } = useParams<ParamsType>();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [activeSupplier, setActiveSupplier] = useState<I_SERVICE>();

  const { data, isLoading } = useGetServicesQuery(
    {
      pageIndex,
      pageSize,
      vehicleType,
    },
    {
      skip: !vehicleType,
    }
  );

  useEffect(() => {
    if (activeSupplier) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [activeSupplier]);

  return (
    <section className={styles.app_services}>
      <h1>
        {GlobalStrings.appServices.servicesFor}{" "}
        {vehicleType && GlobalStrings.vehicleTypes[vehicleType].toLowerCase()}
      </h1>
      {isLoading ? (
        <IsLoading />
      ) : (
        <div className={styles.services}>
          {data?.services?.map((item: I_SERVICE, index: number) => {
            return (
              <div key={index} className={classNames(styles.service)}>
                <p className={styles.company_name}>{item.company.name}</p>
                <p>
                  <span>{GlobalStrings.formLabels.city}</span>
                  {item.city}
                </p>
                <p>
                  <span>{GlobalStrings.formLabels.status}</span>
                  {GlobalStrings.userAccountStatus[item.status]}
                </p>
                <p>
                  <span>{GlobalStrings.formLabels.serviceName}</span>
                  {GlobalStrings.servicesTypes[item.serviceName]}
                </p>
                <p>
                  <span>{GlobalStrings.formLabels.coveredZone}</span>
                  {item.coveredZone}
                </p>
                {vehicleType === TransportType.CAR && (
                  <>
                    <p>
                      <span>{GlobalStrings.formLabels.amountAC}</span>
                      {isNaN(item.amountAC)
                        ? item.amountAC
                        : item.amountAC + " " + Currency.RON + "/" + GlobalVariables.amountAC}
                    </p>
                    <p>
                      <span>{GlobalStrings.formLabels.amountDC}</span>
                      {isNaN(item.amountDC)
                        ? item.amountDC
                        : item.amountDC + " " + Currency.RON + "/" + GlobalVariables.amountAC}
                    </p>
                  </>
                )}
                {vehicleType === TransportType.BICYCLE && (
                  <p>
                    <span>{GlobalStrings.formLabels.amountBicycle}</span>
                    {isNaN(item.amount) ? item.amount : item.amount + " " + Currency.RON}
                  </p>
                )}
                {vehicleType === TransportType.SCOOTER && (
                  <p>
                    <span>{GlobalStrings.formLabels.amountScooter}</span>
                    {isNaN(item.amount) ? item.amount : item.amount + " " + Currency.RON}
                  </p>
                )}

                <button className={styles.btn_mobile} onClick={() => setActiveSupplier(item)}>
                  {GlobalStrings.formLabels.showContactInfo}
                </button>
              </div>
            );
          })}
        </div>
      )}
      {!isLoading && data?.services?.length === 0 ? <NoResults /> : null}
      <div className={styles.pagination}>
        {!isLoading && (
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            numberOfPages={data?.meta?.numberOfPages}
          />
        )}
      </div>

      <ContactDetailsPopup
        email={activeSupplier?.company.email || "-"}
        phone={activeSupplier?.company.phone || "-"}
        supplierName={activeSupplier?.company.name || "-"}
        onClose={() => setActiveSupplier(undefined)}
        isActive={activeSupplier !== undefined}
      />
    </section>
  );
};

export default AppServicesListing;
