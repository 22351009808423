import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { selectCurrentUser } from "app/auth/authSlice";
import { useDeleteVehicleMutation, useGetVehiclesQuery } from "app/vehicles/vehiclesApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  Battery,
  ConfirmationPopup,
  TableBadge,
  TableComponent,
  TableIcon,
  VehicleType,
} from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { VehicleStatus } from "utils/enums";

import { partnerRoutesPath } from "views/partner/routes";

export type I_VEHICLE = {
  id: number;
  customId: string;
  iotId: string;
  iotType: string;
  model: string;
  position: {
    latitude: number;
    longitude: number;
  };
  status: VehicleStatus;
  batteryLevel: number;
  averageTravelTime: number;
  averageTripsPerDay: number;
  tripsSinceLastCharge: number;
  type: string;
  companyName?: string;
  companyId?: string;
  qrCode?: string;
};

const VehiclesListing = () => {
  const user = useSelector(selectCurrentUser);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [vehicleToDelete, setVehicleToDelete] = useState<any>();
  const toggleModal = () => setModalIsOpen((prev) => !prev);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const columnHelper = createColumnHelper<I_VEHICLE>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { data, isFetching, error, refetch } = useGetVehiclesQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
    partnerId: user.company.id,
  });
  const [deleteVehicle] = useDeleteVehicleMutation();

  useEffect(() => {
    errorHandler(error);
  }, [error]);

  const deleteVehicleHandler = async () => {
    try {
      await deleteVehicle(vehicleToDelete.id).unwrap();
      toggleModal();
      refetch();
      toast.success(GlobalStrings.partners.vehicleHasBeenDeleted);
    } catch (error) {
      errorHandler(error);
    }
  };

  const columns = [
    columnHelper.accessor((row) => row.customId, {
      id: "customId",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.partners.identifier}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row.type, {
      id: "type",
      cell: (info) => <VehicleType vehicles={info.getValue()} />,
      header: () => <span>{GlobalStrings.partners.vehicleType}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row.iotId, {
      id: "iotId",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.IDIoT}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row.qrCode, {
      id: "qrCode",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.qrCode}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => (
        <TableBadge
          color={info.getValue() === VehicleStatus.ACTIVE ? "green" : "red"}
          text={GlobalStrings.vehicleStatus[info.getValue()]}
        />
      ),
      header: () => <span>{GlobalStrings.formLabels.status}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 12,
    }),
    columnHelper.accessor((row) => row.batteryLevel, {
      id: "batteryLevel",
      cell: (info) => <Battery level={info.getValue()} />,
      header: () => <span>{GlobalStrings.formLabels.batteryLevel}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <Link
            to={partnerRoutesPath.Vehicles.Info.replace(
              ":vehicleId",
              info.getValue().id.toString()
            )}
          >
            <TableIcon type="info" />
          </Link>
          <Link
            to={partnerRoutesPath.Vehicles.Vehicle.replace(
              ":vehicleId",
              info.getValue().id.toString()
            )}
          >
            <TableIcon type="edit" />
          </Link>
          <TableIcon
            type="delete"
            onClickAction={() => {
              toggleModal();
              setVehicleToDelete(info.getValue());
            }}
          />
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
    }),
  ];

  const table = useReactTable({
    data: data?.vehicles || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount: data?.meta?.numberOfPages,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <>
      <TableComponent
        table={table}
        tableHasData={data?.vehicles?.length > 0}
        isLoading={isFetching}
      />

      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        title={`${GlobalStrings.partners.modalTitleVehicles} - ${vehicleToDelete?.customId}`}
        body={GlobalStrings.partners.modalBodyVehicles}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deleteVehicleHandler}
      />
    </>
  );
};
export default VehiclesListing;
