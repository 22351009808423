import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";

import {
  useDeleteActiveServicesMutation,
  useGetActiveServicesQuery,
} from "app/suppliers/activeServicesApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ConfirmationPopup, PageHeader, TableComponent, TableIcon } from "components";
import { errorHandler, getSortDetails, GlobalStrings, GlobalVariables } from "utils";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_ACTIVE_SERVICES } from "utils/types";

const ServicesTable = ({
  supplierId,
  pageHeader,
}: {
  supplierId: string | number;
  pageHeader?: {
    title: string;
    tabs?: {
      id: string | number;
      name: string;
      slug: string;
    }[];
  };
}) => {
  const [deleteActiveServices] = useDeleteActiveServicesMutation();
  const columnHelper = createColumnHelper<I_ACTIVE_SERVICES>();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeServiceToDelete, setActiveServiceToDelete] = useState<any>();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, isFetching, refetch } = useGetActiveServicesQuery({
    pageIndex,
    pageSize,
    companyId: supplierId,
    sort: getSortDetails(sorting[0]),
  });

  const toggleModal = () => setModalIsOpen((prev) => !prev);

  const deleteActiveServiceHandler = async () => {
    try {
      await deleteActiveServices(activeServiceToDelete.id).unwrap();
      toggleModal();
      refetch();
      toast.success(GlobalStrings.activeServices.deleteSuccessToast);
    } catch (error) {
      errorHandler(error);
    }
  };

  const columns = [
    columnHelper.accessor((row: any) => row.serviceName, {
      id: "serviceName",
      cell: (info) => (
        <p>
          {GlobalVariables.serviceType.find((service) => service.value === info.getValue())
            ?.label || NO_VALUE_SYMBOL}
        </p>
      ),
      header: () => <span>{GlobalStrings.activeServices.serviceName}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 30,
    }),
    columnHelper.accessor((row: any) => row.vehicleType, {
      id: "vehicleType",
      cell: (info) => (
        <p>
          {GlobalVariables.vehicleTypes.find((vehicle) => vehicle.value === info.getValue())
            ?.label || NO_VALUE_SYMBOL}
        </p>
      ),
      header: () => <span>{GlobalStrings.activeServices.vehicleType}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 30,
    }),
    columnHelper.accessor((row: any) => row.city, {
      id: "city",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.activeServices.city}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 5,
    }),
    columnHelper.accessor((row: any) => row.coveredZone, {
      id: "coveredZone",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.activeServices.coveredZone}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 5,
    }),
    columnHelper.accessor((row: any) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <TableIcon
            type="delete"
            onClickAction={() => {
              toggleModal();
              setActiveServiceToDelete(info.getValue());
            }}
          />
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
    }),
  ];

  const table = useReactTable({
    data: data?.activeServices || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <section>
      {pageHeader && <PageHeader title={pageHeader.title} tabs={pageHeader.tabs} />}

      <TableComponent
        table={table}
        tableHasData={data?.activeServices?.length > 0}
        isLoading={isFetching}
      />

      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        title={`${GlobalStrings.activeServices.deleteService} - ${activeServiceToDelete?.serviceName} - ${activeServiceToDelete?.vehicleType}`}
        body={GlobalStrings.activeServices.deleteServiceModalBody}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deleteActiveServiceHandler}
      />
    </section>
  );
};
export default ServicesTable;
