import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { useInviteUserMutation } from "app/users/usersApiSlice";

import { AlertComponent, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { errorHandler, GlobalStrings, GlobalVariables, Regex } from "utils";
import { I_INVITE_USER_FORM } from "utils/types";

const CreateAdminAccountForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      role: GlobalVariables.userTypes[0].value,
    },
  });
  const [inviteUser, { isLoading }] = useInviteUserMutation();
  const { serverMessage, setServerMessage } = useServerMessage();

  const onSubmit = async ({ email, firstName, lastName, role }: I_INVITE_USER_FORM) => {
    try {
      await inviteUser({ email, firstName, lastName, role }).unwrap();
      setServerMessage({
        type: "success",
        text: GlobalStrings.settings[200],
      });
    } catch (error: any) {
      errorHandler(error, true, setServerMessage, GlobalStrings.settings);
    }
  };

  return (
    <section className="d-flex flex-column gap-5">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.firstName}
                  type="text"
                />
              )}
            />
            <Label for="firstName">{GlobalStrings.formLabels.firstName}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.lastName}
                  type="text"
                />
              )}
            />
            <Label for="lastName">{GlobalStrings.formLabels.lastName}</Label>
          </FormGroup>
        </div>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="email"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.email,
                  message: GlobalStrings.formValidation.invalidEmail,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.email}
                  type="text"
                />
              )}
            />
            <Label for="email">{GlobalStrings.formLabels.email}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="role"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.userType}
                  type="select"
                >
                  {GlobalVariables.userTypes.map((item: any) => {
                    return (
                      <option key={item.id} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              )}
            />
            <Label for="role">{GlobalStrings.formLabels.userType}</Label>
          </FormGroup>
        </div>

        <SubmitButton
          isLoading={isLoading}
          label={GlobalStrings.settings.createAcoountButton}
          className="mt-3"
        />
      </Form>
      <AlertComponent errors={errors} serverMessage={serverMessage} />
    </section>
  );
};
export default CreateAdminAccountForm;
