import React from "react";
import { BsSearch } from "react-icons/bs";

import { GlobalStrings } from "../../utils";

import styles from "./NoResults.module.scss";

const NoResults = () => {
  return (
    <div className={styles.no_results_container}>
      <h3>
        <BsSearch size={27} /> {GlobalStrings.noResults}
      </h3>
    </div>
  );
};

export default NoResults;
