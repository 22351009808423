import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, FormGroup, Input, InputGroupText, Label } from "reactstrap";

import {
  useAddPricesToPartnerMutation,
  useGetPricesByPartnerQuery,
  useUpdatePricesToPartnerMutation,
} from "app/partners/partnersApiSlice";

import { AlertComponent, IsLoading, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { errorHandler, GlobalStrings, GlobalVariables } from "utils";
import { Currency } from "utils/enums";

type PRICES_PARTNER_FORM = {
  info: {
    scootersPrices: {
      unlockPrice: string;
      minutePrice: string;
      minutePriceBreak: string;
    };
    bicyclesPrices: {
      unlockPrice: string;
      minutePrice: string;
      minutePriceBreak: string;
    };
    carsPrices: {
      unlockPrice: string;
      minutePrice: string;
      kilometerPrice: string;
    };
  };
};

const PartnerPrices = (props: { identifier: string; individualTransportVehicles: string }) => {
  const { serverMessage, setServerMessage } = useServerMessage();
  const [pricesOfPartner, setPricesOfPartner] = useState({
    info: {
      scootersPrices: {
        unlockPrice: "",
        minutePrice: "",
        minutePriceBreak: "",
      },
      bicyclesPrices: {
        unlockPrice: "",
        minutePrice: "",
        minutePriceBreak: "",
      },
      carsPrices: { unlockPrice: "", minutePrice: "" },
    },
  });
  const [partnerPriceId, setPartnerPriceId] = useState(null);
  const [allFieldsEmpty, setAllFieldsEmpty] = useState(true);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<PRICES_PARTNER_FORM>({
    defaultValues: {
      info: {
        scootersPrices: {
          unlockPrice: pricesOfPartner?.info?.scootersPrices.unlockPrice || "",
          minutePrice: pricesOfPartner?.info?.scootersPrices.minutePrice || "",
          minutePriceBreak: pricesOfPartner?.info?.scootersPrices.minutePriceBreak || "",
        },
        bicyclesPrices: {
          unlockPrice: pricesOfPartner?.info?.bicyclesPrices.unlockPrice || "",
          minutePrice: pricesOfPartner?.info?.bicyclesPrices.minutePrice || "",
          minutePriceBreak: pricesOfPartner?.info?.bicyclesPrices.minutePriceBreak || "",
        },
        carsPrices: {
          unlockPrice: pricesOfPartner?.info?.carsPrices.unlockPrice || "",
          minutePrice: pricesOfPartner?.info?.carsPrices.minutePrice || "",
        },
      },
    },
  });

  const { data, isLoading, refetch } = useGetPricesByPartnerQuery(props.identifier);

  const [updatePricesToPartner, { isLoading: isLoadingUpdate }] =
    useUpdatePricesToPartnerMutation();

  const [addPricesToPartner, { isLoading: isLoadingAdding }] = useAddPricesToPartnerMutation();

  useEffect(() => {
    if (!isLoading && data) {
      setPricesOfPartner(data);
      setPartnerPriceId(data.id);
    }
  }, [isLoading, data]);

  useEffect(() => {
    const areAllFieldsEmpty = Object.values(pricesOfPartner.info).every((priceInfo: any) =>
      Object.values(priceInfo).every((priceValue: any) => priceValue === "")
    );

    setAllFieldsEmpty(areAllFieldsEmpty);

    reset({
      info: {
        scootersPrices: pricesOfPartner.info.scootersPrices,
        bicyclesPrices: pricesOfPartner.info.bicyclesPrices,
        carsPrices: pricesOfPartner.info.carsPrices,
      },
    });
  }, [reset, pricesOfPartner]);

  const vehicleTypes = props.individualTransportVehicles?.split(", ");
  const includesScooter = vehicleTypes?.includes(GlobalVariables.vehicleTypes[0].value);
  const includesBicycles = vehicleTypes?.includes(GlobalVariables.vehicleTypes[2].value);
  const includesCars = vehicleTypes?.includes(GlobalVariables.vehicleTypes[1].value);

  const updatePricePartner = async (data: PRICES_PARTNER_FORM) => {
    try {
      if (partnerPriceId) {
        await updatePricesToPartner({
          id: partnerPriceId,
          body: data,
        }).unwrap();
        setServerMessage({
          type: "success",
          text: GlobalStrings.addPrice.pricesUpdated,
        });
        refetch();
      }
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  const createPricePartner = async (body: any) => {
    try {
      const requestBody = {
        ...body,
        name: props.identifier,
      };

      await addPricesToPartner(requestBody).unwrap();

      setServerMessage({
        type: "success",
        text: GlobalStrings.addPrice.pricesSaved,
      });
      refetch();
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  const onSubmit = async (formData: PRICES_PARTNER_FORM) => {
    if (partnerPriceId) {
      updatePricePartner(formData);
    } else {
      createPricePartner(formData);
    }
  };

  const handleChangeInput = (field: any, e: any) => {
    field.onChange(e);
    setAllFieldsEmpty(false);
  };

  if (isLoadingAdding) {
    return <IsLoading />;
  }

  return (
    <section>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {includesScooter && (
          <div>
            <h4 className="mb-3">{GlobalStrings.partners.pricesScooters}</h4>
            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-100">
                <Controller
                  name="info.scootersPrices.unlockPrice"
                  control={control}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      onChange={(e) => handleChangeInput(field, e)}
                      placeholder={GlobalStrings.formLabels.unlockPrice}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="info.scootersPrices.unlockPrice">
                  {GlobalStrings.formLabels.unlockPrice}
                </Label>
              </FormGroup>

              <FormGroup floating className="w-100">
                <Controller
                  name="info.scootersPrices.minutePrice"
                  control={control}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      onChange={(e) => handleChangeInput(field, e)}
                      placeholder={GlobalStrings.formLabels.minutePrice}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="info.scootersPrices.minutePrice">
                  {GlobalStrings.formLabels.minutePrice}
                </Label>
              </FormGroup>

              <FormGroup floating className="w-100">
                <Controller
                  name="info.scootersPrices.minutePriceBreak"
                  control={control}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      onChange={(e) => handleChangeInput(field, e)}
                      placeholder={GlobalStrings.formLabels.minutePriceBreak}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="info.scootersPrices.minutePriceBreak">
                  {GlobalStrings.formLabels.minutePriceBreak}
                </Label>
              </FormGroup>
            </div>
          </div>
        )}

        {includesBicycles && (
          <div>
            <h4 className="mb-3">{GlobalStrings.partners.pricesBicycles}</h4>
            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-100">
                <Controller
                  name="info.bicyclesPrices.unlockPrice"
                  control={control}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      onChange={(e) => handleChangeInput(field, e)}
                      placeholder={GlobalStrings.formLabels.unlockPrice}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="info.bicyclesPrices.unlockPrice">
                  {GlobalStrings.formLabels.unlockPrice}
                </Label>
              </FormGroup>

              <FormGroup floating className="w-100">
                <Controller
                  name="info.bicyclesPrices.minutePrice"
                  control={control}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      onChange={(e) => handleChangeInput(field, e)}
                      placeholder={GlobalStrings.formLabels.minutePrice}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="info.bicyclesPrices.minutePrice">
                  {GlobalStrings.formLabels.minutePrice}
                </Label>
              </FormGroup>

              <FormGroup floating className="w-100">
                <Controller
                  name="info.bicyclesPrices.minutePriceBreak"
                  control={control}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      onChange={(e) => handleChangeInput(field, e)}
                      placeholder={GlobalStrings.formLabels.minutePriceBreak}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="info.bicyclesPrices.minutePriceBreak">
                  {GlobalStrings.formLabels.minutePriceBreak}
                </Label>
              </FormGroup>
            </div>
          </div>
        )}

        {includesCars && (
          <div>
            <h4 className="mb-3">{GlobalStrings.partners.pricesCars}</h4>
            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-33">
                <Controller
                  name="info.carsPrices.unlockPrice"
                  control={control}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      onChange={(e) => handleChangeInput(field, e)}
                      placeholder={GlobalStrings.formLabels.unlockPrice}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="info.carsPrices.unlockPrice">
                  {GlobalStrings.formLabels.unlockPrice}
                </Label>
              </FormGroup>

              <FormGroup floating className="w-33">
                <Controller
                  name="info.carsPrices.minutePrice"
                  control={control}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      onChange={(e) => handleChangeInput(field, e)}
                      placeholder={GlobalStrings.formLabels.minutePrice}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="info.carsPrices.minutePrice">
                  {GlobalStrings.formLabels.minutePrice}
                </Label>
              </FormGroup>
            </div>
          </div>
        )}

        <SubmitButton
          isLoading={isLoading || isLoadingUpdate}
          label={partnerPriceId ? GlobalStrings.formLabels.update : GlobalStrings.formLabels.add}
          disabled={allFieldsEmpty}
        />
      </Form>

      <AlertComponent errors={errors} serverMessage={serverMessage} />
    </section>
  );
};

export default PartnerPrices;
