import { apiSlice } from "../apiSlice";

export const settingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<any, any>({
      query: ({ pageIndex, pageSize }) => {
        let url = `${process.env.REACT_APP_CONSUMER_API_URL}/app-settings?`;
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        return { settings: response?.data?.pageData };
      },
    }),
    addSetting: builder.mutation<any, any>({
      query: (body) => ({
        url: `${process.env.REACT_APP_CONSUMER_API_URL}/app-settings`,
        method: "POST",
        body,
      }),
    }),
    updateSetting: builder.mutation<any, any>({
      query: (data: { body: any; id: any }) => ({
        url: `${process.env.REACT_APP_CONSUMER_API_URL}/app-settings/${data.id}`,
        method: "PUT",
        body: data.body,
      }),
    }),
    enableMaintenance: builder.mutation<any, void>({
      query: () => ({
        url: `${process.env.REACT_APP_CONSUMER_API_URL}/maintenance/enable`,
        method: "POST",
      }),
    }),
    disableMaintenance: builder.mutation<any, void>({
      query: () => ({
        url: `${process.env.REACT_APP_CONSUMER_API_URL}/maintenance/disable`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useAddSettingMutation,
  useUpdateSettingMutation,
  useDisableMaintenanceMutation,
  useEnableMaintenanceMutation,
} = settingsApiSlice;
