import React from "react";

import classNames from "classnames";
import { GlobalStrings, GlobalVariables } from "utils";
import { TransportType } from "utils/enums";

import styles from "./VehicleType.module.scss";

const VehicleType = (props: { vehicles: string }) => {
  const vehiclesArray = props.vehicles.split(", ") as TransportType[];

  return (
    <div className={classNames(styles.vehicle_types)}>
      {vehiclesArray.map((item, index: number) => {
        return (
          <div key={index} className={classNames(styles.vehicle_type, styles[item])}>
            <div className={styles.icon}>
              <img src={GlobalVariables.vehicleTypeIcons[item]} alt={item} />
            </div>
            <div className={styles.text}>
              <p>{GlobalStrings.vehicleTypes[item]}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VehicleType;
