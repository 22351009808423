import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { PageHeader, UsersList } from "components";
import { GlobalStrings } from "utils";

import CreateAdminAccountForm from "./CreateAdminAccountForm/CreateAdminAccountForm";
import GlobalSettings from "./GlobalSettings/GlobalSettings";
import MaintenanceSettings from "./MaintenanceSettings/MaintenanceSettings";

import { adminRoutesPath } from "../routes";

const Settings = () => {
  const settingsTabs = [
    {
      id: 0,
      name: "Conturi",
      slug: adminRoutesPath.Settings.Accounts,
    },
    {
      id: 1,
      name: GlobalStrings.settings.createNewAccount,
      slug: adminRoutesPath.Settings.AddAccount,
    },
    {
      id: 2,
      name: GlobalStrings.settings.globalSettings,
      slug: adminRoutesPath.Settings.GlobalSettings,
    },
    {
      id: 3,
      name: GlobalStrings.settings.maintenanceSettings,
      slug: adminRoutesPath.Settings.MaintenanceSettings,
    },
  ];
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    if (location.pathname.includes(settingsTabs[0].slug)) {
      setActiveIndex(0);
    } else if (location.pathname.includes(settingsTabs[1].slug)) {
      setActiveIndex(1);
    } else if (location.pathname.includes(settingsTabs[2].slug)) {
      setActiveIndex(2);
    } else if (location.pathname.includes(settingsTabs[3].slug)) {
      setActiveIndex(3);
    }
  }, [location.pathname]);

  return (
    <section>
      <PageHeader
        title={GlobalStrings.settings.settings}
        tabsCurrentview={settingsTabs}
        tabsCurrentviewActiveIndex={activeIndex}
      />

      {location.pathname.includes("add-account") ? (
        <div className="card">
          <h4 className="mb-4">{GlobalStrings.settings.createNewAccountTitle}</h4>
          <CreateAdminAccountForm />
        </div>
      ) : location.pathname.includes("accounts") ? (
        <div>
          <UsersList />
        </div>
      ) : location.pathname.includes("maintenance") ? (
        <MaintenanceSettings />
      ) : (
        location.pathname.includes("global") && <GlobalSettings />
      )}
    </section>
  );
};
export default Settings;
