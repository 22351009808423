import React from "react";

import { Currency, ProductUM } from "utils/enums";

import { formatPrice, GlobalStrings, GlobalVariables } from "../../utils";

const Price = (props: {
  className?: string;
  value: number;
  currency: Currency;
  unit: ProductUM;
}) => {
  return (
    <div className={props.className}>
      <p className="price">
        {formatPrice(+props.value.toString().split(".")[0])}
        {"."}
        <span>
          {props.value.toFixed(2).toString().split(".")[1]}{" "}
          {GlobalVariables.currency[props.currency]}{" "}
          {props.unit && `/ ${GlobalStrings.marketplace[props.unit]}`}
        </span>
      </p>
    </div>
  );
};

export default Price;
