import React, { useEffect, useMemo, useState } from "react";

import { useGetServicesQuery } from "app/services/servicesApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Popover, TableBadge, TableComponent } from "components";
import { displayPriceForService, errorHandler, getSortDetails, GlobalStrings } from "utils";
import { ServiceType, TransportType } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_SERVICE } from "utils/types";

const ServicesListing = (props: { vehicleType: "car" | "bicycle" | "scooter" }) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const [sorting, setSorting] = useState<SortingState>([{ id: "createdAt", desc: true }]);
  const [columnVisibility, setColumnVisibility] = useState({});

  const { data, isFetching, error } = useGetServicesQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
    vehicleType: props.vehicleType,
  });

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  const columnHelper = createColumnHelper<I_SERVICE>();

  const columns = [
    columnHelper.accessor((row) => row.company.name, {
      id: "name",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.supplier}</span>,
      enableSorting: false,
      enableMultiSort: false,
      size: 1,
      minSize: 20,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.city, {
      id: "city",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.city}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => (
        <TableBadge
          text={GlobalStrings.userAccountStatus[info.getValue()]}
          color={
            info.getValue() === "active"
              ? "green"
              : info.getValue() === "inactive"
              ? "orange"
              : "red"
          }
        />
      ),
      header: () => <span>{GlobalStrings.formLabels.status}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.serviceName, {
      id: "serviceName",
      cell: (info) => <p>{GlobalStrings.servicesTypes[info.getValue()]}</p>,
      header: () => <span>{GlobalStrings.formLabels.serviceName}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.coveredZone, {
      id: "coveredZone",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.coveredZone}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "amountAC",
      cell: (info) => (
        <p>
          {displayPriceForService(
            (info.getValue().vehicleType === TransportType.CAR ||
              info.getValue().vehicleType === TransportType.RIDESHARING) &&
              info.getValue().serviceName === ServiceType.RECHARGEBATTERY
              ? info.getValue().amountAC
              : info.getValue().amount,
            info.getValue().serviceName,
            info.getValue().vehicleType
          )}
        </p>
      ),
      header: () => (
        <span>
          {GlobalStrings.formLabels.amountAC}
          <br />
          {GlobalStrings.formLabels.amountCar}
        </span>
      ),
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
      enableHiding: props.vehicleType !== "car",
    }),
    columnHelper.accessor((row) => row, {
      id: "amountDC",
      cell: (info) => (
        <p>
          {info.getValue().serviceName !== ServiceType.RECHARGEBATTERY
            ? NO_VALUE_SYMBOL
            : displayPriceForService(
                info.getValue().amountDC,
                info.getValue().serviceName,
                info.getValue().vehicleType
              )}
        </p>
      ),
      header: () => <span>{GlobalStrings.formLabels.amountDC}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
      enableHiding: props.vehicleType !== "car",
    }),
    columnHelper.accessor((row) => row, {
      id: "amount",
      cell: (info) => (
        <p>
          {displayPriceForService(
            info.getValue().amount,
            info.getValue().serviceName,
            info.getValue().vehicleType
          )}
        </p>
      ),
      header: () => (
        <span>
          {props.vehicleType === "bicycle"
            ? GlobalStrings.formLabels.amountBicycle
            : GlobalStrings.formLabels.amountScooter}
        </span>
      ),
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
      enableHiding: props.vehicleType === "car",
    }),
    columnHelper.accessor((row) => row, {
      id: "contact",
      cell: (info) => (
        <>
          <button
            className="btn btn-success btn-sm p-2 w-100"
            id={`PopoverClick-${info.getValue().id}`}
          >
            {GlobalStrings.formLabels.showContactInfo}
          </button>
          <Popover id={`PopoverClick-${info.getValue().id}`} title={info.getValue().company.name}>
            <p>
              {GlobalStrings.formLabels.email}: {info.getValue().company.email}
            </p>
            <p>
              {GlobalStrings.formLabels.phone}: {info.getValue().company.phone}
            </p>
          </Popover>
        </>
      ),
      header: () => <span>{GlobalStrings.formLabels.contact}</span>,
      enableSorting: false,
      enableMultiSort: false,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
  ];

  const table = useReactTable({
    data: data?.services || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
      columnVisibility,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
  });

  useEffect(() => {
    table.toggleAllColumnsVisible(false);
  }, [props.vehicleType]);

  return (
    <section>
      <TableComponent
        table={table}
        tableHasData={data?.services?.length > 0}
        isLoading={isFetching}
      />
    </section>
  );
};

export default ServicesListing;
