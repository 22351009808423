import React from "react";

import { SEO } from "components";
import { GlobalStrings } from "utils";

const Page404 = () => {
  return (
    <>
      <SEO title={GlobalStrings.SEO.common.notFound} />
      <section className="page_404">
        <h1>{GlobalStrings.page404Title}</h1>
        <h4>{GlobalStrings.page404Body}</h4>
      </section>
    </>
  );
};
export default Page404;
