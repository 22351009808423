import { I_PARTNER, I_PARTNER_USER } from "utils/types";

import { apiSlice } from "../apiSlice";

export const partnersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPartners: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort }) =>
        `${process.env.REACT_APP_API_URL}/companies?pagination=true&page=${
          pageIndex + 1
        }&limit=${pageSize}${
          sort ? `&sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}` : ""
        }&type=partner`,
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const partners: I_PARTNER[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          partners.push({
            id: item.id,
            name: item?.name || "N/A",
            identifier: item?.identifier || "N/A",
            phone: item?.phone || "N/A",
            email: item?.email || "N/A",
            city: item?.city || "N/A",
            type: item?.type || "N/A",
            individualTransportVehicles: item?.individualTransportVehicles || "N/A",
          });
        });
        return {
          partners,
          meta,
        };
      },
    }),

    getPartner: builder.query<any, any>({
      query: (partnerId: number) => `${process.env.REACT_APP_API_URL}/companies/${partnerId}`,
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    getJuicers: builder.query<any, any>({
      query: (partnerId: number) => `${process.env.REACT_APP_API_URL}/companies/${partnerId}`,
      keepUnusedDataFor: 300,
      transformResponse: (response: any) => {
        const data: any = [];
        response?.data?.juicers?.forEach((juicer: any) => {
          data.push({
            id: juicer.id,
            name: juicer?.name,
            city: juicer?.city,
            email: juicer?.email,
            phone: juicer?.phone,
            status: juicer?.owner?.status,
            type: juicer?.type,
            services: juicer.services,
            owner: juicer.owner,
          });
        });
        return data;
      },
    }),
    getCharges: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, companyId }) => {
        let url = `${
          process.env.REACT_APP_VEHICLE_API_URL
        }/vehicles/charges-paginated?pagination=true&page=${pageIndex + 1}&limit=${pageSize}`;
        if (sort) {
          url += `&sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}`;
        }
        if (companyId) {
          url += `&companyId=${companyId}`;
        }
        return url;
      },
      keepUnusedDataFor: 300,
    }),
    addPartner: builder.mutation<any, Partial<any>>({
      query: (newPartner) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/partner-register`,
        method: "POST",
        body: { ...newPartner, type: "partner" },
      }),
    }),

    updatePartner: builder.mutation({
      query: (data: { body: any; id?: string | number }) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/${data.id}`,
        method: "PUT",
        body: { ...data.body },
      }),
    }),

    deletePartner: builder.mutation({
      query: (id: number) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/${id}`,
        method: "DELETE",
      }),
    }),

    addUserToPartner: builder.mutation<any, any>({
      query: ({ id, user }) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/create-employee-partner/${id}`,
        method: "POST",
        body: user,
      }),
    }),

    deleteUserFromPartner: builder.mutation({
      query: ({ partnerId, userId }) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/${partnerId}/remove-user`,
        method: "DELETE",
        body: { userId },
      }),
    }),

    getUsersFromPartner: builder.query<any, any>({
      query: ({ partnerId }) =>
        `${process.env.REACT_APP_API_URL}/companies/${partnerId}?relations=users`,
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const users: I_PARTNER_USER[] = [];
        response?.data?.users?.forEach((item: any) => {
          users.push({
            id: item.id,
            firstName: item?.firstName || "N/A",
            lastName: item?.lastName || "N/A",
            phone: item?.phone || "N/A",
            email: item?.email || "N/A",
          });
        });
        return {
          users,
          meta,
        };
      },
    }),

    getPricesByPartner: builder.query<any, any>({
      query: (identifier: string) =>
        `${process.env.REACT_APP_API_URL}/app-settings/by-name/${identifier}`,
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        let parsedInfo = {};
        if (response?.data?.info) {
          try {
            parsedInfo = response.data.info;
          } catch (error) {
            console.error("Error parsing info:", error);
          }
        }
        const data: any = {
          id: response.data.id,
          info: parsedInfo,
          name: response.data.name,
        };
        return data;
      },
    }),

    addPricesToPartner: builder.mutation<any, any>({
      query: (body) => ({
        url: `${process.env.REACT_APP_CONSUMER_API_URL}/app-settings`,
        method: "POST",
        body,
      }),
    }),

    updatePricesToPartner: builder.mutation<any, any>({
      query: (data: { body: any; id: number }) => ({
        url: `${process.env.REACT_APP_CONSUMER_API_URL}/app-settings/${data.id}`,
        method: "PUT",
        body: data.body,
      }),
    }),
    getCompaniesWithServices: builder.query<any, any>({
      query: (vehicleType) =>
        `${process.env.REACT_APP_API_URL}/companies/companies-with-services?vehicleTypes=[${vehicleType}]`,
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        return response?.data.pageData;
      },
    }),
    addJuicersToPartner: builder.mutation<any, any>({
      query: ({ body, partnerId }) => ({
        url: `${process.env.REACT_APP_CONSUMER_API_URL}/companies/add-juicers-to-partner/${partnerId}`,
        method: "POST",
        body,
      }),
    }),
    contractServiceToPartner: builder.mutation<any, any>({
      query: ({ body, serviceId }) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/services/add-partners/${serviceId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteServiceFromPartner: builder.mutation<any, any>({
      query: ({ body, serviceId }) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/services/remove-partners/${serviceId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteJuicerFromPartner: builder.mutation<any, any>({
      query: ({ body, partnerId }) => ({
        url: `${process.env.REACT_APP_CONSUMER_API_URL}/companies/remove-juicers-from-partner/${partnerId}`,
        method: "DELETE",
        body,
      }),
    }),
    getCompanyServices: builder.query<any, any>({
      query: ({ companyId, partnerId }) =>
        `${process.env.REACT_APP_VEHICLE_API_URL}/services/available-for-contract?companyId=${companyId}&partnerId=${partnerId}`,
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    payChargeConfirmation: builder.mutation<any, any>({
      query: ({ chargeId }) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/vehicles/payment-confirmation/${chargeId}`,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useGetPartnersQuery,
  useGetPartnerQuery,
  useGetJuicersQuery,
  useGetChargesQuery,
  useUpdatePartnerMutation,
  useDeletePartnerMutation,
  useAddPartnerMutation,
  useAddUserToPartnerMutation,
  useGetUsersFromPartnerQuery,
  useDeleteUserFromPartnerMutation,
  useGetPricesByPartnerQuery,
  useAddPricesToPartnerMutation,
  useUpdatePricesToPartnerMutation,
  useGetCompaniesWithServicesQuery,
  useAddJuicersToPartnerMutation,
  useContractServiceToPartnerMutation,
  useDeleteServiceFromPartnerMutation,
  useDeleteJuicerFromPartnerMutation,
  useGetCompanyServicesQuery,
  usePayChargeConfirmationMutation,
} = partnersApiSlice;
