import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { useGetCitiesQuery } from "app/common/commonApiSlice";
import { useUpdatePartnerMutation } from "app/partners/partnersApiSlice";

import classNames from "classnames";
import { AlertComponent, CitiesSelect, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { errorHandler, GlobalStrings, GlobalVariables, Regex } from "utils";

export type I_PARTNER_FORM = {
  name: string;
  identifier: string;
  phone: string;
  email: string;
  city: any;
  individualTransportVehicles: string;
  id?: string | number;
  maxScooterBatteryLevelToPickUp?: number;
  minScooterBatteryLevelToChargeTo?: number;
  maxBicycleBatteryLevelToPickUp?: number;
  minBicycleBatteryLevelToChargeTo?: number;
};

const animatedComponents = makeAnimated();

const PartnerForm = (props: {
  data: I_PARTNER_FORM;
  id: string | undefined;
  refetch: Function;
  hasFiedlsDisabled?: boolean;
}) => {
  const { data: cities } = useGetCitiesQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: props?.data?.name || "",
      identifier: props?.data?.identifier?.replace("/P", "") || "",
      email: props?.data?.email || "",
      phone: props?.data?.phone || "",
      city: undefined,
      individualTransportVehicles: props?.data?.individualTransportVehicles || "",
    },
  });

  useEffect(() => {
    if (cities)
      setValue(
        "city",
        cities?.find((item: any) => item.value === props?.data?.city)
      );
  }, [props?.data?.city, cities]);

  const [updatePartner, { isLoading }] = useUpdatePartnerMutation();
  const { serverMessage, setServerMessage } = useServerMessage();

  const onSubmit = async (data: I_PARTNER_FORM) => {
    try {
      const payload = {
        body: {
          ...data,
          city: data.city.value,
        },
        id: props?.data?.id,
      };
      await updatePartner(payload).unwrap();

      setServerMessage({
        type: "success",
        text: GlobalStrings.partners.partnerWasSaved,
      });

      props.refetch();
    } catch (error: any) {
      errorHandler(error, true, setServerMessage);
    }
  };

  return (
    <section className="d-flex flex-column gap-5">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="name"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.companyName}
                  type="text"
                  disabled={props.hasFiedlsDisabled}
                />
              )}
            />
            <Label for="name">{GlobalStrings.formLabels.companyName}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="identifier"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.cifOrCnp,
                  message: GlobalStrings.formValidation.invalidCIForCNP,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.cifCnp}
                  type="text"
                  disabled={props.hasFiedlsDisabled}
                />
              )}
            />
            <Label for="identifier">{GlobalStrings.formLabels.cifCnp}</Label>
          </FormGroup>
        </div>

        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="phone"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.phone,
                  message: GlobalStrings.formValidation.invalidPhone,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.phoneNumber}
                  type="text"
                  disabled={props.hasFiedlsDisabled}
                />
              )}
            />
            <Label for="phone">{GlobalStrings.formLabels.phoneNumber}</Label>
          </FormGroup>
          <FormGroup floating className="w-100">
            <Controller
              name="email"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.email,
                  message: GlobalStrings.formValidation.invalidEmail,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.email}
                  type="text"
                  disabled
                />
              )}
            />
            <Label for="email">{GlobalStrings.formLabels.email}</Label>
          </FormGroup>
        </div>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <CitiesSelect name="city" control={control} disabled={props.hasFiedlsDisabled} />
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="individualTransportVehicles"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field }) => (
                <Select
                  className={classNames("react_select", {
                    invalid: errors.individualTransportVehicles,
                  })}
                  name="individualTransportVehicles"
                  isMulti
                  placeholder={GlobalStrings.formLabels.publicTransportVehicles}
                  value={GlobalVariables.vehicleTypes.filter((option) =>
                    field.value.split(", ").includes(option.value)
                  )}
                  onChange={(selected) => {
                    const value = selected.map((item) => item.value).join(", ");
                    field.onChange(value);
                  }}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  options={GlobalVariables.vehicleTypes}
                  isDisabled={props.hasFiedlsDisabled}
                />
              )}
            />
          </FormGroup>
        </div>

        {!props.hasFiedlsDisabled && (
          <SubmitButton isLoading={isLoading} label={GlobalStrings.formLabels.save} />
        )}
      </Form>

      <AlertComponent errors={errors} serverMessage={serverMessage} />
    </section>
  );
};
export default PartnerForm;
