import { apiSlice } from "app/apiSlice";

import { I_SUPPLIER } from "utils/types";

export const suppliersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSuppliers: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort }) =>
        `${process.env.REACT_APP_API_URL}/companies?pagination=true&page=${
          pageIndex + 1
        }&limit=${pageSize}${
          sort ? `&sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}` : ""
        }&type=supplier`,
      keepUnusedDataFor: 3,
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const suppliers: I_SUPPLIER[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          suppliers.push({
            id: item.id,
            name: item?.name || "N/A",
            identifier: item?.identifier || "N/A",
            email: item?.email || "N/A",
            phone: item?.phone || "N/A",
            city: item?.city || "N/A",
            value: item?.name || "N/A",
            label: item?.name || "N/A",
          });
        });
        return {
          suppliers,
          meta,
        };
      },
    }),
    getSuppliersWithProducts: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort }) =>
        `${process.env.REACT_APP_API_URL}/companies/companies-with-products?pagination=true&page=${
          pageIndex + 1
        }&limit=${pageSize}${
          sort ? `&sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}` : ""
        }&type=supplier`,
      keepUnusedDataFor: 2,
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const suppliers: I_SUPPLIER[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          suppliers.push({
            id: item.id,
            name: item?.name || "N/A",
            identifier: item?.identifier || "N/A",
            email: item?.email || "N/A",
            phone: item?.phone || "N/A",
            city: item?.city || "N/A",
          });
        });
        return {
          suppliers,
          meta,
        };
      },
    }),
    getSuppliersWithServices: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort }) =>
        `${process.env.REACT_APP_API_URL}/companies/companies-with-services?pagination=true&page=${
          pageIndex + 1
        }&limit=${pageSize}${
          sort ? `&sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}` : ""
        }&type=supplier`,
      keepUnusedDataFor: 2,
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const suppliers: I_SUPPLIER[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          suppliers.push({
            id: item.id,
            name: item?.name || "N/A",
            identifier: item?.identifier || "N/A",
            email: item?.email || "N/A",
            phone: item?.phone || "N/A",
            city: item?.city || "N/A",
          });
        });
        return {
          suppliers,
          meta,
        };
      },
    }),
    addSupplier: builder.mutation<any, any>({
      query: (newSupplier) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/supplier-register`,
        method: "POST",
        body: {
          ...newSupplier,
          type: "supplier",
        },
      }),
    }),
    getSupplier: builder.query<any, any>({
      query: (supplierId: number) => `${process.env.REACT_APP_API_URL}/companies/${supplierId}`,
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),
    updateSupplier: builder.mutation({
      query: (data: {
        body: {
          name: string;
          identifier: string;
          email: string;
          phone: string;
          city: string;
        };
        id?: string | number;
      }) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/${data.id}`,
        method: "PUT",
        body: { ...data.body },
      }),
    }),
    deleteSupplier: builder.mutation({
      query: (id: number) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/${id}`,
        method: "DELETE",
      }),
    }),
    addUserToSupplier: builder.mutation<any, any>({
      query: ({ supplierId, user }) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/create-employee-supplier/${supplierId}`,
        method: "POST",
        body: user,
      }),
    }),
    deleteSupplierService: builder.mutation({
      query: ({ supplierId, serviceId }) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/${supplierId}/remove-service/${serviceId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetSuppliersQuery,
  useGetSupplierQuery,
  useAddSupplierMutation,
  useUpdateSupplierMutation,
  useAddUserToSupplierMutation,
  useDeleteSupplierServiceMutation,
  useDeleteSupplierMutation,
  useGetSuppliersWithProductsQuery,
  useGetSuppliersWithServicesQuery,
} = suppliersApiSlice;
