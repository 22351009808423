import React, { useEffect, useState } from "react";
import Select from "react-select";

import { GlobalVariables } from "utils";

const Pagination = (props: {
  pageIndex: number;
  setPageIndex: Function;
  pageSize: number;
  setPageSize: Function;
  numberOfPages: number;
}) => {
  const [pages, setPages] = useState<{ value: number; label: number }[]>([]);

  useEffect(() => {
    const pageNumbers: any = [];
    for (let i = 1; i <= props.numberOfPages; i++) pageNumbers.push({ value: i, label: i });
    setPages(pageNumbers);
  }, [props.numberOfPages]);

  return (
    <div className="table_pagination">
      <div className="d-flex gap-2 align-items-center"></div>
      <div className="d-flex">
        <div className="d-flex">
          <button
            className="btn btn-pagination"
            onClick={() => props.setPageIndex(0)}
            disabled={props.pageIndex === 0}
          >
            {"<<"}
          </button>
          <button
            className="btn btn-pagination"
            onClick={() => props.setPageIndex((prev: any) => prev - 1)}
            disabled={props.pageIndex === 0}
          >
            {"<"}
          </button>
        </div>
        <Select
          menuPlacement="top"
          className="page_number"
          options={pages}
          value={{
            value: props.pageIndex + 1,
            label: props.pageIndex + 1,
          }}
          captureMenuScroll
          onChange={(newValue) => props.setPageIndex(Number(newValue?.value) - 1)}
          isSearchable={false}
        />

        <div className="d-flex">
          <button
            className="btn btn-pagination"
            onClick={() => props.setPageIndex((prev: any) => prev + 1)}
            disabled={props.pageIndex === props.numberOfPages - 1}
          >
            {">"}
          </button>

          <button
            className="btn btn-pagination"
            onClick={() => props.setPageIndex(props.numberOfPages - 1)}
            disabled={props.pageIndex === props.numberOfPages - 1}
          >
            {">>"}
          </button>
        </div>

        <Select
          className="page_number"
          menuPlacement="top"
          options={GlobalVariables.paginationRows}
          captureMenuScroll
          value={{
            value: props.pageSize,
            label: props.pageSize,
          }}
          onChange={(newValue) => {
            props.setPageSize(Number(newValue?.value));
            props.setPageIndex(0);
          }}
          isSearchable={false}
        />
      </div>
    </div>
  );
};

export default Pagination;
