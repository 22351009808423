import React from "react";
import { GiShoppingCart } from "react-icons/gi";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "app/auth/authSlice";
import { selectMarketplaceIsUsedInApp } from "app/common/commonSlice";

import classNames from "classnames";
import { Price } from "components";
import { GlobalStrings, roleValidation } from "utils";
import { Currency } from "utils/enums";
import { I_PRODUCT } from "utils/types";

import styles from "./ProductDetails.module.scss";

const ProductDetails = (props: { product: I_PRODUCT; addToCart: Function }) => {
  const user = useSelector(selectCurrentUser);
  const marketplaceIsUsedInApp = useSelector(selectMarketplaceIsUsedInApp);

  return (
    <div
      className={classNames(styles.product_info, {
        [styles.used_for_app]: marketplaceIsUsedInApp,
      })}
    >
      <h2>{props.product.name}</h2>
      <p>{props.product.description}</p>

      <div className={styles.price_container}>
        <h4>{GlobalStrings.formLabels.productPrice}</h4>
        <Price value={props.product.price} currency={Currency.RON} unit={props.product.unit} />
      </div>
      {props.product.company && (
        <p>
          {GlobalStrings.marketplace.productsSellBy} {props.product.company.name}
        </p>
      )}
      {!roleValidation("admin", user?.roles) && (
        <button className={styles.add_to_cart} onClick={() => props.addToCart(props.product.id)}>
          <GiShoppingCart size={30} /> {GlobalStrings.marketplace.addToCart}
        </button>
      )}
    </div>
  );
};

export default ProductDetails;
