import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { PageHeader } from "components";
import GlobalStrings from "utils/globalStrings";

import ContainersTable from "./Containers/ContainersTable";
import ContainersMap from "./ContainersMap/ContainersMap";
import Prices from "./Prices/Prices";
import StationsTable from "./Stations/StationsTable";
import StationsMap from "./StationsMap/StationsMap";

import { chargingParterRoutesPath } from "../routes";

const ContainersAndStations = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  const buttons = [
    {
      id: 0,
      name: GlobalStrings.containersAndStations.addContainer,
      slug: chargingParterRoutesPath.ContainersAndStations.AddContainer,
    },
    {
      id: 1,
      name: GlobalStrings.containersAndStations.addStation,
      slug: chargingParterRoutesPath.ContainersAndStations.AddStation,
    },
  ];

  const electricityContainersTabs = [
    {
      id: 0,
      name: GlobalStrings.containersAndStations.containersMap,
      slug: chargingParterRoutesPath.ContainersAndStations.ContainersMap,
    },
    {
      id: 1,
      name: GlobalStrings.containersAndStations.containers,
      slug: chargingParterRoutesPath.ContainersAndStations.Containers,
    },
    {
      id: 0,
      name: GlobalStrings.containersAndStations.stationsMaps,
      slug: chargingParterRoutesPath.ContainersAndStations.StationsMap,
    },
    {
      id: 3,
      name: GlobalStrings.containersAndStations.stations,
      slug: chargingParterRoutesPath.ContainersAndStations.Stations,
    },
    {
      id: 4,
      name: GlobalStrings.containersAndStations.prices,
      slug: chargingParterRoutesPath.ContainersAndStations.Prices,
    },
  ];

  useEffect(() => {
    if (location.pathname.includes(electricityContainersTabs[0].slug)) {
      setActiveIndex(0);
    } else if (location.pathname.includes(electricityContainersTabs[1].slug)) {
      setActiveIndex(1);
    } else if (location.pathname.includes(electricityContainersTabs[2].slug)) {
      setActiveIndex(2);
    } else if (location.pathname.includes(electricityContainersTabs[3].slug)) {
      setActiveIndex(3);
    } else if (location.pathname.includes(electricityContainersTabs[4].slug)) {
      setActiveIndex(4);
    }
  }, [location.pathname]);

  return (
    <section>
      <PageHeader
        title={GlobalStrings.containersAndStations.title}
        tabsCurrentview={electricityContainersTabs}
        tabsCurrentviewActiveIndex={activeIndex}
        tabs={activeIndex === 1 ? [buttons[0]] : activeIndex === 3 ? [buttons[1]] : undefined}
      />

      {activeIndex === 0 && <ContainersMap />}
      {activeIndex === 1 && <ContainersTable />}
      {activeIndex === 2 && <StationsMap />}
      {activeIndex === 3 && <StationsTable />}
      {activeIndex === 4 && <Prices />}
    </section>
  );
};
export default ContainersAndStations;
