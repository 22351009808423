import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import {
  useAddJuicersToPartnerMutation,
  useGetCompaniesWithServicesQuery,
  useGetJuicersQuery,
} from "app/partners/partnersApiSlice";

import { SubmitButton } from "components";
import { errorHandler, GlobalStrings } from "utils";
import { TransportType } from "utils/enums";
import { I_JUICER } from "utils/types";

import CompaniesSelect from "./CompaniesSelect/CompaniesSelect";

import styles from "./styles.module.scss";

export default function AddJuicersButtonModal() {
  const user = useSelector(selectCurrentUser);
  const { partnerId } = useParams();
  const { refetch, data: juicers } = useGetJuicersQuery(partnerId, {
    refetchOnMountOrArgChange: false,
  });
  const { data: companiesWithServices, error } = useGetCompaniesWithServicesQuery([
    TransportType.SCOOTER,
    TransportType.BICYCLE,
  ]);
  const [modal, setModal] = useState(false);
  const [companies, setCompanies] = useState([]);
  const toggleModal = () => setModal((prev) => !prev);
  const [addJuicersToPartner] = useAddJuicersToPartnerMutation();

  const { handleSubmit, getValues, control, setValue, watch } = useForm({
    defaultValues: {
      company: { label: "-", value: "-" },
    },
  });

  useEffect(() => {
    if (juicers && companiesWithServices) {
      const filteredCompanies = companiesWithServices.filter(
        (company: I_JUICER) => !juicers.some((juicer: I_JUICER) => company.id === juicer.id)
      );
      setCompanies(filteredCompanies);
    }
  }, [juicers, companiesWithServices]);

  const onSubmit = async () => {
    const reqBody = {
      juicersIds: [getValues("company")?.value],
    };
    try {
      await addJuicersToPartner({
        body: reqBody,
        partnerId: partnerId || user.company.id,
      });
      refetch();
      setModal(false);
      toast.success(GlobalStrings.activeServices.juicerAddedSuccesfully);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <>
      <Button
        className={`btn btn-primary ${styles.addButton}`}
        size="sm"
        onClick={() => {
          setModal(true);
          setValue("company", { label: "-", value: "-" });
        }}
      >
        {GlobalStrings.activeServices.addJuicers}
      </Button>
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggleModal}>{GlobalStrings.activeServices.addJuicers}</ModalHeader>
          <ModalBody>
            <CompaniesSelect control={control} name={"company"} companies={companies} />
          </ModalBody>
          <ModalFooter>
            <SubmitButton
              disabled={watch("company").value === "-"}
              isLoading={false}
              color={"primary"}
              label={GlobalStrings.add}
            />
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
