import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { selectCurrentUser } from "app/auth/authSlice";
import {
  useDeleteJuicerFromPartnerMutation,
  useGetJuicersQuery,
} from "app/partners/partnersApiSlice";

import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { TableBadge, TableComponent, TableIcon } from "components";
import { errorHandler, GlobalStrings } from "utils";
import { UserStatus } from "utils/enums";

import GetServicesButtonModal from "./GetServicesButtonModal/GetServicesButtonModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

interface I_JUICER {
  id: number | string;
  name: string;
  city: string;
  email: string;
  phone: string;
  status: UserStatus;
  type: string;
}

const JuicersListing = () => {
  const { partnerId } = useParams();
  const user = useSelector(selectCurrentUser);
  const [deleteJuicerFromPartner] = useDeleteJuicerFromPartnerMutation();

  useEffect(() => {
    table.toggleAllColumnsVisible(false);
  }, [user]);

  const {
    refetch,
    isFetching,
    error,
    data: juicers,
  } = useGetJuicersQuery(partnerId, {
    refetchOnMountOrArgChange: false,
  });

  const deleteJuicerHandler = async (juicerId: string | number) => {
    if (partnerId) {
      const reqBody = {
        juicersIds: [juicerId],
      };
      try {
        await deleteJuicerFromPartner({
          body: reqBody,
          partnerId,
        });
        toast.success(GlobalStrings.activeServices.deleteJuicerSuccessToast);
        refetch();
      } catch (error: any) {
        errorHandler(error);
      }
    }
  };

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  const columnHelper = createColumnHelper<I_JUICER>();

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.juicer}</span>,
      enableSorting: false,
      size: 1,
      minSize: 20,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.city, {
      id: "city",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.city}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.email, {
      id: "email",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.email}</span>,
      enableSorting: false,
      size: 1,
      enableHiding: false,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.phone, {
      id: "phone",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.phone}</span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => (
        <TableBadge
          text={GlobalStrings.userAccountStatus[info.getValue()]}
          color={
            info.getValue() === "active"
              ? "green"
              : info.getValue() === "inactive"
              ? "orange"
              : "red"
          }
        />
      ),
      header: () => <span>{GlobalStrings.formLabels.status}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.type, {
      id: "type",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.type}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "id",
      maxSize: 10,
      enableHiding: false,
      cell: (info) => {
        return <GetServicesButtonModal companyId={info.getValue().id} />;
      },
      header: () => <span>{GlobalStrings.formLabels.contract}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "actions",
      minSize: 5,
      maxSize: 6,
      enableHiding: !(user.role === "admin" || user.role === "partial_admin"),
      cell: (info) => {
        return (
          <ConfirmationModal
            title={GlobalStrings.activeServices.deleteJuicer}
            buttonText={GlobalStrings.delete}
            icon={<TableIcon type="delete" />}
            handler={() => {
              deleteJuicerHandler(info.getValue().id);
            }}
          />
        );
      },
      header: () => <span></span>,
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    data: juicers || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  return (
    <section className="mt-5">
      <TableComponent
        table={table}
        tableHasData={juicers?.length > 0}
        isLoading={isFetching}
        hidePagination
      />
    </section>
  );
};

export default JuicersListing;
