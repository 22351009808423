import moment from "moment";
import { REPORTS_PARTNER } from "utils/types";

import { apiSlice } from "../apiSlice";

export const statsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllReportsPartner: builder.query<any, any>({
      query: ({ startDate, endDate, day, vehicleId, partnerId }) => {
        let url = `${process.env.REACT_APP_PAYMENT_API_URL}/reports/partner-reports-admin?`;

        if (startDate) {
          url += `startDate=${moment(startDate).format("YYYY-MM-DD")}&`;
        }
        if (endDate) {
          url += `endDate=${moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")}&`;
        }
        if (day) {
          url += `day=${moment(day).format("DD-MM-YYYY")}&`;
        }
        if (vehicleId) {
          url += `vehicleId=${vehicleId}&`;
        }
        if (partnerId) {
          url += `partnerId=${partnerId}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        const data = response.data;
        const restOfData: REPORTS_PARTNER = {
          total: data.total,
          averageAmountPerVehicle: data.averageAmountPerVehicle,
          averageOfNumberOfTripsBetweenCharges: data.averageOfNumberOfTripsBetweenCharges,
          averageDistnaceBetweenCharges: data.averageDistnaceBetweenCharges,
          averageAmountBetweenCharges: data.averageAmountBetweenCharges,
          averageNumberOfBookings: data.averageNumberOfBookings,
          averageRating: data.averageRating,
        };
        const income = data.income.data.map((item: any, index: any) => {
          return {
            date: moment(item.date).format("DD.MM.YYYY"),
            value: parseFloat(item.data.toFixed(2)),
          };
        });
        const tripsPerHour: any = [];
        data.tripsPerHour.forEach((day: any) => {
          day.data.forEach((hour: any) => {
            tripsPerHour.push({
              name: moment(hour.hour).format("DD.MM.YYYY, HH:mm"),
              count: hour.count,
            });
          });
        });
        return { restOfData, income, tripsPerHour };
      },
    }),
    getReportsOverall: builder.query<any, any>({
      query: ({ startDate, endDate }) => {
        let url = `${process.env.REACT_APP_PAYMENT_API_URL}/reports/analytics-data-overall?`;
        if (startDate) {
          url += `startDate=${moment(startDate).format("YYYY-MM-DD")}&`;
        }
        if (endDate) {
          url += `endDate=${moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")}&`;
        }
        return url;
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    getPaymentsReportsOverall: builder.query<any, any>({
      query: ({ startDate, endDate }) => {
        let url = `${process.env.REACT_APP_PAYMENT_API_URL}/reports/payment-reports-overall?`;
        if (startDate) {
          url += `startDate=${moment(startDate).format("YYYY-MM-DD")}&`;
        }
        if (endDate) {
          url += `endDate=${moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")}&`;
          return url;
        }
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        const data = response.data.paymentsOverall;
        return data.bicyclesData.map((item: any, index: any) => {
          return {
            name: moment(item.date).format("DD-MMM-YYYY"),
            bikes: parseFloat(item.data.toFixed(2)),
            scooters: parseFloat(data.scootersData[index].data.toFixed(2)),
            cars: parseFloat(data.carsData[index].data.toFixed(2)),
            containers: parseFloat(data.containersData[index].data.toFixed(2)),
          };
        });
      },
    }),
    getTripsReportsOverall: builder.query<any, any>({
      query: ({ startDate, endDate }) => {
        let url = `${process.env.REACT_APP_PAYMENT_API_URL}/reports/number-of-trips-reports-overall?`;
        if (startDate) {
          url += `startDate=${moment(startDate).format("YYYY-MM-DD")}&`;
        }
        if (endDate) {
          url += `endDate=${moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")}`;
          return url;
        }
      },
      keepUnusedDataFor: 300,
      transformResponse: (response: any) => {
        const data = response.data.tripsOverall;
        const vehiclesChart = data.scootersData.map((item: any, index: any) => {
          return {
            name: moment(item.date).format("DD.MM.YYYY"),
            scooters: parseFloat(item.averageDuration?.toFixed(2)),
            cars: parseFloat(data.carsData[index].averageDuration?.toFixed(2)),
            bikes: parseFloat(data.bicyclesData[index].averageDuration?.toFixed(2)),
          };
        });
        const tripsPerHour: any = [];
        data.tripsPerHour.forEach((day: any) => {
          day.data.forEach((hour: any) => {
            tripsPerHour.push({
              name: moment(hour.hour).format("DD.MM.YYYY, HH:mm"),
              count: parseFloat(hour.count?.toFixed(2)),
            });
          });
        });
        const overallData = data.overallData.map((item: any) => {
          return {
            name: moment(item.date).format("DD.MM.YYYY"),
            numberOfTrips: parseFloat(item.numberOfTrips?.toFixed(2)),
          };
        });

        return {
          vehiclesChart,
          tripsPerHour,
          overallData,
        };
      },
    }),
    getMapReportsOverall: builder.query<any, any>({
      query: ({ startDate, endDate, partnerId }) => {
        let url = `${process.env.REACT_APP_VEHICLE_API_URL}/trips?tripStatus=finished`;
        if (startDate && endDate) {
          url += `&startDate=in,${moment(startDate).format("YYYY-MM-DD")},${moment(endDate).format(
            "YYYY-MM-DD"
          )}`;
        }
        if (partnerId) {
          url += `&companyId=${partnerId}`;
        }
        url += "";
        return url;
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        const startPositions: any = [];
        const finishPositions: any = [];
        response?.data?.pageData?.forEach((item: any) => {
          startPositions.push({
            lat: item?.startPosition?.latitude,
            lng: item?.startPosition?.longitude,
          });
          finishPositions.push({
            lat: item?.finishPosition?.latitude,
            lng: item?.finishPosition?.longitude,
          });
        });

        return {
          startPositions,
          finishPositions,
        };
      },
    }),
  }),
});

export const {
  useGetAllReportsPartnerQuery,
  useGetReportsOverallQuery,
  useGetPaymentsReportsOverallQuery,
  useGetTripsReportsOverallQuery,
  useGetMapReportsOverallQuery,
} = statsApiSlice;
