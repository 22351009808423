import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FormGroup, Input } from "reactstrap";

import { PageHeader } from "components";
import moment from "moment";
import { GlobalStrings } from "utils";

import GeneralCard from "./AnalyticsList/GeneralTab/GeneralTab";
import MapTab from "./AnalyticsList/MapTab/MapTab";
import PaymentTab from "./AnalyticsList/PaymentTab/PaymentTab";
import TripsTab from "./AnalyticsList/TripsTab/TripsTab";

import { adminRoutesPath } from "../routes";

const Analytics = () => {
  const analyticsTabs = [
    {
      id: 0,
      name: GlobalStrings.analytics.general,
      slug: adminRoutesPath.Analytics.General,
    },
    {
      id: 1,
      name: GlobalStrings.analytics.payments,
      slug: adminRoutesPath.Analytics.Payments,
    },
    {
      id: 2,
      name: GlobalStrings.analytics.trips,
      slug: adminRoutesPath.Analytics.Trips,
    },
    {
      id: 3,
      name: GlobalStrings.analytics.maps,
      slug: adminRoutesPath.Analytics.Maps,
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  const [startDate, setStartDate] = useState(
    moment().clone().subtract(1, "weeks").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    const index = analyticsTabs.findIndex((tab) => location.pathname.includes(tab.slug));
    if (index >= 0) {
      setActiveIndex(index);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.includes(analyticsTabs[0].slug)) {
      setActiveIndex(0);
    } else if (location.pathname.includes(analyticsTabs[1].slug)) {
      setActiveIndex(1);
    } else if (location.pathname.includes(analyticsTabs[2].slug)) {
      setActiveIndex(2);
    } else if (location.pathname.includes(analyticsTabs[3].slug)) {
      setActiveIndex(3);
    }
  }, [location.pathname]);

  return (
    <section>
      <PageHeader
        title={GlobalStrings.analytics.analyticsTitle}
        tabsCurrentview={analyticsTabs}
        tabsCurrentviewActiveIndex={activeIndex}
      />
      <div className="card">
        <div className="d-flex justify-content-end">
          <div className="d-flex flex-md-row flex-column mb-5 align-items-center justify-content-between ">
            <div className=" d-flex align-items-center">
              <FormGroup>
                <Input
                  id="startDate"
                  name="startDate"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  max={endDate}
                />
              </FormGroup>
              <p className="px-3">-</p>
              <FormGroup>
                <Input
                  id="endDate"
                  name="endDate"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  max={moment().format("YYYY-MM-DD")}
                  min={startDate}
                />
              </FormGroup>
            </div>
          </div>
        </div>
        {(activeIndex === 0 && <GeneralCard startDate={startDate} endDate={endDate} />) ||
          (activeIndex === 1 && <PaymentTab startDate={startDate} endDate={endDate} />) ||
          (activeIndex === 2 && <TripsTab startDate={startDate} endDate={endDate} />) ||
          (activeIndex === 3 && <MapTab startDate={startDate} endDate={endDate} />)}
      </div>
    </section>
  );
};
export default Analytics;
