import React from "react";
import { useParams } from "react-router-dom";

import { PageHeader } from "components";
import { GlobalStrings } from "utils";

import { adminRoutesPath } from "views/admin/routes";
import UsersTable from "views/common/Partners/UsersTable/UsersTable";

const Accounts = () => {
  const { partnerId } = useParams();

  const AddButton = [
    {
      id: 1,
      name: `${GlobalStrings.formLabels.addUser}`,
      slug: adminRoutesPath.Partners.AddUser.replace(":id", partnerId || ""),
    },
  ];

  return (
    <section>
      <div className="mt-4">
        <div className="card">
          <PageHeader title={GlobalStrings.partners.associatedUserAccounts} tabs={AddButton} />
          {partnerId && <UsersTable id={partnerId} />}
        </div>
      </div>
    </section>
  );
};
export default Accounts;
