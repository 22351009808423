import React from "react";

import { PageHeader } from "components";
import { GlobalStrings } from "utils";

import VehiclesListing from "./VehiclesListing/VehiclesListing";

import { partnerRoutesPath } from "../routes";

const Vehicles = () => {
  const tabs = [
    {
      id: 0,
      name: GlobalStrings.partners.addVehicle,
      slug: partnerRoutesPath.Vehicles.AddVehicle,
    },
  ];

  return (
    <section>
      <PageHeader title={GlobalStrings.partners.partnersVehicle} tabs={tabs} />
      <VehiclesListing />
    </section>
  );
};
export default Vehicles;
