import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import {
  useCreateElectricityContainerMutation,
  useUpdateElectricityContainerMutation,
} from "app/containersAndStations/containersApiSlice";

import { AlertComponent, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { GlobalVariables, Regex } from "utils";
import { ElectricityContainerType } from "utils/enums";
import { errorHandler } from "utils/globalFunctions";
import GlobalStrings from "utils/globalStrings";

import GetCoordinatesMap from "components/GetCoordinatesMap/GetCoordinatesMap";
import { adminRoutesPath } from "views/admin/routes";

type FormData = {
  type: ElectricityContainerType;
  name: string;
  totalCapacity: number;
  numberOfPorts: number;
  address: string;
  lat: number | "";
  lng: number | "";
};

const AddEditContainerForm = (props: { data?: any; refetch: Function }) => {
  const user = useSelector(selectCurrentUser);
  const { id } = useParams();
  const navigate = useNavigate();
  const { serverMessage, setServerMessage } = useServerMessage();

  const [createContainer, { isLoading }] = useCreateElectricityContainerMutation();
  const [updateContainer, { isLoading: isLoadingUpdate }] = useUpdateElectricityContainerMutation();

  const [markerPoistion, setMarkerPosition] = useState({
    lat: props?.data?.lat || "",
    lng: props?.data?.lng || "",
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      type: props.data?.type || ElectricityContainerType.FIXED,
      name: props.data?.name || "",
      totalCapacity: props.data?.totalCapacity || "",
      numberOfPorts: props.data?.numberOfPorts || 1,
      address: props.data?.address || "",
      lat: props.data?.lat || "",
      lng: props.data?.lng || "",
    },
  });

  useEffect(() => {
    if (markerPoistion.lat || markerPoistion.lng) {
      setValue("lat", markerPoistion.lat, { shouldValidate: true });
      setValue("lng", markerPoistion.lng, { shouldValidate: true });
    }
  }, [markerPoistion]);

  useEffect(() => {
    setMarkerPosition({ lat: +watch("lat"), lng: +watch("lng") });
  }, [watch("lat"), watch("lng")]);

  const createElectricityContainer = async (body: any) => {
    try {
      const response = await createContainer(body).unwrap();
      navigate(
        adminRoutesPath.ContainersAndStations.EditContainer.replace(
          ":id",
          response?.data?.id.toString()
        )
      );
      toast.success(GlobalStrings.addElectricityContainer.electricityContainerSucces);
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  const updateElectricityContainer = async (body: any) => {
    try {
      await updateContainer(body).unwrap();
      setServerMessage({
        type: "success",
        text: GlobalStrings.addElectricityContainer.electricityContainerUpdateSucces,
      });
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  const onSubmit = (data: FormData) => {
    const body = {
      type: data.type,
      name: data.name,
      totalCapacity: +data.totalCapacity,
      numberOfPorts: +data.numberOfPorts,
      address: data.address,
      latitude: +data.lat,
      longitude: +data.lng,
      companyId: user.company.id,
      companyName: user.company.name,
    };
    if (id) {
      const data = {
        body,
        id,
      };
      updateElectricityContainer(data);
    } else {
      createElectricityContainer(body);
    }
  };

  return (
    <section className="d-flex flex-column gap-4">
      <h4 className="mb-0">
        {id
          ? GlobalStrings.addElectricityContainer.editContainer
          : GlobalStrings.addElectricityContainer.title}
      </h4>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <div className="w-100 d-flex flex-column">
            <FormGroup floating className="w-100">
              <Controller
                name="type"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.addElectricityContainer.typeContainer}
                    type="select"
                  >
                    {GlobalVariables.electricityContainerTypes.map((item: any) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </Input>
                )}
              />
              <Label for="type">{GlobalStrings.addElectricityContainer.typeContainer}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.name}
                    type="text"
                  />
                )}
              />
              <Label for="totalCapacity">{GlobalStrings.formLabels.name}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="totalCapacity"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.addElectricityContainer.capacityContainer}
                    type="text"
                  />
                )}
              />
              <Label for="totalCapacity">
                {GlobalStrings.addElectricityContainer.capacityContainer}
              </Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="address"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.addElectricityContainer.address}
                    type="text"
                  />
                )}
              />
              <Label for="address">{GlobalStrings.addElectricityContainer.address}</Label>
            </FormGroup>
            <FormGroup floating className="w-100">
              <Controller
                name="lat"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  pattern: {
                    value: Regex.latitude,
                    message: GlobalStrings.formValidation.invalidLatitude,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.latitude}
                    type="text"
                  />
                )}
              />
              <Label for="lat">{GlobalStrings.formLabels.latitude}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="lng"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  pattern: {
                    value: Regex.longitude,
                    message: GlobalStrings.formValidation.invalidLongitude,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.longitude}
                    type="text"
                  />
                )}
              />
              <Label for="lng">{GlobalStrings.formLabels.longitude}</Label>
            </FormGroup>
          </div>
          <div className="map_container">
            <GetCoordinatesMap
              markerPosition={markerPoistion}
              setMarkerPosition={setMarkerPosition}
            />
          </div>
        </div>

        <SubmitButton
          isLoading={isLoading || isLoadingUpdate}
          label={id ? GlobalStrings.formLabels.save : GlobalStrings.add}
          className="mt-3"
        />
      </Form>
      <AlertComponent errors={errors} serverMessage={serverMessage} />
    </section>
  );
};

export default AddEditContainerForm;
