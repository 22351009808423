import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";

import { selectCurrentUser, setCredentials } from "app/auth/authSlice";
import { useGetCitiesQuery } from "app/common/commonApiSlice";

import { Layout, SEO } from "components";
import { errorHandler, loadFromLocalStorage, roleValidation } from "utils";
import {
  adminRoutes,
  chargingPartnerRoutes,
  commonRoutes,
  partnerMobileRoutes,
  partnerRoutes,
  supplierRoutes,
} from "views";
import { LayoutAppMarketplace, Page404, RequireAuth } from "views/common";

import "./styles/styles.scss";

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const localStorageUser = loadFromLocalStorage("user");
  const user = useSelector(selectCurrentUser);
  const { error } = useGetCitiesQuery();

  useEffect(() => {
    errorHandler(error);
  }, [error]);

  useEffect(() => {
    if (
      localStorageUser !== null &&
      !location.pathname.includes("marketplace/app") &&
      !location.pathname.includes("services/app")
    ) {
      dispatch(
        setCredentials({
          user: localStorageUser,
          accessToken: "",
        })
      );
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        {/* public routes */}

        {commonRoutes.map(({ path, Component, metaTitle }) => (
          <Route
            key={path}
            path={path}
            element={
              <>
                <SEO title={metaTitle} />
                <Component />
              </>
            }
          />
        ))}

        {/* WEBVIEWS ROUTES */}
        {user && roleValidation("partner", user?.roles) && (
          <Route element={<LayoutAppMarketplace />}>
            {partnerMobileRoutes.map(({ path, Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
          </Route>
        )}

        {/* protected routes */}
        <Route element={<RequireAuth />}>
          <Route element={<Layout />}>
            {/* ADMIN ROUTES */}
            {roleValidation("admin", user?.roles) && (
              <>
                {adminRoutes.map(({ path, Component, metaTitle }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <>
                        <SEO title={metaTitle} />
                        <Component />
                      </>
                    }
                  />
                ))}
              </>
            )}
            {/* PARTNER ROUTES */}
            {roleValidation("partner", user?.roles) && (
              <>
                {partnerRoutes.map(({ path, Component, metaTitle }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <>
                        <SEO title={metaTitle} />
                        <Component type="partner" />
                      </>
                    }
                  />
                ))}
              </>
            )}
            {/* SUPPLIER ROUTES */}
            {roleValidation("supplier", user?.roles) && (
              <>
                {supplierRoutes.map(({ path, Component, metaTitle }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <>
                        <SEO title={metaTitle} />
                        <Component type="supplier" />
                      </>
                    }
                  />
                ))}
              </>
            )}
            {/* CHARGING PARTNERS ROUTES */}
            {roleValidation("charging_partner", user?.roles) && (
              <>
                {chargingPartnerRoutes.map(({ path, Component, metaTitle }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <>
                        <SEO title={metaTitle} />
                        <Component />
                      </>
                    }
                  />
                ))}
              </>
            )}

            <Route path="*" element={<Page404 />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
