import React from "react";

import classNames from "classnames";

import styles from "./Disclaimer.module.scss";

const Disclaimer = (props: { black?: boolean }) => {
  return (
    <div className={classNames(styles.disclaimer_container, { [styles.black]: props.black })}>
      <div className="container">
        <div className={styles.disclaimer}>
          <div>
            Conţinutul acestui material nu reprezintă în mod obligatoriu poziţia oficială a Uniunii
            Europene sau a Guvernului României.
            <br />
            Pentru informații detaliate despre celelate programe cofinanțate de Uniunea Europeană,
            vă invităm să vizitați{" "}
            <a target="_blank" href="www.fonduri-ue.ro">
              www.fonduri-ue.ro
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
