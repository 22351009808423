import React, { useEffect, useState } from "react";

import { useGetNearbyVehiclesQuery } from "app/vehicles/vehiclesApiSlice";

import { GoogleMap, MarkerClustererF, useLoadScript } from "@react-google-maps/api";
import { IsLoading, VehiclesFilters, VehiclesMarker } from "components";
import { GlobalVariables } from "utils";

import { I_MARKER } from "./VehiclesMarker";

const containerStyle = {
  width: "100%",
  height: "75vh",
};

function VehiclesMap() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
  });

  const [activeFilter, setActiveFilter] = useState<string>("vehicles");
  const [map, setMap] = useState<any>();
  const [bounds, setBounds] = useState<any>(null);
  const [boundsCopy, setBoundsCopy] = useState<any>(null);
  const [active, setActive] = useState<I_MARKER>();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { data, isLoading } = useGetNearbyVehiclesQuery(
    {
      westLongitude: bounds?.getSouthWest().lng(),
      eastLongitude: bounds?.getNorthEast().lng(),
      northLatitude: bounds?.getNorthEast().lat(),
      southLatitude: bounds?.getSouthWest().lat(),
      agencyId: 1,
      withScooters: activeFilter === "vehicles",
      withCars: activeFilter === "vehicles",
      withBicycles: activeFilter === "vehicles",
      withContainers: activeFilter === "containers",
      withChargingStations: activeFilter === "recharge-stations",
      withBusStops: activeFilter === "public-transport-stations",
    },
    { skip: !bounds }
  );

  const onBoundsChanged = () => {
    if (map) {
      const bounds = map.getBounds();
      setBoundsCopy(bounds);
    }
  };

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    const delay = setTimeout(
      () => {
        setBounds(boundsCopy);
      },
      isFirstLoad ? 0 : 1000
    );
    return () => clearTimeout(delay);
  }, [boundsCopy]);

  const filterHandler = (filter: string) => {
    setActiveFilter(filter);
  };

  return isLoading || !isLoaded ? (
    <IsLoading />
  ) : (
    <div className="position-relative">
      <VehiclesFilters activeFilters={activeFilter} filterHandler={filterHandler} />
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={GlobalVariables.center}
        zoom={13}
        onBoundsChanged={onBoundsChanged}
        onLoad={(mapInstance) => setMap(mapInstance)}
      >
        <MarkerClustererF averageCenter={false} maxZoom={20} gridSize={50} minimumClusterSize={5}>
          {(clusterer) =>
            data?.map((item: any, index: number) => {
              return (
                <VehiclesMarker
                  clusterer={clusterer}
                  item={item}
                  key={`${item.objectType}-${item.id}-${item.type}`}
                  active={active}
                  setActive={setActive}
                  index={index}
                />
              );
            })
          }
        </MarkerClustererF>
      </GoogleMap>
    </div>
  );
}

export default React.memo(VehiclesMap);
