import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { selectCurrentUser } from "app/auth/authSlice";
import {
  useDeleteElectricityContainerMutation,
  useGetSortedElectricityContainerQuery,
} from "app/containersAndStations/containersApiSlice";
import { I_STATION } from "app/containersAndStations/stationsApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Battery, ConfirmationPopup, TableBadge, TableComponent, TableIcon } from "components";
import {
  calculateRemainingCapacity,
  errorHandler,
  getSortDetails,
  GlobalStrings,
  GlobalVariables,
} from "utils";
import { ElectricityContainerStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_CONNECTOR } from "utils/types";

import { chargingParterRoutesPath } from "../../routes";

export type ElectricityContainers = {
  id: number;
  name?: string;
  status: ElectricityContainerStatus;
  totalCapacity: number;
  levelOfBattery: number;
  remainingEnergyCapacity: number;
  numberOfDailyCharges: number;
  address: string;
  lat: number;
  lng: number;
  companyName: string;
  connectors: I_CONNECTOR[];
  stations: I_STATION[];
  latitude: number;
  longitude: number;
};

const ContainersTable = () => {
  const user = useSelector(selectCurrentUser);
  const [deleteContainer] = useDeleteElectricityContainerMutation();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const pagination = {
    pageIndex,
    pageSize,
  };

  const columnHelper = createColumnHelper<ElectricityContainers>();
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, isFetching, refetch, error } = useGetSortedElectricityContainerQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
    companyId: user.company.id,
  });

  if (error) {
    errorHandler(error);
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<any>();
  const toggleModal = () => setModalIsOpen((prev) => !prev);

  const deleteContainerHandler = async () => {
    try {
      await deleteContainer(userToDelete.id).unwrap();
      toggleModal();
      refetch();
      toast.success(GlobalStrings.containersAndStations.electricityContainerDeleted);
    } catch (error) {
      errorHandler(error);
    }
  };

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.name}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => (
        <TableBadge
          text={GlobalStrings.electricityContainerStatus?.[info.getValue()]}
          color={
            info.getValue() === ElectricityContainerStatus.AVAILABLE
              ? "green"
              : info.getValue() === ElectricityContainerStatus.CHARGING
              ? "orange"
              : "red"
          }
        />
      ),
      header: () => (
        <span className="d-block text-center">{GlobalStrings.containersAndStations.status}</span>
      ),
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row.totalCapacity, {
      id: "totalCapacity",
      cell: (info) => (
        <p>
          {info.getValue() ? (
            <>
              {info.getValue()} {GlobalVariables.capacityUM}
            </>
          ) : (
            info.getValue()
          )}
        </p>
      ),
      header: () => <span>{GlobalStrings.containersAndStations.totalCapacity}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row.levelOfBattery, {
      id: "levelOfBattery",
      cell: (info) =>
        (info.getValue() as any) !== NO_VALUE_SYMBOL ? (
          <Battery level={info.getValue()} />
        ) : (
          <p className="text-center">{GlobalStrings.noData}</p>
        ),

      header: () => <span>{GlobalStrings.containersAndStations.levelOfBattery}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor(
      (row: any) => {
        return calculateRemainingCapacity(row.totalCapacity, row.levelOfBattery);
      },
      {
        id: "remainingEnergyCapacity",
        cell: (info) => (
          <p>
            {!isNaN(+info.getValue()) ? (
              <>
                {info.getValue()} {GlobalVariables.capacityUM}
              </>
            ) : (
              info.getValue()
            )}
          </p>
        ),
        header: () => <span>{GlobalStrings.containersAndStations.remainingEnergyCapacity}</span>,
        enableSorting: false,
        size: 1,
        minSize: 15,
      }
    ),

    columnHelper.accessor((row) => row.numberOfDailyCharges, {
      id: "numberOfDailyCharges",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.containersAndStations.numberOfDailyCharges}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),

    columnHelper.accessor((row) => row.connectors?.length, {
      id: "connectors",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.connectors}</span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
    }),

    columnHelper.accessor((row) => row.stations?.length, {
      id: "stations",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.stations}</span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
    }),

    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <Link
            to={chargingParterRoutesPath.ContainersAndStations.EditContainer.replace(
              ":id",
              info.getValue().id.toString()
            )}
          >
            <TableIcon type="edit" />
          </Link>
          <TableIcon
            type="delete"
            onClickAction={() => {
              toggleModal();
              setUserToDelete(info.getValue());
            }}
          />
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
    }),
  ];

  const table = useReactTable({
    data: data?.electricityContainers || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount: data?.meta?.numberOfPages || Math.ceil(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <>
      <TableComponent
        table={table}
        tableHasData={data?.electricityContainers?.length > 0}
        isLoading={isFetching}
      />
      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        title={GlobalStrings.settings.modalTitleElectricityContainer}
        body={GlobalStrings.settings.modalBodyElectricityContainer}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deleteContainerHandler}
      />
    </>
  );
};
export default ContainersTable;
