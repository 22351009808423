import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useDeletePublicTransportPartnersMutation,
  useGetPublicTransportPartnersQuery,
} from "app/publicTransportParteners/publicTransportPartnersApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  ConfirmationPopup,
  PageHeader,
  SEO,
  TableComponent,
  TableIcon,
  VehicleType,
} from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_PUBLIC_TRANSPORT_PARTNER } from "utils/types";

import { adminRoutesPath } from "../routes";

const PublicTransportPartners = () => {
  const [deletePublicTransportPartner] = useDeletePublicTransportPartnersMutation();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, isFetching, refetch } = useGetPublicTransportPartnersQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
  });

  const columnHelper = createColumnHelper<I_PUBLIC_TRANSPORT_PARTNER>();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [publicTransportPartnerToDelete, setPublicTransportPartnerToDelete] = useState<any>();

  const toggleModal = () => setModalIsOpen((prev) => !prev);

  const deletePublicTransportPartnerHandler = async () => {
    try {
      await deletePublicTransportPartner(publicTransportPartnerToDelete.id).unwrap();
      toggleModal();
      refetch();
      toast.success(GlobalStrings.PublicTransportParteners.deleteSuccessToast);
    } catch (error) {
      errorHandler(error);
    }
  };

  const columns = [
    columnHelper.accessor((row: any) => row.name, {
      id: "name",
      cell: (info) => <p>{info.getValue() || NO_VALUE_SYMBOL}</p>,
      header: () => <span>{GlobalStrings.PublicTransportParteners.nameTransport}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row: any) => row.city, {
      id: "city",
      cell: (info) => {
        const text = info.getValue() || NO_VALUE_SYMBOL;
        return <p>{text.charAt(0).toUpperCase() + text.slice(1)}</p>;
      },
      header: () => <span>{GlobalStrings.PublicTransportParteners.city}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row: any) => row, {
      id: "contactDetails",
      cell: (info) => {
        const { address, email, phone } = info.getValue();
        return (
          <p>
            {address || NO_VALUE_SYMBOL} <br />
            {email || NO_VALUE_SYMBOL} <br />
            {phone || NO_VALUE_SYMBOL}
          </p>
        );
      },
      header: () => <span>{GlobalStrings.PublicTransportParteners.contactDetails}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((row: any) => row.publicTransportVehicles, {
      id: "publicTransportVehicles",
      cell: (info) => <VehicleType vehicles={info.getValue()} />,
      header: () => <span>{GlobalStrings.PublicTransportParteners.meansOfTransport}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),

    columnHelper.accessor((row: any) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <Link
            to={adminRoutesPath.PublicTransport.Edit.replace(":id", info.getValue().id.toString())}
          >
            <TableIcon type="edit" />
          </Link>
          <TableIcon
            type="delete"
            onClickAction={() => {
              toggleModal();
              setPublicTransportPartnerToDelete(info.getValue());
            }}
          />
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    data: data?.publicTransportParteners || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  const publicTransportPartenersTabs = [
    {
      id: 0,
      name: GlobalStrings.formLabels.add,
      slug: adminRoutesPath.PublicTransport.Add,
    },
  ];

  return (
    <section>
      <SEO title={GlobalStrings.SEO.admin.publicTransportPartners} />
      <PageHeader
        title={GlobalStrings.PublicTransportParteners.pageTitle}
        tabs={publicTransportPartenersTabs}
      />

      <TableComponent
        table={table}
        tableHasData={data?.publicTransportParteners?.length > 0}
        isLoading={isFetching}
      />

      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        title={`${GlobalStrings.settings.partnerModalTitle} - ${publicTransportPartnerToDelete?.name}`}
        body={GlobalStrings.settings.partnerModalBody}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deletePublicTransportPartnerHandler}
      />
    </section>
  );
};
export default PublicTransportPartners;
