import React from "react";
import { useParams } from "react-router-dom";

import { IsLoading } from "components";
import { GlobalStrings } from "utils";

import PartnerForm, { I_PARTNER_FORM } from "views/common/Partners/PartnerForm/PartnerForm";

const GeneralInfo = (props: { isFetching: boolean; data: I_PARTNER_FORM; refetch: Function }) => {
  const { partnerId } = useParams();

  return (
    <section>
      {props.isFetching ? (
        <IsLoading />
      ) : (
        <div className="mt-4">
          <div className="card">
            <h4>{GlobalStrings.partners.generalInfo}</h4>
            <PartnerForm data={props.data} id={partnerId} refetch={props.refetch} />
          </div>
        </div>
      )}
    </section>
  );
};
export default GeneralInfo;
