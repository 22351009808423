import React from "react";

import { LoginForm } from "components";

import Disclaimer from "components/Disclaimer/Disclaimer";

const Public = () => {
  return (
    <section className="d-flex align-items-center justify-content-center min-vh-100 py-5">
      <div className="form-card">
        <LoginForm />
        <Disclaimer black/>
      </div>
    </section>
  );
};
export default Public;
