import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  useGetAllElectricityContainersQuery,
  useGetNumberOfUsedContainersQuery,
} from "app/containersAndStations/containersApiSlice";
import {
  useGetNumberOfStationsInUseQuery,
  useGetStationsQuery,
} from "app/containersAndStations/stationsApiSlice";

import { ContainersMap, PageHeader } from "components";
import { errorHandler } from "utils";
import GlobalStrings from "utils/globalStrings";

import BookingHistory from "./BookingHistory/BookingHistory";
import ContainersTable from "./Containers/ContainersTable";
import Prices from "./Prices/Prices";
import StationsTable from "./Stations/StationsTable";
import StationsMap from "./StationsMap/StationsMap";

import { adminRoutesPath } from "../routes";

const ContainersAndStations = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const { data: containers, error: numberOfContainersError } =
    useGetNumberOfUsedContainersQuery(undefined);
  const { data: numberOfStationsInUse } = useGetNumberOfStationsInUseQuery(undefined);
  const { data: electricityContainers, error: mapError } = useGetAllElectricityContainersQuery({});
  const { data: stations, error: stationsMapError } = useGetStationsQuery({});

  const buttons = [
    {
      id: 0,
      name: GlobalStrings.containersAndStations.addContainer,
      slug: adminRoutesPath.ContainersAndStations.AddContainer,
    },
    {
      id: 1,
      name: GlobalStrings.containersAndStations.addStation,
      slug: adminRoutesPath.ContainersAndStations.AddStation,
    },
  ];

  const electricityContainersTabs = [
    {
      id: 0,
      name: GlobalStrings.containersAndStations.containersMap,
      slug: adminRoutesPath.ContainersAndStations.ContainersMap,
    },
    {
      id: 1,
      name: GlobalStrings.containersAndStations.containers,
      slug: adminRoutesPath.ContainersAndStations.Containers,
    },
    {
      id: 0,
      name: GlobalStrings.containersAndStations.stationsMaps,
      slug: adminRoutesPath.ContainersAndStations.StationsMap,
    },
    {
      id: 3,
      name: GlobalStrings.containersAndStations.stations,
      slug: adminRoutesPath.ContainersAndStations.Stations,
    },
    {
      id: 4,
      name: GlobalStrings.containersAndStations.prices,
      slug: adminRoutesPath.ContainersAndStations.Prices,
    },
    {
      id: 5,
      name: GlobalStrings.containersAndStations.bookingHistory,
      slug: adminRoutesPath.ContainersAndStations.BookingHistory,
    },
  ];

  useEffect(() => {
    if (location.pathname.includes(electricityContainersTabs[0].slug)) {
      setActiveIndex(0);
    } else if (location.pathname.includes(electricityContainersTabs[1].slug)) {
      setActiveIndex(1);
    } else if (location.pathname.includes(electricityContainersTabs[2].slug)) {
      setActiveIndex(2);
    } else if (location.pathname.includes(electricityContainersTabs[3].slug)) {
      setActiveIndex(3);
    } else if (location.pathname.includes(electricityContainersTabs[4].slug)) {
      setActiveIndex(4);
    } else if (location.pathname.includes(electricityContainersTabs[5].slug)) {
      setActiveIndex(5);
    }
  }, [location.pathname]);

  useEffect(() => {
    errorHandler(mapError);
    errorHandler(numberOfContainersError);
    errorHandler(stationsMapError);
  }, [numberOfContainersError, mapError]);

  return (
    <section>
      <PageHeader
        title={GlobalStrings.containersAndStations.title}
        tabsCurrentview={electricityContainersTabs}
        tabsCurrentviewActiveIndex={activeIndex}
        tabs={activeIndex === 1 ? [buttons[0]] : activeIndex === 3 ? [buttons[1]] : undefined}
      />

      {activeIndex === 0 && <ContainersMap data={electricityContainers} containers={containers} />}
      {activeIndex === 1 && <ContainersTable />}
      {activeIndex === 2 && (
        <StationsMap data={stations?.stations} numberOfStationsInUse={numberOfStationsInUse} />
      )}
      {activeIndex === 3 && <StationsTable />}
      {activeIndex === 4 && <Prices />}
      {activeIndex === 5 && <BookingHistory />}
    </section>
  );
};
export default ContainersAndStations;
