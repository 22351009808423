import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Form, FormGroup, Input, Label } from "reactstrap";

import { useCreateActiveServicesMutation } from "app/suppliers/activeServicesApiSlice";
import { useGetSuppliersQuery } from "app/suppliers/suppliersApiSlice";

import { CitiesSelect, PageHeader, SubmitButton } from "components";
import { GlobalVariables, Regex } from "utils";
import { errorHandler, returnFirstErrorMessage } from "utils/globalFunctions";
import GlobalStrings from "utils/globalStrings";
import { I_SERVICE } from "utils/types";

import { adminRoutesPath } from "views/admin/routes";

const AddServiceForm = () => {
  const [addService, { isLoading }] = useCreateActiveServicesMutation();
  const { data } = useGetSuppliersQuery({});
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const { supplierId } = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<I_SERVICE>({
    defaultValues: {
      city: "",
      coveredZone: "",
      serviceName: undefined,
      vehicleType: undefined,
      companyId: "",
    },
  });

  const onSubmit = async (data: I_SERVICE) => {
    try {
      if (!selectedCompany && supplierId === undefined) {
        toast.error(GlobalStrings.formValidation.fieldsRequired);
        return;
      }
      await addService({
        city: data.city.value,
        coveredZone: data.coveredZone,
        serviceName: data.serviceName,
        vehicleType: data.vehicleType,
        companyId: supplierId || selectedCompany.id,
      }).unwrap();
      toast.success(GlobalStrings.servicesSuppliers.serviceWasAdded);

      if (supplierId) {
        navigate(
          adminRoutesPath.Suppliers.Services.EditSupplier.replace(
            "suppplierId",
            supplierId.toString()
          )
        );
      } else if (selectedCompany) {
        navigate(
          adminRoutesPath.Suppliers.Services.EditSupplier.replace(
            "suppplierId",
            selectedCompany.id.toString()
          )
        );
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleCompanySelection = (companyName: any) => {
    const selectedCompanyData = data?.suppliers.find((item: any) => item.name === companyName);
    if (selectedCompanyData) {
      setSelectedCompany(selectedCompanyData);
      reset({
        company: {
          name: selectedCompanyData.name || "",
          phone: selectedCompanyData.phone || "",
          email: selectedCompanyData.email || "",
          identifier: selectedCompanyData.identifier.replace("/S", "") || "",
        },
      });
    } else {
      setSelectedCompany(selectedCompany);
      reset({
        company: {
          name: "",
          phone: "",
          email: "",
          identifier: "",
        },
      });
    }
  };

  return (
    <>
      <PageHeader title={`${GlobalStrings.servicesSuppliers.addService}`} backRoute="-1" />

      <section className="d-flex flex-column gap-5 card">
        <Form onSubmit={handleSubmit(onSubmit)}>
          {supplierId === undefined && (
            <>
              <div className="d-flex gap-2 flex-column flex-md-row">
                <FormGroup floating className="w-100">
                  <Controller
                    name="company.name"
                    control={control}
                    rules={
                      supplierId === undefined
                        ? {
                            required: GlobalStrings.formValidation.fieldsRequired,
                          }
                        : undefined
                    }
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        {...field}
                        invalid={invalid}
                        id="supplierName"
                        placeholder={GlobalStrings.formLabels.chooseSupplier}
                        type="select"
                        onChange={(e) => handleCompanySelection(e.target.value)}
                      >
                        <option value="">-</option>
                        {data?.suppliers.map((item: any) => {
                          return (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Input>
                    )}
                  />
                  <Label for="company.name">{GlobalStrings.formLabels.addSupplier}</Label>
                </FormGroup>

                <FormGroup floating className="w-100">
                  <Controller
                    name="company.identifier"
                    control={control}
                    rules={
                      supplierId === undefined
                        ? {
                            required: GlobalStrings.formValidation.fieldsRequired,
                          }
                        : undefined
                    }
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        {...field}
                        invalid={invalid}
                        placeholder={GlobalStrings.formLabels.CIF}
                        type="text"
                        disabled
                      />
                    )}
                  />
                  <Label for="company.identifier">
                    {GlobalStrings.servicesSuppliers.serviceSupplierCif}
                  </Label>
                </FormGroup>
              </div>

              <div className="d-flex gap-2 flex-column flex-md-row">
                <FormGroup floating className="w-100">
                  <Controller
                    name="company.email"
                    control={control}
                    rules={
                      supplierId === undefined
                        ? {
                            required: GlobalStrings.formValidation.fieldsRequired,
                            pattern: {
                              value: Regex.email,
                              message: GlobalStrings.formValidation.invalidEmail,
                            },
                          }
                        : undefined
                    }
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        {...field}
                        invalid={invalid}
                        placeholder={GlobalStrings.formLabels.email}
                        type="text"
                        disabled
                      />
                    )}
                  />
                  <Label for="company.email">{GlobalStrings.formLabels.email}</Label>
                </FormGroup>

                <FormGroup floating className="w-100">
                  <Controller
                    name="company.phone"
                    control={control}
                    rules={
                      supplierId === undefined
                        ? {
                            required: GlobalStrings.formValidation.fieldsRequired,
                            pattern: {
                              value: Regex.phone,
                              message: GlobalStrings.formValidation.invalidPhone,
                            },
                          }
                        : undefined
                    }
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        {...field}
                        invalid={invalid}
                        placeholder={GlobalStrings.formLabels.phoneNumber}
                        type="text"
                        disabled
                      />
                    )}
                  />
                  <Label for="company.phone">{GlobalStrings.formLabels.phoneNumber}</Label>
                </FormGroup>
              </div>
            </>
          )}

          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-100">
              <CitiesSelect control={control} name="city" />
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="coveredZone"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.city}
                    type="text"
                  />
                )}
              />
              <Label for="coveredZone">{GlobalStrings.servicesSuppliers.supplierZoneCovered}</Label>
            </FormGroup>
          </div>

          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-100">
              <Controller
                name="serviceName"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.city}
                    type="select"
                  >
                    <option value="">-</option>
                    {GlobalVariables.serviceType.map((item: any) => {
                      return (
                        <option key={item.id} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </Input>
                )}
              />
              <Label for="serviceName">{GlobalStrings.formLabels.serviceType}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="vehicleType"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.city}
                    type="select"
                  >
                    <option value="">-</option>
                    {GlobalVariables.vehicleTypes.map((item: any) => {
                      return (
                        <option key={item.id} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </Input>
                )}
              />
              <Label for="vehicleType">{GlobalStrings.servicesSuppliers.vehicleType}</Label>
            </FormGroup>
          </div>

          <SubmitButton isLoading={isLoading} label={GlobalStrings.formLabels.save} />

          <Alert color="danger" fade={true} isOpen={Object.keys(errors).length > 0}>
            {returnFirstErrorMessage(errors) || GlobalStrings.formValidation.fieldsRequired}
          </Alert>
        </Form>
      </section>
    </>
  );
};

export default AddServiceForm;
