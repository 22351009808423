import React from "react";
import { Control, Controller } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import classNames from "classnames";
import { GlobalStrings } from "utils";
import { I_PARTNER } from "utils/types";

const CompaniesSelect = (props: {
  control: Control<any, any>;
  name: string;
  companies: I_PARTNER[];
  disabled?: boolean;
}) => {
  const animatedComponents = makeAnimated();

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        required: GlobalStrings.formValidation.fieldsRequired,
      }}
      render={({ field, fieldState: { invalid } }) =>
        props.companies && (
          <Select
            className={classNames("react_select", {
              invalid,
            })}
            name={props.name}
            placeholder={GlobalStrings.formLabels.city}
            value={field.value}
            onChange={(selected) => {
              field.onChange(selected);
            }}
            closeMenuOnSelect={true}
            components={animatedComponents}
            options={props?.companies?.map((company: any) => ({
              value: company.id,
              label: company.name,
            }))}
            isDisabled={props.disabled}
          />
        )
      }
    />
  );
};

export default CompaniesSelect;
