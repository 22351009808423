import React from "react";

import classNames from "classnames";
import parse from "html-react-parser";

import styles from "./TableBadge.module.scss";

const TableBadge = (props: {
  text: string;
  color: "red" | "green" | "orange" | "transparent" | "gray";
}) => {
  return (
    <div className={classNames(styles.table_badge, styles[props.color])}>{parse(props.text)}</div>
  );
};

export default TableBadge;
