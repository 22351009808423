import React, { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import { useGetEndUsersWithDocumentsQuery } from "app/users/usersApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { PageHeader, TableBadge, TableComponent, TableIcon } from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { UserStatus } from "utils/enums";

import SearchBar from "components/SearchBar/SearchBar";

import { adminRoutesPath } from "../routes";

type EndUser = {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  phone: string;
  status: UserStatus;
  reasonForBlocking: string;
  identityCard: {
    createdAt: string;
    updatedAt: string;
  };
  driverLicence: {
    createdAt: string;
    updatedAt: string;
  };
  transportTypes: {
    id: string;
    name: string;
    type: string;
  }[];
  numberOfTrips: number;
  totalPaid: number;
};

const Documents = () => {
  const columnHelper = createColumnHelper<EndUser>();
  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, isFetching, error } = useGetEndUsersWithDocumentsQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
    search,
  });

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setSearch(searchTerm);
    }, 500);
    return () => clearInterval(delay);
  }, [searchTerm]);

  const columns = [
    columnHelper.accessor((row) => row.lastName, {
      id: "lastName",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.personUsers.lastName}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row.firstName, {
      id: "firstName",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.personUsers.firstName}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),

    columnHelper.accessor((row) => row.email, {
      id: "email",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.personUsers.email}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.phone, {
      id: "phone",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.personUsers.phone}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row.identityCard.createdAt, {
      id: "createdAt",
      cell: (info) => (
        <p>
          <Moment date={info.getValue()} format="DD.MM.YYYY, HH:mm" />
        </p>
      ),
      header: () => <span> {GlobalStrings.documents.createdAt}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor(
      (row) => {
        if (row?.driverLicence?.updatedAt < row?.identityCard?.updatedAt) {
          return row?.identityCard?.updatedAt;
        }
        return row?.driverLicence?.updatedAt;
      },
      {
        id: "updatedAt",
        cell: (info) => (
          <p>
            <Moment date={info.getValue()} format="DD.MM.YYYY, HH:mm" />
          </p>
        ),
        header: () => <span>{GlobalStrings.documents.updatedAt}</span>,
        enableSorting: true,
        enableMultiSort: true,
        size: 1,
        minSize: 10,
      }
    ),
    columnHelper.accessor((row) => row, {
      id: "status",
      cell: (info) => {
        return (
          <div>
            <TableBadge
              color={
                info.getValue().status === UserStatus.blocked
                  ? "red"
                  : info.getValue().status === UserStatus.inactive
                  ? "orange"
                  : "green"
              }
              text={GlobalStrings.userAccountStatus[info.getValue().status]}
            />
          </div>
        );
      },
      header: () => <span>{GlobalStrings.personUsers.accountStatus}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <Link
            to={adminRoutesPath.EndUsers.EndUsersDocuments.replace(
              ":id",
              info.getValue().id.toString()
            )}
          >
            <TableIcon type="edit" />
          </Link>
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
    }),
  ];

  const table = useReactTable({
    data: data?.endUsers,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount: data?.meta?.numberOfPages,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <section>
      <PageHeader
        title={GlobalStrings.documents.notApprovedDocuments}
        backRoute={adminRoutesPath.EndUsers.Index}
      />

      <div className="d-flex justify-content-end">
        <div className="w-md-33 w-100 mb-3">
          <SearchBar
            searchTerm={searchTerm}
            onSearchChange={setSearchTerm}
            placeholder={GlobalStrings.formLabels.phoneNumber}
          />
        </div>
      </div>

      <TableComponent
        table={table}
        tableHasData={data?.endUsers?.length > 0}
        isLoading={isFetching}
      />
    </section>
  );
};
export default Documents;
