import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { selectCurrentUser } from "app/auth/authSlice";
import { useAddToCartMutation, useGetCartProductsQuery } from "app/marketplace/cartApiSlice";

import { ProductTeaser } from "components";
import { errorHandler, GlobalStrings } from "utils";
import { I_PRODUCT } from "utils/types";

import styles from "./ProductsListing.module.scss";

const ProductsListing = (props: { products: I_PRODUCT[] }) => {
  const user = useSelector(selectCurrentUser);
  const [addToCart] = useAddToCartMutation();
  const { refetch } = useGetCartProductsQuery(user?.id, {
    refetchOnMountOrArgChange: false,
  });

  const addToCartHandler = async (productId: number) => {
    try {
      await addToCart({ productId }).unwrap();
      toast.info(GlobalStrings.marketplace.productAdded);
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };
  return (
    <section className={styles.products_container}>
      {props.products?.map((item: I_PRODUCT, index: number) => {
        return (
          <div key={index}>
            <ProductTeaser
              id={item.id}
              productName={item.name}
              productImage={item?.imagesKeys?.[0] || ""}
              price={item.price}
              unit={item.unit}
              inStock={item.inStock}
              type="partner"
              slug="products"
              addToCartFunction={addToCartHandler}
            />
          </div>
        );
      })}
    </section>
  );
};
export default ProductsListing;
