import React, { useCallback, useEffect, useState } from "react";

import { useGetElectricityContainerQuery } from "app/containersAndStations/containersApiSlice";
import { useGetVehicleQuery } from "app/vehicles/vehiclesApiSlice";

import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { IsLoading } from "components";
import { TransportType } from "utils/enums";
import { calculatePosition } from "utils/globalFunctions";

import bikePin from "assets/icons/bike_pin.svg";
import carPin from "assets/icons/car_pin.svg";
import scooterPin from "assets/icons/scooter_pin.svg";
import stationPin from "assets/icons/station_pin.svg";
import greenPin from "assets/images/recharge_station_green.svg";
import yellowPin from "assets/images/recharge_station_yellow.svg";

import MarkerData from "./MarkerData";

export interface I_MARKER {
  id: number;
  objectType: "vehicle" | "container" | "bus_stop" | "charging_station";
  type: TransportType;
  position: {
    latitude: string;
    longitude: string;
  };
  latitude: string;
  longitude: string;
}

const VehiclesMarker = (props: {
  clusterer: any;
  item: I_MARKER;
  active: I_MARKER | undefined;
  setActive: any;
  index: number;
}) => {
  const google = window.google;
  const [MVCObject, setMVCObject] = useState(undefined);
  const handleOnLoad = useCallback((marker: any) => {
    setMVCObject(marker);
  }, []);
  const {
    data: vehicleData,
    isLoading: isLoadingVehicleData,
    refetch,
  } = useGetVehicleQuery(props.active?.id, {
    skip:
      !props.active?.id ||
      props.active?.id !== props.item.id ||
      props.item.objectType !== "vehicle",
  });

  const { data: electricityContainerData, isLoading: isLoadingElectricityContainerData } =
    useGetElectricityContainerQuery(props.active?.id, {
      skip:
        !props.active?.id ||
        props.active?.id !== props.item.id ||
        props.item.objectType !== "container",
    });

  const [image, setImage] = useState("");
  useEffect(() => {
    let image;
    switch (props.item.objectType) {
      case "bus_stop":
        image = stationPin;
        break;
      case "vehicle":
        if (props.item?.type && props.item?.type === "car") {
          image = carPin;
        } else if (props.item?.type && props.item?.type === "bicycle") {
          image = bikePin;
        } else {
          image = scooterPin;
        }
        break;
      case "charging_station":
        image = yellowPin;
        break;
      case "container":
        image = greenPin;
        break;
      default:
        image = yellowPin;
    }
    setImage(image);
  }, [props.item]);

  return (
    <>
      {image && (
        <MarkerF
          onLoad={handleOnLoad}
          icon={{
            url: image,
            scaledSize: new google.maps.Size(50, 50),
          }}
          position={{
            lat:
              calculatePosition(props.index, parseFloat(props.item?.position?.latitude)) ||
              calculatePosition(props.index, parseFloat(props.item?.latitude)),
            lng:
              calculatePosition(props.index, parseFloat(props.item?.position?.longitude)) ||
              calculatePosition(props.index, parseFloat(props.item?.longitude)),
          }}
          clusterer={props.clusterer}
          clickable
          onClick={() => props.setActive(props.item)}
        >
          {props.active && props.active?.id === props.item?.id && (
            <InfoWindowF
              anchor={MVCObject}
              onCloseClick={() => {
                setMVCObject(undefined);
                props.setActive(undefined);
              }}
              position={{
                lat: parseFloat(props.item?.position?.latitude) || parseFloat(props.item?.latitude),
                lng:
                  parseFloat(props.item?.position?.longitude) || parseFloat(props.item?.longitude),
              }}
            >
              <div className="map_pin">
                {isLoadingVehicleData || isLoadingElectricityContainerData ? (
                  <IsLoading />
                ) : (
                  <MarkerData
                    data={
                      vehicleData || electricityContainerData?.electricityContainer || props.active
                    }
                    objectType={props.active.objectType}
                    refetch={refetch}
                  />
                )}
              </div>
            </InfoWindowF>
          )}
        </MarkerF>
      )}
    </>
  );
};

export default React.memo(VehiclesMarker);
