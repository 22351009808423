import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { setMarketplaceIsUsedInApp } from "app/common/commonSlice";

const LayoutAppMarketplace = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMarketplaceIsUsedInApp());
  }, []);

  return (
    <section>
      <div className="marketplace_app_container">
        <Outlet />
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          icon={false}
        />
      </div>
    </section>
  );
};
export default LayoutAppMarketplace;
