import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import { useAddUserToSupplierMutation } from "app/suppliers/suppliersApiSlice";

import { AlertComponent, PageHeader, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { Regex } from "utils";
import { errorHandler, roleValidation } from "utils/globalFunctions";
import GlobalStrings from "utils/globalStrings";
import { I_INVITE_USER_FORM } from "utils/types";

import Page404 from "views/common/Page404/Page404";

const AddSupplierUserForm = () => {
  const { supplierId } = useParams();
  const user = useSelector(selectCurrentUser);
  const [addUserToSupplier, { isLoading }] = useAddUserToSupplierMutation();
  const { serverMessage, setServerMessage } = useServerMessage();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<I_INVITE_USER_FORM>({
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },
  });

  const onSubmit = async (data: I_INVITE_USER_FORM) => {
    try {
      await addUserToSupplier({
        supplierId,
        user: {
          lastName: data.lastName,
          firstName: data.firstName,
          email: data.email,
          phone: data.phone,
        },
      }).unwrap();

      setServerMessage({
        type: "success",
        text: GlobalStrings.partners.userWasSaved,
      });
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  if (
    supplierId?.toString() !== user?.company?.id.toString() &&
    roleValidation("supplier", user?.roles)
  )
    return <Page404 />;

  return (
    <>
      <PageHeader title={`${GlobalStrings.servicesSuppliers.addUser}`} backRoute="-1" />
      <section className="d-flex flex-column gap-5 card">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-100">
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.firstName}
                    type="text"
                  />
                )}
              />
              <Label for="firstName">{GlobalStrings.formLabels.firstName}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.lastName}
                    type="text"
                  />
                )}
              />
              <Label for="lastName">{GlobalStrings.formLabels.lastName}</Label>
            </FormGroup>
          </div>
          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-100">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  pattern: {
                    value: Regex.email,
                    message: GlobalStrings.formValidation.invalidEmail,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.email}
                    type="text"
                  />
                )}
              />
              <Label for="email">{GlobalStrings.formLabels.email}</Label>
            </FormGroup>
            <FormGroup floating className="w-100">
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  pattern: {
                    value: Regex.phone,
                    message: GlobalStrings.formValidation.invalidPhone,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.phoneNumber}
                    type="text"
                  />
                )}
              />
              <Label for="phone">{GlobalStrings.formLabels.phoneNumber}</Label>
            </FormGroup>
          </div>

          <SubmitButton isLoading={isLoading} label={GlobalStrings.add} className="mb-3" />

          <AlertComponent errors={errors} serverMessage={serverMessage} />
        </Form>
      </section>
    </>
  );
};

export default AddSupplierUserForm;
