import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { selectCurrentUser } from "app/auth/authSlice";
import { useGetServicesQuery } from "app/services/servicesApiSlice";
import { useDeleteActiveServicesMutation } from "app/suppliers/activeServicesApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ConfirmationPopup, PageHeader, TableComponent, TableIcon, VehicleType } from "components";
import { displayPriceForService, errorHandler, getSortDetails, GlobalStrings } from "utils";
import { ServiceType, TransportType } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_SERVICE } from "utils/types";

import { supplierRoutesPath } from "../routes";

const SupplierServices = () => {
  const user = useSelector(selectCurrentUser);
  const [deleteActiveServices] = useDeleteActiveServicesMutation();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeServicesToDelete, setActiveServicesToDelete] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const columnHelper = createColumnHelper<I_SERVICE>();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const { data, isFetching, refetch, error } = useGetServicesQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
    companyId: user.company.id,
  });

  const toggleModal = () => setModalIsOpen((prev) => !prev);

  const deleteActiveServicesHandler = async () => {
    try {
      await deleteActiveServices(activeServicesToDelete.id).unwrap();
      toggleModal();
      refetch();
      toast.success(GlobalStrings.activeServices.deleteSuccessToast);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  const columns = [
    columnHelper.accessor((row) => row.vehicleType, {
      id: "vehicleType",
      cell: (info) => {
        return info.getValue() !== "N/A" ? (
          <VehicleType vehicles={info.getValue()} />
        ) : (
          info.getValue()
        );
      },
      header: () => <span>{GlobalStrings.activeServices.vehicleType}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row.serviceName, {
      id: "serviceName",
      cell: (info) => <p>{GlobalStrings.servicesTypes[info.getValue()]}</p>,
      header: () => <span>{GlobalStrings.activeServices.serviceName}</span>,
      enableSorting: false,
    }),

    columnHelper.accessor((row) => row.city, {
      id: "city",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.activeServices.city}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row.coveredZone, {
      id: "coveredZone",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.activeServices.coveredZone}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row, {
      id: "amountAC",
      cell: (info) => (
        <p>
          {displayPriceForService(
            (info.getValue().vehicleType === TransportType.CAR ||
              info.getValue().vehicleType === TransportType.RIDESHARING) &&
              info.getValue().serviceName === ServiceType.RECHARGEBATTERY
              ? info.getValue().amountAC
              : info.getValue().amount,
            info.getValue().serviceName,
            info.getValue().vehicleType
          )}
        </p>
      ),
      header: () => (
        <span>
          {GlobalStrings.formLabels.amountAC}
          <br />
          {GlobalStrings.formLabels.amountBicycleAndScooter}
        </span>
      ),
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row, {
      id: "amountDC",
      cell: (info) => (
        <p>
          {(info.getValue().vehicleType === TransportType.CAR ||
            info.getValue().vehicleType === TransportType.RIDESHARING) &&
          info.getValue().serviceName === ServiceType.RECHARGEBATTERY
            ? displayPriceForService(
                info.getValue().amountDC,
                info.getValue().serviceName,
                info.getValue().vehicleType
              )
            : NO_VALUE_SYMBOL}
        </p>
      ),
      header: () => <span>{GlobalStrings.formLabels.amountDC}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),

    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <Link
            to={supplierRoutesPath.Services.Service.replace(
              ":serviceId",
              info.getValue().id.toString()
            )}
          >
            <TableIcon type="edit" />
          </Link>
          <TableIcon
            type="delete"
            onClickAction={() => {
              toggleModal();
              setActiveServicesToDelete(info.getValue());
            }}
          />
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    data: data?.services || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  const activeServicesTabs = [
    {
      id: 0,
      name: GlobalStrings.formLabels.addService,
      slug: supplierRoutesPath.Services.AddService,
    },
  ];

  const activeServicesTabsJuicers = [
    {
      id: 0,
      name: GlobalStrings.formLabels.addService,
      slug: supplierRoutesPath.Services.AddService,
    },
    {
      id: 1,
      name: GlobalStrings.formLabels.manageVehicles,
      slug: supplierRoutesPath.Services.VehiclesMap,
    },
  ];

  return (
    <section>
      <PageHeader
        title={GlobalStrings.activeServices.pageTitle}
        tabs={
          user.company.juicerForCompanies.length ? activeServicesTabsJuicers : activeServicesTabs
        }
      />

      <TableComponent
        table={table}
        tableHasData={data?.services?.length > 0}
        isLoading={isFetching}
      />

      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        title={`${GlobalStrings.settings.serviceModalTitle} - ${activeServicesToDelete?.id}`}
        body={GlobalStrings.settings.serviceModalBody}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deleteActiveServicesHandler}
      />
    </section>
  );
};
export default SupplierServices;
