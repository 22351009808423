import React from "react";
import Slider from "react-slick";

import placeholderImage from "assets/images/product_placeholder.png";

import styles from "./ProductImagesSlider.module.scss";

function ProductImagesSlider(props: { images: any }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return props.images.length > 0 ? (
    <div className={styles.slider}>
      <Slider {...settings}>
        {props.images.map((item: any, index: number) => {
          return (
            <div key={index} className={styles.image_container}>
              <img src={typeof item === "string" ? item : URL.createObjectURL(item)} key={index} />
            </div>
          );
        })}
      </Slider>
    </div>
  ) : (
    <img src={placeholderImage} className={styles.placeholder} />
  );
}

export default ProductImagesSlider;
