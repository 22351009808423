import React from "react";
import { useParams } from "react-router-dom";

import { useGetActiveServicesIdQuery } from "app/suppliers/activeServicesApiSlice";

import { IsLoading, PageHeader, SEO } from "components";
import { GlobalStrings } from "utils";
import { ServiceType } from "utils/enums";
import { Page404 } from "views/common";

import AddEditSupplierServiceForm from "./AddEditSupplierServiceForm/AddEditSupplierServiceForm";

const AddEditSupplierService = () => {
  const { serviceId } = useParams();
  const { data, isLoading, error } = useGetActiveServicesIdQuery(serviceId, {
    skip: !serviceId,
  });
  const serviceName = data?.serviceName as ServiceType;

  if (error && !isLoading && serviceId) {
    return <Page404 />;
  } else
    return (
      <section>
        {data && (
          <SEO
            title={
              GlobalStrings.servicesTypes[serviceName] + GlobalStrings.SEO.supplier.editService
            }
          />
        )}
        <PageHeader
          title={
            serviceId
              ? `${GlobalStrings.activeServices.activeServicesEditTitle} - ${GlobalStrings.servicesTypes[serviceName]}`
              : GlobalStrings.formLabels.addService
          }
          backRoute="/services"
        />
        <div className="mt-4 card">
          {isLoading ? <IsLoading /> : <AddEditSupplierServiceForm serviceData={data} />}
        </div>
      </section>
    );
};
export default AddEditSupplierService;
