import React from "react";
import { Control, Controller } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import classNames from "classnames";
import { GlobalStrings } from "utils";
import { ServiceType, TransportType } from "utils/enums";
import { I_SERVICE } from "utils/types";

const ServicesSelect = (props: {
  control: Control<any, any>;
  name: string;
  services: I_SERVICE[];
  disabled?: boolean;
}) => {
  const animatedComponents = makeAnimated();

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        required: GlobalStrings.formValidation.fieldsRequired,
      }}
      render={({ field, fieldState: { invalid } }) =>
        props.services && (
          <Select
            className={classNames("react_select", {
              invalid,
            })}
            name={props.name}
            placeholder={GlobalStrings.formLabels.city}
            value={field.value}
            onChange={(selected) => {
              field.onChange(selected);
            }}
            closeMenuOnSelect={true}
            components={animatedComponents}
            options={props?.services
              ?.filter(
                (service: I_SERVICE) =>
                  (service.vehicleType === TransportType.SCOOTER ||
                    service.vehicleType === TransportType.BICYCLE) &&
                  service.serviceName === ServiceType.RECHARGEBATTERY
              )
              .map((service: I_SERVICE) => {
                return {
                  ...service,
                  value: service.id,
                  label:
                    GlobalStrings.servicesTypes[ServiceType.RECHARGEBATTERY] +
                    " " +
                    GlobalStrings.vehicleTypes[service.vehicleType].toLowerCase(),
                };
              })}
            isDisabled={props.disabled}
          />
        )
      }
    />
  );
};

export default ServicesSelect;
