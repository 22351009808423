import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useGetElectricityContainerQuery } from "app/containersAndStations/containersApiSlice";

import { IsLoading, PageHeader } from "components";
import { errorHandler } from "utils";
import GlobalStrings from "utils/globalStrings";

import { adminRoutesPath } from "../../routes";
import Connectors from "../Connectors/Connectors";
import Stations from "./Stations/Stations";

import AddEditContainerForm from "./AddEditContainerForm";

const AddEditContainer = () => {
  const location = useLocation();
  const { id } = useParams();
  const { data, refetch, isFetching, error } = useGetElectricityContainerQuery(id, {
    skip: id === undefined,
  });

  useEffect(() => {
    errorHandler(error);
  }, [error]);

  return (
    <section>
      {isFetching ? (
        <IsLoading />
      ) : (
        <>
          <PageHeader title={GlobalStrings.containersAndStations.title} backRoute="-1" />

          <div className="card">
            {location.pathname.includes(adminRoutesPath.ContainersAndStations.AddContainer) && (
              <AddEditContainerForm refetch={refetch} />
            )}

            {id && data?.electricityContainer && (
              <AddEditContainerForm data={data?.electricityContainer} refetch={refetch} />
            )}
            <div className="d-flex flex-md-row flex-column gap-5 mt-t pt-5">
              {id && (
                <div className="w-md-50 w-100 mt-5">
                  <Connectors
                    connectors={data?.electricityContainer.connectors}
                    companyId={data?.electricityContainer.companyId}
                    containerId={data?.electricityContainer.id}
                    refetch={refetch}
                  />
                </div>
              )}
              {id && (
                <div className="w-md-50 w-100 mt-5">
                  <Stations data={data?.electricityContainer} refetch={refetch} />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </section>
  );
};
export default AddEditContainer;
