import React, { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { toast } from "react-toastify";

import {
  I_VEHICLE_CHARGE,
  useGetVehiclesChargesForJuicerQuery,
  useStopChargingModeMutation,
} from "app/vehicles/vehiclesApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Battery, ConfirmationPopup, TableBadge, TableComponent, TableIcon } from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { ChargeStatus, VehicleStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

const VehiclesCharges = () => {
  const columnHelper = createColumnHelper<I_VEHICLE_CHARGE>();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [vehicleToActivate, setVehicleToActivate] = useState<any>();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const toggleModal = () => setModalIsOpen((prev) => !prev);

  const { data, isFetching, error, refetch } = useGetVehiclesChargesForJuicerQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
  });

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  const [stopChargingMode] = useStopChargingModeMutation();
  const activateVehicleHandler = async () => {
    try {
      await stopChargingMode(vehicleToActivate?.vehicle?.id).unwrap();
      refetch();
      toast.success("Starea vehiculului a fost schimbată cu succes!");
      setModalIsOpen(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  const columns = [
    columnHelper.accessor((row) => row.vehicle?.companyName, {
      id: "name",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.partnerName}</span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.vehicle.qrCode, {
      id: "vehicleId",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.qrCode}</span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.batteryLevelAtStart, {
      id: "batteryLevelAtStart",
      cell: (info) => <>{<Battery level={info.getValue()} />}</>,
      header: () => <span>{GlobalStrings.formLabels.batteryLevelAtStart}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.startDate, {
      id: "startDate",
      cell: (info) => (
        <p>
          {info.getValue() !== NO_VALUE_SYMBOL ? (
            <Moment date={info.getValue()} format="DD.MM.YYYY, HH:mm:ss" />
          ) : (
            info.getValue()
          )}
        </p>
      ),
      header: () => <span>{GlobalStrings.formLabels.startDateJuicer}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.endDate, {
      id: "endDate",
      cell: (info) => (
        <p>
          {info.getValue() !== NO_VALUE_SYMBOL ? (
            <Moment date={info.getValue()} format="DD.MM.YYYY, HH:mm:ss" />
          ) : (
            info.getValue()
          )}
        </p>
      ),
      header: () => <span>{GlobalStrings.formLabels.endDateJuicer}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.batteryLevelAtEnd, {
      id: "batteryLevelAtEnd",
      cell: (info) => (
        <>{info.getValue() < 0 ? <p>{info.getValue()}</p> : <Battery level={info.getValue()} />}</>
      ),
      header: () => <span>{GlobalStrings.formLabels.batteryLevelAtEnd}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.tariff, {
      id: "tariff",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.tarif}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 5,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => (
        <TableBadge
          text={GlobalStrings.chargeStatus[info.getValue()]}
          color={
            info.getValue() === ChargeStatus.PAID
              ? "green"
              : info.getValue() === ChargeStatus.PENDING
              ? "orange"
              : "red"
          }
        />
      ),
      header: () => <span>{GlobalStrings.formLabels.paymentStatus}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.vehicle.status, {
      id: "vehicleStatus",
      cell: (info) => (
        <TableBadge
          text={GlobalStrings.vehicleStatus[info.getValue()]}
          color={
            info.getValue() === VehicleStatus.ACTIVE
              ? "green"
              : info.getValue() === VehicleStatus.CHARGING
              ? "orange"
              : "red"
          }
        />
      ),
      header: () => <span>{GlobalStrings.formLabels.vehicleStatus}</span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row.vehicle, {
      id: "vehicleBattery",
      cell: (info) => {
        return (
          <>
            {isNaN(info.getValue().batteryLevel) ? (
              <p>{info.getValue().batteryLevel}</p>
            ) : (
              <Battery level={info.getValue().batteryLevel} />
            )}
          </>
        );
      },
      header: () => <span>{GlobalStrings.formLabels.currentBatteryLevel}</span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          {info.getValue().vehicle.status === VehicleStatus.CHARGING && (
            <TableIcon
              type="unlock"
              onClickAction={() => {
                setModalIsOpen(true);
                setVehicleToActivate(info.getValue());
              }}
            />
          )}
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
    }),
  ];

  const table = useReactTable({
    data: data?.vehicles || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <section>
      <TableComponent
        table={table}
        tableHasData={data?.vehicles?.length > 0}
        isLoading={isFetching}
      />
      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        title={`${GlobalStrings.servicesSuppliers.activateVehicleModalTitle} ${vehicleToActivate?.vehicle?.qrCode}`}
        body={GlobalStrings.servicesSuppliers.activateVehicleModalBody}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={activateVehicleHandler}
      />
    </section>
  );
};
export default VehiclesCharges;
