import { BookingStatus, BookingType } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_BOOKING_HISTORY } from "utils/types";

import { apiSlice } from "../apiSlice";

export interface I_CHARGED_VEHICLES {
  type: string;
  id: number;
  userId: number;
  vehicleType: string;
  qrCode: string;
  startDate: string;
  endDate: string;
  amount: number;
  status: BookingStatus;
  bookType: BookingType;
  payment: any;
  durationInMinutes: number;
  refunded: boolean;
  payments: any;
  vehicleInfo: {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    model: string;
    baterie: string;
  };
}

export const chargedVehiclesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChargedVehicles: builder.query<{ meta: any; chargedVehicles: I_CHARGED_VEHICLES[] }, any>({
      query: ({ pageIndex, pageSize, sort, userId }) => {
        let url = `${process.env.REACT_APP_CONTAINERS_API_URL}/books/history-admin-charging?`;
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (userId) {
          url += `userId=${userId}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const chargedVehicles: I_CHARGED_VEHICLES[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          chargedVehicles.push({
            type: item.type || NO_VALUE_SYMBOL,
            vehicleInfo: item.vehicleInfo,
            id: item.id || NO_VALUE_SYMBOL,
            userId: item.userId || NO_VALUE_SYMBOL,
            startDate: item.chargingStartDate || NO_VALUE_SYMBOL,
            endDate: item.chargingEndDate || NO_VALUE_SYMBOL,
            vehicleType: item.vehicleType,
            bookType: item.bookType,
            qrCode: item.qrCode || NO_VALUE_SYMBOL,
            amount: item.amount,
            status: item.status || NO_VALUE_SYMBOL,
            payment: item.paymentsSummary.payment || NO_VALUE_SYMBOL,
            refunded: item.refunded || NO_VALUE_SYMBOL,
            durationInMinutes:
              item.durationInMinutes && item.durationInMinutes >= 0
                ? item.durationInMinutes
                : NO_VALUE_SYMBOL,
            payments: item.payments || NO_VALUE_SYMBOL,
          });
        });
        return {
          chargedVehicles,
          meta,
        };
      },
    }),
    getBookingHistory: builder.query<{ meta: any; bookingHistory: I_BOOKING_HISTORY[] }, any>({
      query: ({ pageIndex, pageSize, sort }) => {
        let url = `${process.env.REACT_APP_CONTAINERS_API_URL}/books/history-admin?`;
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const bookingHistory: I_BOOKING_HISTORY[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          bookingHistory.push({
            id: item?.id || NO_VALUE_SYMBOL,
            userFullName: item?.userFullName || NO_VALUE_SYMBOL,
            userId: item?.userId,
            isUserDeleted: item?.isUserDeleted,
            bookType: item?.bookType || NO_VALUE_SYMBOL,
            address: item?.address || NO_VALUE_SYMBOL,
            status: item?.status || NO_VALUE_SYMBOL,
            createdAt: item?.createdAt,
            chargeTime: item?.chargeTime || NO_VALUE_SYMBOL,
            startDate: item?.startDate,
            endDate: item?.endDate,
            chargingStartDate: item?.chargingStartDate,
            chargingEndDate: item?.chargingEndDate,
            amount: item?.amount,
            totalKwUsed: item?.totalKwUsed || NO_VALUE_SYMBOL,
            chargingSessions: item.chargingSessions || [],
            chargingConnector: {
              id: item?.chargingConnector?.id || NO_VALUE_SYMBOL,
            },
            container: {
              companyName: item?.container?.companyName,
            },
            chargingStation: {
              companyName: item?.chargingStation?.companyName,
            },
            vehicleInfo: {
              model: item?.vehicleInfo?.model || NO_VALUE_SYMBOL,
            },
            payment: {
              payment: {
                amount: item?.payment?.payment?.amount,
                initialPayment: +parseFloat(item?.payment?.payment?.initialPayment)?.toFixed(2),
                status: item?.payment?.payment?.status || NO_VALUE_SYMBOL,
                panMask: item?.payment?.payment?.panMask || NO_VALUE_SYMBOL,
              },
            },
          });
        });
        return {
          bookingHistory,
          meta,
        };
      },
    }),
    startCharging: builder.mutation<any, Partial<any>>({
      query: (body) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/charging/admin/start-charging`,
        method: "POST",
        body,
      }),
    }),
    stopCharging: builder.mutation<any, Partial<any>>({
      query: (body) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/charging/admin/stop-charging`,
        method: "POST",
        body,
      }),
    }),
    cancelBooking: builder.mutation<any, string | number>({
      query: (id: string | number) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/books/cancel-reservation-admin/${id}`,
        method: "POST",
      }),
    }),
    endBooking: builder.mutation<any, string | number>({
      query: (id: string | number) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/books/end-reservation-admin/${id}`,
        method: "POST",
      }),
    }),
    refundBooking: builder.mutation<any, string | number>({
      query: (id: string | number) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/books/refund-admin/${id}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetChargedVehiclesQuery,
  useGetBookingHistoryQuery,
  useStartChargingMutation,
  useStopChargingMutation,
  useCancelBookingMutation,
  useEndBookingMutation,
  useRefundBookingMutation,
} = chargedVehiclesApiSlice;
