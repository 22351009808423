import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "app/auth/authSlice";
import { useGetCitiesQuery } from "app/common/commonApiSlice";
import { useGetVehiclesQuery } from "app/vehicles/vehiclesApiSlice";

import { GoogleMap, MarkerClustererF, useLoadScript } from "@react-google-maps/api";
import { IsLoading } from "components";
import { getCenterCoordinates } from "utils";
import { MapCenter } from "utils/types";

import PartnerVehiclesMarker from "./PartnerVehiclesMarker";

const containerStyle = {
  width: "100%",
  height: "75vh",
};

function VehiclesMap() {
  const user = useSelector(selectCurrentUser);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
  });
  const { data: cities } = useGetCitiesQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const [active, setActive] = useState();
  const [center, setCenter] = useState<MapCenter>();

  useEffect(() => {
    if (cities?.length) {
      setCenter(getCenterCoordinates(cities, user.company.city));
    }
  }, [cities]);

  const { data, isLoading } = useGetVehiclesQuery({
    partnerId: user.company.id,
  });

  return isLoading ? (
    <IsLoading />
  ) : isLoaded ? (
    <div className="position-relative">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={13}>
        <MarkerClustererF averageCenter={false} maxZoom={20} gridSize={50} minimumClusterSize={5}>
          {(clusterer) => (
            <>
              {data?.vehicles?.map((item: any, index: any) => {
                return (
                  <PartnerVehiclesMarker
                    clusterer={clusterer}
                    item={item}
                    key={index}
                    active={active}
                    setActive={setActive}
                  />
                );
              })}
            </>
          )}
        </MarkerClustererF>
      </GoogleMap>
    </div>
  ) : (
    <>Loading....</>
  );
}

export default React.memo(VehiclesMap);
