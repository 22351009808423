import React from "react";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "app/auth/authSlice";

import { Table } from "@tanstack/react-table";
import classNames from "classnames";

import { IsLoading, NoResults, TableBody, TableHeading, TablePagination } from "..";

const TableComponent = ({
  table,
  tableTitle,
  tableHasData,
  isLoading,
  hidePagination,
}: {
  table: Table<any>;
  tableTitle?: string;
  tableHasData: boolean;
  isLoading: boolean;
  hidePagination?: boolean;
}) => {
  const user = useSelector(selectCurrentUser);

  return (
    <section className={classNames("table_container", user?.role)}>
      <table className="styled_table">
        <TableHeading table={table} tableTitle={tableTitle} />
        {tableHasData && !isLoading && <TableBody table={table} />}
      </table>
      {tableHasData && !isLoading && !hidePagination && <TablePagination table={table} />}
      {!tableHasData && !isLoading && <NoResults />}
      {isLoading && <IsLoading />}
    </section>
  );
};

export default TableComponent;
