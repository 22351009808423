import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { useGetCitiesQuery } from "app/common/commonApiSlice";
import { useUpdateSupplierMutation } from "app/suppliers/suppliersApiSlice";

import { AlertComponent, CitiesSelect, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { GlobalStrings, Regex } from "utils";
import { I_SUPPLIER } from "utils/types";

const SupplierServiceForm = (props: {
  data: I_SUPPLIER;
  supplierId: string | undefined;
  refetch: Function;
}) => {
  const { data: cities } = useGetCitiesQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: props?.data?.name || "",
      identifier: props?.data?.identifier || "",
      city: props?.data?.city || "",
      phone: props?.data?.phone || "",
      email: props?.data?.email || "",
    },
  });

  const [updateSupplier, { isLoading }] = useUpdateSupplierMutation();
  const { serverMessage, setServerMessage } = useServerMessage();

  const onSubmit = async (data: any) => {
    try {
      const supplierPayload = {
        body: {
          name: data.name,
          identifier: data.identifier,
          city: data.city.value,
          phone: data.phone,
          email: data.email,
        },
        id: props?.data?.id,
      };
      await updateSupplier(supplierPayload).unwrap();

      setServerMessage({
        type: "success",
        text: GlobalStrings.servicesSuppliers.serviceWasUpdated,
      });

      props.refetch();
    } catch (error: any) {
      setServerMessage({
        type: "danger",
        text: GlobalStrings.partners.savingError,
      });
    }
  };

  useEffect(() => {
    if (cities)
      setValue(
        "city",
        cities?.find((item: any) => item.value === props.data?.city)
      );
  }, [props.data?.city, cities]);

  return (
    <section className="d-flex flex-column gap-5">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="name"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.companyName}
                  type="text"
                />
              )}
            />
            <Label for="name">{GlobalStrings.formLabels.companyName}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="identifier"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.cifOrCnp,
                  message: GlobalStrings.formValidation.invalidCIForCNP,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.cifCnp}
                  type="text"
                />
              )}
            />
            <Label for="identifier">{GlobalStrings.formLabels.cifCnp}</Label>
          </FormGroup>
        </div>

        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="email"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.email,
                  message: GlobalStrings.formValidation.invalidEmail,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.email}
                  type="text"
                  disabled
                />
              )}
            />
            <Label for="email">{GlobalStrings.formLabels.email}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="phone"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.phone,
                  message: GlobalStrings.formValidation.invalidPhone,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.phoneNumber}
                  type="text"
                />
              )}
            />
            <Label for="phone">{GlobalStrings.formLabels.phoneNumber}</Label>
          </FormGroup>
        </div>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-50">
            <CitiesSelect control={control} name="city" />
          </FormGroup>
        </div>

        <SubmitButton isLoading={isLoading} label={GlobalStrings.formLabels.save} />
      </Form>

      <AlertComponent errors={errors} serverMessage={serverMessage} />
    </section>
  );
};

export default SupplierServiceForm;
