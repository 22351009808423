import React from "react";
import { useParams } from "react-router-dom";

import { useGetProductQuery } from "app/marketplace/marketplaceApiSlice";

import { Gallery, IsLoading, PageHeader, ProductInfo, SEO } from "components";
import { GlobalStrings } from "utils";

import styles from "./MarketplaceProduct.module.scss";

const MarketplaceProduct = () => {
  const { productId } = useParams();
  const { data: productData, isLoading } = useGetProductQuery(productId, {
    skip: !productId,
  });

  return (
    <section className={styles.marketplace}>
      <PageHeader title={GlobalStrings.marketplace.marketplaceTitle} backRoute="/marketplace" />
      {isLoading ? (
        <IsLoading />
      ) : (
        <>
          <SEO title={productData?.name + GlobalStrings.SEO.admin.marketplaceProduct} />
          <div className={styles.container}>
            <div className={styles.gallery}>
              <Gallery images={productData?.imagesKeys} />
            </div>
            <ProductInfo product={productData} />
          </div>
        </>
      )}
    </section>
  );
};
export default MarketplaceProduct;
