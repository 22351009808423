import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";

import io from "socket.io-client";

interface SocketData {
  isConnected: boolean;
  data?: any;
  error?: any;
}

const WebSocketContext = createContext<SocketData | null>(null);

export const useWebSocket = () => useContext(WebSocketContext);

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
  const [socketData, setSocketData] = useState<SocketData>({ isConnected: false });
  console.log("message", socketData);
  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_WORKERS_WS_URL}`, {
      transports: ["websocket"],
    });

    socket.on("connect", () => {
      console.log("WebSocket connection established");
      setSocketData({ isConnected: true, data: null, error: null });
    });

    socket.on("message", (data) => {
      setSocketData({ isConnected: true, data, error: null });
    });

    socket.on("trainingLog", (data) => {
      console.log("Received training log:", data);
      setSocketData({ isConnected: true, data, error: null });
    });

    socket.on("locationUpdate", (data) => {
      console.log("Received location update:", data);
      setSocketData({ isConnected: true, data, error: null });
    });

    socket.on("connect_error", (error) => {
      console.log("connect_error");
      console.error("WebSocket error:", error);
      setSocketData({ isConnected: false, data: null, error });
    });

    socket.on("exception", (error) => {
      console.log("exception");
      setSocketData({ isConnected: false, data: null, error });
    });
    socket.on("disconnect", (error) => {
      console.log("disconnect");
      setSocketData({ isConnected: false, data: null, error });
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return <WebSocketContext.Provider value={socketData}>{children}</WebSocketContext.Provider>;
};
