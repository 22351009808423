import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { PageHeader } from "components";
import { GlobalStrings } from "utils";

import SuppliersProducts from "./SuppliersProducts/SuppliersProducts";
import SuppliersServices from "./SuppliersServices/SuppliersServices";

import { adminRoutesPath } from "../routes";

const Suppliers = () => {
  const suppliersTabs = [
    {
      id: 0,
      name: GlobalStrings.servicesSuppliers.products,
      slug: adminRoutesPath.Suppliers.Products.Index,
    },
    {
      id: 1,
      name: GlobalStrings.servicesSuppliers.services,
      slug: adminRoutesPath.Suppliers.Services.Index,
    },
  ];

  const ProductsButtons = [
    {
      id: 0,
      name: GlobalStrings.servicesSuppliers.addSupplier,
      slug: adminRoutesPath.Suppliers.AddSupplier,
    },
  ];

  const ServicesButtons = [
    {
      id: 0,
      name: `${GlobalStrings.servicesSuppliers.addSupplier}`,
      slug: adminRoutesPath.Suppliers.AddSupplier,
    },
    {
      id: 1,
      name: `${GlobalStrings.servicesSuppliers.addService}`,
      slug: adminRoutesPath.Suppliers.AddService,
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes(suppliersTabs[0].slug)) {
      setActiveIndex(0);
    } else if (location.pathname.includes(suppliersTabs[1].slug)) {
      setActiveIndex(1);
    }
  }, [location.pathname]);

  return (
    <section>
      <PageHeader
        title={GlobalStrings.servicesSuppliers.serviceSupplierManagement}
        tabs={activeIndex === 0 ? ProductsButtons : ServicesButtons}
        tabsCurrentview={suppliersTabs}
        tabsCurrentviewActiveIndex={activeIndex}
      />
      {activeIndex === 1 ? <SuppliersServices /> : <SuppliersProducts />}
    </section>
  );
};
export default Suppliers;
