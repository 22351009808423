import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { selectCurrentUser } from "app/auth/authSlice";
import {
  useDeleteUserFromPartnerMutation,
  useGetUsersFromPartnerQuery,
} from "app/partners/partnersApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ConfirmationPopup, TableComponent, TableIcon } from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { UserRoles } from "utils/enums";
import { I_PARTNER_USER } from "utils/types";

const UsersTable = (props: { id: string | number }) => {
  const user = useSelector(selectCurrentUser);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<any>();
  const toggleModal = () => setModalIsOpen((prev) => !prev);
  const [deleteUser] = useDeleteUserFromPartnerMutation();

  const deleteUserHandler = async () => {
    try {
      if (!userToDelete) {
        return;
      }
      await deleteUser({
        partnerId: props.id,
        userId: userToDelete.id,
      }).unwrap();
      toggleModal();
      refetch();
      toast.success(GlobalStrings.settings.userDeleted);
    } catch (err: any) {
      errorHandler(err);
    }
  };

  const { data, isFetching, refetch } = useGetUsersFromPartnerQuery({
    partnerId: props.id || "",
    sort: getSortDetails(sorting[0]),
  });

  const columnHelper = createColumnHelper<I_PARTNER_USER>();

  const columns = [
    columnHelper.accessor((row) => row.firstName, {
      id: "firstName",
      cell: (info) => <p>{info.getValue() || "-"}</p>,
      header: () => <span>{GlobalStrings.formLabels.firstName}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: "lastName",
      cell: (info) => <p>{info.getValue() || "-"}</p>,
      header: () => <span>{GlobalStrings.formLabels.lastName}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row.email, {
      id: "email",
      cell: (info) => <p>{info.getValue() || "-"}</p>,
      header: () => <span>{GlobalStrings.formLabels.email}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          {info.row.index !== 0 && user.role !== UserRoles.supplier && (
            <span>
              <TableIcon
                type="delete"
                onClickAction={() => {
                  toggleModal();
                  setUserToDelete(info.getValue());
                }}
              />
            </span>
          )}
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
    }),
  ];

  const table = useReactTable({
    data: data?.users || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    onSortingChange: setSorting,
  });

  return (
    <section>
      <TableComponent
        table={table}
        tableHasData={data?.users?.length > 0}
        isLoading={isFetching}
        hidePagination={true}
      />

      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        title={`${GlobalStrings.settings.partnerModalTitle} - ${userToDelete?.firstName} ${userToDelete?.lastName}`}
        body={GlobalStrings.settings.partnerModalBody}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deleteUserHandler}
      />
    </section>
  );
};

export default UsersTable;
