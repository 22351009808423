import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import { useAddUserToPartnerMutation, useGetPartnerQuery } from "app/partners/partnersApiSlice";

import { AlertComponent, IsLoading, PageHeader } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { GlobalStrings, Regex } from "utils";
import { errorHandler } from "utils/globalFunctions";
import { I_PARTNER_USER } from "utils/types";

const AddUserToPartner = () => {
  const [partnerName, setPartnerName] = useState("");
  const [addUserToPartner] = useAddUserToPartnerMutation();
  const { id } = useParams();
  const { data, isLoading } = useGetPartnerQuery(id);
  const { serverMessage, setServerMessage } = useServerMessage();

  useEffect(() => {
    if (data && id) {
      setPartnerName(data.name);
    }
  }, [data, id]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<I_PARTNER_USER>({
    defaultValues: {
      lastName: "",
      firstName: "",
      email: "",
      phone: "",
    },
  });

  const onSubmit = async (data: I_PARTNER_USER) => {
    try {
      if (id) {
        await addUserToPartner({
          id: +id,
          user: {
            id: data.id,
            lastName: data.lastName,
            firstName: data.firstName,
            email: data.email,
            phone: data.phone,
          },
        }).unwrap();

        toast.success(GlobalStrings.partners.userWasSaved);
      }
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  return (
    <section>
      {isLoading ? (
        <IsLoading />
      ) : (
        <>
          <PageHeader
            title={`${GlobalStrings.partners.addUserToPartner} - ${partnerName}`}
            backRoute={"-1"}
          />
          <div className="card">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex gap-2 flex-column flex-md-row">
                <FormGroup floating className="w-100">
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: GlobalStrings.formValidation.fieldsRequired,
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        {...field}
                        invalid={invalid}
                        placeholder={GlobalStrings.formLabels.lastName}
                        type="text"
                      />
                    )}
                  />
                  <Label for="lastName">{GlobalStrings.formLabels.lastName}</Label>
                </FormGroup>

                <FormGroup floating className="w-100">
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: GlobalStrings.formValidation.fieldsRequired,
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        {...field}
                        invalid={invalid}
                        placeholder={GlobalStrings.formLabels.firstName}
                        type="text"
                      />
                    )}
                  />
                  <Label for="firstName">{GlobalStrings.formLabels.firstName}</Label>
                </FormGroup>
              </div>

              <div className="d-flex gap-2 flex-column flex-md-row">
                <FormGroup floating className="w-100">
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: GlobalStrings.formValidation.fieldsRequired,
                      pattern: {
                        value: Regex.email,
                        message: GlobalStrings.formValidation.invalidEmail,
                      },
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        {...field}
                        invalid={invalid}
                        placeholder={GlobalStrings.formLabels.email}
                        type="text"
                      />
                    )}
                  />
                  <Label for="email">{GlobalStrings.formLabels.email}</Label>
                </FormGroup>

                <FormGroup floating className="w-100">
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: GlobalStrings.formValidation.fieldsRequired,
                      pattern: {
                        value: Regex.phone,
                        message: GlobalStrings.formValidation.invalidPhone,
                      },
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        {...field}
                        invalid={invalid}
                        placeholder={GlobalStrings.formLabels.phoneNumber}
                        type="text"
                      />
                    )}
                  />
                  <Label for="phone">{GlobalStrings.formLabels.phoneNumber}</Label>
                </FormGroup>
              </div>

              <Button
                color="success"
                type="submit"
                className="d-flex align-item-center gap-2 mt-3 mb-3"
              >
                {GlobalStrings.formLabels.addUser}
              </Button>

              <AlertComponent errors={errors} serverMessage={serverMessage} />
            </Form>
          </div>
        </>
      )}
    </section>
  );
};

export default AddUserToPartner;
