import { NO_VALUE_SYMBOL } from "utils/globalStrings";

import { Invoice } from "views/admin/Invoices/InvoicesList/InvoicesList";

import { apiSlice } from "../apiSlice";

export const invoicesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, type, supplierId, partnerId }) => {
        let url = `${process.env.REACT_APP_PAYMENT_API_URL}/invoices?`;
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (type) {
          url += `type=${type}&`;
        }
        if (supplierId) {
          url += `supplierId=${supplierId}&`;
        }
        if (partnerId) {
          url += `partnerId=${partnerId}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const invoices: Invoice[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          invoices.push({
            id: item.id,
            firstName: item?.firstName || NO_VALUE_SYMBOL,
            lastName: item?.lastName || NO_VALUE_SYMBOL,
            CIF: item?.CIF || NO_VALUE_SYMBOL,
            name: item?.name || NO_VALUE_SYMBOL,
            email: item?.email || NO_VALUE_SYMBOL,
            phone: item?.phone || NO_VALUE_SYMBOL,
            currency: item?.currency || NO_VALUE_SYMBOL,
            amount: item?.amount || NO_VALUE_SYMBOL,
            tripId: item?.tripId,
            containerBookingId: item?.containerBookingId,
            paymentId: item?.paymentId,
          });
        });
        return {
          invoices,
          meta,
        };
      },
    }),
  }),
});

export const { useGetInvoicesQuery } = invoicesApiSlice;
