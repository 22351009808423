import { NO_VALUE_SYMBOL } from "utils/globalStrings";

import { I_HISTORY_SERVICE } from "../../utils/types";

import { apiSlice } from "../apiSlice";

export const historyServiceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRequestsOffers: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, supplierIds, partnerId }) => {
        let url = `${process.env.REACT_APP_VEHICLE_API_URL}/offer-requests?`;
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (supplierIds) {
          url += `supplierIds=contains,${supplierIds}&`;
        }
        if (partnerId) {
          url += `partnerId=${partnerId}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      keepUnusedDataFor: 3,
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const requestOffers: I_HISTORY_SERVICE[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          requestOffers.push({
            data: item?.createdAt || NO_VALUE_SYMBOL,
            sentByCompany: item?.sentByCompany || NO_VALUE_SYMBOL,
            vehicleType: item?.vehicleType || NO_VALUE_SYMBOL,
            serviceType: item?.serviceType || NO_VALUE_SYMBOL,
            description: item?.description || NO_VALUE_SYMBOL,
            email: item?.contact?.email || NO_VALUE_SYMBOL,
            phone: item?.contact?.phone || NO_VALUE_SYMBOL,
          });
        });
        return {
          requestOffers,
          meta,
        };
      },
    }),
    addRequestOffer: builder.mutation<any, any>({
      query: (body) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/offer-requests`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetRequestsOffersQuery, useAddRequestOfferMutation } = historyServiceApiSlice;
