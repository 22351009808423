import React from "react";
import { Controller, useForm } from "react-hook-form";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { useForgotPasswordMutation } from "app/auth/authApiSlice";

import { AlertComponent, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { errorHandler, GlobalStrings, Regex } from "utils";

import logo from "assets/logo/ecohop-logo.png";

const ForgotPasswordForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });
  const { serverMessage, setServerMessage } = useServerMessage();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      await forgotPassword(email).unwrap();
      setServerMessage({
        type: "success",
        text: GlobalStrings.forgotPassword.resetSuccessfully,
      });
    } catch (error: any) {
      errorHandler(error, true, setServerMessage, GlobalStrings.forgotPassword);
    }
  };

  return (
    <section className="d-flex flex-column gap-5">
      <div className="signin_header">
        <h3 className="title">{GlobalStrings.forgotPassword.forgotPasswordTitle}</h3>
        <div className="image_container">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <p>{GlobalStrings.auth.forgotPasswordDescription}</p>
        <FormGroup floating>
          <Controller
            name="email"
            control={control}
            rules={{
              required: GlobalStrings.formValidation.emailIsRequired,
              pattern: {
                value: Regex.email,
                message: GlobalStrings.formValidation.invalidEmail,
              },
            }}
            render={({ field, fieldState: { invalid } }) => (
              <Input
                {...field}
                invalid={invalid}
                placeholder={GlobalStrings.formLabels.email}
                type="text"
              />
            )}
          />
          <Label for="email">{GlobalStrings.formLabels.email}</Label>
        </FormGroup>

        <div className="d-flex flex-sm-row flex-column align-items-md-center justify-content-between align-items-start gap-md-0 gap-3">
          <SubmitButton isLoading={isLoading} label={GlobalStrings.auth.resetPasswordButton} />

          <Link to="/" className="mb-0 btn btn-link d-flex align-items-center gap-1">
            <BiArrowBack />
            {GlobalStrings.auth.backToAuth}
          </Link>
        </div>
      </Form>
      <AlertComponent errors={errors} serverMessage={serverMessage} />
    </section>
  );
};
export default ForgotPasswordForm;
