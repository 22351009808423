import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAddToCartMutation } from "app/marketplace/cartApiSlice";
import { useGetProductQuery } from "app/marketplace/marketplaceApiSlice";

import { Gallery, IsLoading, PageHeader } from "components";
import { errorHandler, GlobalStrings } from "utils";

import ProductDetails from "./ProductDetails/ProductDetails";
import placeholder from "assets/images/product_placeholder.jpg";

import styles from "./MarketplacePartnerProduct.module.scss";

const MarketplacePartnerProduct = () => {
  const { productId } = useParams();
  const [addToCart] = useAddToCartMutation();
  const { data: productData, isLoading } = useGetProductQuery(productId, {
    skip: !productId,
  });

  const addToCartHandler = async (productId: number) => {
    try {
      const body = {
        productId,
      };
      await addToCart(body).unwrap();
      toast.info(GlobalStrings.marketplace.productAdded);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <section className={styles.marketplace}>
      <PageHeader title={GlobalStrings.marketplace.marketplaceTitle} backRoute="-1" />
      {isLoading ? (
        <IsLoading />
      ) : (
        <div className={styles.container}>
          <div className={styles.gallery}>
            <Gallery
              images={productData?.imagesKeys?.length ? productData?.imagesKeys : [placeholder]}
            />
          </div>
          <ProductDetails product={productData} addToCart={addToCartHandler} />
        </div>
      )}
    </section>
  );
};
export default MarketplacePartnerProduct;
