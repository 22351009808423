import React from "react";

import { WebSocketProvider } from "app/websocket/WebSocketContext";

import { GlobalStrings } from "utils";
import {
  AddEditContainer,
  AddEditStation,
  AddPartner,
  AddPublicTransportPartners,
  AddServiceForm,
  AdminVehicles,
  AiController,
  Analytics,
  ContainersAndStations,
  Dashboard,
  Document,
  Documents,
  EditSupplierAndProduct,
  EditSupplierAndService,
  EndUser,
  EndUsers,
  Geofencing,
  Invoices,
  Marketplace,
  MarketplacePartnerProduct,
  MarketplacePartners,
  MarketplaceProduct,
  PartnerEdit,
  Partners,
  PublicTransportPartners,
  PublicTransportPartnersEdit,
  Settings,
  Suppliers,
  Trips,
} from "views/admin";
import { AddSupplierForm, AddSupplierUserForm, AddUserToPartner, Vehicle } from "views/common";

export const adminRoutesPath = {
  Dashboard: "/dashboard",
  ContainersAndStations: {
    ContainersMap: "/containers-and-stations/containers/map",
    StationsMap: "/containers-and-stations/stations/map",
    Containers: "/containers-and-stations/containers",
    Stations: "/containers-and-stations/stations",
    Prices: "/containers-and-stations/prices",
    BookingHistory: "/containers-and-stations/booking-history",
    AddStation: "/containers-and-stations/stations/add",
    AddContainer: "/containers-and-stations/containers/add",
    EditStation: "/containers-and-stations/stations/:id/edit",
    EditContainer: "/containers-and-stations/contaiers/:id/edit",
  },
  Partners: {
    Index: "/partners",
    AddPartner: "/partners/add",
    AddUser: "/partners/:id/add-user",
    EditPartner: "/partners/:partnerId/general-info",
    EditPartnerTrips: "/partners/:partnerId/trips",
    EditPartnerAccounts: "/partners/:partnerId/accounts",
    EditPartnerReports: "/partners/:partnerId/reports",
    EditPartnerPrices: "/partners/:partnerId/prices",
    EditPartnerJuicers: "/partners/:partnerId/juicers",
  },
  PublicTransport: {
    Index: "/public-transport-partners",
    Add: "/public-transport-partners/add",
    Edit: "/public-transport-partners/:id/edit",
  },
  Suppliers: {
    Services: {
      Index: "/suppliers/services",
      AddService: "/suppliers/services/:supplierId/add-service",
      AddUser: "/suppliers/services/:supplierId/add-user",
      EditSupplier: "/suppliers/services/:supplierId/edit",
    },
    AddSupplier: "/suppliers/services/add",
    AddService: "/suppliers/services/add-service",
    AddUser: "/suppliers/:supplierId/add-user",
    Products: {
      Index: "/suppliers/products",
      AddSupplier: "/suppliers/products/add",
      EditSupplier: "/suppliers/products/:supplierId/edit",
    },
  },
  Vehicles: {
    Index: "/vehicles",
    Vehicle: "/vehicles/:vehicleId/info",
  },
  Marketplace: {
    Index: "/marketplace",
    Product: "/marketplace/product/review/:productId",
  },
  MarketplaceListing: {
    Index: "/marketplace-listing",
    Product: "/marketplace-listing/products/:productId",
  },
  EndUsers: {
    Index: "/end-users",
    EndUserPayments: "/end-users/:userId/info/payments",
    EndUserCards: "/end-users/:userId/info/cards",
    EndUserTrips: "/end-users/:userId/info/trips",
    EndUserDocuments: "/end-users/:userId/info/documents",
    EndUserBookings: "/end-users/:userId/info/bookings",
    EndUserVouchers: "/end-users/:userId/info/vouchers",
    EndUserIncidents: "/end-users/:userId/info/incidents",
    EndUsersWithDocuments: "/end-users/documents",
    EndUsersDocuments: "/end-users/documents/:id/review",
  },
  Trips: "/trips",
  Analytics: {
    General: "/reports/general",
    Payments: "/reports/payments",
    Trips: "/reports/trips",
    Maps: "/reports/maps",
  },
  Invoices: {
    EndUsers: "/invoices/user",
    Partners: "/invoices/company",
    Suppliers: "/invoices/supplier",
  },
  AIController: "/ai-controller",
  Settings: {
    Accounts: "/settings/accounts",
    AddAccount: "/settings/add-account",
    GlobalSettings: "/settings/global-settings",
    MaintenanceSettings: "/settings/maintenance-settings",
  },
  Geofencing: "/geofencing",
};

export const adminRoutes = [
  {
    path: adminRoutesPath.Dashboard,
    Component: Dashboard,
    metaTitle: GlobalStrings.SEO.admin.dashboard,
  },
  {
    path: adminRoutesPath.Settings.Accounts,
    Component: Settings,
    metaTitle: GlobalStrings.SEO.admin.accounts,
  },
  {
    path: adminRoutesPath.Settings.AddAccount,
    Component: Settings,
    metaTitle: GlobalStrings.SEO.admin.addAccount,
  },
  {
    path: adminRoutesPath.Settings.GlobalSettings,
    Component: Settings,
    metaTitle: GlobalStrings.SEO.admin.globalSettings,
  },
  {
    path: adminRoutesPath.Settings.MaintenanceSettings,
    Component: Settings,
    metaTitle: GlobalStrings.SEO.admin.maintenanceSettings,
  },
  {
    path: adminRoutesPath.Analytics.General,
    Component: Analytics,
    metaTitle: GlobalStrings.SEO.admin.generalAnalytics,
  },
  {
    path: adminRoutesPath.Analytics.Payments,
    Component: Analytics,
    metaTitle: GlobalStrings.SEO.admin.paymentsAnalytics,
  },
  {
    path: adminRoutesPath.Analytics.Trips,
    Component: Analytics,
    metaTitle: GlobalStrings.SEO.admin.tripsAnalytics,
  },
  {
    path: adminRoutesPath.Analytics.Maps,
    Component: Analytics,
    metaTitle: GlobalStrings.SEO.admin.heatMap,
  },
  {
    path: adminRoutesPath.Invoices.EndUsers,
    Component: Invoices,
    metaTitle: GlobalStrings.SEO.admin.endUsersInvoices,
  },
  {
    path: adminRoutesPath.Invoices.Partners,
    Component: Invoices,
    metaTitle: GlobalStrings.SEO.admin.partnersInvoices,
  },
  {
    path: adminRoutesPath.Invoices.Suppliers,
    Component: Invoices,
    metaTitle: GlobalStrings.SEO.admin.suppliersInvoices,
  },
  {
    path: adminRoutesPath.Partners.Index,
    Component: Partners,
    metaTitle: GlobalStrings.SEO.admin.partners,
  },
  {
    path: adminRoutesPath.Partners.AddPartner,
    Component: AddPartner,
    metaTitle: GlobalStrings.SEO.admin.addPartner,
  },
  {
    path: adminRoutesPath.Partners.AddUser,
    Component: AddUserToPartner,
    metaTitle: GlobalStrings.SEO.admin.addPartnerUser,
  },
  {
    path: adminRoutesPath.Partners.EditPartner,
    Component: PartnerEdit,
    metaTitle: GlobalStrings.SEO.admin.editPartner,
  },
  {
    path: adminRoutesPath.Partners.EditPartnerAccounts,
    Component: PartnerEdit,
    metaTitle: GlobalStrings.SEO.admin.editPartner,
  },
  {
    path: adminRoutesPath.Partners.EditPartnerJuicers,
    Component: PartnerEdit,
    metaTitle: GlobalStrings.SEO.admin.editPartner,
  },
  {
    path: adminRoutesPath.Partners.EditPartnerPrices,
    Component: PartnerEdit,
    metaTitle: GlobalStrings.SEO.admin.editPartner,
  },
  {
    path: adminRoutesPath.Partners.EditPartnerReports,
    Component: PartnerEdit,
    metaTitle: GlobalStrings.SEO.admin.editPartner,
  },
  {
    path: adminRoutesPath.Partners.EditPartnerTrips,
    Component: PartnerEdit,
    metaTitle: GlobalStrings.SEO.admin.editPartner,
  },
  {
    path: adminRoutesPath.Marketplace.Index,
    Component: Marketplace,
    metaTitle: GlobalStrings.SEO.admin.marketplace,
  },
  {
    path: adminRoutesPath.Marketplace.Product,
    Component: MarketplaceProduct,
    metaTitle: GlobalStrings.SEO.admin.dashboard,
  },
  {
    path: adminRoutesPath.MarketplaceListing.Index,
    Component: MarketplacePartners,
    metaTitle: GlobalStrings.SEO.partner.marketplace,
  },
  {
    path: adminRoutesPath.MarketplaceListing.Product,
    Component: MarketplacePartnerProduct,
    metaTitle: GlobalStrings.SEO.partner.marketplace,
  },
  {
    path: adminRoutesPath.ContainersAndStations.ContainersMap,
    Component: ContainersAndStations,
    metaTitle: GlobalStrings.SEO.admin.containersMap,
  },
  {
    path: adminRoutesPath.ContainersAndStations.StationsMap,
    Component: ContainersAndStations,
    metaTitle: GlobalStrings.SEO.admin.rechargeStationsMap,
  },
  {
    path: adminRoutesPath.ContainersAndStations.Containers,
    Component: ContainersAndStations,
    metaTitle: GlobalStrings.SEO.admin.containers,
  },
  {
    path: adminRoutesPath.ContainersAndStations.Prices,
    Component: ContainersAndStations,
    metaTitle: GlobalStrings.SEO.admin.rechargeStationsPrices,
  },
  {
    path: adminRoutesPath.ContainersAndStations.BookingHistory,
    Component: ContainersAndStations,
    metaTitle: GlobalStrings.SEO.admin.rechargeBookingHistory,
  },
  {
    path: adminRoutesPath.ContainersAndStations.Stations,
    Component: ContainersAndStations,
    metaTitle: GlobalStrings.SEO.admin.rechargeStations,
  },
  {
    path: adminRoutesPath.ContainersAndStations.AddStation,
    Component: AddEditStation,
    metaTitle: GlobalStrings.SEO.admin.addRechargeStation,
  },
  {
    path: adminRoutesPath.ContainersAndStations.EditStation,
    Component: AddEditStation,
    metaTitle: GlobalStrings.SEO.admin.addRechargeStation,
  },
  {
    path: adminRoutesPath.ContainersAndStations.EditStation,
    Component: AddEditStation,
    metaTitle: GlobalStrings.SEO.admin.rechargeStation,
  },
  {
    path: adminRoutesPath.ContainersAndStations.AddContainer,
    Component: AddEditContainer,
    metaTitle: GlobalStrings.SEO.admin.addRechargeStation,
  },
  {
    path: adminRoutesPath.ContainersAndStations.EditContainer,
    Component: AddEditContainer,
    metaTitle: GlobalStrings.SEO.admin.rechargeStation,
  },
  {
    path: adminRoutesPath.PublicTransport.Index,
    Component: PublicTransportPartners,
    metaTitle: GlobalStrings.SEO.admin.publicTransportPartners,
  },
  {
    path: adminRoutesPath.PublicTransport.Add,
    Component: AddPublicTransportPartners,
    metaTitle: GlobalStrings.SEO.admin.addPublicTransportPartner,
  },
  {
    path: adminRoutesPath.PublicTransport.Edit,
    Component: PublicTransportPartnersEdit,
    metaTitle: GlobalStrings.SEO.admin.editPublicTransportPartner,
  },
  {
    path: adminRoutesPath.EndUsers.Index,
    Component: EndUsers,
    metaTitle: GlobalStrings.SEO.admin.endUsers,
  },
  {
    path: adminRoutesPath.EndUsers.EndUserPayments,
    Component: EndUser,
    metaTitle: GlobalStrings.SEO.admin.endUser,
  },
  {
    path: adminRoutesPath.EndUsers.EndUserCards,
    Component: EndUser,
    metaTitle: GlobalStrings.SEO.admin.endUser,
  },
  {
    path: adminRoutesPath.EndUsers.EndUserDocuments,
    Component: EndUser,
    metaTitle: GlobalStrings.SEO.admin.endUser,
  },
  {
    path: adminRoutesPath.EndUsers.EndUserTrips,
    Component: EndUser,
    metaTitle: GlobalStrings.SEO.admin.endUser,
  },
  {
    path: adminRoutesPath.EndUsers.EndUserBookings,
    Component: EndUser,
    metaTitle: GlobalStrings.SEO.admin.endUser,
  },
  {
    path: adminRoutesPath.EndUsers.EndUserVouchers,
    Component: EndUser,
    metaTitle: GlobalStrings.SEO.admin.endUser,
  },
  {
    path: adminRoutesPath.EndUsers.EndUserIncidents,
    Component: EndUser,
    metaTitle: GlobalStrings.SEO.admin.endUser,
  },
  {
    path: adminRoutesPath.EndUsers.EndUsersWithDocuments,
    Component: Documents,
    metaTitle: GlobalStrings.SEO.admin.endUsersDocuments,
  },
  {
    path: adminRoutesPath.EndUsers.EndUsersDocuments,
    Component: Document,
    metaTitle: GlobalStrings.SEO.admin.endUserDocuments,
  },
  {
    path: adminRoutesPath.Trips,
    Component: Trips,
    metaTitle: GlobalStrings.SEO.admin.dashboard,
  },
  {
    path: adminRoutesPath.AIController,
    Component: () => (
      <WebSocketProvider>
        <AiController />
      </WebSocketProvider>
    ),
    metaTitle: GlobalStrings.SEO.admin.AIController,
  },
  {
    path: adminRoutesPath.Geofencing,
    Component: Geofencing,
    metaTitle: GlobalStrings.SEO.admin.geofencing,
  },
  {
    path: adminRoutesPath.Vehicles.Index,
    Component: AdminVehicles,
    metaTitle: GlobalStrings.SEO.admin.vehicles,
  },
  {
    path: adminRoutesPath.Vehicles.Vehicle,
    Component: Vehicle,
    metaTitle: GlobalStrings.SEO.admin.vehicle,
  },
  {
    path: adminRoutesPath.Suppliers.AddUser,
    Component: AddSupplierUserForm,
    metaTitle: GlobalStrings.SEO.admin.createSupplierAccount,
  },
  {
    path: adminRoutesPath.Suppliers.Products.Index,
    Component: Suppliers,
    metaTitle: GlobalStrings.SEO.admin.productsSuppliers,
  },
  {
    path: adminRoutesPath.Suppliers.Products.AddSupplier,
    Component: AddSupplierForm,
    metaTitle: GlobalStrings.SEO.admin.addSupplier,
  },
  {
    path: adminRoutesPath.Suppliers.Products.EditSupplier,
    Component: EditSupplierAndProduct,
    metaTitle: GlobalStrings.SEO.admin.editSupplier,
  },
  {
    path: adminRoutesPath.Suppliers.Services.Index,
    Component: Suppliers,
    metaTitle: GlobalStrings.SEO.admin.servicesSuppliers,
  },
  {
    path: adminRoutesPath.Suppliers.AddSupplier,
    Component: AddSupplierForm,
    metaTitle: GlobalStrings.SEO.admin.addSupplier,
  },
  {
    path: adminRoutesPath.Suppliers.Services.AddService,
    Component: AddServiceForm,
    metaTitle: GlobalStrings.SEO.admin.addSupplierService,
  },
  {
    path: adminRoutesPath.Suppliers.Services.AddUser,
    Component: AddSupplierUserForm,
    metaTitle: GlobalStrings.SEO.admin.createSupplierAccount,
  },
  {
    path: adminRoutesPath.Suppliers.Services.EditSupplier,
    Component: EditSupplierAndService,
    metaTitle: GlobalStrings.SEO.admin.editSupplier,
  },
  {
    path: adminRoutesPath.Suppliers.AddService,
    Component: AddServiceForm,
    metaTitle: GlobalStrings.SEO.admin.addSupplierService,
  },
];
