import React from "react";

import { CreditCard, NoResults } from "components";

import styles from "../EndUser.module.scss";

const CreditCards = (props: {
  cards: {
    panMask: string;
    createdAt: string;
    isDefault: boolean;
  }[];
}) => {
  return (
    <>
      <div className={styles.credit_cards}>
        {props.cards.length > 0 &&
          props.cards.map((item, index) => {
            return <CreditCard key={index} {...item} />;
          })}
      </div>
      {!props.cards.length && <NoResults />}
    </>
  );
};
export default CreditCards;
