import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useDeletePartnerMutation, useGetPartnersQuery } from "app/partners/partnersApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ConfirmationPopup, PageHeader, TableComponent, TableIcon, VehicleType } from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { I_PARTNER } from "utils/types";

import { adminRoutesPath } from "../routes";

const Partners = () => {
  const columnHelper = createColumnHelper<I_PARTNER>();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [partnerToDelete, setPartnerToDelete] = useState<any>();
  const toggleModal = () => setModalIsOpen((prev) => !prev);
  const [deletePartner] = useDeletePartnerMutation();

  const AddButton = [
    {
      id: 1,
      name: GlobalStrings.partners.addPartner,
      slug: adminRoutesPath.Partners.AddPartner,
    },
  ];

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const [sorting, setSorting] = useState<SortingState>([{ id: "createdAt", desc: true }]);

  const { data, isFetching, refetch } = useGetPartnersQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
  });

  const deletePartnerHandler = async () => {
    try {
      await deletePartner(partnerToDelete.id).unwrap();
      toggleModal();
      refetch();
      toast.success(GlobalStrings.settings.partnerDeleted);
    } catch (err: any) {
      errorHandler(err);
    }
  };

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      cell: (info) => <p>{info.getValue() || "-"}</p>,
      header: () => <span>{GlobalStrings.partners.partner}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 40,
    }),
    columnHelper.accessor((row) => row.individualTransportVehicles, {
      id: "individualTransportVehicles",
      cell: (info) => {
        return info.getValue() !== "N/A" ? <VehicleType vehicles={info.getValue()} /> : null;
      },
      header: () => <span>{GlobalStrings.partners.vehicleType}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 50,
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <span>
            <Link
              to={adminRoutesPath.Partners.EditPartner.replace(
                ":partnerId",
                info.getValue().id.toString()
              )}
            >
              <TableIcon type="edit" />
            </Link>
          </span>
          <span>
            <Link
              to={adminRoutesPath.Partners.AddUser.replace(":id", info.getValue().id.toString())}
            >
              <TableIcon type="create" />
            </Link>
          </span>
          <span>
            <TableIcon
              type="delete"
              onClickAction={() => {
                toggleModal();
                setPartnerToDelete(info.getValue());
              }}
            />
          </span>
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
    }),
  ];

  const table = useReactTable({
    data: data?.partners || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <section>
      <PageHeader title={GlobalStrings.menu.transportPartners} tabs={AddButton} />

      <TableComponent
        table={table}
        tableHasData={data?.partners?.length > 0}
        isLoading={isFetching}
      />

      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        title={`${GlobalStrings.settings.partnerModalTitle} - ${partnerToDelete?.name}`}
        body={GlobalStrings.settings.partnerModalBody}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deletePartnerHandler}
      />
    </section>
  );
};
export default Partners;
