import React from "react";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "app/auth/authSlice";

import { PageHeader } from "components";
import { GlobalStrings } from "utils";
import { UserRoles } from "utils/enums";

import UsersTable from "views/common/Partners/UsersTable/UsersTable";

import { partnerRoutesPath } from "../routes";

const PartnerAccounts = () => {
  const user = useSelector(selectCurrentUser);
  const tabs = [
    {
      id: 0,
      name: GlobalStrings.formLabels.addUser,
      slug: partnerRoutesPath.Accounts.CreateAccount.replace(":id", user.company.id.toString()),
    },
  ];
  return (
    <section>
      <PageHeader
        title={GlobalStrings.menuPartners.accounts}
        tabs={user.roles.includes(UserRoles.partner_admin) ? tabs : undefined}
      />
      <UsersTable id={user.company.id} />
    </section>
  );
};
export default PartnerAccounts;
