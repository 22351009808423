import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, FormGroup, Input, InputGroupText, Label } from "reactstrap";

import {
  useGetPricesQuery,
  useUpdatePricesMutation,
} from "app/containersAndStations/containersAndStationsApiSlice";

import { AlertComponent, IsLoading, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { errorHandler, GlobalStrings, Regex } from "utils";
import { Currency } from "utils/enums";

type FORM = {
  fastChargePrices10minute: string;
  fastChargePrices20minute: string;
  fastChargePrices30minute: string;

  carChargePrices22KW: string;
  carChargePrices50KW: string;
  carChargePrices100KW: string;
  carChargePrices150KW: string;

  noShowFeeBookFee: string;
  noShowFeeOrderFee: string;

  stationTransportFee: string;
};
const Prices = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FORM>();
  const { serverMessage, setServerMessage } = useServerMessage();
  const { data: prices, isLoading, refetch } = useGetPricesQuery();
  const [updatePrices] = useUpdatePricesMutation();

  useEffect(() => {
    if (prices) {
      setValue("fastChargePrices10minute", prices?.fastChargePrices["10minute"]);
      setValue("fastChargePrices20minute", prices?.fastChargePrices["20minute"]);
      setValue("fastChargePrices30minute", prices?.fastChargePrices["30minute"]);

      setValue("noShowFeeBookFee", prices?.noShowFee?.bookFee);
      setValue("noShowFeeOrderFee", prices?.noShowFee?.orderFee);

      setValue("stationTransportFee", prices?.stationTransportFee);
    }
  }, [prices]);

  const onSubmit = async (formData: FORM) => {
    const data = {
      fastChargePrices: {
        "10minute": formData.fastChargePrices10minute,
        "20minute": formData.fastChargePrices20minute,
        "30minute": formData.fastChargePrices30minute,
      },
      stationTransportFee: formData.stationTransportFee,
      noShowFee: {
        bookFee: formData.noShowFeeBookFee,
        orderFee: formData.noShowFeeOrderFee,
      },
    };
    try {
      updatePrices({ body: { info: data }, id: prices.id }).unwrap();
      setServerMessage({
        type: "success",
        text: GlobalStrings.containersAndStations.pricesUpdated,
      });
      refetch();
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  return (
    <section>
      {isLoading ? (
        <IsLoading />
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <h4 className="mb-3">{GlobalStrings.partners.carChargePrices}</h4>
            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-100 w-md-25">
                <Controller
                  name="stationTransportFee"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.price,
                      message: GlobalStrings.formValidation.wrongPriceFormat,
                    },
                    min: {
                      value: 0,
                      message: GlobalStrings.formValidation.amountNegative,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      placeholder={GlobalStrings.formLabels.stationTransportFee}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="stationTransportFee">
                  {GlobalStrings.formLabels.stationTransportFee}
                </Label>
              </FormGroup>
            </div>
            <hr />
            <h4 className="mb-3">{GlobalStrings.partners.fastChargeBikeAndScooter}</h4>
            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-100 w-md-25">
                <Controller
                  name="fastChargePrices10minute"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.price,
                      message: GlobalStrings.formValidation.wrongPriceFormat,
                    },
                    min: {
                      value: 0,
                      message: GlobalStrings.formValidation.amountNegative,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      placeholder={GlobalStrings.formLabels.fastCharge10}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="fastChargePrices10minute">
                  {GlobalStrings.formLabels.fastCharge10}
                </Label>
              </FormGroup>

              <FormGroup floating className="w-100 w-md-25">
                <Controller
                  name="fastChargePrices20minute"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.price,
                      message: GlobalStrings.formValidation.wrongPriceFormat,
                    },
                    min: {
                      value: 0,
                      message: GlobalStrings.formValidation.amountNegative,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      placeholder={GlobalStrings.formLabels.fastCharge20}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="fastChargePrices20minute">
                  {GlobalStrings.formLabels.fastCharge20}
                </Label>
              </FormGroup>

              <FormGroup floating className="w-100 w-md-25">
                <Controller
                  name="fastChargePrices30minute"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.price,
                      message: GlobalStrings.formValidation.wrongPriceFormat,
                    },
                    min: {
                      value: 0,
                      message: GlobalStrings.formValidation.amountNegative,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      placeholder={GlobalStrings.formLabels.fastCharge30}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="fastChargePrices30minute">
                  {GlobalStrings.formLabels.fastCharge30}
                </Label>
              </FormGroup>
            </div>

            <hr />
            <h4 className="mb-3">{GlobalStrings.partners.noShowFee}</h4>
            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-100 w-md-25">
                <Controller
                  name="noShowFeeBookFee"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.price,
                      message: GlobalStrings.formValidation.wrongPriceFormat,
                    },
                    min: {
                      value: 0,
                      message: GlobalStrings.formValidation.amountNegative,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      placeholder={GlobalStrings.formLabels.bookFee}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="noShowFeeBookFee">{GlobalStrings.formLabels.bookFee}</Label>
              </FormGroup>

              <FormGroup floating className="w-100 w-md-25">
                <Controller
                  name="noShowFeeOrderFee"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.price,
                      message: GlobalStrings.formValidation.wrongPriceFormat,
                    },
                    min: {
                      value: 0,
                      message: GlobalStrings.formValidation.amountNegative,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      value={field.value}
                      placeholder={GlobalStrings.formLabels.orderFee}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="noShowFeeOrderFee">{GlobalStrings.formLabels.orderFee}</Label>
              </FormGroup>
            </div>
            <SubmitButton isLoading={false} label={GlobalStrings.save} />
            <AlertComponent errors={errors} serverMessage={serverMessage} />
          </div>
        </Form>
      )}
    </section>
  );
};
export default Prices;
