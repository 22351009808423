import { apiSlice } from "../apiSlice";

export const commonApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCities: builder.query<[], void>({
      query: () => {
        let url = `${process.env.REACT_APP_CONSUMER_API_URL}/app-settings?name=cities`;

        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        return (
          response.data?.pageData?.[0]?.info?.map((item: any) => {
            return {
              ...item,
              value: item.city,
              label: item.city,
            };
          }) || []
        );
      },
    }),
  }),
});

export const { useGetCitiesQuery } = commonApiSlice;
