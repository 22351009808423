import React, { useEffect, useRef } from "react";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, ButtonGroup } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import { selectMarketplaceIsUsedInApp } from "app/common/commonSlice";

import classNames from "classnames";
import { roleValidation } from "utils";

import styles from "./PageHeader.module.scss";

const PageHeader = (props: {
  title: string;
  backRoute?: string;
  tabsCurrentviewActiveIndex?: number;
  tabs?: {
    id: string | number;
    name: string;
    slug: string;
  }[];
  tabsCurrentview?: {
    id: string | number;
    name: string;
    slug: string;
  }[];
  onTabClick?: Function;
  disableNavigation?: boolean;
}) => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const usedInApp = useSelector(selectMarketplaceIsUsedInApp);
  const tabsCurrentViewGridRef = useRef<any>();
  const tabsCurrentViewActiveRef = useRef<any>();

  const handleBackNavigation = () => {
    if (props.backRoute === "-1") {
      navigate(-1);
    } else {
      navigate(props.backRoute || "");
    }
  };

  useEffect(() => {
    if (props?.tabsCurrentview && props.tabsCurrentview.length > 0) {
      tabsCurrentViewGridRef.current.scrollLeft =
        tabsCurrentViewActiveRef?.current?.offsetLeft || 0;
    }
  }, [props.tabsCurrentviewActiveIndex]);

  return (
    <div className={styles.page_header}>
      <div className={styles.header_top}>
        <div className={styles.header_title}>
          <span
            className={classNames({
              [styles.admin]: usedInApp,
              [styles[user?.role]]: !usedInApp,
            })}
          ></span>

          {props.backRoute && (
            <IoIosArrowDropleftCircle
              size={30}
              className={classNames("back_button", {
                [user?.role]: !usedInApp,
                admin: usedInApp,
              })}
              onClick={handleBackNavigation}
            />
          )}
          <h1>{props.title}</h1>
        </div>

        <ButtonGroup className={styles.buttons_container}>
          {props?.tabs?.map((item: any, index: number) => {
            const isSecondButton = index === 1;

            return (
              <Button
                className={classNames("header_button", {
                  secondButton: isSecondButton,
                  [user?.role]: !usedInApp,
                  admin: usedInApp || roleValidation("admin", user.roles),
                })}
                onClick={() => navigate(item.slug)}
                key={item.id}
              >
                {item.name}
              </Button>
            );
          })}
        </ButtonGroup>
      </div>

      {props?.tabsCurrentview && (
        <div className={styles.header_bottom_tabs}>
          <div
            className={styles.grid_buttons}
            ref={tabsCurrentViewGridRef}
            style={{
              gridTemplateColumns: `repeat(${props?.tabsCurrentview.length}, 220px)`,
            }}
          >
            <div
              className={classNames(styles.active_border, styles[user?.role])}
              style={{
                transform: `translateX(${220 * (props?.tabsCurrentviewActiveIndex || 0)}px)`,
              }}
            ></div>

            {props?.tabsCurrentview?.map((item, index: number) => (
              <Link
                key={`${item.id}-${index}`}
                to={item.slug}
                ref={(e) =>
                  props.tabsCurrentviewActiveIndex === index
                    ? (tabsCurrentViewActiveRef.current = e)
                    : null
                }
                className={classNames(styles[user?.role])}
                onClick={() => (props?.onTabClick ? props?.onTabClick(item.id) : undefined)}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PageHeader;
