import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useAcceptProductMutation,
  useDeclineProductMutation,
} from "app/marketplace/marketplaceApiSlice";

import { ConfirmationPopup, Price } from "components";
import { errorHandler, GlobalStrings } from "utils";
import { Currency } from "utils/enums";
import { I_PRODUCT } from "utils/types";

import styles from "./ProductInfo.module.scss";

const ProductInfo = (props: { product: I_PRODUCT }) => {
  const navigate = useNavigate();
  const [acceptProduct] = useAcceptProductMutation();
  const [declineProduct] = useDeclineProductMutation();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [actionType, setActionType] = useState<any>("");
  const [declineReason, setDeclineReason] = useState("");

  const acceptProductHandler = async (productId: number) => {
    try {
      await acceptProduct(productId).unwrap();
      toast.success(GlobalStrings.marketplace.productAccepted);
      setModalIsOpen(false);
      navigate("/marketplace");
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const declineProductHandler = async (productId: number) => {
    try {
      await declineProduct({
        productId,
        reasonForDeclining: declineReason,
      }).unwrap();
      setDeclineReason("");
      setModalIsOpen(false);
      toast.success(GlobalStrings.marketplace.productDeclined);
      navigate("/marketplace");
    } catch (error: any) {
      errorHandler(error);
    }
  };
  return (
    <div className={styles.product_info}>
      <h2>{props.product.name}</h2>
      <p className="mt-3">Descriere: {props.product.description}</p>
      <h4 className="mt-3">Categorii si subcategorie</h4>
      {props.product.categories && (
        <p>
          {GlobalStrings.formLabels.categories}:{" "}
          {props.product.categories?.map((item) => {
            return item.name;
          })}
        </p>
      )}

      {props.product.subCategory && (
        <p>
          {GlobalStrings.formLabels.subcategory}: {props.product.subCategory.name}
        </p>
      )}
      <h4 className="mt-2">Atribute si caracteristici</h4>

      {props.product.characteristics &&
        props.product.characteristics.map((item, index) => {
          return `${index > 0 ? ", " : ""}${item.attribute.name} - ${item.value}`;
        })}
      <h4 className="mt-3">{GlobalStrings.formLabels.productPrice}</h4>
      <Price value={props.product.price} currency={Currency.RON} unit={props.product.unit} />
      <h4>Informatii companie</h4>
      {props.product.company && (
        <div>
          <p>
            {GlobalStrings.formLabels.companyName}: {props.product.company.name}
          </p>
          <p>
            {GlobalStrings.formLabels.address}: {props.product.company.address}
          </p>
          <p>
            {GlobalStrings.formLabels.email}: {props.product.company.email}
          </p>
          <p>
            {GlobalStrings.formLabels.phoneNumber}: {props.product.company.phone}
          </p>
        </div>
      )}
      <div className="d-flex gap-2">
        <button
          className="btn btn-success"
          onClick={() => {
            setModalIsOpen(true);
            setActionType("accept");
          }}
        >
          {GlobalStrings.marketplace.accept}
        </button>
        <button
          className="btn btn-danger"
          onClick={() => {
            setModalIsOpen(true);
            setActionType("decline");
          }}
        >
          {GlobalStrings.marketplace.decline}
        </button>
      </div>
      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={setModalIsOpen}
        title={
          actionType === "accept"
            ? GlobalStrings.marketplace.acceptModal.title + " - " + props.product?.name
            : GlobalStrings.marketplace.declineModal.title + " - " + props.product?.name
        }
        body={
          actionType === "accept"
            ? GlobalStrings.marketplace.acceptModal.body
            : GlobalStrings.marketplace.declineModal.body
        }
        cancelText={GlobalStrings.cancel}
        confirmFunction={() =>
          props.product?.id &&
          (actionType === "accept"
            ? acceptProductHandler(props.product?.id)
            : declineProductHandler(props.product?.id))
        }
        confirmText={GlobalStrings.yes}
        textareaValue={declineReason}
        setTextareaValue={actionType === "decline" ? setDeclineReason : undefined}
      />
    </div>
  );
};

export default ProductInfo;
