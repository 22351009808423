import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import { useAddVehicleMutation, useUpdateVehicleMutation } from "app/vehicles/vehiclesApiSlice";

import { AlertComponent, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { errorHandler, GlobalStrings, GlobalVariables, Regex } from "utils";
import { TransportType } from "utils/enums";

import { partnerRoutesPath } from "views/partner/routes";

const AddEditVehicleForm = (props: { data: any }) => {
  const { vehicleId } = useParams();
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      status: props.data?.status || "",
      type: props.data?.type || "",
      customId: props.data?.customId || "",
      iotType: props.data?.iotType || "",
      iotId: props.data?.iotId || "",
      model: props.data?.model || "",
      qrCode: props.data?.qrCode || "",
      imei: props.data?.hardware?.imei || "",
      phoneNumber: props.data?.hardware?.phoneNumber || "",
      name: props.data?.hardware?.name || "",
      licensePlate: props.data?.hardware?.licensePlate || "",
    },
  });
  const { serverMessage, setServerMessage } = useServerMessage();
  const [addVehicle, { isLoading }] = useAddVehicleMutation();
  const [updateVehicle, { isLoading: isLoadingUpdate }] = useUpdateVehicleMutation();

  const addVehicleHandler = async (body: any) => {
    try {
      const response = await addVehicle(body).unwrap();
      navigate(
        partnerRoutesPath.Vehicles.Vehicle.replace(":vehicleId", response?.data?.id.toString())
      );

      toast.success(GlobalStrings.partners.vehicleHasBeenAdded);
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  const updateVehicleHandler = async (body: any) => {
    try {
      await updateVehicle({ body, vehicleId }).unwrap();
      setServerMessage({
        type: "success",
        text: GlobalStrings.partners.vehicleHasBeenUpdated,
      });
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  const onSubmit = async (data: any) => {
    const body = {
      ...data,
      companyId: user.company.id,
      companyName: user.company.name,
      companyIdentifier: user.company.identifier,
      hardware: {
        imei: data.imei,
        name: data.name,
        licensePlate: data.licensePlate,
      },
    };
    delete body.imei;
    delete body.name;
    delete body.phoneNumber;
    delete body.licensePlate;
    if (data.type === TransportType.CAR) {
      body.hardware.phoneNumber = data.phoneNumber;
    }

    if (vehicleId) {
      updateVehicleHandler(body);
    } else {
      addVehicleHandler(body);
    }
  };

  return (
    <section>
      <div className="card mt-4 mb-5">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-100">
              <Controller
                name="type"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.city}
                    type="select"
                  >
                    <option value="">-</option>
                    {GlobalVariables.vehicleTypes.map((item: any) => {
                      return (
                        <option key={item.id} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </Input>
                )}
              />
              <Label for="type">{GlobalStrings.servicesSuppliers.vehicleType}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="customId"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  maxLength: {
                    value: 120,
                    message: GlobalStrings.formValidation.maxChars120,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.vehicleID}
                    type="text"
                  />
                )}
              />
              <Label for="customId">{GlobalStrings.formLabels.vehicleID}</Label>
            </FormGroup>
          </div>
          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-100">
              <Controller
                name="iotType"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  maxLength: {
                    value: 120,
                    message: GlobalStrings.formValidation.maxChars120,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.TypeIoT}
                    type="text"
                  />
                )}
              />
              <Label for="iotType">{GlobalStrings.formLabels.TypeIoT}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="iotId"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  maxLength: {
                    value: 120,
                    message: GlobalStrings.formValidation.maxChars120,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.IDIoT}
                    type="text"
                  />
                )}
              />
              <Label for="iotId">{GlobalStrings.formLabels.IDIoT}</Label>
            </FormGroup>
          </div>

          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-100">
              <Controller
                name="model"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  maxLength: {
                    value: 120,
                    message: GlobalStrings.formValidation.maxChars120,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.model}
                    type="text"
                  />
                )}
              />
              <Label for="model">{GlobalStrings.formLabels.model}</Label>
            </FormGroup>
            <FormGroup floating className=" w-100">
              <Controller
                name="status"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.status}
                    type="select"
                  >
                    <option value="">-</option>
                    {watch("type") === TransportType.CAR
                      ? GlobalVariables.carStatus.map((item: any) => {
                          return (
                            <option key={item.id} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })
                      : GlobalVariables.vehicleStatus.map((item: any) => {
                          return (
                            <option key={item.id} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                  </Input>
                )}
              />
              <Label for="status">{GlobalStrings.formLabels.status}</Label>
            </FormGroup>
          </div>

          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-md-50 w-100">
              <Controller
                name="qrCode"
                control={control}
                rules={{
                  pattern: {
                    value: Regex.qrCode,
                    message: GlobalStrings.formValidation.qrCode,
                  },
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.qrCode}
                    type="text"
                  />
                )}
              />
              <Label for="qrCode">{GlobalStrings.formLabels.qrCode}</Label>
            </FormGroup>
          </div>

          <h4>{GlobalStrings.formLabels.hardware}</h4>

          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-md-50 w-100">
              <Controller
                name="imei"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.IMEI}
                    type="text"
                  />
                )}
              />
              <Label for="imei">{GlobalStrings.formLabels.IMEI}</Label>
            </FormGroup>

            <FormGroup floating className="w-md-50 w-100">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.name}
                    type="text"
                  />
                )}
              />
              <Label for="name">{GlobalStrings.formLabels.name}</Label>
            </FormGroup>
          </div>

          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-md-50 w-100">
              <Controller
                name="licensePlate"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.licensePlate}
                    type="text"
                  />
                )}
              />
              <Label for="licensePlate">{GlobalStrings.formLabels.licensePlate}</Label>
            </FormGroup>

            {watch("type") === TransportType.CAR && (
              <FormGroup floating className="w-md-50 w-100">
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    pattern: {
                      value: Regex.phone,
                      message: GlobalStrings.formValidation.invalidPhone,
                    },
                    required: GlobalStrings.formValidation.fieldsRequired,
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      placeholder={GlobalStrings.formLabels.phone}
                      type="text"
                    />
                  )}
                />
                <Label for="phoneNumber">{GlobalStrings.formLabels.phone}</Label>
              </FormGroup>
            )}
          </div>

          <SubmitButton
            isLoading={isLoading || isLoadingUpdate}
            label={GlobalStrings.formLabels.save}
          />
        </Form>

        <AlertComponent errors={errors} serverMessage={serverMessage} />
      </div>
    </section>
  );
};

export default AddEditVehicleForm;
