import bicycleIcon from "assets/icons/vehicle_bike.svg";
import busIcon from "assets/icons/vehicle_bus.svg";
import busTramIcon from "assets/icons/vehicle_bus_tram.svg";
import carIcon from "assets/icons/vehicle_car.svg";
import scooterIcon from "assets/icons/vehicle_scooter.svg";
import scooterOrBicycle from "assets/icons/vehicle_scooter_or_bicycle.svg";
import sharingIcon from "assets/icons/vehicle_sharing.svg";
import tramIcon from "assets/icons/vehicle_tram.svg";

import {
  Connector,
  ConnectorStatus,
  Currency,
  ElectricityContainerType,
  ProductStatus,
  ProductUM,
  StationStatus,
  VehicleStatus,
} from "./enums";
import GlobalStrings from "./globalStrings";

export const GlobalVariables = {
  redColor: "#dd2849",
  whiteColor: "#fff",
  blackColor: "#1a1a1a",
  capacityUM: "KW",
  amountAC: "kWh",
  userTypes: [
    {
      id: 0,
      name: GlobalStrings.userRoles.admin,
      value: "admin",
    },
    {
      id: 1,
      name: GlobalStrings.userRoles.partial_admin,
      value: "partial_admin",
    },
  ],
  electricityContainerTypes: [
    {
      label: GlobalStrings.electricityContainerTypes.fixed,
      value: ElectricityContainerType.FIXED,
    },
    {
      label: GlobalStrings.electricityContainerTypes.mobile,
      value: ElectricityContainerType.MOBILE,
    },
  ],
  publicTransportVehicles: [
    {
      id: 0,
      value: "bus",
      label: "Autobuz",
    },
    {
      id: 1,
      value: "tram",
      label: "Tramvai",
    },
    {
      id: 2,
      value: "bus-tram",
      label: "Autobuz/Tramvai",
    },
  ],

  vehicleTypes: [
    {
      id: 0,
      value: "scooter",
      label: "Trotinete",
    },
    {
      id: 1,
      value: "car",
      label: "Masini",
    },
    {
      id: 2,
      value: "bicycle",
      label: "Biciclete",
    },
  ],
  serviceType: [
    {
      id: 0,
      value: "recharge battery",
      label: GlobalStrings.servicesTypes["recharge battery"],
    },
    {
      id: 1,
      value: "repair",
      label: GlobalStrings.servicesTypes.repair,
    },
    {
      id: 2,
      value: "vulcanization",
      label: GlobalStrings.servicesTypes.vulcanization,
    },
  ],
  currency: {
    EURO: "EURO",
    RON: "RON",
    LEI: "LEI",
  },
  currencyArray: [
    {
      id: 0,
      value: Currency.EURO,
      name: Currency.EURO,
    },
    {
      id: 1,
      value: Currency.RON,
      name: Currency.RON,
    },
  ],
  unitsArray: [
    {
      id: 0,
      value: ProductUM.PIECE,
      name: GlobalStrings.marketplace.pc,
    },
    {
      id: 1,
      value: ProductUM.HOUR,
      name: GlobalStrings.marketplace.hour,
    },
    {
      id: 2,
      value: ProductUM.KG,
      name: GlobalStrings.marketplace.kg,
    },
    {
      id: 3,
      value: ProductUM.L,
      name: GlobalStrings.marketplace.l,
    },
  ],
  paginationRows: [
    {
      value: 10,
      label: 10,
    },
    {
      value: 20,
      label: 20,
    },
    {
      value: 30,
      label: 30,
    },
    {
      value: 40,
      label: 40,
    },
    {
      value: 50,
      label: 50,
    },
  ],
  payStatus: {
    paid: GlobalStrings.invoices.paid,
    notpaid: GlobalStrings.invoices.notpaid,
  },
  productStatus: [
    {
      id: 0,
      name: GlobalStrings.yes,
      value: ProductStatus.IN_STOCK,
    },
    {
      id: 1,
      name: GlobalStrings.no,
      value: ProductStatus.OUT_OF_STOCK,
    },
  ],
  chargingPortsNumber: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  vehicleTypeIcons: {
    scooter_or_bicycle: scooterOrBicycle,
    bicycle: bicycleIcon,
    scooter: scooterIcon,
    car: carIcon,
    rideSharing: sharingIcon,
    bus: busIcon,
    tram: tramIcon,
    "bus-tram": busTramIcon,
  },
  vehicleStatus: [
    {
      id: 0,
      value: VehicleStatus.ACTIVE,
      label: GlobalStrings.vehicleStatus.active,
    },
    {
      id: 1,
      value: VehicleStatus.INACTIVE,
      label: GlobalStrings.vehicleStatus.inactive,
    },
    {
      id: 2,
      value: VehicleStatus.DAMAGED,
      label: GlobalStrings.vehicleStatus.damaged,
    },
    {
      id: 3,
      value: VehicleStatus.STOLEN,
      label: GlobalStrings.vehicleStatus.stolen,
    },
    {
      id: 4,
      value: VehicleStatus.CHARGING,
      label: GlobalStrings.vehicleStatus.charging,
    },
    {
      id: 5,
      value: VehicleStatus.IN_USE,
      label: GlobalStrings.vehicleStatus.in_use,
    },
    {
      id: 6,
      value: VehicleStatus.RESERVED,
      label: GlobalStrings.vehicleStatus.reserved,
    },
  ],
  carStatus: [
    {
      id: 0,
      value: VehicleStatus.ACTIVE,
      label: GlobalStrings.vehicleStatus.active,
    },
    {
      id: 1,
      value: VehicleStatus.INACTIVE,
      label: GlobalStrings.vehicleStatus.inactive,
    },
    {
      id: 2,
      value: VehicleStatus.DAMAGED,
      label: GlobalStrings.vehicleStatus.damaged,
    },
    {
      id: 3,
      value: VehicleStatus.STOLEN,
      label: GlobalStrings.vehicleStatus.stolen,
    },
    {
      id: 4,
      value: VehicleStatus.CHARGING,
      label: GlobalStrings.vehicleStatus.charging,
    },
    {
      id: 5,
      value: VehicleStatus.IN_USE,
      label: GlobalStrings.vehicleStatus.in_use,
    },
  ],
  connectorType: [
    {
      id: 0,
      value: Connector.CCS,
      label: GlobalStrings.connectorType.CCS,
    },
    {
      id: 1,
      value: Connector.CHAdeMO,
      label: GlobalStrings.connectorType.CHAdeMO,
    },
    {
      id: 2,
      value: Connector.AC,
      label: GlobalStrings.connectorType.AC,
    },
    {
      id: 3,
      value: Connector.OUTLET_220,
      label: GlobalStrings.connectorType.OUTLET_220,
    },
  ],
  stationStatus: [
    {
      id: 0,
      value: StationStatus.ACTIVE,
      label: GlobalStrings.stationsStatus.active,
    },
    {
      id: 1,
      value: StationStatus.MAINTENANCE,
      label: GlobalStrings.stationsStatus.maintenance,
    },
    {
      id: 2,
      value: StationStatus.OUTOFUSE,
      label: GlobalStrings.stationsStatus.outofuse,
    },
  ],
  connectorStatus: [
    {
      id: 0,
      value: ConnectorStatus.ACTIVE,
      label: GlobalStrings.connectorStatus.active,
    },
    {
      id: 1,
      value: ConnectorStatus.AVAILABLE,
      label: GlobalStrings.connectorStatus.available,
    },
    {
      id: 2,
      value: ConnectorStatus.CANCELED,
      label: GlobalStrings.connectorStatus.canceled,
    },
    {
      id: 3,
      value: ConnectorStatus.CHARGING,
      label: GlobalStrings.connectorStatus.charging,
    },
    {
      id: 4,
      value: ConnectorStatus.FINISHED,
      label: GlobalStrings.connectorStatus.finished,
    },
    {
      id: 5,
      value: ConnectorStatus.IN_USE,
      label: GlobalStrings.connectorStatus.in_use,
    },
    {
      id: 6,
      value: ConnectorStatus.OUTOFUSE,
      label: GlobalStrings.connectorStatus.outofuse,
    },
  ],
  documentImageStatus: [
    {
      id: 0,
      value: "",
      label: "-",
    },
    {
      id: 1,
      value: false,
      label: GlobalStrings.documentImageStatus.rejected,
    },
    {
      id: 2,
      value: true,
      label: GlobalStrings.documentImageStatus.accepted,
    },
  ],
  center: {
    lat: parseFloat(`${process.env.REACT_APP_CENTER_LAT}`),
    lng: parseFloat(`${process.env.REACT_APP_CENTER_LNG}`),
  },
};
