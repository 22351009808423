import { GlobalStrings } from "utils";
import {
  AppServicesAuth,
  ForgotPassword,
  MarketplaceAppAuth,
  NotAuthorised,
  Public,
  ResetPassword,
  SetPassword,
} from "views/common";

export const commonRoutesPath = {
  Public: "/",
  ForgotPassword: "/forgot-password",
  ResetPassword: "/reset-password/:token",
  SetPassword: "/set-password/:token",
  NotAuthorised: "/not-authorised",
  MarketplaceAppAuth: "/marketplace/app/:token",
  AppServicesAuth: "/services/app/:token",
};

export const commonRoutes = [
  {
    path: commonRoutesPath.Public,
    Component: Public,
    metaTitle: GlobalStrings.SEO.common.auth,
  },
  {
    path: commonRoutesPath.ForgotPassword,
    Component: ForgotPassword,
    metaTitle: GlobalStrings.SEO.common.forgotPassword,
  },
  {
    path: commonRoutesPath.ResetPassword,
    Component: ResetPassword,
    metaTitle: GlobalStrings.SEO.common.forgotPassword,
  },
  {
    path: commonRoutesPath.SetPassword,
    Component: SetPassword,
    metaTitle: GlobalStrings.SEO.common.setPassword,
  },
  {
    path: commonRoutesPath.NotAuthorised,
    Component: NotAuthorised,
    metaTitle: GlobalStrings.SEO.common.notAuthorised,
  },
  {
    path: commonRoutesPath.MarketplaceAppAuth,
    Component: MarketplaceAppAuth,
    metaTitle: GlobalStrings.SEO.common.auth,
  },
  {
    path: commonRoutesPath.AppServicesAuth,
    Component: AppServicesAuth,
    metaTitle: GlobalStrings.SEO.common.auth,
  },
];
