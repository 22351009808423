import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { UncontrolledTooltip } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";

import classNames from "classnames";

import { I_MENU_ITEM } from "../../../utils/types";
import LogoutModal from "../../LogoutModal/LogoutModal";
import styles from "../Menu/Menu.module.scss";

const MenuItem = (props: {
  item: I_MENU_ITEM;
  type?: "logout";
  setActiveIndex: Function;
  activeIndex: number;
}) => {
  const menuIsOpen = useSelector((state: any) => state.common.menuIsOpen);
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (
      location.pathname.includes(props.item.slug) ||
      location.pathname.split("/")[1] === props.item.slug.split("/")[1]
    )
      props.setActiveIndex(props.item.id);
  }, [props.item]);

  const handleClick = () => {
    if (props.type === "logout") {
      setModalIsOpen(true);
    } else {
      navigate(props.item.slug);
      props.setActiveIndex(props.item.id);
    }
  };

  return (
    <>
      <li
        id={`menu-${props.item.id}`}
        className={classNames(styles.menu_item)}
        onClick={handleClick}
      >
        <div className={styles.icon}>
          <ReactSVG
            src={props.item.icon}
            className={classNames(styles.svg, {
              [styles.active]: props.activeIndex === props.item.id,
              [styles[user?.role]]: true,
            })}
          />
        </div>
        {!menuIsOpen && (
          <UncontrolledTooltip
            target={`menu-${props.item.id}`}
            placement="right"
            className={styles.menu_tooltip}
          >
            {props.item.text}
          </UncontrolledTooltip>
        )}
        <div className={`${styles.menu_item_text} ${menuIsOpen ? styles.active : ""}`}>
          {props.item.text}
        </div>
      </li>
      {props.type === "logout" && (
        <LogoutModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      )}
    </>
  );
};

export default MenuItem;
