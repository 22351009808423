import React from "react";
import { useParams } from "react-router-dom";

import TripsTable from "views/common/Partners/TripsTable/TripsTable";

const Trips = () => {
  const { partnerId } = useParams();

  return (
    <section>
      <div className="mt-4">
        <div className="card">
          <TripsTable id={partnerId} />
        </div>
      </div>
    </section>
  );
};
export default Trips;
