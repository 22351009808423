import React, { useEffect, useState } from "react";
import { GiElectric } from "react-icons/gi";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "app/auth/authSlice";
import {
  useGetAllElectricityContainersQuery,
  useGetNumberOfUsedContainersQuery,
} from "app/containersAndStations/containersApiSlice";

import { GoogleMap, MarkerClustererF, useLoadScript } from "@react-google-maps/api";
import { IsLoading, StatisticItem } from "components";
import { errorHandler, GlobalStrings, GlobalVariables } from "utils";

import ContainerMarker from "./ContainerMarker";

const containerStyle = {
  width: "100%",
  height: "75vh",
};

function ContainersMap() {
  const user = useSelector(selectCurrentUser);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
  });
  const { data: numberOfContainers } = useGetNumberOfUsedContainersQuery(user.company.id);
  const {
    data,
    error: mapError,
    isLoading,
  } = useGetAllElectricityContainersQuery({
    companyId: user.company.id,
  });
  const [active, setActive] = useState();

  useEffect(() => {
    if (mapError) {
      errorHandler(mapError);
    }
  }, [mapError]);

  if (isLoading) return <IsLoading />;

  return isLoaded ? (
    <>
      <div className="stats d-flex gap-3 flex-column flex-md-row mb-2">
        <div className="w-md-25 w-100">
          <StatisticItem
            icon={<GiElectric size={30} />}
            count={numberOfContainers}
            title={GlobalStrings.containersAndStations.containereENECactive}
          />
        </div>
      </div>
      <GoogleMap mapContainerStyle={containerStyle} center={GlobalVariables.center} zoom={13}>
        <MarkerClustererF averageCenter={false} maxZoom={20} gridSize={50} minimumClusterSize={2}>
          {(clusterer) => (
            <>
              {data?.map((item: any, index: number) => {
                return (
                  <ContainerMarker
                    clusterer={clusterer}
                    item={item}
                    key={index}
                    active={active}
                    setActive={setActive}
                  />
                );
              })}
            </>
          )}
        </MarkerClustererF>
      </GoogleMap>
    </>
  ) : (
    <>Loading....</>
  );
}

export default React.memo(ContainersMap);
