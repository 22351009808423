export const Regex = {
  /* eslint-disable no-useless-escape */
  email: /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,8}$/,
  loginEmail: /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,8}\s*$/,
  password: /^(?=.*[A-Z])(?=.*\d).{8,64}$/,
  number: /^\d+$/,
  floatNumber: /[+-]?([0-9]*[.])?[0-9]+/,
  phone:
    /^(\+4|)?(07[0-9]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/,
  price: /^\d+(\.\d{1,2})?$/,
  latitude: /^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})$/,
  longitude: /^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})?$/,
  cifOrCnp: /^[1-9]\d{0,8}$|^[1-2]\d{12}$/i,
  qrCode: /^\d{6}$/,
  date: /\d{1,2}.\d{1,2}.\d{1}\d{1}\d{1}\d{1}/,
  positiveNumber: /^\d*[1-9]\d*$/,
};
