import { apiSlice } from "app/apiSlice";

export const vouchersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVoucherStatus: builder.query<any, { voucherId: number; userId: string }>({
      query: ({ voucherId, userId }) => {
        const url = `${process.env.REACT_APP_VEHICLE_API_URL}/trips/is-voucher-valid-for-user?voucherId=${voucherId}&userId=${userId}`;
        return url;
      },
    }),
  }),
});

export const { useGetVoucherStatusQuery } = vouchersApiSlice;
