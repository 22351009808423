import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useDeleteSupplierMutation,
  useGetSuppliersWithProductsQuery,
} from "app/suppliers/suppliersApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ConfirmationPopup, TableComponent, TableIcon } from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { I_SUPPLIER } from "utils/types";

import { adminRoutesPath } from "views/admin/routes";

const SuppliersProducts = () => {
  const [deleteSupplier] = useDeleteSupplierMutation();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, isFetching, refetch } = useGetSuppliersWithProductsQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
  });

  const columnHelper = createColumnHelper<I_SUPPLIER>();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [supplierToDelete, setSupplierToDelete] = useState<any>();
  const toggleModal = () => setModalIsOpen((prev) => !prev);

  const deleteSupplierHandler = async () => {
    try {
      await deleteSupplier(supplierToDelete.id).unwrap();
      toggleModal();
      refetch();
      toast.success(GlobalStrings.productsSuppliers.supplierDeleted);
    } catch (err: any) {
      errorHandler(err);
    }
  };

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "company",
      cell: (info) => <p>{info.getValue() || "-"}</p>,
      header: () => <span>{GlobalStrings.productsSuppliers.name}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row.identifier, {
      id: "CIF",
      cell: (info) => <p>{(info.getValue() || "-").replace("/S", "")}</p>,
      header: () => <span>{GlobalStrings.productsSuppliers.productSupplierCif}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row.email, {
      id: `email`,
      cell: (info) => <p>{info.getValue() || "-"}</p>,
      header: () => <span>{GlobalStrings.productsSuppliers.supplierEmail}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),

    columnHelper.accessor((row) => row.phone, {
      id: `phone`,
      cell: (info) => <p>{info.getValue() || "-"}</p>,
      header: () => <span>{GlobalStrings.productsSuppliers.supplierPhone}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),

    columnHelper.accessor((row) => row.city, {
      id: `city`,
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.productsSuppliers.supplierCity}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),

    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <span>
            <Link
              to={adminRoutesPath.Suppliers.Products.EditSupplier.replace(
                ":supplierId",
                info.getValue().id.toString()
              )}
            >
              <TableIcon type="edit" />
            </Link>
          </span>
          <span>
            <Link
              to={adminRoutesPath.Suppliers.AddUser.replace(
                ":supplierId",
                info.getValue().id.toString()
              )}
            >
              <TableIcon type="create" />
            </Link>
          </span>
          <span>
            <TableIcon
              type="delete"
              onClickAction={() => {
                toggleModal();
                setSupplierToDelete(info.getValue());
              }}
            />
          </span>
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 25,
    }),
  ];

  const table = useReactTable({
    data: data?.suppliers || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <section>
      <TableComponent
        table={table}
        tableHasData={data?.suppliers?.length > 0}
        isLoading={isFetching}
      />

      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        title={GlobalStrings.settings.supplierModalBody}
        body={GlobalStrings.settings.supplierModalBody}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deleteSupplierHandler}
      />
    </section>
  );
};

export default SuppliersProducts;
