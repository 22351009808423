import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { selectCurrentUser } from "app/auth/authSlice";
import { selectMarketplaceIsUsedInApp } from "app/common/commonSlice";
import {
  useAddToCartMutation,
  useDecreaseQuantityFromCartMutation,
  useGetCartProductsQuery,
  useRemoveFromCartMutation,
} from "app/marketplace/cartApiSlice";

import classNames from "classnames";
import { Price } from "components";
import { errorHandler, GlobalStrings } from "utils";
import { Currency } from "utils/enums";
import { I_PRODUCT } from "utils/types";

import styles from "./ProductsListing.module.scss";

const ProductsListing = (props: {
  products: {
    id: number;
    quantity: number;
    product: I_PRODUCT;
  }[];
}) => {
  const user = useSelector(selectCurrentUser);
  const marketplaceIsUsedInApp = useSelector(selectMarketplaceIsUsedInApp);
  const [addToCart] = useAddToCartMutation();
  const [removeFromCart] = useRemoveFromCartMutation();
  const [decreaseQuantityFromCart] = useDecreaseQuantityFromCartMutation();
  const { refetch } = useGetCartProductsQuery(user.id, {
    refetchOnMountOrArgChange: false,
  });

  const addToCartHandler = async (productId?: number) => {
    try {
      await addToCart({ productId }).unwrap();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  const removeFromCartHandler = async (productId?: number) => {
    try {
      await removeFromCart({ productId }).unwrap();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  const decreaseQuantityFromCartHandler = async (productId?: number) => {
    try {
      await decreaseQuantityFromCart({ productId }).unwrap();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <section
      className={classNames(styles.products_container, {
        [styles.used_for_app]: marketplaceIsUsedInApp,
      })}
    >
      {props.products?.map((item, index: number) => {
        return (
          <div key={index} className={styles.product}>
            <div className={styles.image_container}>
              <img src={item.product.imagesKeys?.[0]} alt={item.product.name} />
            </div>
            <div className={styles.product_details}>
              <Link to={`/marketplace/products/${item.product.id}`} className={styles.name}>
                {item.product.name}
              </Link>
              {item.product.inStock && (
                <p className={styles.product_status}>{GlobalStrings.marketplace.availableStatus}</p>
              )}
            </div>
            <div className={styles.price}>
              <Price
                value={item.product.price}
                currency={Currency.RON}
                unit={item.product.unit}
                className={styles.product_price}
              />
              <div>
                <div className={styles.quantity}>
                  <button
                    onClick={() => decreaseQuantityFromCartHandler(item.product.id)}
                    className={styles.decrease}
                  />
                  <p>{item.quantity}</p>
                  <button
                    onClick={() => addToCartHandler(item.product.id)}
                    className={styles.increase}
                  />
                </div>
                <button
                  className="btn btn-link"
                  onClick={() => removeFromCartHandler(item.product.id)}
                >
                  {GlobalStrings.delete}
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};
export default ProductsListing;
