import React from "react";

import classNames from "classnames";
import { GlobalStrings } from "utils";

import carPin from "assets/icons/car_pin.svg";
import stationPin from "assets/icons/station_pin.svg";
import greenPin from "assets/images/recharge_station_green.svg";
import yellowPin from "assets/images/recharge_station_yellow.svg";

import styles from "./VehiclesFilters.module.scss";

const VehiclesFilters = (props: { activeFilters: string; filterHandler: Function }) => {
  const filters = [
    {
      type: "vehicles",
      image: carPin,
      text: GlobalStrings.vehicleTypes.vehicles,
    },
    {
      type: "recharge-stations",
      image: yellowPin,
      text: GlobalStrings.containersAndStations.rechargeStation,
    },
    {
      type: "containers",
      image: greenPin,
      text: GlobalStrings.containersAndStations.containers,
    },
    {
      type: "public-transport-stations",
      image: stationPin,
      text: GlobalStrings.vehicleTypes.publicTransportStations,
    },
  ];
  return (
    <div className={styles.filters}>
      {filters.map((item, index: number) => {
        return (
          <div
            className={classNames(styles.filter, {
              [styles.active]: props.activeFilters === item.type,
            })}
            onClick={() => props.filterHandler(item.type)}
            key={index}
          >
            <img src={item.image} alt={item.text} />
            <p>{item.text}</p>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(VehiclesFilters);
