import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Form, FormGroup, Input, InputGroupText, Label } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import {
  I_STATION,
  useCreateStationMutation,
  useUpdateStationMutation,
} from "app/containersAndStations/stationsApiSlice";

import { AlertComponent, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { GlobalVariables, Regex } from "utils";
import { StationStatus } from "utils/enums";
import { errorHandler } from "utils/globalFunctions";
import GlobalStrings from "utils/globalStrings";

import Connectors from "../Connectors/Connectors";
import GetCoordinatesMap from "components/GetCoordinatesMap/GetCoordinatesMap";
import { adminRoutesPath } from "views/admin/routes";

type FormData = {
  name: string;
  power: number;
  status: string;
  address: string;
  identity: string;
  lat: number | "";
  lng: number | "";
};

const AddEditStationForm = (props: { data?: I_STATION; refetch: Function }) => {
  const { id } = useParams();
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const { serverMessage, setServerMessage } = useServerMessage();

  const [createStation, { isLoading }] = useCreateStationMutation();
  const [updateStation, { isLoading: isLoadingUpdate }] = useUpdateStationMutation();

  const [markerPoistion, setMarkerPosition] = useState({
    lat: props?.data?.latitude || 0,
    lng: props?.data?.longitude || 0,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      power: props.data?.power || undefined,
      status: props.data?.status,
      name: props.data?.name || "",
      address: props.data?.address || "",
      identity: props.data?.identity || "",
      lat: props.data?.latitude || "",
      lng: props.data?.longitude || "",
    },
  });

  useEffect(() => {
    if (markerPoistion.lat || markerPoistion.lng) {
      setValue("lat", markerPoistion.lat, { shouldValidate: true });
      setValue("lng", markerPoistion.lng, { shouldValidate: true });
    }
  }, [markerPoistion]);

  useEffect(() => {
    setMarkerPosition({ lat: +watch("lat"), lng: +watch("lng") });
  }, [watch("lat"), watch("lng")]);

  const createStationHandler = async (body: any) => {
    try {
      const response = await createStation(body).unwrap();
      navigate(
        adminRoutesPath.ContainersAndStations.EditStation.replace(":id", response?.data?.id)
      );
      toast.success(GlobalStrings.addElectricityContainer.stationAdded);
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  const updateStationHandler = async (body: any) => {
    try {
      await updateStation(body).unwrap();
      setServerMessage({
        type: "success",
        text: GlobalStrings.addElectricityContainer.stationUpdated,
      });
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  const onSubmit = (data: FormData) => {
    const body = {
      power: +data.power,
      address: data.address,
      latitude: +data.lat,
      longitude: +data.lng,
      name: data.name,
      companyId: user.company.id,
      companyName: user.company.name,
      identity: data.identity,
      status: data.status || StationStatus.ACTIVE,
    };
    if (id) {
      const data = {
        body,
        id,
      };
      updateStationHandler(data);
    } else {
      createStationHandler(body);
    }
  };

  return (
    <section className="card  d-flex flex-column gap-4">
      <h4 className="mb-0">
        {id
          ? GlobalStrings.addElectricityContainer.editStation
          : GlobalStrings.addElectricityContainer.addStation}
      </h4>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <div className="w-100 d-flex flex-column">
            <FormGroup floating className="w-100">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.name}
                    type="text"
                  />
                )}
              />
              <Label for="name">{GlobalStrings.formLabels.name}</Label>
            </FormGroup>

            {props.data && (
              <div className="d-flex gap-2 flex-column flex-md-row">
                <FormGroup floating className="w-100">
                  <Controller
                    name="status"
                    control={control}
                    rules={{
                      required: GlobalStrings.formValidation.fieldsRequired,
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        {...field}
                        invalid={invalid}
                        placeholder={GlobalStrings.addElectricityContainer.selectStationStatus}
                        type="select"
                      >
                        {GlobalVariables.stationStatus.map((item: any) => {
                          return (
                            <option key={item.id} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Input>
                    )}
                  />
                  <Label for="status">
                    {GlobalStrings.addElectricityContainer.selectStationStatus}
                  </Label>
                </FormGroup>
              </div>
            )}

            <FormGroup floating className="w-100">
              <Controller
                name="power"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  pattern: {
                    value: Regex.floatNumber,
                    message: GlobalStrings.formValidation.invalidNumber,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.power}
                    type="text"
                  />
                )}
              />
              <InputGroupText className="input-group-text">
                {GlobalVariables.amountAC}
              </InputGroupText>
              <Label for="power">{GlobalStrings.formLabels.power}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="identity"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.addElectricityContainer.identity}
                    type="text"
                  />
                )}
              />
              <Label for="identity">{GlobalStrings.addElectricityContainer.identity}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="address"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.addElectricityContainer.address}
                    type="text"
                    disabled={props.data?.container?.id !== undefined}
                  />
                )}
              />
              <Label for="address">{GlobalStrings.addElectricityContainer.address}</Label>
            </FormGroup>
            <FormGroup floating className="w-100">
              <Controller
                name="lat"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  pattern: {
                    value: Regex.latitude,
                    message: GlobalStrings.formValidation.invalidLatitude,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.latitude}
                    type="text"
                    disabled={props.data?.container?.id !== undefined}
                  />
                )}
              />
              <Label for="lat">{GlobalStrings.formLabels.latitude}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="lng"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  pattern: {
                    value: Regex.longitude,
                    message: GlobalStrings.formValidation.invalidLongitude,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.longitude}
                    type="text"
                    disabled={props.data?.container?.id !== undefined}
                  />
                )}
              />
              <Label for="lng">{GlobalStrings.formLabels.longitude}</Label>
            </FormGroup>
            {props.data && (
              <Alert color="info">
                {props.data?.container
                  ? GlobalStrings.addElectricityContainer.stationConnectedWithContainer
                  : GlobalStrings.addElectricityContainer.stationNotConnectedWithContainer}
                {"  "}
                {props.data?.container && (
                  <Link
                    to={adminRoutesPath.ContainersAndStations.EditContainer.replaceAll(
                      ":id",
                      props.data?.container?.id.toString()
                    )}
                  >
                    {props.data?.container?.id}
                  </Link>
                )}
              </Alert>
            )}
          </div>
          <div className="map_container">
            <GetCoordinatesMap
              markerPosition={markerPoistion}
              setMarkerPosition={
                props.data?.container?.id === undefined ? setMarkerPosition : undefined
              }
            />
          </div>
        </div>

        <SubmitButton
          isLoading={isLoading || isLoadingUpdate}
          label={id ? GlobalStrings.formLabels.save : GlobalStrings.add}
          className="mt-3"
        />
      </Form>
      <AlertComponent errors={errors} serverMessage={serverMessage} />
      {props.data && (
        <div className="mt-5">
          <Connectors
            connectors={props.data?.connectors}
            companyId={props.data.companyId}
            stationId={props.data.id}
            refetch={props.refetch}
          />
        </div>
      )}
    </section>
  );
};

export default AddEditStationForm;
