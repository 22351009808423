import React from "react";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "app/auth/authSlice";
import { selectMarketplaceIsUsedInApp } from "app/common/commonSlice";
import { useGetCartProductsQuery } from "app/marketplace/cartApiSlice";

import classNames from "classnames";
import { IsLoading, PageHeader } from "components";
import { formatPrice, GlobalStrings } from "utils";
import { Currency } from "utils/enums";

import CartSummary from "./CartSummary/CartSummary";
import ProductsListing from "./ProductsListing/ProductsListing";

import styles from "./Cart.module.scss";

const Cart = () => {
  const user = useSelector(selectCurrentUser);
  const marketplaceIsUsedInApp = useSelector(selectMarketplaceIsUsedInApp);
  const { data, isLoading } = useGetCartProductsQuery(user.id);

  return (
    <section
      className={classNames(styles.cart, {
        [styles.used_for_app]: marketplaceIsUsedInApp,
      })}
    >
      <PageHeader title={GlobalStrings.marketplace.myCart} backRoute="-1" />
      {isLoading ? (
        <IsLoading />
      ) : data?.cart?.companies?.length > 0 ? (
        <>
          {data?.cart?.companies?.map((item: any, index: number) => {
            return (
              <div key={index} className={styles.cart_products_container}>
                <p className={styles.company_name}>
                  <strong>{GlobalStrings.marketplace.productsSellBy}</strong> {item?.name}
                </p>
                <div className={styles.container}>
                  <ProductsListing products={item?.cartItems} />
                  <CartSummary
                    total={item?.total}
                    supplierName={item?.name}
                    supplierId={item?.id}
                  />
                </div>
              </div>
            );
          })}
          <h4>
            {GlobalStrings.marketplace.total}: {formatPrice(data?.cart?.total)} {Currency.RON}
          </h4>
        </>
      ) : (
        <h4>{GlobalStrings.marketplace.noProductsInCart}</h4>
      )}
    </section>
  );
};
export default Cart;
