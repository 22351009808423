import React from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export default function TrafficChartComponent({
  data,
}: {
  data: { name: string; count: number; weather: string }[];
}) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data} width={100}>
        <CartesianGrid strokeDasharray="4" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="count" fill="#dc2949" barSize={40} />
      </BarChart>
    </ResponsiveContainer>
  );
}

function CustomTooltip({ active, payload, label }: any) {
  if (active && payload && payload.length) {
    const { name, count, weather } = payload[0].payload;
    return (
      <div style={{ background: "#fff", border: "1px solid #ccc", padding: "10px" }}>
        <p>{`${name}`}</p>
        <p>{`${label}: ${count}`}</p>
        <p>{`Vremea: ${weather}`}</p>
      </div>
    );
  }

  return null;
}
