import React, { useState } from "react";
import { toast } from "react-toastify";
import { AccordionBody, AccordionHeader, AccordionItem, Alert } from "reactstrap";

import { useUpdateSettingMutation } from "app/settings/settingsApiSlice";

import { SubmitButton } from "components";
import { errorHandler, GlobalStrings, validateJSON } from "utils";

const Accordion = (props: { id: number; name: string; info: string }) => {
  const [updateSetting, { isLoading }] = useUpdateSettingMutation();
  const [value, setValue] = useState(JSON.stringify(props.info, null, 4) || "");
  const [isValid, setIsValid] = useState(true);

  const textareaHandler = (e: any) => {
    setValue(e.target.value);

    if (validateJSON(e.target.value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const data = {
      id: props.id,
      body: {
        name: props.name,
        info: JSON.parse(value),
      },
    };
    try {
      const response = await updateSetting(data).unwrap();
      setValue(JSON.stringify(JSON.parse(response.data.info), null, 4));
      toast.success(GlobalStrings.settings.settingHasBeenUpdated);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  return (
    <AccordionItem className="mb-2">
      <AccordionHeader targetId={props.id.toString()}>{props.name}</AccordionHeader>
      <AccordionBody accordionId={props.id.toString()}>
        <form onSubmit={onSubmit}>
          <textarea rows={20} className="w-100" value={value} onChange={textareaHandler} />

          <Alert color="danger" isOpen={!isValid} className="mt-2">
            {GlobalStrings.settings.wrongJSONFormat}
          </Alert>

          <SubmitButton
            isLoading={isLoading}
            label={GlobalStrings.formLabels.save}
            className="mt-2"
            disabled={!isValid}
          />
        </form>
      </AccordionBody>
    </AccordionItem>
  );
};
export default Accordion;
