import { GlobalStrings } from "utils";
import { DocumentStatus, UserRoles } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_TRANSPORT_TYPE } from "utils/types";

import { User } from "views/admin/Settings/UsersList/UsersList";

import { apiSlice } from "../apiSlice";

type EndUser = User & {
  phone: string;
  reasonForBlocking: string;
  transportTypes: I_TRANSPORT_TYPE;
  numberOfTrips: number;
  totalPaid: number;
  isBlockedForReservation: boolean;
  identityCard?: {
    createdAt?: string;
    updatedAt?: string;
  };
  driverLicence?: {
    createdAt?: string;
    updatedAt?: string;
  };
};

type Documents = {
  identityCard: {
    id: number;
    faceS3Key: string;
    backS3Key: string;
    isFaceValid: boolean;
    isBackValid: boolean;
    expiresAt: string;
    status: DocumentStatus;
    fullName: string;
    updatedAt: string;
  };
  driverLicence: {
    id: number;
    faceS3Key: string;
    backS3Key: string;
    isFaceValid: boolean;
    isBackValid: boolean;
    expiresAt: string;
    status: DocumentStatus;
    fullName: string;
    updatedAt: string;
  };
};

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsersByRoles: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, rolesNames }) => {
        let url = `${process.env.REACT_APP_API_URL}/users/by-roles?`;
        if (rolesNames) {
          url += `rolesNames=[${rolesNames.map((role: string) => `%22${role}%22`)}]&`;
        }
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const users: User[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          const userRolesArray = item.roles.map((role: any) => role.name);
          const userRoles = userRolesArray
            .map((role: UserRoles) => GlobalStrings.userRoles[role])
            .join(", ");
          users.push({
            id: item.id,
            firstName: item?.firstName || NO_VALUE_SYMBOL,
            lastName: item?.lastName || NO_VALUE_SYMBOL,
            email: item?.email || NO_VALUE_SYMBOL,
            roles: userRoles,
            status: item.status,
          });
        });
        return {
          users,
          meta,
        };
      },
    }),
    inviteUser: builder.mutation({
      query: (body: { firstName: string; lastName: string; email: string; role: string }) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/send-invitation`,
        method: "POST",
        body: { ...body },
      }),
    }),
    deleteUser: builder.mutation({
      query: (id: string | number) => ({
        url: `${process.env.REACT_APP_API_URL}/users/${id}`,
        method: "DELETE",
      }),
    }),
    getEndUsers: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, search }) => {
        let url = `${process.env.REACT_APP_API_URL}/users/end-users?`;
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (search) {
          url += `search=true&value=${encodeURIComponent(search)}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const endUsers: EndUser[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          endUsers.push({
            id: item.id,
            firstName: item?.firstName || NO_VALUE_SYMBOL,
            lastName: item?.lastName || NO_VALUE_SYMBOL,
            email: item?.email || NO_VALUE_SYMBOL,
            roles: item.roles,
            phone: item?.phone || NO_VALUE_SYMBOL,
            status: item?.status || NO_VALUE_SYMBOL,
            reasonForBlocking: item?.reasonForBlocking || NO_VALUE_SYMBOL,
            transportTypes: item?.transportTypes || [],
            numberOfTrips: item?.numberOfTrips || NO_VALUE_SYMBOL,
            totalPaid: item?.totalPaid || NO_VALUE_SYMBOL,
            isBlockedForReservation: item?.isBlockedForReservation,
          });
        });

        return {
          endUsers,
          meta,
        };
      },
    }),
    getEndUsersWithDocuments: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, search }) => {
        let url = `${process.env.REACT_APP_API_URL}/users/with-documents?`;
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (search) {
          url += `phone=contains,${encodeURIComponent(search)}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const endUsers: EndUser[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          endUsers.push({
            id: item.id,
            firstName: item?.firstName || NO_VALUE_SYMBOL,
            lastName: item?.lastName || NO_VALUE_SYMBOL,
            email: item?.email || NO_VALUE_SYMBOL,
            roles: item.roles,
            phone: item?.phone || NO_VALUE_SYMBOL,
            status: item?.status || NO_VALUE_SYMBOL,
            reasonForBlocking: item?.reasonForBlocking,
            transportTypes: item?.transportTypes || [],
            numberOfTrips: item?.numberOfTrips || NO_VALUE_SYMBOL,
            totalPaid: item?.totalPaid || NO_VALUE_SYMBOL,
            isBlockedForReservation: item?.isBlockedForReservation,
            driverLicence: {
              createdAt: item?.driverLicence?.createdAt,
              updatedAt: item?.driverLicence?.updatedAt,
            },
            identityCard: {
              createdAt: item?.identityCard?.createdAt,
              updatedAt: item?.identityCard?.updatedAt,
            },
          });
        });

        return {
          endUsers,
          meta,
        };
      },
    }),
    getDocuments: builder.query<Documents, any>({
      query: ({ id }) => {
        let url = `${process.env.REACT_APP_API_URL}/documents/all-of-user/${id}?`;

        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    getEndUser: builder.query<any, any>({
      query: ({ userId }) => {
        const url = `${process.env.REACT_APP_API_URL}/users/${userId}`;
        return url;
      },
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    updateEndUser: builder.mutation<EndUser, Pick<EndUser, "id" | "status" | "reasonForBlocking">>({
      query: ({ id, status, reasonForBlocking }) => ({
        url: `${process.env.REACT_APP_API_URL}/users/${id}`,
        method: "PUT",
        body: {
          status,
          reasonForBlocking,
        },
      }),
    }),
    updateDocument: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `${process.env.REACT_APP_API_URL}/documents/${id}`,
        method: "PUT",
        body,
      }),
    }),
    unblockUserForReservation: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `${process.env.REACT_APP_API_URL}/users/unblock-user-for-charging-station-booking/${id}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetUsersByRolesQuery,
  useInviteUserMutation,
  useDeleteUserMutation,
  useGetEndUsersQuery,
  useGetEndUsersWithDocumentsQuery,
  useGetDocumentsQuery,
  useGetEndUserQuery,
  useUpdateEndUserMutation,
  useUpdateDocumentMutation,
  useUnblockUserForReservationMutation,
} = usersApiSlice;
