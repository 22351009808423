import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuIsOpen: false,
  marketplaceIsUsedInApp: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    closeMenu: (state) => {
      state.menuIsOpen = false;
    },
    toggleMenu: (state) => {
      state.menuIsOpen = !state.menuIsOpen;
    },
    setMarketplaceIsUsedInApp: (state) => {
      state.marketplaceIsUsedInApp = true;
    },
  },
});

export const { closeMenu, toggleMenu, setMarketplaceIsUsedInApp } = commonSlice.actions;

export default commonSlice.reducer;

export const selectMarketplaceIsUsedInApp = (state: any) => state.common.marketplaceIsUsedInApp;
