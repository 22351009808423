import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, ButtonGroup } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import { useGetCitiesQuery } from "app/common/commonApiSlice";
import { useGetMapReportsOverallQuery } from "app/reports/reportsApiSlice";

import { GoogleMap, HeatmapLayer, useLoadScript } from "@react-google-maps/api";
import { IsLoading } from "components";
import { getCenterCoordinates, GlobalStrings } from "utils";
import { MapCenter } from "utils/types";

const containerStyle = {
  width: "100%",
  height: "75vh",
};
const libraries = ["visualization"] as any;

const MapTripsPartner = ({
  startDate,
  endDate,
  partnerId,
  partnerCity,
}: {
  startDate: string;
  endDate: string;
  partnerId: number | string;
  partnerCity?: string;
}) => {
  const google = window.google;
  const user = useSelector(selectCurrentUser);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
    libraries,
  });
  const { data: cities } = useGetCitiesQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const [filter, setFilter] = useState<"startPositions" | "finishPositions">("startPositions");

  const [center, setCenter] = useState<MapCenter>();

  useEffect(() => {
    if (cities?.length) {
      setCenter(getCenterCoordinates(cities, partnerCity || user.company.city));
    }
  }, [cities]);

  const { data, isFetching } = useGetMapReportsOverallQuery({
    startDate,
    endDate,
    partnerId,
  });

  if (!isLoaded || isFetching || !center) {
    return (
      <div>
        <IsLoading />
      </div>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <ButtonGroup className="w-md-25 w-100">
          <Button
            color="danger"
            outline
            onClick={() => {
              setFilter("startPositions");
            }}
            active={filter === "startPositions"}
          >
            {GlobalStrings.analytics.startTrip}
          </Button>
          <Button
            color="danger"
            outline
            onClick={() => {
              setFilter("finishPositions");
            }}
            active={filter === "finishPositions"}
          >
            {GlobalStrings.analytics.endTrip}
          </Button>
        </ButtonGroup>
      </div>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={13}>
        <HeatmapLayer
          key={`${filter}-${Math.random()}`}
          data={
            data?.[filter]?.map((item: any) => {
              return new google.maps.LatLng(item?.lat, item?.lng);
            }) || []
          }
          options={{
            opacity: 0.8,
          }}
        />
      </GoogleMap>
    </>
  );
};

export default MapTripsPartner;
