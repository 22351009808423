import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useGetPublicTransportPartenersIdQuery } from "app/publicTransportParteners/publicTransportPartnersApiSlice";

import { IsLoading, PageHeader, SEO } from "components";
import { errorHandler, GlobalStrings } from "utils";

import PublicTransportPartnersForm from "./PublicTransportPartnersForm";

const PublicTransportPartnersEdit = () => {
  const { id } = useParams();
  const { data, isLoading, refetch, error } = useGetPublicTransportPartenersIdQuery(id, {
    skip: !id,
  });

  useEffect(() => {
    errorHandler(error);
  }, [error]);

  return (
    <section>
      <PageHeader
        title={`${GlobalStrings.PublicTransportParteners.publicTransportEditTitle} - ${data?.name}`}
        backRoute="/public-transport-partners"
      />

      {isLoading ? (
        <IsLoading />
      ) : (
        <>
          <SEO title={data?.name + GlobalStrings.SEO.admin.editPublicTransportPartner} />
          <div className="mt-4">
            <div className="card">
              <h4>{GlobalStrings.PublicTransportParteners.generalInfo}</h4>
              <PublicTransportPartnersForm data={data} id={id} refetch={refetch} />
            </div>
          </div>
        </>
      )}
    </section>
  );
};
export default PublicTransportPartnersEdit;
