import { adminRoutes } from "./admin/routes";
import { chargingPartnerRoutes } from "./chargingPartner/routes";
import { commonRoutes } from "./common/routes";
import { partnerMobileRoutes } from "./partner/mobileRoutes";
import { partnerRoutes } from "./partner/routes";
import { supplierRoutes } from "./supplier/routes";

export {
  adminRoutes,
  partnerRoutes,
  partnerMobileRoutes,
  supplierRoutes,
  commonRoutes,
  chargingPartnerRoutes,
};
