import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";

import classNames from "classnames";

import styles from "./SearchBar.module.scss";

const SearchBar = ({
  searchTerm,
  onSearchChange,
  placeholder,
}: {
  searchTerm: string;
  onSearchChange: (newTerm: string) => void;
  placeholder?: string;
}) => {
  const [searchValue, setSearchValue] = useState(searchTerm);

  useEffect(() => {
    const timerId = setTimeout(() => {
      onSearchChange(searchValue);
    }, 700);

    return () => clearTimeout(timerId);
  }, [searchValue, onSearchChange]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className={styles.search_bar_container}>
      <div className={classNames(styles.search_bar, "card")}>
        <input
          type="search"
          placeholder={placeholder}
          value={searchValue}
          onChange={handleSearchChange}
        />
        <AiOutlineSearch className={styles.search_icon} />
      </div>
    </div>
  );
};

export default SearchBar;
