import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useGetSupplierQuery } from "app/suppliers/suppliersApiSlice";

import { IsLoading, PageHeader, SEO } from "components";
import { errorHandler, GlobalStrings } from "utils";

import ProductsTable from "../ProductsTable/ProductsTable";
import SupplierProductForm from "../SupplierProductForm/SupplierProductForm";
import UsersTable from "../UsersTable/UsersTable";
import { adminRoutesPath } from "views/admin/routes";

const EditSupplierAndProduct = () => {
  const { supplierId } = useParams();
  const { data: supplier, isLoading, refetch, error } = useGetSupplierQuery(supplierId || "");

  useEffect(() => {
    errorHandler(error);
  }, []);

  return (
    <div>
      <section>
        {isLoading ? (
          <IsLoading />
        ) : (
          <>
            <SEO title={supplier?.name + GlobalStrings.SEO.admin.editSupplier} />
            <PageHeader
              title={`${GlobalStrings.productsSuppliers.supplierProducts} - ${supplier?.name}`}
              backRoute="-1"
            />
            <div className="mt-4">
              <div className="card">
                <h4>{GlobalStrings.partners.generalInfo}</h4>
                <SupplierProductForm data={supplier} supplierId={supplierId} refetch={refetch} />
              </div>
            </div>

            {supplierId && (
              <>
                <div className="mt-4">
                  <div className="card">
                    <ProductsTable
                      supplierId={supplierId}
                      pageHeader={{
                        title: GlobalStrings.productsSuppliers.associatedProductsSupplier,
                      }}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="card">
                    <UsersTable
                      supplierId={supplierId}
                      addUserBaseSlug={adminRoutesPath.Suppliers.Services.AddUser.replace(
                        ":supplierId",
                        supplierId.toString()
                      )}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default EditSupplierAndProduct;
