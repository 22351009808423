import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { selectCurrentUser } from "app/auth/authSlice";

import { loadFromLocalStorage, roleValidation } from "utils";

const RequireAuth = () => {
  const user = useSelector(selectCurrentUser);
  const userLocalStorage = loadFromLocalStorage("user");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && !userLocalStorage) {
      navigate("/");
    }
  }, [user]);

  let content = <></>;

  if (user?.roles?.length > 0) {
    if (roleValidation("all", user?.roles)) {
      content = <Outlet />;
    } else {
      content = <Navigate to="/not-authorised" />;
    }
  } else {
    if (user !== null) content = <Navigate to="/" state={{ from: location }} replace />;
  }

  return content;
};
export default RequireAuth;
