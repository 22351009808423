import {
  calculateDuration,
  calculateRemainingCapacity,
  convertToBase64,
  displayPriceForService,
  errorHandler,
  formatPrice,
  generateRandomId,
  getAddressFromCoordinates,
  getCenterCoordinates,
  getDocumentImage,
  getSortDetails,
  loadFromLocalStorage,
  returnFirstErrorMessage,
  roleValidation,
  saveToLocalStorage,
  validateField,
  validateJSON,
} from "./globalFunctions";
import GlobalStrings from "./globalStrings";
import { GlobalVariables } from "./globalVariables";
import { Regex } from "./regex";

export {
  GlobalStrings,
  GlobalVariables,
  Regex,
  validateField,
  returnFirstErrorMessage,
  loadFromLocalStorage,
  saveToLocalStorage,
  getSortDetails,
  errorHandler,
  calculateRemainingCapacity,
  generateRandomId,
  convertToBase64,
  formatPrice,
  roleValidation,
  displayPriceForService,
  validateJSON,
  getCenterCoordinates,
  getDocumentImage,
  getAddressFromCoordinates,
  calculateDuration,
};
