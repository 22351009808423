

import React, { useEffect } from "react";
import { MapContainer, Marker, Polyline, Popup, TileLayer } from "react-leaflet";

import { useGetTrafficQuery } from "app/aicontroller/aicontrollerApiSlice";

import L from "leaflet";
import { GlobalVariables } from "utils";
import { decodePolyline } from "utils/globalFunctions";

import carIcon from "assets/icons/vehicle_car.svg";
import "leaflet/dist/leaflet.css";
import { AIVehicleLocation } from "../AiController";

const options = { color: "#dc2949" };

const colors = ["#0000ff", "#000", "#00aaff", "#525207", "#1a542e", "#07423c", "#a15f08"];

const getColorForVehicle = (imei: string) => {
  const colors = ["#007FFF", "#E0115F", "#7FFF00", "#FFA500", "#8F00FF", "#00FFFF", "#FF00FF"];
  let hash = 0;
  for (let i = 0; i < imei.length; i++) {
    hash = imei.charCodeAt(i) + ((hash << 5) - hash);
  }
  return colors[Math.abs(hash) % colors.length];
};

const getTrafficColor = (speed: number, speedLimit: number) => {
  const ratio = speed / speedLimit;
  if (ratio < 0.5) return "#FF0000"; // rosu trafic mare
  if (ratio < 0.8) return "#FFA500"; // galben traffic moderat
  return "#008000"; // verde fara trafic
};

interface AiControllerMapProps {
  liveLocation: AIVehicleLocation[] | [];
  socketData?: any;
  encodedPolylines: string[];
}

const createCustomIcon = (imei: string) => {
  return L.divIcon({
    className: "custom-div-icon",
    html: `<div style="background-color: ${getColorForVehicle(imei)}; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; border-radius: 50%;">
             <img src="${carIcon}" style="width: 20px; height: 20px;">
           </div>`,
    iconSize: [30, 30],
    iconAnchor: [15, 15],
  });
};


const AiControllerMap: React.FC<AiControllerMapProps> = ({
  liveLocation,
  socketData,
  encodedPolylines,
}) => {
  const { data: trafficData, refetch } = useGetTrafficQuery();
  useEffect(() => {
    if (socketData) {
      refetch(); // This will refetch traffic data whenever new socket data is received
    }
  }, [socketData, refetch]);
  console.log('liveLocation:' + liveLocation)
  return (
    <MapContainer center={GlobalVariables.center} zoom={12} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {trafficData?.map((item, index) => (
        <Polyline
          positions={item.geometry.map((point) => [point.lat, point.lon])}
          pathOptions={options}
          key={index}
        />
      ))}
      {encodedPolylines?.map((item: any, index: number) => {
        return (
          <Polyline
            key={index}
            positions={decodePolyline(item)
              .map(([lat, lng]) => ({
                lat,
                lon: lng,
              }))
              ?.map((point: any) => [point.lat, point.lon])}
            color={colors[index % 7]}
          />
        );
      })}
      {(liveLocation as AIVehicleLocation[]).map((vehicle, index) => (
        <React.Fragment key={index}>
        <Marker position={vehicle.location} icon={createCustomIcon(vehicle.imei)}>
          <Popup>
            IMEI: {vehicle.imei}<br />
            Viteza: {vehicle.speed} km/h<br />
            Viteza Limita: {vehicle.speedLimit} km/h
          </Popup>
        </Marker>
        <Polyline
          positions={[
            [vehicle.segmentStart.lat, vehicle.segmentStart.lon],
            [vehicle.segmentEnd.lat, vehicle.segmentEnd.lon]
          ]}
          color={getTrafficColor(vehicle.speed, vehicle.speedLimit)}
          weight={5}
        />
      </React.Fragment>
      ))}
    </MapContainer>
  );
};

export default React.memo(AiControllerMap);

