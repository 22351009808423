import React, { useState } from "react";
import { GiElectric } from "react-icons/gi";

import { I_STATION } from "app/containersAndStations/stationsApiSlice";

import { GoogleMap, MarkerClustererF, useLoadScript } from "@react-google-maps/api";
import { StatisticItem } from "components";
import { GlobalStrings, GlobalVariables } from "utils";

import StationMarker from "./StationMarker";

const containerStyle = {
  width: "100%",
  height: "75vh",
};

function StationsMap(props: { data: I_STATION[]; numberOfStationsInUse: number }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
  });
  const [active, setActive] = useState();

  return isLoaded ? (
    <>
      <div className="stats d-flex gap-3 flex-column flex-md-row mb-2">
        <div className="w-md-25 w-100">
          <StatisticItem
            icon={<GiElectric size={30} />}
            count={props.numberOfStationsInUse}
            title={GlobalStrings.containersAndStations.numberOfUsedStations}
          />
        </div>
      </div>
      <GoogleMap mapContainerStyle={containerStyle} center={GlobalVariables.center} zoom={13}>
        <MarkerClustererF averageCenter={false} maxZoom={20} gridSize={50} minimumClusterSize={2}>
          {(clusterer) => {
            return (
              <>
                {props.data?.map((item, index) => {
                  return (
                    <StationMarker
                      clusterer={clusterer}
                      item={item}
                      key={index}
                      index={index}
                      active={active}
                      setActive={setActive}
                    />
                  );
                })}
              </>
            );
          }}
        </MarkerClustererF>
      </GoogleMap>
    </>
  ) : (
    <>Loading....</>
  );
}

export default React.memo(StationsMap);
