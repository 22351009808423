import React from "react";

import { ForgotPasswordForm } from "components";

const ForgotPassword = () => {
  return (
    <section className="d-flex align-items-center justify-content-center min-vh-100 py-5">
      <div className="form-card">
        <ForgotPasswordForm />
      </div>
    </section>
  );
};
export default ForgotPassword;
