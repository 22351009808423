import AddEditVehicle from "./AddEditVehicle/AddEditVehicle";
import AppServicesListing from "./AppServiceListing/AppServicesListing";
import AppServices from "./AppServices/AppServices";
import Cart from "./Cart/Cart";
import CartCheckout from "./Cart/CartCheckout";
import MarketplacePartners from "./MarketplacePartner/MarketplacePartner";
import MarketplacePartnerProduct from "./MarketplacePartnerProduct/MarketplacePartnerProduct";
import Orders from "./Orders/Orders";
import PartnerAccounts from "./PartnerAccounts/PartnerAccounts";
import PartnerDashboard from "./PartnerDashboard/PartnerDashboard";
import RequestOffer from "./RequestOffer/RequestOffer";
import Vehicles from "./Vehicles/Vehicles";
import Juicers from "views/partner/ServicesPartner/Juicers/Juicers";
import ServicesPartners from "views/partner/ServicesPartner/ServicesPartners";

export {
  PartnerDashboard,
  Vehicles,
  AddEditVehicle,
  MarketplacePartners,
  MarketplacePartnerProduct,
  Cart,
  CartCheckout,
  Orders,
  ServicesPartners,
  Juicers,
  RequestOffer,
  PartnerAccounts,
  AppServices,
  AppServicesListing,
};
