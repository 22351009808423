import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { useAddRequestOfferMutation } from "app/requestOffer/requestOfferApiSlice";

import { AlertComponent, CitiesSelect, PageHeader, SubmitButton, WYSIWYGEditor } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { stripHtml } from "string-strip-html";
import { errorHandler, GlobalStrings, GlobalVariables } from "utils";
import { TransportType } from "utils/enums";

const RequestOffer = () => {
  const [addRequestOffer, { isLoading }] = useAddRequestOfferMutation();
  const { serverMessage, setServerMessage } = useServerMessage();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      vehicleType: "",
      city: "",
      serviceType: "",
      description: "",
    },
  });

  const onSubmit = async (data: any) => {
    try {
      await addRequestOffer({
        ...data,
        city: data.city.value,
      }).unwrap();
      setServerMessage({
        type: "success",
        text: GlobalStrings.requestOffer.requestHasBeenSent,
      });
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  return (
    <section>
      <div className="mb-4">
        <PageHeader title={GlobalStrings.requestOffer.title} />
      </div>

      <div className="card mt-4 mb-5">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex gap-2 flex-column flex-md-row">
            <div className="w-100">
              <h5>{GlobalStrings.formLabels.electricVehicleType}</h5>
              <FormGroup check>
                <Controller
                  name="vehicleType"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input type="radio" {...field} invalid={invalid} value={TransportType.CAR} />
                  )}
                />
                <Label check>{GlobalStrings.vehicleTypes.car}</Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  name="vehicleType"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      type="radio"
                      {...field}
                      invalid={invalid}
                      value={TransportType.SCOOTER}
                    />
                  )}
                />
                <Label check>{GlobalStrings.vehicleTypes.scooter}</Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  name="vehicleType"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      type="radio"
                      {...field}
                      invalid={invalid}
                      value={TransportType.BICYCLE}
                    />
                  )}
                />
                <Label check>{GlobalStrings.vehicleTypes.bicycle}</Label>
              </FormGroup>
            </div>
            <div className="w-100">
              <FormGroup floating className="w-100">
                <CitiesSelect control={control} name="city" />
              </FormGroup>
              <FormGroup floating className="w-100">
                <Controller
                  name="serviceType"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      placeholder={GlobalStrings.formLabels.city}
                      type="select"
                    >
                      <option value="">-</option>
                      {GlobalVariables.serviceType.map((item: any) => {
                        return (
                          <option key={item.id} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </Input>
                  )}
                />
                <Label for="serviceType">{GlobalStrings.formLabels.serviceType}</Label>
              </FormGroup>
            </div>
          </div>

          <FormGroup floating>
            <Controller
              render={({ field }) => {
                return (
                  <WYSIWYGEditor value={field.value} onChange={field.onChange} toolbarHidden />
                );
              }}
              name="description"
              control={control}
              defaultValue=""
              rules={{
                validate: {
                  required: (v) =>
                    (v && stripHtml(v).result.length > 0) ||
                    GlobalStrings.formValidation.fieldsRequired,
                },
              }}
            />
            <Label for="description">{GlobalStrings.formLabels.description}</Label>
          </FormGroup>

          <SubmitButton
            isLoading={isLoading}
            label={GlobalStrings.formLabels.sendOffer}
            className="mt-3"
          />
        </Form>
        <AlertComponent errors={errors} serverMessage={serverMessage} />
      </div>
    </section>
  );
};

export default RequestOffer;
