import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import parse from "html-react-parser";

import styles from "./ConfirmationPopup.module.scss";

const ConfirmationPopup = (props: {
  modalIsOpen: boolean;
  toggleModal: Function;
  title?: string;
  body: string;
  confirmText: string;
  cancelText?: string;
  confirmFunction: Function;
  textareaValue?: string;
  setTextareaValue?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const handleConfirm = () => {
    props.confirmFunction();
  };

  const handleClose = () => {
    props.toggleModal();
  };

  return (
    <Modal isOpen={props.modalIsOpen} toggle={() => props.toggleModal()} centered>
      <ModalHeader toggle={() => props.toggleModal()}>{props.title}</ModalHeader>
      <ModalBody>
        {parse(props.body)}
        {props.setTextareaValue && (
          <textarea
            className={styles.textarea}
            value={props.textareaValue}
            onChange={(e) =>
              props.setTextareaValue ? props.setTextareaValue(e.target.value) : undefined
            }
            rows={4}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleConfirm}>
          {props.confirmText}
        </Button>{" "}
        {props.cancelText && (
          <Button color="secondary" onClick={handleClose}>
            {props.cancelText}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationPopup;
