import React from "react";

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { GlobalVariables } from "utils";

const containerStyle = {
  width: "100%",
  height: "75vh",
};

const marker1 = {
  lat: 47.144,
  lng: 27.60516,
};

const marker2 = {
  lat: 47.15844,
  lng: 27.61516,
};

function MyComponent() {
  const { isLoaded } = useJsApiLoader({
    id: "",
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
  });

  const [, /* map */ setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(GlobalVariables.center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={GlobalVariables.center}
      zoom={7}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker position={marker1} />
      <Marker position={marker2} />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
