import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "app/auth/authSlice";
import { useGetRequestsOffersQuery } from "app/requestOffer/requestOfferApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { PageHeader, TableBadge, TableComponent } from "components";
import parse from "html-react-parser";
import moment from "moment";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { I_HISTORY_SERVICE } from "utils/types";

const RequestsOffers = (props: { type: "partner" | "supplier" }) => {
  const user = useSelector(selectCurrentUser);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, isFetching, error } = useGetRequestsOffersQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
    supplierIds: props.type === "supplier" ? user.company.id : undefined,
    partnerId: props.type === "partner" ? user.company.id : undefined,
  });

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  useEffect(() => {
    table.toggleAllColumnsVisible(false);
  }, [props.type]);

  const columnHelper = createColumnHelper<I_HISTORY_SERVICE>();

  const columns = [
    columnHelper.accessor((row: any) => row.data, {
      id: "createdAt",
      cell: (info) => (
        <TableBadge
          text={moment(info.getValue()).format("DD.MM.YYYY, HH:mm").toString()}
          color="green"
        />
      ),
      header: () => <span>{GlobalStrings.historyServices.data}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row, {
      id: props.type === "supplier" ? "sentByCompany" : "vehicleType",
      cell: (info) => (
        <p>
          {props.type === "supplier"
            ? info.getValue().sentByCompany
            : GlobalStrings.requestOffer.suppliers +
              " - " +
              GlobalStrings.vehicleTypes[info.getValue().vehicleType]}
        </p>
      ),
      header: () => (
        <span>
          {props.type === "supplier"
            ? GlobalStrings.historyServices.sentBy
            : GlobalStrings.historyServices.sendTo}
        </span>
      ),
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
      enableHiding: false,
    }),

    columnHelper.accessor((row) => row, {
      id: "emailAndPhone",
      cell: (info) => {
        return (
          <div>
            <p>
              {GlobalStrings.formLabels.phone}: {info.getValue().phone}
            </p>
            <p>
              {GlobalStrings.formLabels.email}: {info.getValue().email}
            </p>
          </div>
        );
      },
      header: () => <span>{GlobalStrings.historyServices.contactData}</span>,
      enableSorting: false,
      size: 1,
      minSize: 30,
      enableHiding: props.type === "partner",
    }),

    columnHelper.accessor((row) => row.serviceType, {
      id: "serviceType",
      cell: (info) => <p>{GlobalStrings.servicesTypes[info.getValue()]}</p>,
      header: () => <span>{GlobalStrings.historyServices.serviceType}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
      enableHiding: false,
    }),
    columnHelper.accessor((row: any) => row.description, {
      id: "description",
      cell: (info) => <>{parse(info.getValue())}</>,
      header: () => <span>{GlobalStrings.historyServices.description}</span>,
      enableSorting: false,
      size: 1,
      minSize: 25,
      enableHiding: false,
    }),
  ];

  const table = useReactTable({
    data: data?.requestOffers || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
      columnVisibility,
    },
    pageCount: data?.meta?.numberOfPages,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
  });

  return (
    <section>
      <PageHeader
        title={
          props.type === "supplier"
            ? GlobalStrings.historyServices.historyServicesTitle
            : GlobalStrings.menuPartners.requestOfferHistory
        }
      />

      <TableComponent
        table={table}
        tableHasData={data?.requestOffers?.length > 0}
        isLoading={isFetching}
      />
    </section>
  );
};
export default RequestsOffers;
