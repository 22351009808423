import React, { MouseEventHandler } from "react";
import { ReactSVG } from "react-svg";
import { UncontrolledTooltip } from "reactstrap";

import { generateRandomId, GlobalStrings } from "utils";

import createIcon from "assets/icons/add_icon.svg";
import creditCardIcon from "assets/icons/card_icon.svg";
import deleteIcon from "assets/icons/delete2_icon.svg";
import downloadIcon from "assets/icons/download_icon.svg";
import editIcon from "assets/icons/edit_icon.svg";
import infoIcon from "assets/icons/info_icon.svg";
import lockedIcon from "assets/icons/locked_icon.svg";
import startIcon from "assets/icons/start_icon.svg";
import stopIcon from "assets/icons/stop_icon.svg";
import unlockedIcon from "assets/icons/unlocked_icon.svg";

const id = generateRandomId();

const TableIcon = (props: {
  type:
    | "start"
    | "stop"
    | "edit"
    | "delete"
    | "download"
    | "create"
    | "lock"
    | "unlock"
    | "payment"
    | "info"
    | "pay";
  onClickAction?: MouseEventHandler;
  label?: string;
  id?: string;
}) => {
  let image;
  switch (props.type) {
    case "start":
      image = startIcon;
      break;
    case "stop":
      image = stopIcon;
      break;
    case "edit":
      image = editIcon;
      break;
    case "delete":
      image = deleteIcon;
      break;
    case "download":
      image = downloadIcon;
      break;
    case "create":
      image = createIcon;
      break;
    case "lock":
      image = lockedIcon;
      break;
    case "unlock":
      image = unlockedIcon;
      break;
    case "payment":
      image = creditCardIcon;
      break;
    case "pay":
      image = creditCardIcon;
      break;
    case "info":
      image = infoIcon;
      break;
    default:
      image = editIcon;
  }
  return (
    <>
      <ReactSVG
        src={image}
        className={props.type}
        id={props?.id || props.type + id}
        onClick={props?.onClickAction}
      />
      <UncontrolledTooltip placement="bottom" target={props?.id || props.type + id}>
        {props?.label || GlobalStrings[props.type]}
      </UncontrolledTooltip>
    </>
  );
};

export default TableIcon;
