import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { useLogoutMutation } from "app/auth/authApiSlice";
import { logOut } from "app/auth/authSlice";

import { errorHandler, GlobalStrings } from "utils";

function LogoutModal(props: { modalIsOpen: boolean; setModalIsOpen: Function }) {
  const toggle = () => props.setModalIsOpen((prev: boolean) => !prev);
  const [logoutBackend] = useLogoutMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Modal isOpen={props.modalIsOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{GlobalStrings.auth.logOutTitle}</ModalHeader>
      <ModalBody>{GlobalStrings.auth.logOutBody}</ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            try {
              logoutBackend();
            } catch (error) {
              errorHandler(error);
            }
            dispatch(logOut());
            navigate("/");
          }}
        >
          {GlobalStrings.yes}
        </Button>
        <Button color="secondary" onClick={toggle}>
          {GlobalStrings.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default LogoutModal;
