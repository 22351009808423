import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useGetVehicleQuery } from "app/vehicles/vehiclesApiSlice";

import { IsLoading, PageHeader } from "components";
import { errorHandler, GlobalStrings } from "utils";

import AddEditVehicleForm from "./AddEditVehicleForm/AddEditVehicleForm";

const AddEditVehicle = () => {
  const { vehicleId } = useParams();
  const { data, isLoading, error } = useGetVehicleQuery(vehicleId, {
    skip: !vehicleId,
  });
  useEffect(() => {
    errorHandler(error);
  }, [error]);

  return (
    <section>
      <PageHeader
        title={vehicleId ? GlobalStrings.partners.editVehicle : GlobalStrings.partners.addVehicle}
        backRoute="/vehicles"
      />
      {isLoading ? <IsLoading /> : <AddEditVehicleForm data={data} />}
    </section>
  );
};

export default AddEditVehicle;
