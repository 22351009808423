import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import {
  useGetChargesQuery,
  useGetJuicersQuery,
  usePayChargeConfirmationMutation,
} from "app/partners/partnersApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Battery, TableBadge, TableComponent } from "components";
import moment from "moment";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

interface I_JUICER_BATTERY_LEVEL {
  id: number | string;
  juicerName: string;
  batteryLevelAtEnd: number;
  batteryLevelAtStart: number;
  status: string;
  startDate: string;
  endDate: string;
  vehicle: {
    qrCode: string;
  };
}

const JuicersServicesBatteryListing = () => {
  const { partnerId } = useParams();
  const user = useSelector(selectCurrentUser);
  const [chargeId, setChargeId] = useState<number | string | undefined>();
  const columnHelper = createColumnHelper<I_JUICER_BATTERY_LEVEL>();
  const togglePayModal = () => setPayModal((prev) => !prev);
  const [payChargeConfirmation] = usePayChargeConfirmationMutation();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const [sorting, setSorting] = useState<SortingState>([{ id: "createdAt", desc: true }]);
  const [columnVisibility, setColumnVisibility] = useState({});

  const {
    isFetching,
    data,
    error,
    refetch: refectCharges,
  } = useGetChargesQuery({
    companyId: partnerId || user.company.id,
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
  });
  const { refetch: refetchJuicers } = useGetJuicersQuery(partnerId || user.company.id, {
    refetchOnMountOrArgChange: false,
  });
  const [payModal, setPayModal] = useState(false);

  useEffect(() => {
    table.toggleAllColumnsVisible(false);
  }, [user]);

  const payConfirmationHandler = async () => {
    try {
      await payChargeConfirmation({
        chargeId,
      });
      refectCharges();
      refetchJuicers();
      setPayModal(false);
      toast.success(GlobalStrings.activeServices.payConfirmationSuccesfully);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  const columns = [
    columnHelper.accessor((row) => row.juicerName, {
      id: "name",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.juicer}</span>,
      enableSorting: false,
      size: 1,
      minSize: 20,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.vehicle.qrCode, {
      id: "qrCode",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.qrCode}</span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.startDate, {
      id: "startDate",
      cell: (info) => (
        <TableBadge text={moment(info.getValue()).format("DD.MM.YYYY, HH:mm:ss")} color="green" />
      ),
      header: () => <span>{GlobalStrings.partners.startHour}</span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.endDate, {
      id: "endDate",
      cell: (info) => (
        <TableBadge text={moment(info.getValue()).format("DD.MM.YYYY, HH:mm:ss")} color="red" />
      ),
      header: () => <span>{GlobalStrings.partners.finishHour}</span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.batteryLevelAtStart, {
      id: "batteryLevelAtStart",
      cell: (info) => (
        <>{info.getValue() === null ? NO_VALUE_SYMBOL : <Battery level={info.getValue()} />}</>
      ),
      header: () => <span>{GlobalStrings.formLabels.batteryLevelAtStart}</span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.batteryLevelAtEnd, {
      id: "batteryLevelAtEnd",
      cell: (info) => (
        <>{info.getValue() === null ? NO_VALUE_SYMBOL : <Battery level={info.getValue()} />}</>
      ),
      header: () => <span>{GlobalStrings.formLabels.batteryLevelAtEnd}</span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "status",
      maxSize: 10,
      cell: (info) =>
        info.getValue().status === "paid" ? (
          <div className="d-flex justify-content-center gap-2">
            <div className="d-flex justify-content-center gap-2">
              <Button className="btn btn-secondary" size="sm" disabled>
                {GlobalStrings.formLabels.alreadyPaid}
              </Button>
            </div>
          </div>
        ) : info.getValue().status === "pending" ? (
          <div className="d-flex justify-content-center gap-2">
            <Button
              className="btn btn-primary"
              size="sm"
              onClick={() => {
                setChargeId(info.getValue().id);
                setPayModal(true);
              }}
            >
              {GlobalStrings.formLabels.paid}
            </Button>
          </div>
        ) : info.getValue().status === "suspended" ? (
          <div className="d-flex justify-content-center gap-2">
            <div className="d-flex justify-content-center gap-2">
              <Button className="btn btn-secondary" size="sm" disabled>
                {GlobalStrings.formLabels.suspended}
              </Button>
            </div>
          </div>
        ) : (
          <></>
        ),
      header: () => <>{GlobalStrings.payment}</>,
      enableSorting: false,
      enableHiding: false,
    }),
  ];

  const table = useReactTable({
    data: data?.data?.pageData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
      columnVisibility,
    },
    pageCount:
      data?.data?.numberOfPages || Math.round(data?.data?.totalRows / data?.data?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
  });

  return (
    <section>
      <TableComponent
        table={table}
        tableHasData={data?.data?.pageData?.length > 0}
        isLoading={isFetching}
      />
      <Modal isOpen={payModal} toggle={togglePayModal} centered>
        <ModalHeader toggle={togglePayModal}>{GlobalStrings.areYouSure}</ModalHeader>
        <ModalFooter>
          <Button color="primary" onClick={payConfirmationHandler}>
            {GlobalStrings.yes}
          </Button>
          <Button color="secondary" onClick={togglePayModal}>
            {GlobalStrings.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    </section>
  );
};

export default JuicersServicesBatteryListing;
