import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { useGetCitiesQuery } from "app/common/commonApiSlice";

import classNames from "classnames";
import { AlertComponent, CitiesSelect, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { errorHandler, GlobalStrings, GlobalVariables, Regex } from "utils";

import { useUpdatePublicTransportPartnersMutation } from "../../../app/publicTransportParteners/publicTransportPartnersApiSlice";

type I_PUBLIC_TRANSPORT_FORM = {
  name: string;
  phone: string;
  email: string;
  city: any;
  publicTransportVehicles: string;
  address: string;
  type: string;
  id?: string | number;
};

const animatedComponents = makeAnimated();

const PublicTransportPartnersForm = (props: {
  data: I_PUBLIC_TRANSPORT_FORM;
  id: string | undefined;
  refetch: Function;
}) => {
  const [formData, setFormData] = useState<I_PUBLIC_TRANSPORT_FORM>(
    props?.data || ({} as I_PUBLIC_TRANSPORT_FORM)
  );
  const { data: cities } = useGetCitiesQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  useEffect(() => {
    setFormData(props?.data);
  }, [props?.data]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: formData?.name || "",
      email: formData?.email || "",
      phone: formData?.phone || "",
      city: formData?.city || "",
      publicTransportVehicles: formData?.publicTransportVehicles || "",
      address: formData?.address || "",
      type: formData?.type || "",
    },
  });

  useEffect(() => {
    if (cities)
      setValue(
        "city",
        cities?.find((item: any) => item.value === props.data?.city)
      );
  }, [props.data?.city, cities]);

  const [isValueSelected, setValueSelected] = useState(formData?.publicTransportVehicles !== "");

  const [updatePublicTransportPartners, { isLoading }] = useUpdatePublicTransportPartnersMutation();
  const { serverMessage, setServerMessage } = useServerMessage();

  const onSubmit = async (data: I_PUBLIC_TRANSPORT_FORM) => {
    try {
      const payload = {
        body: {
          ...data,
          city: data.city.value,
        },
        id: props?.data?.id,
      };

      await updatePublicTransportPartners(payload).unwrap();
      setServerMessage({
        type: "success",
        text: GlobalStrings.PublicTransportParteners.publicTransportPartnerWasSaved,
      });

      props.refetch();
    } catch (error) {
      errorHandler(error);

      setServerMessage({
        type: "danger",
        text: GlobalStrings.partners.savingError,
      });
    }
  };

  return (
    <section className="d-flex flex-column gap-5">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="name"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.companyName}
                  type="text"
                />
              )}
            />
            <Label for="name">{GlobalStrings.formLabels.companyName}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="address"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.address}
                  type="text"
                />
              )}
            />
            <Label for="address">{GlobalStrings.formLabels.address}</Label>
          </FormGroup>
        </div>

        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="phone"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.phone,
                  message: GlobalStrings.formValidation.invalidPhone,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.phoneNumber}
                  type="text"
                />
              )}
            />
            <Label for="phone">{GlobalStrings.formLabels.phoneNumber}</Label>
          </FormGroup>
          <FormGroup floating className="w-100">
            <Controller
              name="email"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.email,
                  message: GlobalStrings.formValidation.invalidEmail,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.email}
                  type="text"
                />
              )}
            />
            <Label for="email">{GlobalStrings.formLabels.email}</Label>
          </FormGroup>
        </div>

        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <CitiesSelect control={control} name="city" />
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="publicTransportVehicles"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field }) => (
                <Select
                  className={classNames("react_select", {
                    invalid: errors.publicTransportVehicles,
                  })}
                  name="publicTransportVehicles"
                  placeholder=""
                  value={GlobalVariables.publicTransportVehicles.filter((option) =>
                    field.value.split(", ").includes(option.value)
                  )}
                  onChange={(selected) => {
                    const actualSelected = Array.isArray(selected) ? selected[0] : selected;
                    const value = actualSelected ? actualSelected.value : null;
                    if (value) {
                      field.onChange(value);
                      setValueSelected(value !== "");
                    }
                  }}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={GlobalVariables.publicTransportVehicles}
                />
              )}
            />
            {!isValueSelected && (
              <Label for="publicTransportVehicles">
                {GlobalStrings.formLabels.publicTransportVehicles}
              </Label>
            )}
          </FormGroup>
        </div>

        <SubmitButton isLoading={isLoading} label={GlobalStrings.formLabels.save} />
        <AlertComponent errors={errors} serverMessage={serverMessage} />
      </Form>
    </section>
  );
};

export default PublicTransportPartnersForm;
