import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import { Button } from "reactstrap";

import { useStartVehicleMutation, useStopVehicleMutation } from "app/vehicles/vehiclesApiSlice";

import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { Battery, TableBadge } from "components";
import { errorHandler, GlobalStrings } from "utils";
import { VehicleStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_VEHICLE } from "utils/types";

import bikePin from "assets/icons/bike_pin.svg";
import carPin from "assets/icons/car_pin.svg";
import scooterPin from "assets/icons/scooter_pin.svg";
import stationPin from "assets/icons/station_pin.svg";
import googleMapsImage from "assets/images/google_maps.svg";
import yellowPin from "assets/images/recharge_station_yellow.svg";
import { partnerRoutesPath } from "views/partner/routes";

const PartnerVehiclesMarker = (props: {
  clusterer: any;
  item: any;
  active: I_VEHICLE | undefined;
  setActive: any;
}) => {
  const google = window.google;
  const [MVCObject, setMVCObject] = useState(undefined);
  const handleOnLoad = useCallback((marker: any) => {
    setMVCObject(marker);
  }, []);

  const [startVehicle, { isLoading: isLoadingStart }] = useStartVehicleMutation();
  const [stopVehicle, { isLoading: isLoadingStop }] = useStopVehicleMutation();

  const startVehicleHandler = async () => {
    try {
      const response = await startVehicle(props.active?.id).unwrap();
      toast.success(GlobalStrings.partners.vehicleStarted);
      props.setActive(response.data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const stopVehicleHandler = async () => {
    try {
      const response = await stopVehicle(props.active?.id).unwrap();
      toast.success(GlobalStrings.partners.vehicleStoped);
      props.setActive(response.data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const [image, setImage] = useState("");
  useEffect(() => {
    let image;
    switch (true) {
      case props.item?.name !== undefined && props.item?.locationType !== undefined:
        image = stationPin;
        break;
      case props.item?.type && props.item?.type === "car":
        image = carPin;
        break;
      case props.item?.type && props.item?.type === "bicycle":
        image = bikePin;
        break;
      case props.item?.type && props.item?.type === "scooter":
        image = scooterPin;
        break;
      default:
        image = yellowPin;
    }
    setImage(image);
  }, [props.item]);

  return (
    <>
      {image && (
        <MarkerF
          onLoad={handleOnLoad}
          icon={{
            url: image,
            scaledSize: new google.maps.Size(50, 50),
          }}
          position={{
            lat: parseFloat(props.item?.position?.latitude) || parseFloat(props.item?.latitude),
            lng: parseFloat(props.item?.position?.longitude) || parseFloat(props.item?.longitude),
          }}
          clusterer={props.clusterer}
          clickable
          onClick={() => props.setActive(props.item)}
        >
          {props.active && props.active?.id === props.item?.id && (
            <InfoWindowF
              anchor={MVCObject}
              onCloseClick={() => {
                setMVCObject(undefined);
                props.setActive(undefined);
              }}
              position={{
                lat: +props.item.lat,
                lng: +props.item.lng,
              }}
            >
              <div className="map_pin">
                <h4>{GlobalStrings.vehicleTypes[props.active.type]}</h4>
                <div className="maps_container">
                  <p>#{props.active?.qrCode}</p>
                  <Link
                    to={`https://www.google.com/maps/dir/?api=1&destination=${props.active.position.latitude},${props.active.position.longitude}`}
                    title={GlobalStrings.dashboard.showRoadToVehicle}
                    target="_blank"
                  >
                    <ReactSVG src={googleMapsImage} />
                  </Link>
                </div>
                <div className="mb-3">
                  {
                    <TableBadge
                      text={"Status: " + GlobalStrings.vehicleStatus?.[props.active.status]}
                      color={
                        props.active.status === VehicleStatus.ACTIVE
                          ? "green"
                          : props.active.status === VehicleStatus.IN_USE
                          ? "orange"
                          : "red"
                      }
                    />
                  }
                </div>

                <p>
                  <span>{GlobalStrings.formLabels.averageTravelTime}</span>
                  <span>{props.active?.averageTravelTime || NO_VALUE_SYMBOL}</span>
                </p>
                <p>
                  <span>{GlobalStrings.formLabels.averageTripsPerDay}</span>
                  <span>{props.active?.averageTripsPerDay || NO_VALUE_SYMBOL}</span>
                </p>
                <p>
                  <span>{GlobalStrings.formLabels.tripsSinceListCharge}</span>
                  <span>{props.active?.tripsSinceLastCharge || NO_VALUE_SYMBOL}</span>
                </p>
                <div className="d-flex gap-2 align-items-center justify-content-between">
                  <Link
                    to={partnerRoutesPath.Vehicles.Info.replace(
                      ":vehicleId",
                      props.active.id.toString()
                    )}
                    className="mb-3 d-block"
                  >
                    {GlobalStrings.partners.vehicleDetails}
                  </Link>
                </div>
                <div className="d-flex gap-1 align-items-center">
                  {props.active.status === VehicleStatus.ACTIVE && (
                    <Button
                      color="success"
                      outline
                      size="sm"
                      onClick={startVehicleHandler}
                      disabled={isLoadingStart}
                    >
                      {GlobalStrings.start}
                    </Button>
                  )}
                  {props.active.status === VehicleStatus.IN_USE && (
                    <Button
                      color="success"
                      outline
                      size="sm"
                      onClick={stopVehicleHandler}
                      disabled={isLoadingStop}
                    >
                      {GlobalStrings.stop}
                    </Button>
                  )}
                  <Battery level={props.active?.batteryLevel} />
                </div>
              </div>
            </InfoWindowF>
          )}
        </MarkerF>
      )}
    </>
  );
};

export default PartnerVehiclesMarker;
