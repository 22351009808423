// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Juicers_tablesContainer__zwc0Z {
  display: flex;
  gap: 40px;
  width: 100%;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/views/partner/ServicesPartner/Juicers/Juicers.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,WAAA;EACA,sBAAA;AACF","sourcesContent":[".tablesContainer {\n  display: flex;\n  gap: 40px;\n  width: 100%;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablesContainer": `Juicers_tablesContainer__zwc0Z`
};
export default ___CSS_LOADER_EXPORT___;
