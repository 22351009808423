import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";

import {
  useDeleteActiveProductsMutation,
  useGetActiveProductsQuery,
} from "app/products/activeProductsApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ConfirmationPopup, PageHeader, TableBadge, TableComponent, TableIcon } from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { ProductAcceptanceStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_ACTIVE_PRODUCTS } from "utils/types";

const ProductsTable = ({
  supplierId,
  pageHeader,
}: {
  supplierId: string | number;
  pageHeader?: {
    title: string;
  };
}) => {
  const [deleteActiveProducts] = useDeleteActiveProductsMutation();
  const columnHelper = createColumnHelper<I_ACTIVE_PRODUCTS>();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeProductsToDelete, setActiveProductsToDelete] = useState<any>();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, isFetching, refetch } = useGetActiveProductsQuery({
    pageIndex,
    pageSize,
    companyId: supplierId,
    sort: getSortDetails(sorting[0]),
  });

  const toggleModal = () => setModalIsOpen((prev) => !prev);

  const deleteActiveProductsHandler = async () => {
    try {
      await deleteActiveProducts(activeProductsToDelete.id).unwrap();
      toggleModal();
      refetch();
      toast.success(GlobalStrings.activeProducts.productDeleted);
    } catch (error) {
      errorHandler(error);
    }
  };

  const columns = [
    columnHelper.accessor((row: any) => row.name, {
      id: "name",
      cell: (info) => <p>{info.getValue() || NO_VALUE_SYMBOL}</p>,
      header: () => <span>{GlobalStrings.activeProducts.productName}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 30,
    }),
    columnHelper.accessor((row: any) => row.categories, {
      id: "categories",
      cell: (info) => (
        <p>
          {info.getValue()?.map((item: any, index: number) => {
            return `${index > 0 ? ", " : ""}` + item.name;
          }) || NO_VALUE_SYMBOL}
        </p>
      ),
      header: () => <span>{GlobalStrings.activeProducts.category}</span>,
      enableSorting: false,
      size: 1,
      minSize: 30,
    }),
    columnHelper.accessor((row: any) => row.subCategory, {
      id: "subCategory",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.subcategory}</span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row: any) => row.inStock, {
      id: "inStock",
      cell: (info) => <p>{info.getValue() ? GlobalStrings.yes : GlobalStrings.no}</p>,
      header: () => <span>{GlobalStrings.activeProducts.inStock}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row: any) => row.acceptanceStatus, {
      id: "acceptanceStatus",
      cell: (info) => (
        <TableBadge
          text={GlobalStrings.productStatus[info.getValue() as ProductAcceptanceStatus] || "N/A"}
          color={
            info.getValue() === "pending"
              ? "orange"
              : info.getValue() === "accepted"
              ? "green"
              : "red"
          }
        />
      ),
      header: () => <span>{GlobalStrings.formLabels.status}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row: any) => row.price, {
      id: "price",
      cell: (info) => <p>{info.getValue() || NO_VALUE_SYMBOL}</p>,
      header: () => <span>{GlobalStrings.activeProducts.price}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 5,
    }),

    columnHelper.accessor((row: any) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <TableIcon
            type="delete"
            onClickAction={() => {
              toggleModal();
              setActiveProductsToDelete(info.getValue());
            }}
          />
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
    }),
  ];

  const table = useReactTable({
    data: data?.activeProducts || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <section>
      {pageHeader && <PageHeader title={pageHeader.title} />}

      <TableComponent
        table={table}
        tableHasData={data?.activeProducts?.length > 0}
        isLoading={isFetching}
      />

      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        title={`${GlobalStrings.activeProducts.deleteProduct} - ${activeProductsToDelete?.name}`}
        body={GlobalStrings.activeProducts.deleteProductModalBody}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deleteActiveProductsHandler}
      />
    </section>
  );
};
export default ProductsTable;
