import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectCurrentToken } from "app/auth/authSlice";
import { useGetDocumentsQuery } from "app/users/usersApiSlice";

import { IsLoading, PageHeader } from "components";
import { getDocumentImage, GlobalStrings } from "utils";

import { adminRoutesPath } from "../routes";
import styles from "./Document.module.scss";
import DriverLicence from "./DriverLicence";
import IdentityCard from "./IdentityCard";

const Document = () => {
  const token = useSelector(selectCurrentToken);

  const { id } = useParams();
  const [images, setImages] = useState<any>({
    identityCardFace: "",
    identityCardBack: "",
    driverLicenceFace: "",
    driverLicenceBack: "",
  });

  const { data: documents, isFetching, refetch } = useGetDocumentsQuery({ id });

  useEffect(() => {
    if (token) {
      const fetchImages = async () => {
        const identityCardFace = await getDocumentImage(token, id, "identity-card-face-admin");
        const identityCardBack = await getDocumentImage(token, id, "identity-card-back-admin");
        const driverLicenceFace = await getDocumentImage(token, id, "driver-licence-face-admin");
        const driverLicenceBack = await getDocumentImage(token, id, "driver-licence-back-admin");
        setImages({
          identityCardFace,
          identityCardBack,
          driverLicenceBack,
          driverLicenceFace,
        });
      };
      fetchImages();
    }
  }, [token]);

  return (
    <section className={styles.document}>
      <PageHeader
        title={GlobalStrings.documents.document}
        backRoute={adminRoutesPath.EndUsers.EndUsersWithDocuments}
      />
      {isFetching ? (
        <IsLoading />
      ) : (
        <div className="d-flex gap-5 flex-column">
          <IdentityCard
            faceImage={images.identityCardFace}
            backImage={images.identityCardBack}
            document={documents?.identityCard}
            refetch={refetch}
          />
          <DriverLicence
            faceImage={images.driverLicenceFace}
            backImage={images.driverLicenceBack}
            document={documents?.driverLicence}
            refetch={refetch}
          />
        </div>
      )}
    </section>
  );
};
export default Document;
