import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { selectCurrentUser } from "app/auth/authSlice";
import {
  useDeleteServiceFromPartnerMutation,
  useGetJuicersQuery,
} from "app/partners/partnersApiSlice";

import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { TableBadge, TableComponent, TableIcon } from "components";
import { errorHandler, GlobalStrings, roleValidation } from "utils";
import { Currency, TransportType, UserStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

interface I_JUICER {
  id: number | string;
  name: string;
  city: string;
  email: string;
  phone: string;
  price: number;
  numberOfCharges: number;
  totalPaid: number;
  totalPending: number;
  totalSuspended: number;
  status: UserStatus;
  vehicleType: TransportType;
}

const JuicersServicesListing = () => {
  const { partnerId } = useParams();
  const user = useSelector(selectCurrentUser);
  const columnHelper = createColumnHelper<I_JUICER>();
  const {
    refetch,
    isFetching,
    data: juicers,
    error,
  } = useGetJuicersQuery(partnerId || user.company.id, {
    refetchOnMountOrArgChange: false,
  });
  const [deleteServiceFromPartner] = useDeleteServiceFromPartnerMutation();
  const [services, setServices] = useState([]);

  useEffect(() => {
    table.toggleAllColumnsVisible(false);
  }, [user]);

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  useEffect(() => {
    const filteredServices: any = [];
    if (juicers) {
      juicers.forEach((juicer: any) => {
        if (juicer.services) {
          juicer?.services?.forEach((service: any) => {
            if (service.isContracted) {
              filteredServices.push({
                id: service.id,
                name: juicer?.name,
                city: juicer?.city,
                email: juicer?.email,
                phone: juicer?.phone,
                status: juicer?.owner?.status,
                price: service?.price,
                numberOfCharges: service?.numberOfCharges,
                vehicleType: service?.vehicleType,
                totalPaid: service?.totalPaid,
                totalPending: service?.totalPending,
                totalSuspended: service?.totalSuspended,
              });
            }
          });
        }
      });
    }
    setServices(filteredServices);
  }, [juicers]);

  const deleteServiceHandler = async (serviceId: number | string) => {
    if (partnerId) {
      const reqBody = {
        partnersIds: [partnerId.toString()],
      };
      try {
        await deleteServiceFromPartner({
          body: reqBody,
          serviceId,
        });
        refetch();
        toast.success(GlobalStrings.activeServices.deleteSuccessToast);
      } catch (error: any) {
        errorHandler(error);
      }
    }
  };

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.juicer}</span>,
      enableSorting: false,
      size: 1,
      minSize: 20,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.city, {
      id: "city",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.city}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.email, {
      id: "email",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.email}</span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.phone, {
      id: "phone",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.phone}</span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => (
        <TableBadge
          text={GlobalStrings.userAccountStatus[info.getValue()]}
          color={
            info.getValue() === UserStatus.active
              ? "green"
              : info.getValue() === UserStatus.inactive
              ? "orange"
              : "red"
          }
        />
      ),
      header: () => <span>{GlobalStrings.formLabels.status}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
      enableHiding: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "price",
      cell: (info) => {
        return (
          <p>
            {`${info.getValue().price} 
            ${Currency.RON} / 
            ${GlobalStrings.vehicleTypes[info.getValue().vehicleType]}`}
          </p>
        );
      },
      header: () => <span>{GlobalStrings.formLabels.feeCharged}</span>,
      enableSorting: false,
      size: 1,
      enableHiding: false,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.numberOfCharges, {
      id: "numberOfCharges",
      cell: (info) => <p>{info.getValue() >= 0 ? info.getValue() : NO_VALUE_SYMBOL}</p>,
      header: () => <span>{GlobalStrings.formLabels.numberOfCharges}</span>,
      enableSorting: false,
      size: 1,
      enableHiding: false,
      minSize: 5,
    }),
    columnHelper.accessor((row) => row.totalPaid, {
      id: "totalPaid",
      cell: (info) => <TableBadge text={`${info.getValue()} ${Currency.LEI}`} color="green" />,
      header: () => <span>{GlobalStrings.formLabels.totalPaid}</span>,
      enableSorting: false,
      size: 1,
      enableHiding: false,
      minSize: 5,
    }),
    columnHelper.accessor((row) => row.totalPending, {
      id: "totalPending",
      cell: (info) => <TableBadge text={`${info.getValue()} ${Currency.LEI}`} color="orange" />,
      header: () => <span>{GlobalStrings.formLabels.totalPending}</span>,
      enableSorting: false,
      size: 1,
      enableHiding: false,
      minSize: 5,
    }),
    columnHelper.accessor((row) => row.totalSuspended, {
      id: "totalSuspended",
      cell: (info) => <TableBadge text={`${info.getValue()} ${Currency.LEI}`} color="red" />,
      header: () => <span>{GlobalStrings.formLabels.totalSuspended}</span>,
      enableSorting: false,
      size: 1,
      enableHiding: false,
      minSize: 5,
    }),
    columnHelper.accessor((row) => row, {
      id: "id",
      minSize: 7,
      maxSize: 6,
      enableHiding: !roleValidation("admin", user?.roles),
      cell: (info) => {
        return (
          <ConfirmationModal
            icon={<TableIcon type="delete" />}
            handler={() => {
              deleteServiceHandler(info.getValue().id);
            }}
            buttonText={GlobalStrings.delete}
            title={GlobalStrings.activeServices.deleteService}
          />
        );
      },
      header: () => <span></span>,
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    data: services || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  return (
    <section>
      <TableComponent
        table={table}
        tableHasData={services?.length > 0}
        isLoading={isFetching}
        hidePagination
      />
    </section>
  );
};

export default JuicersServicesListing;
