import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import { toggleMenu } from "app/common/commonSlice";

import classNames from "classnames";
import { LogoutModal } from "components";
import { GlobalStrings, roleValidation } from "utils";
import { UserRoles } from "utils/enums";

import logoAdmin from "assets/logo/logo_ecohop_admin.png";
import logoPartner from "assets/logo/logo_ecohop_partner.svg";
import logoSupplier from "assets/logo/logo_ecohop_supplier.svg";
import { adminRoutesPath } from "views/admin/routes";
import { chargingParterRoutesPath } from "views/chargingPartner/routes";
import { partnerRoutesPath } from "views/partner/routes";
import { supplierRoutesPath } from "views/supplier/routes";

import styles from "./Header.module.scss";

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const menuIsOpen = useSelector((state: any) => state.common.menuIsOpen);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [logoutModalIsOpen, setLogoutModalIsOpen] = useState(false);

  let logoURL = "";

  switch (true) {
    case roleValidation("supplier", user.roles):
      logoURL = supplierRoutesPath.Products.Index;
      break;
    case roleValidation("admin", user.roles):
      logoURL = adminRoutesPath.Dashboard;
      break;
    case roleValidation("charging_partner", user.roles):
      logoURL = chargingParterRoutesPath.ContainersAndStations.ContainersMap;
      break;
    case roleValidation("partner_admin", user.roles):
      logoURL = partnerRoutesPath.Dashboard.Index;
      break;
  }

  return (
    <>
      <section className={styles.header}>
        <div className={styles.header_container}>
          <div className={styles.menu_icon}>
            <div
              className={classNames(styles.menu_icon_circle, styles[user?.role], {
                [styles.active]: menuIsOpen,
              })}
              onClick={() => dispatch(toggleMenu())}
            >
              <span></span>
            </div>
          </div>
          <div className={styles.main_header}>
            <div className={styles.logos}>
              <Link to={logoURL} className={styles.header_logo}>
                {user?.role === UserRoles.partner || user?.role === UserRoles.partner_admin ? (
                  <img src={logoPartner} alt="Partner Logo" />
                ) : user?.role === UserRoles.supplier || user?.role === UserRoles.supplier_admin ? (
                  <img src={logoSupplier} alt="Supplier Logo" />
                ) : (
                  <img src={logoAdmin} alt="Admin Logo" />
                )}
              </Link>
              <div className={styles.header_logo}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://european-union.europa.eu/index_ro"
                  className={styles.header_link}
                >
                  <img src="/Flag_of_Europe.svg" alt="steagul Uniunii Europene" />
                </a>
              </div>
              <div className={styles.header_logo}>
                <a target="_blank" rel="noreferrer" href="https://www.gov.ro/" className={styles.header_link}>
                  <img src="/Guvernul_Romaniei.svg" alt="logo Guvernul României" />
                </a>
              </div>
              <div className={styles.header_logo}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={styles.header_link}
                  href="https://www.fonduri-ue.ro/24-programe-operationale/2014-2020/115-poc-poc"
                >
                  <img src="/logo IS-2014-2020.png" alt="logo IS-2014-2020" />
                </a>
              </div>
            </div>

            <div className={styles.user_header}>
              <Dropdown
                isOpen={dropdownIsOpen}
                toggle={() => setDropdownIsOpen((prev) => !prev)}
                direction="down"
                className={styles.dropdown}
              >
                <DropdownToggle caret className={styles.toggle}>
                  {user?.firstName} {user?.lastName}
                </DropdownToggle>
                <DropdownMenu className={styles.dropdown_menu}>
                  <DropdownItem onClick={() => setLogoutModalIsOpen(true)}>
                    {GlobalStrings.menu.logout}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <div className={classNames(styles.user_role, styles[user?.role])}>
                <p>{GlobalStrings.userRoles?.[user?.role]}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LogoutModal modalIsOpen={logoutModalIsOpen} setModalIsOpen={setLogoutModalIsOpen} />
    </>
  );
};

export default Header;
