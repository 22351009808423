import { useEffect, useState } from "react";

import { I_SERVER_MESSAGE } from "utils/types";

export function useServerMessage() {
  const [serverMessage, setServerMessage] = useState<I_SERVER_MESSAGE>({
    type: "",
    text: "",
  });

  useEffect(() => {
    const delay = setTimeout(() => {
      setServerMessage((prev) => {
        return {
          type: "",
          text: "",
        };
      });
    }, 3000);

    return () => clearTimeout(delay);
  }, [serverMessage.text]);

  return {
    serverMessage,
    setServerMessage,
  };
}
