import React from "react";

import { PageHeader } from "components";
import { GlobalStrings } from "utils";

import JuicersServicesBatteryListing from "views/common/JuicersServicesBatteryListing";
import JuicersServicesListing from "views/common/JuicersServicesListing/JuicersServicesListing";

import styles from "./Juicers.module.scss";

const Juicers = () => {
  return (
    <section>
      <PageHeader title={GlobalStrings.activeServices.juicersTitle} backRoute="-1" />
      <div className={styles.tablesContainer}>
        <JuicersServicesListing />
        <JuicersServicesBatteryListing />
      </div>
    </section>
  );
};

export default Juicers;
