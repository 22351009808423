import React from "react";

import { useGetTripsReportsOverallQuery } from "app/reports/reportsApiSlice";

import { IsLoading } from "components";
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GlobalStrings } from "utils";

export default function TripsTab({ startDate, endDate }: { startDate: string; endDate: string }) {
  const { data, isFetching } = useGetTripsReportsOverallQuery({
    startDate,
    endDate,
  });

  if (isFetching) {
    return (
      <div>
        <IsLoading />
      </div>
    );
  }

  return (
    <>
      <h4 className="text-center mb-5">{GlobalStrings.analytics.averageTripsDuration}</h4>

      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={data?.vehiclesChart} className="w-100">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="scooters"
            name={GlobalStrings.analytics.scooters}
            stroke="#8A2BE2"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey="cars"
            stroke="#FFA500"
            name={GlobalStrings.analytics.cars}
          />
          <Line
            type="monotone"
            dataKey="bikes"
            stroke="#82ca9d"
            name={GlobalStrings.analytics.bikes}
          />
          <Brush dataKey="name" height={20} stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
      <hr />
      <h4 className="text-center my-5">{GlobalStrings.analytics.totalNumberOfTripsPerHours}</h4>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={data.tripsPerHour} className="w-100">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="count"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            name={GlobalStrings.analytics.tripsPerHour}
          />
          <Brush dataKey="name" height={20} stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
      <hr />
      <h4 className="text-center my-5">{GlobalStrings.analytics.totalNumberOfTrips}</h4>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={data.overallData} className="w-100">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="numberOfTrips"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            name={GlobalStrings.analytics.trips}
          />
          <Brush dataKey="name" height={20} stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
