import React from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { useAddSettingMutation, useGetSettingsQuery } from "app/settings/settingsApiSlice";

import { AlertComponent, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { errorHandler, GlobalStrings, validateJSON } from "utils";

const GlobalSettingForm = (props: { setFormIsOpen: Function }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      info: "",
    },
  });
  const [addSetting, { isLoading }] = useAddSettingMutation();
  const { refetch } = useGetSettingsQuery({});
  const { serverMessage, setServerMessage } = useServerMessage();

  const onSubmit = async ({ name, info }: any) => {
    const body = {
      name,
      info: JSON.parse(info),
    };
    try {
      await addSetting(body).unwrap();
      setTimeout(() => {
        props.setFormIsOpen(false);
      }, 300);

      toast.success(GlobalStrings.settings.settingHasBeenAdded);
      refetch();
      reset();
    } catch (error: any) {
      errorHandler(error, true, setServerMessage);
    }
  };

  return (
    <section className="d-flex flex-column gap-5 card mb-5">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h5 className="mb-4">{GlobalStrings.settings.addNewSetting}</h5>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="name"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.name}
                  type="text"
                />
              )}
            />
            <Label for="firstName">{GlobalStrings.formLabels.name}</Label>
          </FormGroup>
        </div>
        <div>
          <FormGroup className="w-100">
            <Label for="description">JSON</Label>
            <Controller
              name="info"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                validate: {
                  required: (v) =>
                    !validateJSON(v) ? GlobalStrings.settings.wrongJSONFormat : undefined,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder="JSON"
                  type="textarea"
                  style={{ minHeight: 200 }}
                />
              )}
            />
          </FormGroup>
        </div>

        <SubmitButton isLoading={isLoading} label={GlobalStrings.add} className="mt-3" />
      </Form>
      <AlertComponent errors={errors} serverMessage={serverMessage} />
    </section>
  );
};
export default GlobalSettingForm;
