import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { useDeleteUserMutation, useGetUsersByRolesQuery } from "app/users/usersApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ConfirmationPopup, TableBadge, TableComponent, TableIcon } from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { AccountStatus, AdminUserRoles, UserRoles } from "utils/enums";

export type User = {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  roles: AdminUserRoles;
  status: AccountStatus;
};

const UsersList = () => {
  const [deleteUser] = useDeleteUserMutation();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const columnHelper = createColumnHelper<User>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { data, isFetching, error, refetch } = useGetUsersByRolesQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
    rolesNames: [UserRoles.admin, UserRoles.partial_admin],
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<any>();
  const toggleModal = () => setModalIsOpen((prev) => !prev);

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  const deleteUserHandler = async () => {
    try {
      await deleteUser(userToDelete.id).unwrap();
      toggleModal();
      refetch();
      toast.success(GlobalStrings.settings.userDeleted);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const columns = [
    columnHelper.accessor((row) => row.firstName, {
      id: "firstName",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.firstName}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: "lastName",
      cell: (info) => <p> {info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.lastName}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row.email, {
      id: "email",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.email}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row.roles, {
      id: "roles",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.userType}</span>,
      enableSorting: true,
      enableMultiSort: true,
    }),

    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => (
        <TableBadge
          text={GlobalStrings.userAccountStatus[info.getValue()]}
          color={
            info.getValue() === "active"
              ? "green"
              : info.getValue() === "inactive"
              ? "orange"
              : "red"
          }
        />
      ),
      header: () => (
        <span className="text-center d-block">{GlobalStrings.personUsers.accountStatus}</span>
      ),
      enableSorting: true,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <TableIcon
            type="delete"
            onClickAction={() => {
              toggleModal();
              setUserToDelete(info.getValue());
            }}
          />
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    data: data?.users || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <>
      <TableComponent table={table} tableHasData={data?.users?.length > 0} isLoading={isFetching} />
      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        title={`${GlobalStrings.settings.modalTitle} - ${userToDelete?.email}`}
        body={GlobalStrings.settings.modalBody}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deleteUserHandler}
      />
    </>
  );
};
export default UsersList;
