import { apiSlice } from "../apiSlice";

type Stats = {
  countBicyclesInUse: number;
  countScootersInUse: number;
  countLoggedUsers: number;
  countContainersInUse: number;
};

export const statsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardStats: builder.query<Stats, void>({
      query: () => `${process.env.REACT_APP_CONSUMER_API_URL}/stats/admin-dashboard`,
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const { useGetDashboardStatsQuery } = statsApiSlice;
