import React from "react";

import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { GlobalVariables } from "utils";

import pin from "assets/icons/pin_green.svg";

const containerStyle = {
  width: "100%",
  height: "100%",
};

function GetCoordinatesMap(props: {
  markerPosition: {
    lat: number;
    lng: number;
  };
  setMarkerPosition?: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  center?: {
    lat: number;
    lng: number;
  };
  zoom?: number;
}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
  });
  const google = window.google;

  const clickHandler = (e: any) => {
    props.setMarkerPosition &&
      props?.setMarkerPosition({
        lat: +e.latLng.lat().toFixed(10),
        lng: +e.latLng.lng().toFixed(10),
      });
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={props?.center || GlobalVariables.center}
      zoom={props?.zoom || 11}
      onClick={clickHandler}
    >
      {props.markerPosition.lat && props.markerPosition.lng && (
        <MarkerF
          position={props.markerPosition}
          animation={window.google.maps.Animation.BOUNCE}
          icon={{
            url: pin,
            scaledSize: new google.maps.Size(40, 40),
          }}
        />
      )}
    </GoogleMap>
  ) : (
    <>Loading....</>
  );
}

export default React.memo(GetCoordinatesMap);
