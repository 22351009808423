import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { selectCurrentUser } from "app/auth/authSlice";

import { GlobalStrings, roleValidation } from "utils";
import { UserRoles } from "utils/enums";
import { I_MENU_ITEM } from "utils/types";

import menuIcon1 from "assets/icons/admin_icon1.svg";
import menuIcon2 from "assets/icons/admin_icon2.svg";
import menuIcon3 from "assets/icons/admin_icon3.svg";
import menuIcon4 from "assets/icons/admin_icon4.svg";
import menuIcon5 from "assets/icons/admin_icon5.svg";
import menuIcon6 from "assets/icons/admin_icon6.svg";
import menuIcon7 from "assets/icons/admin_icon7.svg";
import menuIcon8 from "assets/icons/admin_icon8.svg";
import menuIcon9 from "assets/icons/admin_icon9.svg";
import menuIcon10 from "assets/icons/admin_icon10.svg";
import menuIcon12 from "assets/icons/admin_icon12.svg";
import menuIcon13 from "assets/icons/admin_icon13.svg";
import menuIcon14 from "assets/icons/admin_icon14.svg";
import menuIcon15 from "assets/icons/admin_icon15.svg";
import menuIcon16 from "assets/icons/admin_icon16.svg";
import menuIcon17 from "assets/icons/admin_icon17.svg";
import menuIcon18 from "assets/icons/admin_icon18.svg";
import menuIcon20 from "assets/icons/admin_icon20.svg";
import menuIcon19 from "assets/icons/all_vehicles_icon.svg";
import { adminRoutesPath } from "views/admin/routes";
import { chargingParterRoutesPath } from "views/chargingPartner/routes";
import { partnerRoutesPath } from "views/partner/routes";
import { supplierRoutesPath } from "views/supplier/routes";

import { Header, Menu } from "../index";
import Disclaimer from "components/Disclaimer/Disclaimer";

const menuDataAdmin = [
  {
    id: 0,
    text: GlobalStrings.menu.electricityContainers,
    icon: menuIcon1,
    slug: adminRoutesPath.ContainersAndStations.ContainersMap,
  },
  {
    id: 1,
    text: GlobalStrings.menu.transportPartners,
    icon: menuIcon2,
    slug: adminRoutesPath.Partners.Index,
  },
  {
    id: 2,
    text: GlobalStrings.menu.publicTransportPartners,
    icon: menuIcon3,
    slug: adminRoutesPath.PublicTransport.Index,
  },
  {
    id: 3,
    text: GlobalStrings.menu.servicesSuppliers,
    icon: menuIcon4,
    slug: adminRoutesPath.Suppliers.Products.Index,
  },
  {
    id: 4,
    text: GlobalStrings.menu.vehicles,
    icon: menuIcon19,
    slug: adminRoutesPath.Vehicles.Index,
  },
  {
    id: 5,
    text: GlobalStrings.menu.marketplaceProducts,
    icon: menuIcon5,
    slug: adminRoutesPath.Marketplace.Index,
  },
  {
    id: 6,
    text: GlobalStrings.menu.marketplaceListing,
    icon: menuIcon20,
    slug: adminRoutesPath.MarketplaceListing.Index,
  },
  {
    id: 7,
    text: GlobalStrings.menu.personUsers,
    icon: menuIcon6,
    slug: adminRoutesPath.EndUsers.Index,
  },
  {
    id: 8,
    text: GlobalStrings.menu.trips,
    icon: menuIcon7,
    slug: adminRoutesPath.Trips,
  },
  {
    id: 9,
    text: GlobalStrings.menu.analyticsData,
    icon: menuIcon8,
    slug: adminRoutesPath.Analytics.General,
  },
  {
    id: 10,
    text: GlobalStrings.menu.invoices,
    icon: menuIcon9,
    slug: adminRoutesPath.Invoices.EndUsers,
  },
  {
    id: 11,
    text: GlobalStrings.menu.aiController,
    icon: menuIcon18,
    slug: adminRoutesPath.AIController,
  },
  {
    id: 12,
    text: GlobalStrings.menu.settings,
    icon: menuIcon10,
    slug: adminRoutesPath.Settings.Accounts,
  },
  {
    id: 13,
    text: GlobalStrings.menu.geofencing,
    icon: menuIcon10,
    slug: adminRoutesPath.Geofencing,
  },
];

const menuDataPartners = [
  {
    id: 0,
    text: GlobalStrings.menuPartners.generalData,
    icon: menuIcon15,
    slug: partnerRoutesPath.Dashboard.GeneralInfo,
  },
  {
    id: 1,
    text: GlobalStrings.menuPartners.vehicles,
    icon: menuIcon2,
    slug: partnerRoutesPath.Vehicles.Index,
  },
  {
    id: 2,
    text: GlobalStrings.menuPartners.reports,
    icon: menuIcon8,
    slug: partnerRoutesPath.Reports,
  },
  {
    id: 3,
    text: GlobalStrings.menuPartners.marketplace,
    icon: menuIcon5,
    slug: partnerRoutesPath.Marketplace.Index,
  },
  {
    id: 4,
    text: GlobalStrings.menuPartners.orders,
    icon: menuIcon4,
    slug: partnerRoutesPath.Orders,
  },
  {
    id: 5,
    text: GlobalStrings.menuPartners.services,
    icon: menuIcon13,
    slug: partnerRoutesPath.Services.Cars,
  },
  {
    id: 6,
    text: GlobalStrings.menuPartners.requestOffer,
    icon: menuIcon16,
    slug: partnerRoutesPath.RequestOffers,
  },
  {
    id: 7,
    text: GlobalStrings.menuPartners.requestOfferHistory,
    icon: menuIcon17,
    slug: partnerRoutesPath.RequestOffersHistory,
  },
  {
    id: 9,
    text: GlobalStrings.menuPartners.accounts,
    icon: menuIcon6,
    slug: partnerRoutesPath.Accounts.Index,
  },
];

const menuDataProviders = [
  {
    id: 0,
    text: GlobalStrings.menuProviders.activeProducts,
    icon: menuIcon12,
    slug: supplierRoutesPath.Products.Index,
  },
  {
    id: 1,
    text: GlobalStrings.menuProviders.activeServices,
    icon: menuIcon13,
    slug: supplierRoutesPath.Services.Index,
  },
  {
    id: 2,
    text: GlobalStrings.menuProviders.requests,
    icon: menuIcon4,
    slug: supplierRoutesPath.RequestOffers,
  },
  {
    id: 3,
    text: GlobalStrings.menuProviders.decinedProductsMarketplace,
    icon: menuIcon14,
    slug: supplierRoutesPath.DeclinedProducts,
  },
  {
    id: 5,
    text: GlobalStrings.menuProviders.accounts,
    icon: menuIcon6,
    slug: supplierRoutesPath.Accounts.Index,
  },
];

const menuDataChargingPartner = [
  {
    id: 0,
    text: GlobalStrings.menu.electricityContainers,
    icon: menuIcon1,
    slug: chargingParterRoutesPath.ContainersAndStations.ContainersMap,
  },
  {
    id: 1,
    text: GlobalStrings.menu.settings,
    icon: menuIcon10,
    slug: chargingParterRoutesPath.Settings.Index,
  },
];

const Layout = () => {
  const [menu, setMenu] = useState<I_MENU_ITEM[]>();
  const user = useSelector(selectCurrentUser);
  useEffect(() => {
    switch (true) {
      case user?.role === UserRoles.admin || user?.role === UserRoles.partial_admin:
        setMenu(menuDataAdmin);
        break;
      case user?.role === UserRoles.partner || user?.role === UserRoles.partner_admin:
        setMenu(menuDataPartners);
        break;
      case roleValidation("supplier", user.roles):
        setMenu(menuDataProviders);
        break;
      case roleValidation("charging_partner", user.roles):
        setMenu(menuDataChargingPartner);
        break;
      default:
        setMenu(menuDataAdmin);
    }
  }, [user?.role]);
  return (
    <section>
      <Header />
      {menu && <Menu menuData={menu} />}
      <div className="background_header" />
      <div className="main_container">
        <Outlet />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Disclaimer />
      </div>
    </section>
  );
};
export default React.memo(Layout);
