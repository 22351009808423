import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useGetSupplierQuery } from "app/suppliers/suppliersApiSlice";

import { IsLoading, PageHeader, SEO } from "components";
import { errorHandler, GlobalStrings } from "utils";

import UsersTable from "../../SuppliersProducts/UsersTable/UsersTable";
import ServicesTable from "../ServicesTable/ServicesTable";
import SupplierServiceForm from "../SupplierServiceForm/SupplierServiceForm";
import { adminRoutesPath } from "views/admin/routes";

const EditSupplierAndService = () => {
  const { supplierId } = useParams();
  const { data: supplier, isLoading, refetch, error } = useGetSupplierQuery(supplierId || "");

  useEffect(() => {
    errorHandler(error);
  }, [error]);

  return (
    <section>
      {isLoading ? (
        <IsLoading />
      ) : (
        <>
          <SEO title={supplier?.name + GlobalStrings.SEO.admin.editSupplier} />
          <PageHeader
            title={`${GlobalStrings.servicesSuppliers.supplierServices} - ${supplier?.name}`}
            backRoute="-1"
          />
          <div className="mt-4">
            <div className="card">
              <h4>{GlobalStrings.partners.generalInfo}</h4>
              <SupplierServiceForm data={supplier} supplierId={supplierId} refetch={refetch} />
            </div>
          </div>

          {supplierId && (
            <>
              <div className="mt-4">
                <div className="card">
                  <ServicesTable
                    supplierId={supplierId}
                    pageHeader={{
                      title: GlobalStrings.servicesSuppliers.associatedServicesSupplier,
                      tabs: [
                        {
                          id: 1,
                          name: GlobalStrings.servicesSuppliers.addService,
                          slug: adminRoutesPath.Suppliers.Services.AddService.replace(
                            ":supplierId",
                            supplierId.toString()
                          ),
                        },
                      ],
                    }}
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="card">
                  <UsersTable
                    supplierId={supplierId}
                    addUserBaseSlug={adminRoutesPath.Suppliers.Services.AddUser.replace(
                      ":supplierId",
                      supplierId.toString()
                    )}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </section>
  );
};

export default EditSupplierAndService;
