import { apiSlice } from "app/apiSlice";

import { I_SERVICE } from "utils/types";

export const servicesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, vehicleType, companyId }) => {
        let url = `${process.env.REACT_APP_VEHICLE_API_URL}/services?pagination=true&page=${
          pageIndex + 1
        }&limit=${pageSize}`;

        if (sort) {
          url += `&sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}`;
        }
        if (vehicleType) {
          url += `&vehicleType=${vehicleType}`;
        }
        if (companyId) {
          url += `&companyId=${companyId}`;
        }

        return url;
      },
      keepUnusedDataFor: 2,
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const services: I_SERVICE[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          services.push({
            id: item.id,
            city: item?.city || "N/A",
            coveredZone: item?.coveredZone || "N/A",
            serviceName: item?.serviceName || "N/A",
            vehicleType: item?.vehicleType || "N/A",
            amount: item?.amount?.toFixed(2) || "N/A",
            amountAC: item?.amountAC?.toFixed(2) || "N/A",
            amountDC: item?.amountDC?.toFixed(2) || "N/A",
            companyId: item?.companyId || "N/A",
            status: item?.status || "N/A",
            company: {
              id: item.company?.id || "N/A",
              identifier: item.company?.identifier || "N/A",
              name: item.company?.name || "N/A",
              phone: item.company?.phone || "N/A",
              email: item.company?.email || "N/A",
              city: item.company?.city || "N/A",
            },
          });
        });
        return {
          services,
          meta,
        };
      },
    }),

    getService: builder.query<any, any>({
      query: (id: number) => `${process.env.REACT_APP_VEHICLE_API_URL}/services/${id}`,
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),

    addService: builder.mutation<any, Partial<any>>({
      query: (newService) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/services`,
        method: "POST",
        body: newService,
      }),
    }),

    updateService: builder.mutation({
      query: (data: {
        body: {
          coveredZone: string;
          serviceName: string;
          vehicleType: string;
          amountAC?: number;
          amountDC?: number;
          city?: string;
        };
        id?: string | number;
      }) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/services/${data.id}`,
        method: "PUT",
        body: data.body,
      }),
    }),

    deleteService: builder.mutation({
      query: (id: number) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/services/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetServicesQuery,
  useGetServiceQuery,
  useAddServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
} = servicesApiSlice;
