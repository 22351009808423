import { CompanyType } from "utils/enums";
import { I_CONNECTOR } from "utils/types";

import { apiSlice } from "../apiSlice";

export const containersAnsStationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPrices: builder.query<any, void>({
      query: () => `${process.env.REACT_APP_CONSUMER_API_URL}/app-settings?name=prices`,
      transformResponse: (response: any) => {
        return {
          ...response.data?.pageData[0]?.info,
          id: response.data?.pageData[0]?.id,
        };
      },
    }),
    updatePrices: builder.mutation<any, any>({
      query: (data: { body: any; id: number }) => ({
        url: `${process.env.REACT_APP_CONSUMER_API_URL}/app-settings/${data.id}`,
        method: "PUT",
        body: data.body,
      }),
    }),
    getCompanies: builder.query<any, void>({
      query: () =>
        `${process.env.REACT_APP_CONSUMER_API_URL}/companies?type=${CompanyType.CHARGING_STATION_PARTNER}`,
      transformResponse: (response: any) => {
        const data = response.data?.pageData?.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        return data;
      },
    }),
    createConnector: builder.mutation<any, Partial<any>>({
      query: (body) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/charging-connector/create`,
        method: "POST",
        body,
      }),
    }),
    updateConnector: builder.mutation<any, Partial<any>>({
      query: (data: { body: I_CONNECTOR; id: number }) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/charging-connector/${data.id}`,
        method: "PATCH",
        body: data.body,
      }),
    }),
    deleteConnector: builder.mutation<any, any>({
      query: (id: number) => ({
        url: `${process.env.REACT_APP_CONTAINERS_API_URL}/charging-connector/${id}`,
        method: "DELETE",
      }),
    }),
    createAccountPartner: builder.mutation<any, any>({
      query: ({ id, user }) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/create-charging-station-partner-admmin/${id}`,
        method: "POST",
        body: user,
      }),
    }),
  }),
});

export const {
  useGetPricesQuery,
  useGetCompaniesQuery,
  useCreateConnectorMutation,
  useDeleteConnectorMutation,
  useUpdateConnectorMutation,
  useUpdatePricesMutation,
  useCreateAccountPartnerMutation,
} = containersAnsStationsApiSlice;
