import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { useUpdateDocumentMutation } from "app/users/usersApiSlice";

import { SubmitButton } from "components";
import parse from "html-react-parser";
import moment from "moment";
import Tesseract from "tesseract.js";
import { errorHandler, GlobalStrings, GlobalVariables, returnFirstErrorMessage } from "utils";
import { DocumentStatus, TypeOfDocument } from "utils/enums";

import styles from "./Document.module.scss";

type FormData = {
  name: string;
  expiringDateCI: string;
  identityCardFaceStatus: string;
  identityCardBackStatus: string;
};

const IdentityCard = (props: {
  refetch: Function;
  faceImage: string;
  backImage: string;
  document:
    | {
        id: number;
        faceS3Key: string;
        backS3Key: string;
        isFaceValid: boolean;
        isBackValid: boolean;
        expiresAt: string;
        status: DocumentStatus;
        fullName: string;
        updatedAt: string;
      }
    | undefined;
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      name: props.document?.fullName || "",
      expiringDateCI: props.document?.expiresAt
        ? moment(props.document?.expiresAt).format("YYYY-MM-DD")
        : undefined,
      identityCardFaceStatus: props.document?.isFaceValid?.toString(),
      identityCardBackStatus: props.document?.isBackValid?.toString(),
    },
  });
  const [modal, setModal] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const [updateDocument, { isLoading }] = useUpdateDocumentMutation();

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (props.faceImage && !props.document?.fullName) {
      Tesseract.recognize(props.faceImage, "ron").then(({ data: { text } }) => {
        const date = text.match(/[FM](\d{6})/);
        const extractedName = text.match(/IDROU.*?<<</s);

        if (date && date[0]) {
          const formattedDate = moment(`
          20${date?.[1]?.split("")?.[0]}${date?.[1]?.split("")?.[1]}-${date?.[1]?.split("")?.[2]}${
            date?.[1]?.split("")?.[3]
          }-${date?.[1]?.split("")?.[4]}${date?.[1]?.split("")?.[5]}`).format("YYYY-MM-DD");
          if (formattedDate) {
            setValue("expiringDateCI", formattedDate);
          }
        }
        if (extractedName) {
          setValue(
            "name",
            extractedName[0].replace("IDROU", "").replace("<<<", "").replace("<<", " ")
          );
        }
      });
    }
  }, [props.faceImage]);

  const onSubmit = async (data: FormData) => {
    try {
      await updateDocument({
        id: props.document?.id,
        body: {
          type: TypeOfDocument.ID,
          fullName: data.name,
          expiresAt: moment(data.expiringDateCI).format("YYYY-MM-DD"),
          isFaceValid: data.identityCardFaceStatus,
          isBackValid: data.identityCardBackStatus,
        },
      }).unwrap();
      toast.success(GlobalStrings.documents.documentsUpdated);
      props.refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <section className={styles.document}>
      <div className="w-100">
        <div className="mb-5 card">
          <h3>
            {GlobalStrings.documents.identityCard}
            {" ("}
            {props.document?.status &&
              GlobalStrings.formLabels.status +
                " - " +
                GlobalStrings.documentStatus[props.document?.status] +
                ")"}
          </h3>
          <div className={styles.container}>
            <div className={styles.document_container}>
              <h4>{GlobalStrings.documents.front}</h4>
              <div
                className={styles.image_container}
                onClick={() => {
                  setModalImage(props.faceImage);
                  setModal(true);
                }}
              >
                {props.faceImage && <img src={props.faceImage} alt="id card" />}
              </div>
            </div>
            <div className={styles.document_container}>
              <h4>{GlobalStrings.documents.back}</h4>
              <div
                className={styles.image_container}
                onClick={() => {
                  setModalImage(props.backImage);
                  setModal(true);
                }}
              >
                {props.backImage && <img src={props.backImage} alt="id card" />}
              </div>
            </div>
            <div>
              <h4>{GlobalStrings.documents.validate}</h4>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex gap-2 flex-column">
                  <FormGroup floating className="w-100">
                    <Controller
                      name="identityCardFaceStatus"
                      control={control}
                      rules={{
                        required: GlobalStrings.formValidation.fieldsRequired,
                      }}
                      render={({ field, fieldState: { invalid } }) => (
                        <Input
                          {...field}
                          invalid={invalid}
                          placeholder={GlobalStrings.documents.selectFaceStatus}
                          type="select"
                        >
                          {GlobalVariables.documentImageStatus.map((item: any) => {
                            return (
                              <option key={item.id} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </Input>
                      )}
                    />
                    <Label for="identityCardFaceStatus">
                      {GlobalStrings.documents.selectFaceStatus}
                    </Label>
                  </FormGroup>
                  <FormGroup floating className="w-100">
                    <Controller
                      name="identityCardBackStatus"
                      control={control}
                      rules={{
                        required: GlobalStrings.formValidation.fieldsRequired,
                      }}
                      render={({ field, fieldState: { invalid } }) => (
                        <Input
                          {...field}
                          invalid={invalid}
                          placeholder={GlobalStrings.documents.selectBackStatus}
                          type="select"
                        >
                          {GlobalVariables.documentImageStatus.map((item: any) => {
                            return (
                              <option key={item.id} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </Input>
                      )}
                    />
                    <Label for="identityCardBackStatus">
                      {GlobalStrings.documents.selectBackStatus}
                    </Label>
                  </FormGroup>
                  <FormGroup floating className="w-100">
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: GlobalStrings.formValidation.fieldsRequired,
                      }}
                      render={({ field, fieldState: { invalid } }) => (
                        <Input
                          {...field}
                          invalid={invalid}
                          placeholder={`${GlobalStrings.formLabels.lastName} ${GlobalStrings.formLabels.firstName}`}
                          type="text"
                        />
                      )}
                    />
                    <Label for="name">{`${GlobalStrings.formLabels.lastName} ${GlobalStrings.formLabels.firstName}`}</Label>
                  </FormGroup>

                  <FormGroup floating className="w-100">
                    <Controller
                      name="expiringDateCI"
                      control={control}
                      rules={{
                        required: GlobalStrings.formValidation.fieldsRequired,
                      }}
                      render={({ field, fieldState: { invalid } }) => (
                        <Input
                          {...field}
                          invalid={invalid}
                          type="date"
                          min={moment().format("YYYY-MM-DD")}
                        />
                      )}
                    />

                    <Label for="expiringDateCI">{GlobalStrings.formLabels.expiringDateCI}</Label>
                  </FormGroup>
                </div>
                {!props.document?.fullName && (
                  <Alert color="info">{parse(GlobalStrings.documents.disclaimer)}</Alert>
                )}
                <Alert color="danger" isOpen={Object.keys(errors).length > 0}>
                  {returnFirstErrorMessage(errors)}
                </Alert>
                <SubmitButton
                  isLoading={isLoading}
                  label={GlobalStrings.formLabels.save}
                  className="mb-3"
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} centered size="xl">
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <img src={modalImage} className={styles.modal_image} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            {GlobalStrings.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    </section>
  );
};
export default IdentityCard;
