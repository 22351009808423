import React from "react";

import { PageHeader } from "components";
import { GlobalStrings } from "utils";

import PartnerPrices from "views/partner/PartnerDashboard/PartnerPrices/PartnerPrices";

const Prices = (props: { identifier: string; individualTransportVehicles: string }) => {
  return (
    <section>
      <div className="card">
        <PageHeader title={GlobalStrings.partners.prices} />
        <PartnerPrices
          identifier={props.identifier}
          individualTransportVehicles={props.individualTransportVehicles}
        />
      </div>
    </section>
  );
};
export default Prices;
