import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { useResetPasswordMutation } from "app/auth/authApiSlice";

import { AlertComponent, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { errorHandler, GlobalStrings, GlobalVariables, Regex } from "utils";
import { I_CHANGE_PASSWORD } from "utils/types";

import logo from "assets/logo/ecohop-logo.png";

const ResetPasswordComponent = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newPassword: "",
      newPasswordConfirm: "",
    },
  });
  const { token } = useParams();

  const [newPasswordIsVisible, setNewPasswordIsVisible] = useState(false);
  const [newPasswordConfirmIsVisible, setNewPasswordConfirmIsVisible] = useState(false);
  const { serverMessage, setServerMessage } = useServerMessage();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const onSubmit = async ({ newPassword, newPasswordConfirm }: I_CHANGE_PASSWORD) => {
    if (newPassword !== newPasswordConfirm) {
      setServerMessage({
        type: "danger",
        text: GlobalStrings.formValidation.passwordsNotMatching,
      });
    } else {
      try {
        await resetPassword({
          token,
          newPassword,
          newPasswordConfirm,
        }).unwrap();
        setServerMessage({
          type: "success",
          text: GlobalStrings.auth.passwordChangedSuccessfully,
        });
      } catch (error: any) {
        errorHandler(error, true, setServerMessage);
      }
    }
  };

  return (
    <section className="d-flex flex-column gap-5">
      <div className="signin_header">
        <h3 className="title">{GlobalStrings.auth.resetPasswordTitle}</h3>
        <div className="image_container">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <p>{GlobalStrings.auth.resetPasswordDescription}</p>
        <FormGroup floating>
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: GlobalStrings.formValidation.fieldsRequired,
              pattern: {
                value: Regex.password,
                message: GlobalStrings.formValidation.invalidPassword,
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={GlobalStrings.formLabels.newPassword}
                type={newPasswordIsVisible ? "text" : "password"}
              />
            )}
          />

          <Label for="password">{GlobalStrings.formLabels.newPassword}</Label>
          {newPasswordIsVisible ? (
            <AiOutlineEyeInvisible
              className="position-absolute absolute-vertical-center cursor-pointer"
              size="20"
              onClick={() => setNewPasswordIsVisible(false)}
            />
          ) : (
            <AiFillEye
              className="position-absolute absolute-vertical-center cursor-pointer"
              size="20"
              color={GlobalVariables.redColor}
              onClick={() => setNewPasswordIsVisible(true)}
            />
          )}
        </FormGroup>

        <FormGroup floating>
          <Controller
            name="newPasswordConfirm"
            control={control}
            rules={{
              required: GlobalStrings.formValidation.fieldsRequired,
              pattern: {
                value: Regex.password,
                message: GlobalStrings.formValidation.invalidPassword,
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={GlobalStrings.formLabels.newPasswordConfirm}
                type={newPasswordConfirmIsVisible ? "text" : "password"}
              />
            )}
          />
          <Label for="password-confirm">{GlobalStrings.formLabels.newPasswordConfirm}</Label>
          {newPasswordConfirmIsVisible ? (
            <AiOutlineEyeInvisible
              className="position-absolute absolute-vertical-center cursor-pointer"
              size="20"
              onClick={() => setNewPasswordConfirmIsVisible(false)}
            />
          ) : (
            <AiFillEye
              className="position-absolute absolute-vertical-center cursor-pointer"
              size="20"
              color={GlobalVariables.redColor}
              onClick={() => setNewPasswordConfirmIsVisible(true)}
            />
          )}
        </FormGroup>

        <div className="d-flex flex-sm-row flex-column align-items-md-center justify-content-between align-items-start gap-md-0 gap-3">
          <SubmitButton isLoading={isLoading} label={GlobalStrings.auth.resetPasswordButton} />
        </div>
      </Form>
      <AlertComponent errors={errors} serverMessage={serverMessage} />
    </section>
  );
};
export default ResetPasswordComponent;
