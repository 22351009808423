import React from "react";
import Moment from "react-moment";
import { Badge } from "reactstrap";

import { GlobalStrings } from "utils";

import chipImage from "assets/images/chip.png";

import styles from "./CreditCard.module.scss";

const CreditCard = (props: { panMask: string; createdAt: string; isDefault: boolean }) => {
  return (
    <div className={styles.card_group}>
      <div className={styles.card}>
        <div className={styles.chip}>
          <img src={chipImage} alt="chip" />
        </div>
        <div className={styles.number}>{props.panMask}</div>
        <div className={styles.name}>
          {GlobalStrings.personUsers.addDate}
          <br />
          <Moment date={props.createdAt} format="DD.MM.YYYY, HH:mm:ss" />
        </div>
        {props.isDefault && (
          <Badge color="danger" pill className={styles.badge}>
            {GlobalStrings.personUsers.default}
          </Badge>
        )}
      </div>
    </div>
  );
};

export default CreditCard;
