import { I_PRODUCT } from "utils/types";

import { apiSlice } from "../apiSlice";

export interface I_CART {
  total: number;
  companies: {
    name: string;
    phone: string;
    email: string;
    city: string;
    address: string;
    total: number;
    cartItems: {
      quantity: number;
      product: I_PRODUCT;
    }[];
  }[];
}

export const marketplaceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCartProducts: builder.query<any, any>({
      query: (userId) => `${process.env.REACT_APP_MARKETPLACE_API_URL}/carts/user/${userId}`,
      keepUnusedDataFor: 0,
      transformResponse: (response: any) => {
        const { data } = response;

        return {
          cart: {
            ...data,
            companies: data?.companies?.map((company: any) => {
              return {
                ...company,
                cartItems: company?.cartItems?.map((cartItem: any) => {
                  return {
                    ...cartItem,
                    product: {
                      ...cartItem.product,
                      imagesKeys: cartItem.product.imagesKeys?.map(
                        (item: any) =>
                          `${process.env.REACT_APP_MARKETPLACE_API_URL}/products/image/from-bucket?imageKey=${item}`
                      ),
                    },
                  };
                }),
              };
            }),
          } as I_CART,
        };
      },
    }),
    addToCart: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_MARKETPLACE_API_URL}/carts/add-product`,
        method: "POST",
        body,
      }),
    }),
    sendCommand: builder.mutation({
      query: (companyId) => ({
        url: `${process.env.REACT_APP_MARKETPLACE_API_URL}/carts/checkout/${companyId}`,
        method: "POST",
      }),
    }),
    removeFromCart: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_MARKETPLACE_API_URL}/carts/remove-product`,
        method: "DELETE",
        body,
      }),
    }),
    decreaseQuantityFromCart: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_MARKETPLACE_API_URL}/carts/decrease-quantity`,
        method: "DELETE",
        body,
      }),
    }),
  }),
});

export const {
  useGetCartProductsQuery,
  useAddToCartMutation,
  useSendCommandMutation,
  useRemoveFromCartMutation,
  useDecreaseQuantityFromCartMutation,
} = marketplaceApiSlice;
