import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/login-email`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    logout: builder.mutation<any, void>({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/auth/logout`,
        method: "DELETE",
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
        method: "POST",
        body: { email },
      }),
    }),
    resetPassword: builder.mutation({
      query: (body: {
        token: string | undefined;
        newPassword: string;
        newPasswordConfirm: string;
      }) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/reactivate-password`,
        method: "POST",
        body: { ...body },
      }),
    }),
    createAccount: builder.mutation({
      query: (body: {
        token: string | undefined;
        newPassword: string;
        newPasswordConfirm: string;
      }) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/activate-account`,
        method: "POST",
        body: { ...body },
      }),
    }),
    getValidateForgotPasswordToken: builder.query<any, any>({
      query: (token) =>
        `${process.env.REACT_APP_CONSUMER_API_URL}/auth/validate-forgot-password-token/${token}`,
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    getValidateInvitationToken: builder.query<any, any>({
      query: (token) =>
        `${process.env.REACT_APP_CONSUMER_API_URL}/auth/validate-invitation-token/${token}`,
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useCreateAccountMutation,
  useGetValidateForgotPasswordTokenQuery,
  useGetValidateInvitationTokenQuery,
} = authApiSlice;
