import React, { useState } from "react";
import { toast } from "react-toastify";

import {
  useAcceptProductMutation,
  useDeclineProductMutation,
  useGetProductsQuery,
} from "app/marketplace/marketplaceApiSlice";

import { ConfirmationPopup, IsLoading, PageHeader, Pagination, ProductTeaser } from "components";
import { errorHandler, GlobalStrings } from "utils";

import { adminRoutesPath } from "../routes";
import styles from "./Marketplace.module.scss";

const Marketplace = () => {
  const [acceptProduct] = useAcceptProductMutation();
  const [declineProduct] = useDeclineProductMutation();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { data, isLoading, refetch } = useGetProductsQuery(
    {
      pageIndex,
      pageSize,
      acceptanceStatus: "pending",
    },
    { refetchOnMountOrArgChange: true }
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [{ modalType, product }, setAction] = useState<any>({
    modalType: "",
    product: {},
  });
  const [declineReason, setDeclineReason] = useState("");

  const acceptProductHandler = async (productId: number) => {
    try {
      await acceptProduct(productId).unwrap();
      toast.success(GlobalStrings.marketplace.productAccepted);
      setModalIsOpen(false);
      refetch();
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const declineProductHandler = async (productId: number) => {
    try {
      await declineProduct({
        productId,
        reasonForDeclining: declineReason,
      }).unwrap();
      setDeclineReason("");
      setModalIsOpen(false);
      toast.success(GlobalStrings.marketplace.productDeclined);
      refetch();
    } catch (error: any) {
      errorHandler(error);
    }
  };

  return (
    <section className={styles.marketplace}>
      <PageHeader title={GlobalStrings.marketplace.marketplaceTitle} />
      {isLoading ? (
        <IsLoading />
      ) : (
        <div>
          {!data?.products?.length && <h4>{GlobalStrings.marketplace.noProductsToReview}</h4>}
          <div className={styles.marketplace_grid}>
            {data?.products?.length > 0 &&
              data?.products?.map((item: any, index: number) => {
                return (
                  <ProductTeaser
                    key={index}
                    id={item.id}
                    productName={item.name}
                    productImage={item.imagesKeys[0]}
                    price={item.price}
                    inStock={item.inStock}
                    unit={item.unit}
                    type="admin"
                    slug={adminRoutesPath.Marketplace.Product.replace("/:productId", "")}
                    acceptFunction={() => {
                      setAction({ modalType: "accept", product: item });
                      setModalIsOpen(true);
                    }}
                    declineFunction={() => {
                      setAction({ modalType: "decline", product: item });
                      setModalIsOpen(true);
                    }}
                  />
                );
              })}
          </div>
          {data?.products?.length ? (
            <div className={styles.pagination}>
              <Pagination
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageSize={pageSize}
                setPageSize={setPageSize}
                numberOfPages={data?.meta?.numberOfPages}
              />
            </div>
          ) : null}
        </div>
      )}
      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={setModalIsOpen}
        title={
          modalType === "accept"
            ? GlobalStrings.marketplace.acceptModal.title + " - " + product?.name
            : GlobalStrings.marketplace.declineModal.title + " - " + product?.name
        }
        body={
          modalType === "accept"
            ? GlobalStrings.marketplace.acceptModal.body
            : GlobalStrings.marketplace.declineModal.body
        }
        cancelText={GlobalStrings.cancel}
        confirmFunction={() =>
          modalType === "accept"
            ? acceptProductHandler(product?.id)
            : declineProductHandler(product?.id)
        }
        confirmText={GlobalStrings.yes}
        textareaValue={declineReason}
        setTextareaValue={modalType === "decline" ? setDeclineReason : undefined}
      />
    </section>
  );
};
export default Marketplace;
