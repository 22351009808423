import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { selectCurrentUser } from "app/auth/authSlice";
import {
  I_STATION,
  useDeleteStationMutation,
  useGetStationsQuery,
} from "app/containersAndStations/stationsApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ConfirmationPopup, TableBadge, TableComponent, TableIcon } from "components";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { StationStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

import { chargingParterRoutesPath } from "../../routes";

const StationsTable = () => {
  const user = useSelector(selectCurrentUser);
  const columnHelper = createColumnHelper<I_STATION>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<any>();
  const toggleModal = () => setModalIsOpen((prev) => !prev);
  const [deletStation] = useDeleteStationMutation();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const pagination = {
    pageIndex,
    pageSize,
  };

  const { data, isFetching, refetch, error } = useGetStationsQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
    companyId: user.company.id,
  });

  if (error) {
    errorHandler(error);
  }

  const deletStationHandler = async () => {
    try {
      await deletStation(userToDelete.id).unwrap();
      toggleModal();
      refetch();
      toast.success(GlobalStrings.containersAndStations.stationDeleted);
    } catch (error) {
      errorHandler(error);
    }
  };

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.name}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => (
        <TableBadge
          text={GlobalStrings.stationsStatus?.[info.getValue()]}
          color={
            info.getValue() === StationStatus.ACTIVE
              ? "green"
              : info.getValue() === StationStatus.MAINTENANCE
              ? "orange"
              : "red"
          }
        />
      ),
      header: () => <span>{GlobalStrings.containersAndStations.status}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row.power, {
      id: "power",
      cell: (info) => <p>{isNaN(info.getValue()) ? info.getValue() : `${info.getValue()} kWh`}</p>,
      header: () => <span>{GlobalStrings.formLabels.power}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row.connectors?.length, {
      id: "connectors",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.connectors}</span>,
      enableSorting: false,
      size: 1,
      minSize: 15,
    }),
    columnHelper.accessor((row) => row.container?.id, {
      id: "connector",
      cell: (info) => (
        <p>
          {info.getValue() ? (
            <Link
              to={chargingParterRoutesPath.ContainersAndStations.EditContainer.replace(
                ":id",
                info.getValue().toString()
              )}
            >
              {info.getValue()}
            </Link>
          ) : (
            NO_VALUE_SYMBOL
          )}
        </p>
      ),
      header: () => <span>{GlobalStrings.formLabels.container}</span>,
      enableSorting: false,
      size: 1,
      minSize: 15,
    }),

    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => (
        <div className="edit_delete_container">
          <Link
            to={chargingParterRoutesPath.ContainersAndStations.EditStation.replace(
              ":id",
              info.getValue().id.toString()
            )}
          >
            <TableIcon type="edit" />
          </Link>
          <TableIcon
            type="delete"
            onClickAction={() => {
              toggleModal();
              setUserToDelete(info.getValue());
            }}
          />
        </div>
      ),
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
    }),
  ];

  const table = useReactTable({
    data: data?.stations || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount: data?.meta?.numberOfPages || Math.ceil(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <>
      <TableComponent
        table={table}
        tableHasData={data?.stations?.length > 0}
        isLoading={isFetching}
      />
      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        title={GlobalStrings.containersAndStations.deleteStationModalTitle}
        body={GlobalStrings.containersAndStations.deleteStationModalBody}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={deletStationHandler}
      />
    </>
  );
};
export default StationsTable;
