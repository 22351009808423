import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import { useCreateAccountPartnerMutation } from "app/containersAndStations/containersAndStationsApiSlice";

import { AlertComponent, PageHeader, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { GlobalStrings, Regex } from "utils";
import { errorHandler } from "utils/globalFunctions";
import { I_PARTNER_USER } from "utils/types";

import { chargingParterRoutesPath } from "views/chargingPartner/routes";

const CreateAccount = () => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const [createAccount, { isLoading }] = useCreateAccountPartnerMutation();
  const { serverMessage, setServerMessage } = useServerMessage();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<I_PARTNER_USER>({
    defaultValues: {
      lastName: "",
      firstName: "",
      email: "",
      phone: "",
    },
  });

  const onSubmit = async (data: I_PARTNER_USER) => {
    try {
      await createAccount({
        id: user.company.id,
        user: {
          lastName: data.lastName,
          firstName: data.firstName,
          email: data.email,
          phone: data.phone,
        },
      }).unwrap();

      toast.success(GlobalStrings.partners.userWasSaved);
      navigate(chargingParterRoutesPath.Settings.Index);
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  return (
    <section>
      <PageHeader title={GlobalStrings.partners.createAccount} backRoute={"-1"} />
      <div className="card">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-100">
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.lastName}
                    type="text"
                  />
                )}
              />
              <Label for="lastName">{GlobalStrings.formLabels.lastName}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.firstName}
                    type="text"
                  />
                )}
              />
              <Label for="firstName">{GlobalStrings.formLabels.firstName}</Label>
            </FormGroup>
          </div>

          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-100">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  pattern: {
                    value: Regex.email,
                    message: GlobalStrings.formValidation.invalidEmail,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.email}
                    type="text"
                  />
                )}
              />
              <Label for="email">{GlobalStrings.formLabels.email}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  pattern: {
                    value: Regex.phone,
                    message: GlobalStrings.formValidation.invalidPhone,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.phoneNumber}
                    type="text"
                  />
                )}
              />
              <Label for="phone">{GlobalStrings.formLabels.phoneNumber}</Label>
            </FormGroup>
          </div>

          <SubmitButton isLoading={isLoading} label={GlobalStrings.formLabels.addUser} />

          <AlertComponent errors={errors} serverMessage={serverMessage} />
        </Form>
      </div>
    </section>
  );
};

export default CreateAccount;
