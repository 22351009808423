import { GlobalStrings } from "utils";
import {
  Accounts,
  AddEditContainer,
  AddEditStation,
  ContainersAndStations,
  CreateAccount,
} from "views/chargingPartner";

export const chargingParterRoutesPath = {
  ContainersAndStations: {
    ContainersMap: "/containers-and-stations/containers/map",
    StationsMap: "/containers-and-stations/stations/map",
    Containers: "/containers-and-stations/containers",
    Stations: "/containers-and-stations/stations",
    Prices: "/containers-and-stations/prices",
    AddStation: "/containers-and-stations/stations/add",
    AddContainer: "/containers-and-stations/containers/add",
    EditStation: "/containers-and-stations/stations/:id/edit",
    EditContainer: "/containers-and-stations/contaiers/:id/edit",
  },
  Settings: {
    Index: "/settings/accounts",
    CreateAccount: "/settings/accounts/add",
  },
};

export const chargingPartnerRoutes = [
  {
    path: chargingParterRoutesPath.ContainersAndStations.ContainersMap,
    Component: ContainersAndStations,
    metaTitle: GlobalStrings.SEO.chargingPartner.containersMap,
  },
  {
    path: chargingParterRoutesPath.ContainersAndStations.StationsMap,
    Component: ContainersAndStations,
    metaTitle: GlobalStrings.SEO.chargingPartner.rechargeStationsMap,
  },
  {
    path: chargingParterRoutesPath.ContainersAndStations.Containers,
    Component: ContainersAndStations,
    metaTitle: GlobalStrings.SEO.chargingPartner.containers,
  },
  {
    path: chargingParterRoutesPath.ContainersAndStations.Prices,
    Component: ContainersAndStations,
    metaTitle: GlobalStrings.SEO.chargingPartner.rechargeStationsPrices,
  },
  {
    path: chargingParterRoutesPath.ContainersAndStations.Stations,
    Component: ContainersAndStations,
    metaTitle: GlobalStrings.SEO.chargingPartner.rechargeStations,
  },
  {
    path: chargingParterRoutesPath.ContainersAndStations.AddStation,
    Component: AddEditStation,
    metaTitle: GlobalStrings.SEO.chargingPartner.addRechargeStation,
  },
  {
    path: chargingParterRoutesPath.ContainersAndStations.EditStation,
    Component: AddEditStation,
    metaTitle: GlobalStrings.SEO.chargingPartner.addRechargeStation,
  },
  {
    path: chargingParterRoutesPath.ContainersAndStations.EditStation,
    Component: AddEditStation,
    metaTitle: GlobalStrings.SEO.chargingPartner.rechargeStation,
  },
  {
    path: chargingParterRoutesPath.ContainersAndStations.AddContainer,
    Component: AddEditContainer,
    metaTitle: GlobalStrings.SEO.chargingPartner.addRechargeStation,
  },
  {
    path: chargingParterRoutesPath.ContainersAndStations.EditContainer,
    Component: AddEditContainer,
    metaTitle: GlobalStrings.SEO.chargingPartner.rechargeStation,
  },
  {
    path: chargingParterRoutesPath.Settings.Index,
    Component: Accounts,
    metaTitle: GlobalStrings.SEO.chargingPartner.accounts,
  },
  {
    path: chargingParterRoutesPath.Settings.CreateAccount,
    Component: CreateAccount,
    metaTitle: GlobalStrings.SEO.chargingPartner.addAcount,
  },
];
