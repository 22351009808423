import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useGetEndUserQuery } from "app/users/usersApiSlice";

import { IsLoading, PageHeader, SEO } from "components";
import { errorHandler, GlobalStrings } from "utils";

import ChargedVehicles from "./ChargedVehicles/ChargedVehicles";
import CreditCards from "./CrediCards/CreditCards";
import Documents from "./Documents/Documents";
import EndUserProfile from "./EndUserProfile/EndUserProfile";
import Incidents from "./Incidents/Incidents";
import Payments from "./Payments/Payments";
import Trips from "./Trips/Trips";
import Vouchers from "./Vouchers/Vouchers";

import { adminRoutesPath } from "../routes";
import styles from "./EndUser.module.scss";

const EndUser = () => {
  const { userId } = useParams();
  const location = useLocation();
  const {
    data: endUser,
    isLoading,
    error,
    refetch,
  } = useGetEndUserQuery({ userId }, { skip: !userId });
  const [activeIndex, setActiveIndex] = useState(0);

  const tabs = [
    {
      id: 0,
      name: GlobalStrings.personUsers.payments,
      slug: adminRoutesPath.EndUsers.EndUserPayments.replace(":userId", userId?.toString() || ""),
    },
    {
      id: 1,
      name: GlobalStrings.personUsers.creditCards,
      slug: adminRoutesPath.EndUsers.EndUserCards.replace(":userId", userId?.toString() || ""),
    },
    {
      id: 2,
      name: GlobalStrings.personUsers.trips,
      slug: adminRoutesPath.EndUsers.EndUserTrips.replace(":userId", userId?.toString() || ""),
    },
    {
      id: 3,
      name: GlobalStrings.personUsers.documents,
      slug: adminRoutesPath.EndUsers.EndUserDocuments.replace(":userId", userId?.toString() || ""),
    },
    {
      id: 4,
      name: GlobalStrings.personUsers.chargeVehicles,
      slug: adminRoutesPath.EndUsers.EndUserBookings.replace(":userId", userId?.toString() || ""),
    },
    {
      id: 5,
      name: GlobalStrings.personUsers.vouchers,
      slug: adminRoutesPath.EndUsers.EndUserVouchers.replace(":userId", userId?.toString() || ""),
    },
    {
      id: 6,
      name: GlobalStrings.personUsers.incidents,
      slug: adminRoutesPath.EndUsers.EndUserIncidents.replace(":userId", userId?.toString() || ""),
    },
  ];

  useEffect(() => {
    errorHandler(error);
  }, [error]);

  useEffect(() => {
    if (userId) {
      const tab = tabs.find((item) => item.slug === location.pathname);
      setActiveIndex(tab?.id || 0);
    }
  }, [userId, location.pathname]);

  return isLoading ? (
    <IsLoading />
  ) : (
    <section className={styles.end_user}>
      <SEO
        title={
          endUser?.firstName
            ? `${endUser?.firstName} ${endUser?.lastName}${GlobalStrings.SEO.admin.endUser}`
            : `${endUser?.phone}${GlobalStrings.SEO.admin.endUser}`
        }
      />
      <PageHeader title={GlobalStrings.personUsers.endUser} backRoute="-1" />
      <div className="d-flex flex-md-row flex-column gap-5">
        <div className="card w-md-25 h-max w-100">
          {endUser && <EndUserProfile endUser={endUser} refetch={refetch} />}
        </div>

        <div className="card w-md-75 h-max w-100">
          <PageHeader
            title=""
            tabsCurrentview={tabs}
            tabsCurrentviewActiveIndex={activeIndex}
            onTabClick={setActiveIndex}
          />
          {endUser && (
            <>
              {location.pathname === tabs[0].slug && <Payments />}
              {location.pathname === tabs[1].slug && <CreditCards cards={endUser.cards} />}
              {location.pathname === tabs[2].slug && <Trips userId={endUser.id} />}
              {location.pathname === tabs[3].slug && <Documents userId={endUser.id} />}
              {location.pathname === tabs[4].slug && <ChargedVehicles />}
              {location.pathname === tabs[5].slug && <Vouchers vouchers={endUser.vouchers} />}
              {location.pathname === tabs[6].slug && <Incidents refetch={refetch} />}
            </>
          )}
        </div>
      </div>
    </section>
  );
};
export default EndUser;
