// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoResults_no_results_container__Lb4vq {
  padding: 50px 20px;
}
.NoResults_no_results_container__Lb4vq h3 {
  font-size: 26px;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/components/NoResults/NoResults.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAGE;EACE,eAAA;EACA,WAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AADJ","sourcesContent":["@import \"../../styles/utils.scss\";\n\n.no_results_container {\n  padding: 50px 20px;\n\n  h3 {\n    font-size: 26px;\n    width: 100%;\n    display: flex;\n    gap: 10px;\n    align-items: center;\n    justify-content: center;\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no_results_container": `NoResults_no_results_container__Lb4vq`
};
export default ___CSS_LOADER_EXPORT___;
