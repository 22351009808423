import { GlobalStrings } from "utils";
import { AddUserToPartner, ReportsPartner, RequestsOffers, Vehicle } from "views/common";
import {
  AddEditVehicle,
  Cart,
  CartCheckout,
  Juicers,
  MarketplacePartnerProduct,
  MarketplacePartners,
  Orders,
  PartnerAccounts,
  PartnerDashboard,
  RequestOffer,
  ServicesPartners,
  Vehicles,
} from "views/partner";

export const partnerRoutesPath = {
  Dashboard: {
    Index: "/dashboard",
    TripsAndReports: "/dashboard/trips",
    GeneralInfo: "/dashboard/general-info",
    Prices: "/dashboard/prices",
  },
  Vehicles: {
    Index: "/vehicles",
    AddVehicle: "/vehicles/add",
    Vehicle: "/vehicles/:vehicleId/edit",
    Info: "/vehicles/:vehicleId/info",
  },
  Marketplace: {
    Index: "/marketplace",
    Product: "/marketplace/products/:productId",
    Cart: "/marketplace/cart",
    CartCheckout: "/marketplace/cart/checkout/:supplierId",
  },
  Reports: "/reports",
  Orders: "/orders",
  Services: {
    Cars: "/services/cars",
    Scooters: "/services/scooters",
    Bicycles: "/services/bicycles",
    Juicers: "/services/juicers",
  },
  RequestOffers: "/requests-offers",
  RequestOffersHistory: "/requests-offers-history",
  Accounts: {
    Index: "/accounts",
    CreateAccount: "/accounts/:id/add",
  },
};

export const partnerRoutes = [
  {
    path: partnerRoutesPath.Dashboard.Index,
    Component: PartnerDashboard,
    metaTitle: GlobalStrings.SEO.partner.vehiclesMap,
  },
  {
    path: partnerRoutesPath.Dashboard.TripsAndReports,
    Component: PartnerDashboard,
    metaTitle: GlobalStrings.SEO.partner.trips,
  },
  {
    path: partnerRoutesPath.Dashboard.GeneralInfo,
    Component: PartnerDashboard,
    metaTitle: GlobalStrings.SEO.partner.generalData,
  },
  {
    path: partnerRoutesPath.Dashboard.Prices,
    Component: PartnerDashboard,
    metaTitle: GlobalStrings.SEO.partner.prices,
  },
  {
    path: partnerRoutesPath.Vehicles.Index,
    Component: Vehicles,
    metaTitle: GlobalStrings.SEO.partner.vehicles,
  },
  {
    path: partnerRoutesPath.Vehicles.AddVehicle,
    Component: AddEditVehicle,
    metaTitle: GlobalStrings.SEO.partner.addVehicle,
  },
  {
    path: partnerRoutesPath.Vehicles.Vehicle,
    Component: AddEditVehicle,
    metaTitle: GlobalStrings.SEO.partner.editVehicle,
  },
  {
    path: partnerRoutesPath.Vehicles.Info,
    Component: Vehicle,
    metaTitle: GlobalStrings.SEO.partner.vehicle,
  },
  {
    path: partnerRoutesPath.Marketplace.Index,
    Component: MarketplacePartners,
    metaTitle: GlobalStrings.SEO.partner.marketplace,
  },
  {
    path: partnerRoutesPath.Marketplace.Product,
    Component: MarketplacePartnerProduct,
    metaTitle: GlobalStrings.SEO.partner.marketplace,
  },
  {
    path: partnerRoutesPath.Marketplace.Cart,
    Component: Cart,
    metaTitle: GlobalStrings.SEO.partner.marketplace,
  },
  {
    path: partnerRoutesPath.Marketplace.CartCheckout,
    Component: CartCheckout,
    metaTitle: GlobalStrings.SEO.partner.marketplace,
  },
  {
    path: partnerRoutesPath.Reports,
    Component: ReportsPartner,
    metaTitle: GlobalStrings.SEO.partner.reports,
  },
  {
    path: partnerRoutesPath.Orders,
    Component: Orders,
    metaTitle: GlobalStrings.SEO.partner.orders,
  },
  {
    path: partnerRoutesPath.Services.Cars,
    Component: ServicesPartners,
    metaTitle: GlobalStrings.SEO.partner.carsSuppliers,
  },
  {
    path: partnerRoutesPath.Services.Scooters,
    Component: ServicesPartners,
    metaTitle: GlobalStrings.SEO.partner.scootersSuppliers,
  },
  {
    path: partnerRoutesPath.Services.Bicycles,
    Component: ServicesPartners,
    metaTitle: GlobalStrings.SEO.partner.bikesSuppliers,
  },
  {
    path: partnerRoutesPath.Services.Juicers,
    Component: Juicers,
    metaTitle: GlobalStrings.SEO.partner.juicers,
  },
  {
    path: partnerRoutesPath.RequestOffers,
    Component: RequestOffer,
    metaTitle: GlobalStrings.SEO.partner.requestsOffer,
  },
  {
    path: partnerRoutesPath.RequestOffersHistory,
    Component: RequestsOffers,
    metaTitle: GlobalStrings.SEO.partner.requestsOffers,
  },
  {
    path: partnerRoutesPath.Accounts.Index,
    Component: PartnerAccounts,
    metaTitle: GlobalStrings.SEO.partner.accounts,
  },
  {
    path: partnerRoutesPath.Accounts.CreateAccount,
    Component: AddUserToPartner,
    metaTitle: GlobalStrings.SEO.partner.addAccount,
  },
];
