/* global google */
/// <reference types="@types/google.maps" />

import React, { useState, useCallback, useRef, useEffect } from "react";
import { GoogleMap, useLoadScript, Polygon } from "@react-google-maps/api";
import { GlobalVariables } from "utils";

interface LatLngLiteral {
  lat: number;
  lng: number;
}

interface PolygonData {
  id: number;
  paths: LatLngLiteral[];
  type: "allowed" | "restricted" | "drop off";
}

const mapContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "400px",
};

const Geofencing: React.FC = () => {
  const [polygons, setPolygons] = useState<PolygonData[]>([]);
  const [currentPolygonId, setCurrentPolygonId] = useState<number | null>(null);
  const [polygonType, setPolygonType] = useState<"allowed" | "restricted" | "drop off">("allowed");
  const [selectedPolygonId, setSelectedPolygonId] = useState<number | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
  });

  const onMapClick = useCallback((e: any) => {
    if (currentPolygonId !== null) {
      const latLng = e.latLng;
      if (latLng) {
        setPolygons((current) =>
          current.map((polygon) =>
            polygon.id === currentPolygonId
              ? {
                  ...polygon,
                  paths: [...polygon.paths, { lat: latLng.lat(), lng: latLng.lng() }],
                }
              : polygon
          )
        );
      }
    }
  }, [currentPolygonId]);

  const startNewPolygon = () => {
    const newPolygonId = polygons.length ? polygons[polygons.length - 1].id + 1 : 0;
    setPolygons((current) => [
      ...current,
      { id: newPolygonId, paths: [], type: polygonType },
    ]);
    setCurrentPolygonId(newPolygonId);
  };

  const deletePolygon = (id: number) => {
    setPolygons((current) => current.filter((polygon) => polygon.id !== id));
  };

  const savePolygons = async () => {
    console.log(JSON.stringify(polygons))
  };

  const onLoad = useCallback((map: any) => {
    mapRef.current = map;
  }, []);

  const highlightPolygon = (polygon: PolygonData) => {
    if (mapRef.current && polygon.paths.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      polygon.paths.forEach((point) => bounds.extend(point));
      mapRef.current.fitBounds(bounds);
    }
    setSelectedPolygonId(polygon.id);
  };

  useEffect(() => {
    if (selectedPolygonId !== null && mapRef.current) {
      const polygon = polygons.find((p) => p.id === selectedPolygonId);
      if (polygon) {
        highlightPolygon(polygon);
      }
    }
  }, [selectedPolygonId, polygons]);

  if (!isLoaded) return <div>Loading...</div>;

  const getPolygonOptions = (type: "allowed" | "restricted" | "drop off", isSelected: boolean) => {
    let color = "#000000";
    switch (type) {
      case "allowed":
        color = "#00FF00";
        break;
      case "restricted":
        color = "#FF0000";
        break;
      case "drop off":
        color = "#0000FF";
        break;
      default:
        break;
    }
    return {
      fillColor: color,
      fillOpacity: isSelected ? 0.6 : 0.4,
      strokeColor: color,
      strokeOpacity: isSelected ? 1.0 : 0.8,
      strokeWeight: 2,
    };
  };

  return (
    <div>
      <div>
        <label>Selecteaza tip Polygon:</label>
        <select
          value={polygonType}
          onChange={(e) => setPolygonType(e.target.value as "allowed" | "restricted" | "drop off")}
        >
          <option value="allowed">Allowed</option>
          <option value="restricted">Restricted</option>
          <option value="drop off">Drop Off</option>
        </select>
        <button onClick={startNewPolygon}>Adauga Polygon</button>
        <button onClick={savePolygons}>Salveaza Polygons</button>
      </div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={GlobalVariables.center}
        zoom={11}
        onClick={onMapClick}
        onLoad={onLoad}
      >
        {polygons.map((polygon) => (
          <Polygon
            key={polygon.id}
            paths={polygon.paths}
            options={getPolygonOptions(polygon.type, polygon.id === selectedPolygonId)}
            editable
            draggable
          />
        ))}
      </GoogleMap>
      <div>
        {polygons.map((polygon) => (
          <div key={polygon.id}>
            <span>Polygon {polygon.id} ({polygon.type})</span>
            <button onClick={() => highlightPolygon(polygon)}>Highlight</button>
            <button onClick={() => deletePolygon(polygon.id)}>Sterge</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Geofencing;
