import React, { useState } from "react";
import Switch from "react-switch";

import {
  useDisableMaintenanceMutation,
  useEnableMaintenanceMutation,
} from "app/settings/settingsApiSlice";

import { ConfirmationPopup } from "components";
import { errorHandler, GlobalStrings } from "utils";

const MaintenanceSettings = () => {
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [enableMaintenance] = useEnableMaintenanceMutation();
  const [disableMaintenance] = useDisableMaintenanceMutation();
  const toggleModal = () => setModalIsOpen((prev) => !prev);

  const changeStatusHandler = async (status: boolean) => {
    try {
      if (status) {
        await enableMaintenance().unwrap();
      } else {
        await disableMaintenance().unwrap();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const maintenanceHandler = () => {
    changeStatusHandler(!maintenanceMode);
    setMaintenanceMode((prev) => !prev);
    toggleModal();
  };

  return (
    <div>
      <h2>Setări Mentenanță</h2>
      <p>Glisează la dreapta pentru a activa pagina de mententanță în aplicație</p>
      <label htmlFor="disabled-switch">
        <Switch
          onChange={() => {
            setModalIsOpen(true);
          }}
          checked={maintenanceMode}
          className="react-switch"
          id="disabled-switch"
        />
      </label>
      <ConfirmationPopup
        title="Schimbare status pagină mentenanță"
        body="Ești sigur că vrei sa schimbi statusul paginii de mentenanță?"
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={maintenanceHandler}
      />
    </div>
  );
};

export default MaintenanceSettings;
