import React from "react";

import { useGetReportsOverallQuery } from "app/reports/reportsApiSlice";

import { BarChartComponent, IsLoading, PageHeader, PieChartComponent } from "components";
import { GlobalStrings } from "utils";
import { Currency } from "utils/enums";

type OverallReport = {
  total: number;
  totalUnpaid: number;
  averageNumberOfTripsBetweenChargesOverall: {
    forBicycles: number;
    forCars: number;
    forScooters: number;
  };
  averageDistanceBetweenChargesOverall: {
    forBicycles: number;
    forCars: number;
    forScooters: number;
  };
  averageAmountBetweenChargesOverall: {
    forBicycles: number;
    forCars: number;
    forScooters: number;
  };
};

const GeneralCard = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
  const { data: reportsOverallData, isFetching } = useGetReportsOverallQuery({
    startDate,
    endDate,
  });

  if (isFetching) {
    return (
      <div>
        <IsLoading />
      </div>
    );
  }

  const data: OverallReport = reportsOverallData.analyticsData;

  const paymentsData = [
    {
      name: GlobalStrings.analytics.totalPaid,
      value: +(data.total - data.totalUnpaid).toFixed(2),
      total: +data.total.toFixed(2),
      fill: "#06d6a0",
      currency: Currency.LEI,
    },
    {
      name: GlobalStrings.analytics.totalUnpaid,
      value: +data.totalUnpaid.toFixed(2),
      total: +data.total.toFixed(2),
      fill: "#dc2949",
      currency: Currency.LEI,
    },
  ];

  const averageTripsData = [
    {
      name: GlobalStrings.analytics.scooters,
      count: data.averageNumberOfTripsBetweenChargesOverall.forScooters,
    },
    {
      name: GlobalStrings.analytics.bikes,
      count: data.averageNumberOfTripsBetweenChargesOverall.forBicycles,
    },
    {
      name: GlobalStrings.analytics.cars,
      count: data.averageNumberOfTripsBetweenChargesOverall.forCars,
    },
  ];

  const averageDistanceData = [
    {
      name: GlobalStrings.analytics.scooters,
      count: data.averageDistanceBetweenChargesOverall.forScooters,
    },
    {
      name: GlobalStrings.analytics.bikes,
      count: data.averageDistanceBetweenChargesOverall.forBicycles,
    },
    {
      name: GlobalStrings.analytics.cars,
      count: data.averageDistanceBetweenChargesOverall.forCars,
    },
  ];

  const averageEarningsData = [
    {
      name: GlobalStrings.analytics.scooters,
      count: data.averageAmountBetweenChargesOverall.forScooters,
    },
    {
      name: GlobalStrings.analytics.bikes,
      count: data.averageAmountBetweenChargesOverall.forBicycles,
    },
    {
      name: GlobalStrings.analytics.cars,
      count: data.averageAmountBetweenChargesOverall.forCars,
    },
  ];

  return (
    <div>
      <div className="d-flex flex-column flex-md-row gap-5 mb-5 pb-5">
        <div className="w-100 w-md-50">
          <PageHeader title={GlobalStrings.analytics.totalPaymentsApp} />
          <PieChartComponent data={paymentsData} />
        </div>
        <div className="w-100 w-md-50">
          <PageHeader title={GlobalStrings.analytics.totalTripsCicle} />
          <BarChartComponent data={averageTripsData} />
        </div>
      </div>
      <hr />
      <div className="d-flex flex-column flex-md-row gap-5 pt-5">
        <div className="w-100 w-md-50">
          <PageHeader title={GlobalStrings.analytics.mediumTripPerCicle} />
          <BarChartComponent data={averageDistanceData} fillColor="#01699d" />
        </div>
        <div className="w-100 w-md-50">
          <PageHeader title={GlobalStrings.analytics.mediumEarningsperCicle} />
          <BarChartComponent data={averageEarningsData} fillColor="#ffaa33" />
        </div>
      </div>
    </div>
  );
};

export default GeneralCard;
