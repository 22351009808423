import React, { useState } from "react";
import { UncontrolledAccordion } from "reactstrap";

import { useGetSettingsQuery } from "app/settings/settingsApiSlice";

import classNames from "classnames";
import { IsLoading } from "components";
import { GlobalStrings } from "utils";

import Accordion from "./Accordion/Accordion";
import GlobalSettingForm from "./GlobalSettingForm/GlobalSettingForm";

const GlobalSettings = () => {
  const { data, isLoading } = useGetSettingsQuery({});
  const [formIsOpen, setFormIsOpen] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-end mb-2">
        <button
          className={classNames("btn", {
            "btn-success": !formIsOpen,
            "btn-primary": formIsOpen,
          })}
          onClick={() => setFormIsOpen((prev) => !prev)}
        >
          {!formIsOpen ? GlobalStrings.add : GlobalStrings.cancel}
        </button>
      </div>
      <div
        className={classNames("animated", {
          in: formIsOpen,
          out: !formIsOpen,
        })}
      >
        <GlobalSettingForm setFormIsOpen={setFormIsOpen} />
      </div>
      {isLoading ? (
        <IsLoading />
      ) : (
        <UncontrolledAccordion toggle={() => {}}>
          {data?.settings?.map((item: any) => {
            return <Accordion {...item} key={item.id} />;
          })}
        </UncontrolledAccordion>
      )}
    </>
  );
};
export default GlobalSettings;
