import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { selectCurrentUser, setCredentials } from "app/auth/authSlice";
import { useGetProductsQuery } from "app/marketplace/marketplaceApiSlice";

import { IsLoading } from "components";

const AppServicesAuth = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const { refetch } = useGetProductsQuery(
    {
      pageIndex: 0,
      pageSize: 0,
    },
    { refetchOnMountOrArgChange: false }
  );

  useEffect(() => {
    if (user?.id) {
      navigate("/app/services");
    }
  }, [user]);

  useEffect(() => {
    if (token && !user) {
      dispatch(
        setCredentials({
          user: { refreshToken: token },
          accessToken: null,
        })
      );
      refetch();
    }
  }, [token]);

  return (
    <section>
      <IsLoading />
    </section>
  );
};
export default AppServicesAuth;
