import React, { useState } from "react";
import Moment from "react-moment";
import { toast } from "react-toastify";

import { useUnblockUserForReservationMutation } from "app/users/usersApiSlice";

import { ConfirmationPopup, TableIcon } from "components";
import { calculateDuration, errorHandler, GlobalStrings, GlobalVariables } from "utils";
import { AccountStatus, TransportType } from "utils/enums";

import userPlaceholder from "assets/images/joe.svg";

import styles from "../EndUser.module.scss";

const EndUserProfile = ({ endUser, refetch }: { endUser: any; refetch: Function }) => {
  const [unblockUserForReservation] = useUnblockUserForReservationMutation();
  const [unlockModalIsOpen, setUnlockModalIsOpen] = useState(false);
  const toggleUnlockModal = () => setUnlockModalIsOpen((prev) => !prev);
  const status = endUser?.status as AccountStatus;

  const unblockUserForReservationHandler = async () => {
    try {
      await unblockUserForReservation({ id: endUser.id }).unwrap();
      toggleUnlockModal();
      refetch();

      toast.success(GlobalStrings.personUsers.userUnlocked);
    } catch (error) {
      errorHandler(error);
      toggleUnlockModal();
    }
  };

  return (
    <div>
      <div className="d-flex gap-2 align-items-center">
        <div className={styles.user_image}>
          <img src={userPlaceholder} />
        </div>
        <div className="w-100">
          <h5 className="mb-0">
            {endUser.firstName
              ? `${endUser.firstName} ${endUser.lastName}`
              : GlobalStrings.personUsers.unknownUser}
          </h5>
          <p className="mb-0">{endUser?.phone}</p>
          <p className="mb-0">{endUser?.email}</p>
        </div>
      </div>
      <hr />
      <div>
        <div className={styles.group}>
          <p>
            {GlobalStrings.formLabels.ID}: {endUser.id}
          </p>
          <p>{GlobalStrings.formLabels.accountCreatedAt}</p>
          <p>
            <strong>
              <Moment date={endUser.createdAt} format="DD.MM.YYYY, HH:mm" />
            </strong>
            <br />
            {calculateDuration(endUser.createdAt)}
          </p>
        </div>
        <div className={styles.group}>
          <p>{GlobalStrings.formLabels.status}</p>
          <p>
            <strong>{GlobalStrings.userAccountStatus[status]}</strong>
          </p>
        </div>
        <div className={styles.group}>
          <p>{GlobalStrings.formLabels.walletAvailableSold}</p>
          <p>
            <strong>
              {endUser.balance} {GlobalVariables.currency.RON}
            </strong>
          </p>
        </div>
        <div className={styles.group}>
          <p>{GlobalStrings.formLabels.isBlockedForReservation}</p>
          <p className="d-flex gap-2 align-items-center">
            <strong>
              {endUser?.isBlockedForReservation ? GlobalStrings.yes : GlobalStrings.no}
            </strong>
            <div className="w-7 cursor-pointer">
              {endUser?.isBlockedForReservation && (
                <TableIcon
                  type="unlock"
                  onClickAction={() => {
                    toggleUnlockModal();
                  }}
                />
              )}
            </div>
          </p>
        </div>
        <div className={styles.group}>
          <p>{GlobalStrings.personUsers.preferences}</p>
          <p>
            {endUser.transportTypes?.length > 0 ? (
              endUser.transportTypes.map((item: any, index: number) => {
                const type = item.type as TransportType;
                return (
                  <span key={index}>
                    <strong>
                      {item.name} - {GlobalStrings.vehicleTypes[type]}
                    </strong>
                  </span>
                );
              })
            ) : (
              <span>
                <strong>{GlobalStrings.personUsers.noPreferences}</strong>
              </span>
            )}
          </p>
        </div>
      </div>
      <ConfirmationPopup
        title={GlobalStrings.personUsers.unlockAccount}
        body={`${GlobalStrings.personUsers.unlockUser}?`}
        toggleModal={toggleUnlockModal}
        modalIsOpen={unlockModalIsOpen}
        cancelText={GlobalStrings.no}
        confirmText={GlobalStrings.yes}
        confirmFunction={unblockUserForReservationHandler}
      />
    </div>
  );
};
export default EndUserProfile;
