import React from "react";
import { Control, Controller } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { useGetCitiesQuery } from "app/common/commonApiSlice";

import classNames from "classnames";
import { GlobalStrings } from "utils";

const CitiesSelect = (props: { control: Control<any, any>; name: string; disabled?: boolean }) => {
  const animatedComponents = makeAnimated();
  const { data: cities } = useGetCitiesQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        required: GlobalStrings.formValidation.fieldsRequired,
      }}
      render={({ field, fieldState: { invalid } }) => (
        <Select
          className={classNames("react_select", {
            invalid,
          })}
          name={props.name}
          placeholder={GlobalStrings.formLabels.city}
          value={field.value}
          onChange={(selected) => {
            field.onChange(selected);
          }}
          closeMenuOnSelect={true}
          components={animatedComponents}
          options={cities}
          isDisabled={props.disabled}
        />
      )}
    />
  );
};

export default CitiesSelect;
