import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useCancelBookingMutation,
  useEndBookingMutation,
  useGetBookingHistoryQuery,
  useStartChargingMutation,
  useStopChargingMutation,
} from "app/chargedVehicles/chargedVehiclesApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ConfirmationPopup, TableBadge, TableComponent, TableIcon } from "components";
import moment from "moment";
import { errorHandler, getSortDetails, GlobalStrings } from "utils";
import { BookingStatus, BookingType, Currency } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_BOOKING_HISTORY } from "utils/types";

import { adminRoutesPath } from "views/admin/routes";

interface ConfirmationDetails {
  message: string;
  action: (() => Promise<void>) | null;
}

const BookingHistory = () => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const pagination = {
    pageIndex,
    pageSize,
  };

  const columnHelper = createColumnHelper<I_BOOKING_HISTORY>();
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, isFetching, refetch, error } = useGetBookingHistoryQuery({
    pageIndex,
    pageSize,
    sort: getSortDetails(sorting[0]),
  });

  if (error) {
    errorHandler(error);
  }

  const [startCharging] = useStartChargingMutation();
  const [stopCharging] = useStopChargingMutation();
  const [cancelBooking] = useCancelBookingMutation();
  const [endBooking] = useEndBookingMutation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);
  const [activeBooking, setActiveBooking] = useState<I_BOOKING_HISTORY>();
  const [confirmationDetails, setConfirmationDetails] = useState<ConfirmationDetails>({
    message: "",
    action: null,
  });

  const toggleModal = () => setModalIsOpen((prev) => !prev);
  const toggleInfoModal = () => setInfoModalIsOpen((prev) => !prev);

  const startChargingHandler = async (booking: I_BOOKING_HISTORY) => {
    try {
      await startCharging({ bookId: booking.id }).unwrap();
      toast.success(GlobalStrings.containersAndStations.chargingStart);
      refetch();
    } catch (error) {
      errorHandler(error);
    } finally {
      toggleModal();
    }
  };

  const stopChargingHandler = async (booking: I_BOOKING_HISTORY) => {
    try {
      await stopCharging({ bookId: booking.id }).unwrap();
      toast.success(GlobalStrings.containersAndStations.chargingStop);
      refetch();
    } catch (error) {
      errorHandler(error);
    } finally {
      toggleModal();
    }
  };

  const cancelChargingHandler = async (booking: I_BOOKING_HISTORY) => {
    try {
      await cancelBooking(booking.id).unwrap();
      toast.success(GlobalStrings.containersAndStations.bookDeleted);
      refetch();
    } catch (error) {
      errorHandler(error);
    } finally {
      toggleModal();
    }
  };

  const endBookingHandler = async (booking: I_BOOKING_HISTORY) => {
    try {
      await endBooking(booking.id).unwrap();
      toast.success(GlobalStrings.containersAndStations.bookEnded);
      refetch();
    } catch (error) {
      errorHandler(error);
    } finally {
      toggleModal();
    }
  };

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: "bookingId",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.ID}</span>,
      enableSorting: false,
      size: 1,
      minSize: 3,
    }),
    columnHelper.accessor((row) => row, {
      id: "companyName",
      cell: (info) => (
        <p>
          {info.getValue().container.companyName || info.getValue().chargingStation.companyName}
        </p>
      ),
      header: () => <span>{GlobalStrings.formLabels.partner}</span>,
      enableSorting: false,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor(
      (row) => {
        if (row.isUserDeleted) {
          return <p>{row.userFullName}</p>;
        }
        return (
          <Link
            to={adminRoutesPath.EndUsers.EndUserPayments.replace(":userId", row.userId.toString())}
          >
            {row.userFullName}
          </Link>
        );
      },
      {
        id: "userFullName",
        cell: (info) => <>{info.getValue()}</>,
        header: () => <span>{GlobalStrings.formLabels.client}</span>,
        enableSorting: true,
        enableMultiSort: true,
        size: 1,
        minSize: 7,
      }
    ),
    columnHelper.accessor((row) => row.bookType, {
      id: "bookType",
      cell: (info) => <TableBadge text={GlobalStrings.bookingType[info.getValue()]} color="gray" />,
      header: () => <span>{GlobalStrings.formLabels.bookType}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 7,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => (
        <TableBadge
          text={GlobalStrings.bookingStatus[info.getValue()]}
          color={
            info.getValue() === BookingStatus.ACTIVE || info.getValue() === BookingStatus.CHARGING
              ? "green"
              : info.getValue() === BookingStatus.FINISHED
              ? "orange"
              : "red"
          }
        />
      ),
      header: () => <span>{GlobalStrings.formLabels.status}</span>,
      enableSorting: false,
      size: 1,
      minSize: 7,
    }),
    columnHelper.accessor(
      (row) => {
        const dateOfBook = new Date(row.createdAt);
        const formattedDateOfBook = `${dateOfBook.toLocaleDateString()} ${dateOfBook.toLocaleTimeString()}`;
        return formattedDateOfBook;
      },
      {
        id: "dateOfBook",
        cell: (info) => <p>{info.getValue()}</p>,
        header: () => <span>{GlobalStrings.formLabels.dateOfBook}</span>,
        enableSorting: true,
        enableMultiSort: true,
        size: 1,
        minSize: 10,
      }
    ),
    columnHelper.accessor(
      (row) => {
        return !row.chargeTime
          ? NO_VALUE_SYMBOL
          : `${row.chargeTime} ${GlobalStrings.formLabels.minutes}`;
      },
      {
        id: "selectedDuration",
        cell: (info) => <p>{info.getValue()}</p>,
        header: () => <span>{GlobalStrings.formLabels.selectedDuration}</span>,
        enableSorting: true,
        enableMultiSort: true,
        size: 1,
        minSize: 5,
      }
    ),
    columnHelper.accessor(
      (row) => {
        if (!row.chargingStartDate) {
          return NO_VALUE_SYMBOL;
        }

        return (
          <TableBadge
            color="green"
            text={moment(row.chargingStartDate).format("DD.MM.YYYY, HH:mm:ss")}
          />
        );
      },
      {
        id: "chargingStartDate",
        cell: (info) => <>{info.getValue()}</>,
        header: () => <span>{GlobalStrings.formLabels.startDate}</span>,
        enableSorting: true,
        enableMultiSort: true,
        size: 1,
        minSize: 10,
      }
    ),
    columnHelper.accessor(
      (row) => {
        if (!row.chargingEndDate) {
          return NO_VALUE_SYMBOL;
        }

        return (
          <TableBadge
            color="red"
            text={moment(row.chargingEndDate).format("DD.MM.YYYY, HH:mm:ss")}
          />
        );
      },
      {
        id: "chargingEndDate",
        cell: (info) => <>{info.getValue()}</>,
        header: () => <span>{GlobalStrings.formLabels.endDate}</span>,
        enableSorting: true,
        enableMultiSort: true,
        size: 1,
        minSize: 10,
      }
    ),
    columnHelper.accessor((row) => row.totalKwUsed, {
      id: "totalKwUsed",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.formLabels.totalKwUsed}</span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
    }),
    columnHelper.accessor(
      (row) => {
        if (+row?.payment?.payment?.initialPayment >= 0) {
          return (
            <TableBadge
              text={`${row.payment.payment.initialPayment}<br/>${Currency.RON}`}
              color="gray"
            />
          );
        }

        return NO_VALUE_SYMBOL;
      },
      {
        id: "startAmount",
        cell: (info) => <>{info.getValue()}</>,
        header: () => <span>{GlobalStrings.formLabels.startAmount}</span>,
        enableSorting: false,
        size: 1,
        minSize: 6,
      }
    ),
    columnHelper.accessor(
      (row) => {
        const initialAmount = +row.payment.payment.initialPayment;
        const endAmount = +row.amount;
        if (endAmount >= 0 && initialAmount >= 0) {
          const diff = endAmount - initialAmount;
          return (
            <TableBadge
              text={`${diff.toFixed(2)}<br/>${Currency.RON}`}
              color={+diff.toFixed(2) > 0 ? "green" : +diff.toFixed(2) < 0 ? "red" : "gray"}
            />
          );
        }

        return NO_VALUE_SYMBOL;
      },
      {
        id: "differnce",
        cell: (info) => <p>{info.getValue()}</p>,
        header: () => <span>{GlobalStrings.formLabels.diffAmount}</span>,
        enableSorting: false,
        size: 1,
        minSize: 6,
      }
    ),
    columnHelper.accessor(
      (row) => {
        const endAmount = +row.amount;
        if (endAmount >= 0) {
          return <TableBadge text={`${endAmount.toFixed(2)}<br/>${Currency.RON}`} color="gray" />;
        }

        return NO_VALUE_SYMBOL;
      },
      {
        id: "endAmount",
        cell: (info) => <p>{info.getValue()}</p>,
        header: () => <span>{GlobalStrings.formLabels.endAmount}</span>,
        enableSorting: false,
        size: 1,
        minSize: 6,
      }
    ),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => {
        let isCharging = false;
        info.getValue().chargingSessions.forEach((item: any) => {
          if (item.status === BookingStatus.CHARGING) {
            isCharging = true;
          }
        });

        return (
          <div className="edit_delete_container grid">
            {(info.getValue().status === BookingStatus.ACTIVE ||
              (info.getValue().status === BookingStatus.CHARGING && !isCharging)) && (
              <TableIcon
                type="start"
                onClickAction={() => {
                  setConfirmationDetails({
                    message: GlobalStrings.containersAndStations.startChargingModalBody,
                    action: () => startChargingHandler(info.getValue()),
                  });
                  toggleModal();
                }}
                label={GlobalStrings.containersAndStations.startCharging}
                id={`start-charging-${info.getValue().id}`}
              />
            )}

            {isCharging && (
              <TableIcon
                id={`stop-charging-${info.getValue().id}`}
                type="stop"
                onClickAction={() => {
                  setConfirmationDetails({
                    message: GlobalStrings.containersAndStations.stopChargingModalBody,
                    action: () => stopChargingHandler(info.getValue()),
                  });
                  toggleModal();
                }}
                label={GlobalStrings.containersAndStations.stopCharging}
              />
            )}

            {info.getValue().status === BookingStatus.ACTIVE && (
              <TableIcon
                id={`delete-charging-${info.getValue().id}`}
                type="delete"
                onClickAction={() => {
                  setConfirmationDetails({
                    message: GlobalStrings.containersAndStations.deleteBookModalBody,
                    action: () => cancelChargingHandler(info.getValue()),
                  });
                  toggleModal();
                }}
                label={GlobalStrings.containersAndStations.deleteBook}
              />
            )}

            {(info.getValue().status === BookingStatus.ACTIVE ||
              (info.getValue().status === BookingStatus.CHARGING && !isCharging)) && (
              <TableIcon
                id={`end-charging-${info.getValue().id}`}
                type="stop"
                onClickAction={() => {
                  setConfirmationDetails({
                    message: GlobalStrings.containersAndStations.endBookModalBody,
                    action: () => endBookingHandler(info.getValue()),
                  });
                  toggleModal();
                }}
                label={GlobalStrings.containersAndStations.endBook}
              />
            )}

            <TableIcon
              type="info"
              onClickAction={() => {
                setActiveBooking(info.getValue());
                setInfoModalIsOpen(true);
              }}
            />
          </div>
        );
      },
      header: () => <span></span>,
      enableSorting: false,
      size: 1,
      minSize: 5,
    }),
  ];

  const table = useReactTable({
    data: data?.bookingHistory || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount: data?.meta?.numberOfPages || Math.ceil(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <>
      <TableComponent
        table={table}
        tableHasData={data ? data?.bookingHistory?.length > 0 : false}
        isLoading={isFetching}
      />
      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        body={confirmationDetails.message}
        confirmText={GlobalStrings.yes}
        cancelText={GlobalStrings.cancel}
        confirmFunction={() => {
          confirmationDetails.action && confirmationDetails.action();
        }}
      />
      <ConfirmationPopup
        modalIsOpen={infoModalIsOpen}
        toggleModal={toggleInfoModal}
        title={`${activeBooking?.userFullName}`}
        body={`<div class="map_pin w-100"><div><p><span>${
          GlobalStrings.formLabels.address
        }</span> <span>${activeBooking?.address}</span></p><p><span>${
          GlobalStrings.formLabels.chargedVehicleType
        }</span><span>${activeBooking?.vehicleInfo.model}</span></p><p><span>${
          GlobalStrings.formLabels.paymentStatus
        }</span><span> ${activeBooking?.payment.payment.status}</span></p>
        <p><span>${GlobalStrings.formLabels.paymentPanMask}</span><span> ${
          activeBooking?.payment.payment.panMask
        }</span></p><p><span>${GlobalStrings.formLabels.conectorID}</span><span> ${
          activeBooking?.chargingConnector.id
        }</span></p><p><span>${GlobalStrings.formLabels.userDate}</span><span> ${activeBooking?.bookType !== BookingType.FAST_CHARGE ? moment(
          activeBooking?.startDate
        ).format("DD.MM.YYYY, HH:mm:ss") : GlobalStrings.formLabels.intervalNotExisting} <br/> ${ activeBooking?.bookType !== BookingType.FAST_CHARGE ? moment(activeBooking?.endDate).format(
          "DD.MM.YYYY, HH:mm:ss"
        ): ""}</span></p></div></div>
       `}
        confirmText={GlobalStrings.cancel}
        confirmFunction={() => {
          toggleInfoModal();
        }}
      />
    </>
  );
};
export default BookingHistory;
