import ForgotPassword from "./ForgotPassword/ForgotPassword";
import NotAuthorised from "./NotAuthorised/NotAuthorised";
import Page404 from "./Page404/Page404";
import RequestsOffers from "./RequestsOffers/RequestsOffers";
import RequireAuth from "./RequireAuth/RequireAuth";
import ResetPassword from "./ResetPassword/ResetPassword";
import SetPassword from "./SetPassword/SetPassword";
import AddSupplierForm from "./Suppliers/AddSupplierForm/AddSupplierForm";
import AddSupplierUserForm from "./Suppliers/AddSupplierUserForm/AddSupplierUserForm";
import Vehicle from "./Vehicle/Vehicle";
import Public from "./Welcome/Welcome";
import AddUserToPartner from "views/common/Partners/UsersTable/AddUserToPartner/AddUserToPartner";
import ReportsPartner from "views/common/ReportsPartner/ReportsPartner";
import AppServicesAuth from "views/partner/AppServicesAuth/AppServicesAuth";
import LayoutAppMarketplace from "views/partner/LayoutAppMarketplace/LayoutAppMarketplace";
import MarketplaceAppAuth from "views/partner/MarketplaceAppAuth/MarketplaceAppAuth";

export {
  RequestsOffers,
  AddSupplierUserForm,
  ReportsPartner,
  AddUserToPartner,
  Vehicle,
  ForgotPassword,
  ResetPassword,
  SetPassword,
  NotAuthorised,
  MarketplaceAppAuth,
  AppServicesAuth,
  Public,
  AddSupplierForm,
  Page404,
  RequireAuth,
  LayoutAppMarketplace,
};
