import { logOut, setCredentials } from "./auth/authSlice";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.access_token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryReauth = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const refreshToken = getState().auth.user?.refreshToken;
    if (refreshToken) {
      headers.set("authorization", `Bearer ${refreshToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    const refreshResult = await baseQueryReauth(
      {
        url: `/auth/regenerate-token`,
        method: "PUT",
      },
      api,
      extraOptions
    );

    if (refreshResult?.data) {
      api.dispatch(
        setCredentials({
          user: {
            ...refreshResult?.data?.data?.user,
            roles: refreshResult?.data?.data?.user?.roles?.map((item) => item.name),
            role: refreshResult?.data?.data?.user?.roles[0].name,
          },
          accessToken: refreshResult?.data?.data?.token?.access_token,
        })
      );
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
