import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { selectMarketplaceIsUsedInApp } from "app/common/commonSlice";

import classNames from "classnames";
import { formatPrice, GlobalStrings } from "utils";
import { Currency } from "utils/enums";

import styles from "./CartSummary.module.scss";

const CartSummary = (props: { total: number; supplierName: string; supplierId: number }) => {
  const marketplaceIsUsedInApp = useSelector(selectMarketplaceIsUsedInApp);
  return (
    <div
      className={classNames(styles.cart_sum, {
        [styles.used_for_app]: marketplaceIsUsedInApp,
      })}
    >
      <h2>
        {GlobalStrings.marketplace.summary} - {props.supplierName}
      </h2>
      <p>{GlobalStrings.marketplace.total}</p>
      <p>
        {formatPrice(props.total)} {Currency.RON}
      </p>
      <Link className="btn" to={`checkout/${props.supplierId}`}>
        {GlobalStrings.marketplace.completeOrder}
      </Link>
    </div>
  );
};
export default CartSummary;
