import React from "react";
import { fromLatLng, setKey, setLanguage } from "react-geocode";
import { toast } from "react-toastify";

import axios from "axios";
import moment from "moment";

import { Currency, ServiceType, TransportType, UserRoles } from "./enums";
import GlobalStrings, { NO_VALUE_SYMBOL } from "./globalStrings";
import { GlobalVariables } from "./globalVariables";
import { I_SERVER_MESSAGE } from "./types";

export const validateField = (testValue: any, regex: any) => {
  return regex.test(testValue);
};

export const returnFirstErrorMessage = (errors: any) => {
  const keys = Object.keys(errors);
  const firstKey = keys[0];

  return errors[firstKey]?.message;
};

export const loadFromLocalStorage = (key: string) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const generateRandomId = function (length = 6) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
};

export const saveToLocalStorage = (key: string, value: any) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch (error) {
    console.error("localStorage write error:", error);
  }
};

export const getSortDetails = (sortState: { id: string; desc: boolean }) => {
  if (!sortState?.id) return null;
  return {
    sort_direction: sortState.desc === false ? "ASC" : "DESC",
    sort_order: sortState.id,
  };
};

export const calculateRemainingCapacity = (totalCapacity: number, levelOfBattery: number) => {
  if (!isNaN(levelOfBattery) && !isNaN(totalCapacity)) {
    const remainingEnergyCapacity = (levelOfBattery / 100) * totalCapacity;
    return Number(remainingEnergyCapacity.toFixed(1));
  } else return NO_VALUE_SYMBOL;
};

export const convertToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const base64Image = e.target.result;
      resolve(base64Image);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const formatPrice = (price: number) => {
  return new Intl.NumberFormat("en-US").format(price);
};

export const displayPriceForService = (price: any, serviceName: any, vehicleType: any) => {
  if (isNaN(price)) {
    return NO_VALUE_SYMBOL;
  } else {
    if (serviceName === ServiceType.RECHARGEBATTERY) {
      if (vehicleType === TransportType.CAR) {
        return `${formatPrice(price)} ${Currency.RON} / ${GlobalVariables.amountAC}`;
      } else {
        return `${formatPrice(price)} ${Currency.RON}`;
      }
    } else {
      return `${formatPrice(price)} ${Currency.RON}`;
    }
  }
};

export function calculatePosition(index: number, position: number) {
  const latLongDiff = 0.00001;
  return position + latLongDiff * index;
}

export function calculateDuration(creationDate: string): string {
  const currentDate: moment.Moment = moment();
  const accountCreationDate: moment.Moment = moment(creationDate);

  const duration: moment.Duration = moment.duration(currentDate.diff(accountCreationDate));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();

  if (years > 0 && months > 0) {
    if (years === 1 && months === 1) {
      return `${years} an, ${months} lună și ${days} zile`;
    } else if (years === 1) {
      return `${years} an, ${months} luni și ${days} zile`;
    } else return `${years} ani, ${months} luni și ${days} zile`;
  } else if (months > 0) {
    if (months === 1) {
      return `${months} lună și ${days} zile`;
    }
    return `${months} luni și ${days} zile`;
  } else return `${days} zile`;
}

export const roleValidation = (
  type: "admin" | "partner" | "supplier" | "all" | "charging_partner" | "partner_admin",
  roles: UserRoles[]
) => {
  switch (type) {
    case "admin":
      if (roles?.includes(UserRoles.admin) || roles?.includes(UserRoles.partial_admin)) {
        return true;
      }
      break;
    case "partner":
      if (roles?.includes(UserRoles.partner) || roles?.includes(UserRoles.partner_admin)) {
        return true;
      }
      break;
    case "supplier":
      if (roles?.includes(UserRoles.supplier) || roles?.includes(UserRoles.supplier_admin)) {
        return true;
      }
      break;
    case "charging_partner":
      if (roles?.includes(UserRoles.charging_station_partner_admin)) {
        return true;
      }
      break;
    case "partner_admin":
      if (roles?.includes(UserRoles.partner_admin)) {
        return true;
      }
      break;
    case "all":
      if (
        roles?.includes(UserRoles.supplier) ||
        roles?.includes(UserRoles.supplier_admin) ||
        roles?.includes(UserRoles.admin) ||
        roles?.includes(UserRoles.partial_admin) ||
        roles?.includes(UserRoles.partner) ||
        roles?.includes(UserRoles.partner_admin) ||
        roles?.includes(UserRoles.charging_station_partner_admin)
      ) {
        return true;
      }
      break;
    default:
      return false;
  }
  return false;
};

export const validateJSON = (text: any) => {
  try {
    JSON.parse(text);
  } catch (e) {
    return false;
  }
  return true;
};

export const getCenterCoordinates = (cities: [], city: string) => {
  if (cities?.length) {
    const cityCoordinates = cities?.find((item: any) => item.value === city) as any;

    return {
      lat: parseFloat(cityCoordinates?.latitude),
      lng: parseFloat(cityCoordinates?.longitude),
    };
  }
};

export const getAddressFromCoordinates = (
  lat: number,
  lng: number,
  setAddress: React.Dispatch<React.SetStateAction<string>>
) => {
  setKey(`${process.env.REACT_APP_GOOGLE_API_KEY}`);
  setLanguage("ro");
  let address = "";

  fromLatLng(lat, lng)
    .then(({ results }) => {
      address = results[1].formatted_address;
      setAddress(address);
    })
    .catch(console.error);
};

export const errorHandler = (
  error: any,
  isForm = false,
  setServerMessage?: React.Dispatch<React.SetStateAction<I_SERVER_MESSAGE>>,
  customMessage?: {
    400?: string;
    401?: string;
    403?: string;
    404?: string;
    500?: string;
  }
) => {
  console.log(error);

  if (error && error.status) {
    switch (error.status) {
      case 400:
        if (isForm && setServerMessage) {
          if (error?.data?.error?.message.includes("Duplicate entry")) {
            const duplicatedValue = error?.data?.error?.message.match(/'([^']+)'/)?.[0];
            setServerMessage({
              type: "danger",
              text: duplicatedValue + GlobalStrings.server.duplicated,
            });
          } else if (
            error?.data?.error?.message.includes("This service already exists for this company.")
          ) {
            setServerMessage({
              type: "danger",
              text: GlobalStrings.server.serviceExists,
            });
          } else {
            setServerMessage({
              type: "danger",
              text: customMessage?.[400] || GlobalStrings.server[400],
            });
          }
        } else {
          if (error?.data?.error?.message.includes("Duplicate entry")) {
            const duplicatedValue = error?.data?.error?.message.match(/'([^']+)'/)?.[0];
            toast.error(duplicatedValue + GlobalStrings.server.duplicated);
          } else if (
            error?.data?.error?.message.includes("This service already exists for this company.")
          ) {
            toast.error(GlobalStrings.server.serviceExists);
          } else {
            toast.error(customMessage?.[400] || GlobalStrings.server[400]);
          }
        }
        break;
      case 401:
        if (isForm && setServerMessage) {
          setServerMessage({
            type: "danger",
            text: customMessage?.[401] || GlobalStrings.server[401],
          });
        } else {
          toast.error(customMessage?.[401] || GlobalStrings.server[401]);
        }
        break;
      case 403:
        if (isForm && setServerMessage) {
          setServerMessage({
            type: "danger",
            text: customMessage?.[403] || GlobalStrings.server[403],
          });
        } else {
          toast.error(customMessage?.[403] || GlobalStrings.server[403]);
        }
        break;
      case 404:
        if (isForm && setServerMessage) {
          setServerMessage({
            type: "danger",
            text: customMessage?.[404] || GlobalStrings.server[404],
          });
        } else {
          toast.error(customMessage?.[404] || GlobalStrings.server[404]);
        }
        break;
      case 500:
        if (isForm && setServerMessage) {
          setServerMessage({
            type: "danger",
            text: customMessage?.[500] || GlobalStrings.server[500],
          });
        } else {
          toast.error(customMessage?.[500] || GlobalStrings.server[500]);
        }
        break;
      case 1478:
        if (isForm && setServerMessage) {
          setServerMessage({
            type: "danger",
            text: error?.data?.error?.message,
          });
        } else {
          toast.error(error?.data?.error?.message);
        }
        break;
      default:
        if (isForm && setServerMessage) {
          setServerMessage({
            type: "danger",
            text: GlobalStrings.server.noServerResponse,
          });
        } else {
          toast.error(GlobalStrings.server.noServerResponse);
        }
    }
  }
};

export const getDocumentImage = async (
  token: string,
  id: string | undefined,
  path:
    | "identity-card-face-admin"
    | "identity-card-back-admin"
    | "driver-licence-face-admin"
    | "driver-licence-back-admin"
) => {
  try {
    const imageUrl = `${process.env.REACT_APP_API_URL}/documents/${path}/${id}`;

    const response = await axios.get(imageUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    });

    return URL.createObjectURL(response.data);
  } catch (error) {
    errorHandler(error);
  }
};

export function decodePolyline(encoded: string): Array<[number, number]> {
  let index = 0;
  let lat = 0;
  let lng = 0;
  const coordinates: Array<[number, number]> = [];
  const factor = 1e5;

  while (index < encoded.length) {
    // eslint-disable-next-line one-var
    let b,
      shift = 0,
      result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    const dlat = result & 1 ? ~(result >> 1) : result >> 1;
    lat += dlat;

    shift = result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    const dlng = result & 1 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    coordinates.push([lat / factor, lng / factor]);
  }

  return coordinates;
}
