import React from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";

import { flexRender, Table } from "@tanstack/react-table";

const TableHeading = ({ table, tableTitle }: { table: Table<any>; tableTitle?: string }) => {
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup, index) => (
        <React.Fragment key={index}>
          {tableTitle && (
            <tr key="header">
              <th colSpan={headerGroup.headers.length} className="text-center">
                {tableTitle}
              </th>
            </tr>
          )}

          <tr key={index}>
            {headerGroup.headers.map((header, index) => (
              <th
                key={index}
                style={header.getSize() !== 150 ? { width: header.getSize() + "%" } : {}}
                {...{
                  className: header.column.getCanSort()
                    ? "cursor-pointer select-none column_with_sorting"
                    : "",
                  onClick: header.column.getToggleSortingHandler(),
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
                {{
                  asc: <AiOutlineArrowUp className="mr-2" size={20} color="#fff" />,
                  desc: <AiOutlineArrowDown className="mr-2" size={20} color="#fff" />,
                }[header.column.getIsSorted() as string] ?? null}
              </th>
            ))}
          </tr>
        </React.Fragment>
      ))}
    </thead>
  );
};

export default TableHeading;
