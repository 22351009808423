import React from "react";
import { useNavigate } from "react-router-dom";

import { GlobalStrings } from "utils";
import { TransportType } from "utils/enums";

import bike from "assets/images/bicicleta.png";
import car from "assets/images/car.png";
import scooter from "assets/images/trotineta.png";

import styles from "./AppServices.module.scss";

const AppServices = () => {
  const navigate = useNavigate();
  const services = [
    {
      image: bike,
      name: GlobalStrings.appServices.bikes,
      slug: TransportType.BICYCLE,
    },
    {
      image: scooter,
      name: GlobalStrings.appServices.scooters,
      slug: TransportType.SCOOTER,
    },
    {
      image: car,
      name: GlobalStrings.appServices.cars,
      slug: TransportType.CAR,
    },
  ];
  return (
    <section className={styles.app_services}>
      <h1>{GlobalStrings.appServices.chooseVehicleType}</h1>
      <div className={styles.services_container}>
        {services?.map((item: any, index: number) => {
          return (
            <div className={styles.service} key={index} onClick={() => navigate(item.slug)}>
              <img src={item.image} alt={item.name} />
              <p>{item.name}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default AppServices;
