import React from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";
import { GlobalStrings } from "utils";

import email from "assets/icons/mail.svg";
import phone from "assets/icons/phone.svg";

import styles from "./ContactDetailsPopup.module.scss";

const ContactDetailsPopup = (props: {
  supplierName: string;
  phone: string;
  email: string;
  onClose: Function;
  isActive: boolean;
}) => {
  return (
    <div
      className={classNames(styles.contact_details, {
        [styles.active]: props.isActive,
      })}
    >
      <div className={styles.close_icon} onClick={() => props.onClose()}></div>
      <h2>{GlobalStrings.appServices.contactInfo}</h2>
      <div className={styles.contact_details_container}>
        <p>{props.supplierName}</p>
        <div className={styles.contact}>
          <img src={email} alt="email" />
          <Link to={`mailto:${props.email}`}>{props.email}</Link>
        </div>
        <div className={styles.contact}>
          <img src={phone} alt="phone" />
          <Link to={`tel:${props.phone}`}>{props.phone}</Link>
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsPopup;
