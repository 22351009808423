import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap";

import { useAddPartnerMutation } from "app/partners/partnersApiSlice";

import classNames from "classnames";
import { CitiesSelect, PageHeader } from "components";
import { GlobalStrings, GlobalVariables, Regex } from "utils";
import { errorHandler, returnFirstErrorMessage } from "utils/globalFunctions";

import { adminRoutesPath } from "views/admin/routes";

type FormData = {
  name: string;
  identifier: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  city: any;
  individualTransportVehicles: string;
};

const AddPartner = () => {
  const animatedComponents = makeAnimated();
  const [addTransportPartner] = useAddPartnerMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      identifier: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      city: "",
      individualTransportVehicles: "",
    },
  });

  const navigate = useNavigate();

  const onSubmit = async (data: FormData) => {
    try {
      const newPartnerResponse = await addTransportPartner({
        name: data.name,
        identifier: data.identifier,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        individualTransportVehicles: data.individualTransportVehicles,
        city: data.city.value,
      }).unwrap();

      toast.success(GlobalStrings.partners.partnerWasSaved);

      const partnerId = newPartnerResponse.data.id;

      navigate(adminRoutesPath.Partners.EditPartner.replace(":partnerId", partnerId.toString()));
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <section>
      <PageHeader title={`${GlobalStrings.partners.addPartner}`} backRoute="-1" />

      <Form onSubmit={handleSubmit(onSubmit)} className="card">
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="name"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.companyName}
                  type="text"
                />
              )}
            />
            <Label for="name">{GlobalStrings.formLabels.companyName}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="identifier"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.cifOrCnp,
                  message: GlobalStrings.formValidation.invalidCIForCNP,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.cifCnp}
                  type="text"
                />
              )}
            />

            <Label for="identifier">{GlobalStrings.formLabels.cifCnp}</Label>
          </FormGroup>
        </div>

        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.firstName}
                  type="text"
                />
              )}
            />
            <Label for="firstName">{GlobalStrings.formLabels.firstName}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.lastName}
                  type="text"
                />
              )}
            />

            <Label for="lastName">{GlobalStrings.formLabels.lastName}</Label>
          </FormGroup>
        </div>

        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="email"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.email,
                  message: GlobalStrings.formValidation.invalidEmail,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.email}
                  type="text"
                />
              )}
            />
            <Label for="email">{GlobalStrings.formLabels.email}</Label>
          </FormGroup>
          <FormGroup floating className="w-100">
            <CitiesSelect name="city" control={control} />
          </FormGroup>
        </div>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="phone"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.phone,
                  message: GlobalStrings.formValidation.invalidPhone,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.phoneNumber}
                  type="text"
                />
              )}
            />

            <Label for="phone">{GlobalStrings.formLabels.phoneNumber}</Label>
          </FormGroup>
          <FormGroup floating className="w-100">
            <Controller
              name="individualTransportVehicles"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field }) => (
                <Select
                  className={classNames("react_select", {
                    invalid: errors.individualTransportVehicles,
                  })}
                  name="individualTransportVehicles"
                  isMulti
                  placeholder={GlobalStrings.formLabels.publicTransportVehicles}
                  value={GlobalVariables.vehicleTypes.filter((option) =>
                    field.value?.split(", ").includes(option.value)
                  )}
                  onChange={(selected) => {
                    const value = selected.map((item) => item.value).join(", ");
                    field.onChange(value);
                  }}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  options={GlobalVariables.vehicleTypes}
                />
              )}
            />
          </FormGroup>
        </div>

        <div className="d-flex justify-content-start">
          <Button color="success" type="submit" className="d-flex align-item-center gap-2 mt-3 ">
            {GlobalStrings.formLabels.addPartner}
          </Button>
        </div>

        <Alert color="danger" fade={true} isOpen={Object.keys(errors).length > 0} className="mt-2">
          {returnFirstErrorMessage(errors) || GlobalStrings.formValidation.fieldsRequired}
        </Alert>
      </Form>
    </section>
  );
};

export default AddPartner;
