import React, { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "app/auth/authSlice";
import { I_ORDER, useGetOrdersQuery } from "app/marketplace/marketplaceApiSlice";

import {
  createColumnHelper,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { PageHeader, TableComponent } from "components";
import { errorHandler, formatPrice, getSortDetails, GlobalStrings, GlobalVariables } from "utils";

const Orders = () => {
  const user = useSelector(selectCurrentUser);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const columnHelper = createColumnHelper<I_ORDER>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { data, isFetching, error } = useGetOrdersQuery(
    {
      pageIndex,
      pageSize,
      sort: getSortDetails(sorting[0]),
      partnerId: user.company.id,
    },
    { skip: !user.company.id }
  );

  useEffect(() => {
    errorHandler(error);
  }, [error]);

  const columns = [
    columnHelper.accessor((row) => row, {
      id: "createdAt",
      cell: (info) => (
        <p>
          <Moment format="DD.MM.YYYY, HH:mm">{info.getValue().date}</Moment>
        </p>
      ),
      header: () => <span>{GlobalStrings.marketplace.orderDate}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row, {
      id: "supplier",
      cell: (info) => <p>{info.getValue().supplierName}</p>,
      header: () => <span>{GlobalStrings.formLabels.supplier}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
    columnHelper.accessor((row) => row, {
      id: "email",
      cell: (info) => (
        <p>
          {info.getValue().email}
          <br />
          {info.getValue().phone}
        </p>
      ),
      header: () => <span>{GlobalStrings.formLabels.contact}</span>,
      enableSorting: false,
      size: 1,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.products, {
      id: "products",
      cell: (info) => (
        <ul>
          {info.getValue().map((item, index) => {
            return (
              <li key={index}>
                {item.name} x {item.quantity}
                <br />
              </li>
            );
          })}
        </ul>
      ),
      header: () => <span>{GlobalStrings.marketplace.orderedProducts}</span>,
      enableSorting: false,
      size: 1,
      minSize: 50,
    }),
    columnHelper.accessor((row) => row.amount, {
      id: "amount",
      cell: (info) => <p>{formatPrice(info.getValue()) + " " + GlobalVariables.currency.RON}</p>,
      header: () => <span>{GlobalStrings.invoices.amount}</span>,
      enableSorting: true,
      enableMultiSort: true,
      size: 1,
      minSize: 10,
    }),
  ];

  const table = useReactTable({
    data: data?.orders || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    pageCount:
      data?.meta?.numberOfPages || Math.round(data?.meta?.totalRows / data?.meta?.pageSize),
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });
  return (
    <section>
      <div className="mb-4">
        <PageHeader title={GlobalStrings.menuPartners.orders} />
      </div>

      <TableComponent
        table={table}
        tableHasData={data?.orders?.length > 0}
        isLoading={isFetching}
      />
    </section>
  );
};

export default Orders;
