import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { selectCurrentUser } from "app/auth/authSlice";
import { useGetProductsQuery } from "app/marketplace/marketplaceApiSlice";
import { useDeleteActiveProductsMutation } from "app/products/activeProductsApiSlice";

import { ConfirmationPopup, IsLoading, PageHeader, Pagination, ProductTeaser } from "components";
import { errorHandler, GlobalStrings } from "utils";

import styles from "./DeclinedProducts.module.scss";

const DeclinedProducts = () => {
  const user = useSelector(selectCurrentUser);
  const [deleteProduct] = useDeleteActiveProductsMutation();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { data, isLoading, refetch, error } = useGetProductsQuery(
    {
      pageIndex,
      pageSize,
      acceptanceStatus: "declined",
      companyId: user.company.id,
    },
    { refetchOnMountOrArgChange: true, skip: !user }
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [product, setProduct] = useState<any>();

  const deleteProductHandler = async (productId: number) => {
    try {
      await deleteProduct(productId).unwrap();
      setModalIsOpen(false);
      toast.success(GlobalStrings.marketplace.productDeclined);
      refetch();
    } catch (error: any) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <section className={styles.marketplace}>
      <PageHeader title={GlobalStrings.marketplace.marketplaceTitle} />
      {isLoading ? (
        <IsLoading />
      ) : (
        <div>
          <div className={styles.marketplace_grid}>
            {data?.products?.length > 0 &&
              data?.products?.map((item: any, index: number) => {
                return (
                  <ProductTeaser
                    key={index}
                    id={item.id}
                    productName={item.name}
                    productImage={item.imagesKeys[0]}
                    price={item.price}
                    inStock={item.inStock}
                    unit={item.unit}
                    reasonForDeclining={item.reasonForDeclining}
                    type="supplier"
                    slug="/products"
                    declineFunction={() => {
                      setProduct(item);
                      setModalIsOpen(true);
                    }}
                  />
                );
              })}
          </div>
          {data?.products?.length === 0 && (
            <div>
              <h4>{GlobalStrings.marketplace.noProductsToReview}</h4>
            </div>
          )}
          {data?.products?.length > 0 && (
            <div className={styles.pagination}>
              <Pagination
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageSize={pageSize}
                setPageSize={setPageSize}
                numberOfPages={data?.meta?.numberOfPages}
              />
            </div>
          )}
        </div>
      )}
      <ConfirmationPopup
        modalIsOpen={modalIsOpen}
        toggleModal={setModalIsOpen}
        title={GlobalStrings.activeProducts.deleteProduct + " - " + product?.name}
        body={GlobalStrings.activeProducts.deleteProductModalBody}
        cancelText={GlobalStrings.cancel}
        confirmFunction={() => deleteProductHandler(product?.id)}
        confirmText={GlobalStrings.yes}
      />
    </section>
  );
};
export default DeclinedProducts;
