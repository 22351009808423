import { NO_VALUE_SYMBOL } from "utils/globalStrings";
import { I_TRIP } from "utils/types";

import { apiSlice } from "../apiSlice";

export const tripsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTrips: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, phone, userId, companyId, type }) => {
        let url = `${process.env.REACT_APP_VEHICLE_API_URL}/trips?`;
        if (pageIndex >= 0 && pageSize) {
          url += `pagination=true&page=${pageIndex + 1}&limit=${pageSize}&`;
        }
        if (sort) {
          url += `sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}&`;
        }
        if (typeof phone === "string" && phone !== "") {
          url += `phone=contains,${encodeURIComponent(phone)}&`;
        }
        if (userId) {
          url += `userId=${userId}&`;
        }
        if (companyId) {
          url += `companyId=${companyId}&`;
        }
        if (type) {
          url += `type=${type}&`;
        }
        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const trips: I_TRIP[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          const panMasks: string[] = [];
          item?.payments?.forEach((payment: any) => {
            if (!panMasks.includes(payment.panMask)) {
              panMasks.push(payment?.panMask);
            }
          });
          trips.push({
            id: item.id,
            userId: item?.userId,
            path: item?.path,
            companyId: item?.companyId || NO_VALUE_SYMBOL,
            lastName: item?.lastName || NO_VALUE_SYMBOL,
            firstName: item?.firstName || NO_VALUE_SYMBOL,
            phone: item?.phone || NO_VALUE_SYMBOL,
            startDate: item?.startDate || NO_VALUE_SYMBOL,
            endDate: item?.endDate || NO_VALUE_SYMBOL,
            amount: item?.amount || NO_VALUE_SYMBOL,
            type: item?.type || NO_VALUE_SYMBOL,
            tripStatus: item?.tripStatus || NO_VALUE_SYMBOL,
            refunded: item?.refunded || NO_VALUE_SYMBOL,
            durationInMinutes: item?.durationInMinutes || NO_VALUE_SYMBOL,
            isUserDeleted: item?.isUserDeleted || false,
            vehicle: {
              id: item.vehicle?.id,
              type: item?.vehicle?.type || NO_VALUE_SYMBOL,
              qrCode: item?.vehicle?.qrCode || NO_VALUE_SYMBOL,
            },
            company: {
              id: item.company?.id,
              name: item.company?.name || NO_VALUE_SYMBOL,
            },
            startPosition: {
              id: item?.startPosition?.id,
              latitude: item?.startPosition?.latitude || NO_VALUE_SYMBOL,
              longitude: item?.startPosition?.longitude || NO_VALUE_SYMBOL,
            },
            finishPosition: {
              id: item?.finishPosition?.id,
              latitude: item?.finishPosition?.latitude || NO_VALUE_SYMBOL,
              longitude: item?.finishPosition?.longitude || NO_VALUE_SYMBOL,
            },
            payments: item?.payments,
          });
        });
        return {
          trips,
          meta,
        };
      },
    }),
    endTrip: builder.mutation<any, any>({
      query: (tripId) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/trips/end-trip-admin/${tripId}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetTripsQuery, useEndTripMutation } = tripsApiSlice;
