import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useGetStationQuery } from "app/containersAndStations/stationsApiSlice";

import { PageHeader } from "components";
import { errorHandler } from "utils";
import GlobalStrings from "utils/globalStrings";

import { adminRoutesPath } from "../../routes";

import AddEditStationForm from "./AddEditStationForm";

const AddEditStation = () => {
  const location = useLocation();
  const { id } = useParams();
  const { data, refetch, error } = useGetStationQuery(id, {
    skip: id === undefined,
  });

  useEffect(() => {
    errorHandler(error);
  }, [error]);

  return (
    <section>
      <PageHeader title={GlobalStrings.containersAndStations.title} backRoute="-1" />

      {location.pathname.includes(adminRoutesPath.ContainersAndStations.AddStation) && (
        <AddEditStationForm refetch={refetch} />
      )}

      {id && data?.station && <AddEditStationForm data={data?.station} refetch={refetch} />}
    </section>
  );
};
export default AddEditStation;
