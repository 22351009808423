import React from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";

import { useGetVoucherStatusQuery } from "app/vouchers/vouchersApiSlice";

import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { TableComponent } from "components";
import { GlobalStrings } from "utils";
import { VoucherType } from "utils/enums";
import { I_VOUCHER } from "utils/types";

const Vouchers = (props: { vouchers: I_VOUCHER[] }) => {
  const { userId } = useParams();

  const columnHelper = createColumnHelper<I_VOUCHER>();

  const columns = [
    columnHelper.accessor((row) => row.code, {
      id: "code",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>{GlobalStrings.vouchers.promoCode}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.type, {
      id: "type",
      cell: (info) => (
        <p>
          {info.row.original.value}{" "}
          {info.getValue() === VoucherType.MINUTES
            ? GlobalStrings.vouchers.minutesType
            : GlobalStrings.vouchers.couponType}
        </p>
      ),
      header: () => <span>{GlobalStrings.vouchers.promoType}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.id, {
      id: "used",
      cell: (info) => {
        const { data: isUsed, isLoading } = useGetVoucherStatusQuery({
          voucherId: info.getValue(),
          userId: userId!,
        });

        if (isLoading) return <p>{GlobalStrings.vouchers.loading}</p>;
        return <p>{isUsed ? GlobalStrings.no : GlobalStrings.yes}</p>;
      },
      header: () => <span>{GlobalStrings.vouchers.used}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.endDate, {
      id: "endDate",
      cell: (info) => <Moment date={info.getValue()} format="DD.MM.YYYY, HH:mm:ss" />,
      header: () => <span>{GlobalStrings.vouchers.expiryDate}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.endDate, {
      id: "status",
      cell: (info) => {
        const dateToCheck = new Date(info.getValue());
        const currentDate = new Date();
        const isExpired = dateToCheck < currentDate;
        return <p>{isExpired ? GlobalStrings.vouchers.expired : GlobalStrings.vouchers.active}</p>;
      },
      header: () => <span>{GlobalStrings.vouchers.status}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 20,
    }),
    columnHelper.accessor((row) => row.unlookfree, {
      id: "unlock",
      cell: (info) => <p>{info.getValue() ? GlobalStrings.yes : GlobalStrings.no}</p>,
      header: () => <span>{GlobalStrings.vouchers.freeUnlock}</span>,
      enableSorting: false,
      enableMultiSort: true,
      size: 1,
      minSize: 20,
    }),
  ];

  const table = useReactTable({
    data: props?.vouchers || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: false,
  });

  return (
    <>
      <TableComponent table={table} tableHasData={props.vouchers.length > 0} isLoading={false} />
    </>
  );
};

export default Vouchers;
