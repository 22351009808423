import MarketplacePartners from "../partner/MarketplacePartner/MarketplacePartner";
import MarketplacePartnerProduct from "../partner/MarketplacePartnerProduct/MarketplacePartnerProduct";
import AiController from "./AiController/AiController";
import Geofencing from "./Geofencing/Geofencing";
import Analytics from "./Analytics/Analytics";
import AddEditContainer from "./ContainersAndStations/Containers/AddEditContainer";
import ContainersAndStations from "./ContainersAndStations/ContainersAndStations";
import AddEditStation from "./ContainersAndStations/Stations/AddEditStation";
import Dashboard from "./Dashboard/Dashboard";
import Document from "./Document/Document";
import Documents from "./Documents/Documents";
import EndUser from "./EndUser/EndUser";
import EndUsers from "./EndUsers/EndUsers";
import Invoices from "./Invoices/Invoices";
import Marketplace from "./Marketplace/Marketplace";
import MarketplaceProduct from "./MarketplaceProduct/MarketplaceProduct";
import AddPartner from "./Partners/AddPartner/AddPartner";
import PartnerEdit from "./Partners/PartnerEdit/PartnerEdit";
import Partners from "./Partners/Partners";
import AddPublicTransportPartners from "./PublicTransportParteners/AddPublicTransportPartners";
import PublicTransportPartners from "./PublicTransportParteners/PublicTransportPartners";
import PublicTransportPartnersEdit from "./PublicTransportParteners/PublicTransportPartnersEdit";
import Settings from "./Settings/Settings";
import Suppliers from "./Suppliers/Suppliers";
import EditSupplierAndProduct from "./Suppliers/SuppliersProducts/EditSupplierAndProduct/EditSupplierAndProduct";
import AddServiceForm from "./Suppliers/SuppliersServices/AddServiceForm/AddServiceForm";
import EditSupplierAndService from "./Suppliers/SuppliersServices/EditSupplierAndService/EditSupplierAndService";
import Trips from "./Trips/Trips";
import AdminVehicles from "./Vehicles/Vehicles";

export {
  Dashboard,
  Settings,
  Analytics,
  Invoices,
  Partners,
  AddPartner,
  PartnerEdit,
  Marketplace,
  MarketplaceProduct,
  ContainersAndStations,
  AddEditContainer,
  PublicTransportPartners,
  AddPublicTransportPartners,
  PublicTransportPartnersEdit,
  EndUsers,
  EndUser,
  Documents,
  Document,
  Trips,
  AiController,
  Geofencing,
  AdminVehicles,
  Suppliers,
  EditSupplierAndProduct,
  AddServiceForm,
  EditSupplierAndService,
  AddEditStation,
  MarketplacePartners,
  MarketplacePartnerProduct,
};
