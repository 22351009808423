import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { selectCurrentUser } from "app/auth/authSlice";
import { selectMarketplaceIsUsedInApp } from "app/common/commonSlice";

import classNames from "classnames";
import { GlobalStrings, roleValidation } from "utils";

import styles from "./Subcategories.module.scss";

const Subcategories = (props: { subcategories: any }) => {
  const marketplaceIsUsedInApp = useSelector(selectMarketplaceIsUsedInApp);
  const user = useSelector(selectCurrentUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const subcategoryHandler = (subcategory: any) => {
    if (searchParams.get("subcategory") === subcategory.id.toString()) {
      searchParams.delete("subcategory");
      setSearchParams(searchParams);
    } else {
      searchParams.set("subcategory", subcategory.id);
      setSearchParams(searchParams);
    }
  };

  return (
    <div
      className={classNames(styles.subcategories_container, {
        [styles.used_for_app]: marketplaceIsUsedInApp || roleValidation("admin", user?.roles),
      })}
    >
      <p className={styles.title}>{GlobalStrings.formLabels.subcategory}</p>
      <div className={styles.subcategories}>
        {props.subcategories?.map((item: any, index: number) => {
          return (
            <p
              key={index}
              className={classNames(styles.subcategory, {
                [styles.active]: searchParams.get("subcategory") === item.id.toString(),
              })}
              onClick={() => subcategoryHandler(item)}
            >
              {item.name}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default Subcategories;
