import React from "react";
import { AiOutlineUser } from "react-icons/ai";

import { useGetDashboardStatsQuery } from "app/stats/statsApiSlice";

import { VehiclesMap } from "components";
import { GlobalStrings } from "utils";

import bikeIcon from "assets/images/bicicleta.png";
import electricityIcon from "assets/images/energie-electrica.png";
import scooterIcon from "assets/images/trotineta.png";
import StatisticItem from "components/StatisticItem/StatisticItem";

const Dashboard = () => {
  const { data: stats } = useGetDashboardStatsQuery();

  const content = (
    <section className="dashboard">
      <div className="stats d-flex align-items-stretch justify-content-between gap-3 flex-column flex-md-row">
        <StatisticItem
          icon={<AiOutlineUser size={40} />}
          count={stats?.countLoggedUsers}
          title={GlobalStrings.dashboard.usersInApp}
        />
        <StatisticItem
          imageURL={scooterIcon}
          count={stats?.countScootersInUse}
          title={GlobalStrings.dashboard.scootersRidesInProgress}
        />
        <StatisticItem
          imageURL={bikeIcon}
          count={stats?.countBicyclesInUse}
          title={GlobalStrings.dashboard.bikesRidesInProgress}
        />
        <StatisticItem
          imageURL={electricityIcon}
          count={stats?.countContainersInUse}
          title={GlobalStrings.dashboard.noOfElectricityContainers}
        />
      </div>

      <div className="mt-3">
        <VehiclesMap />
      </div>
    </section>
  );

  return content;
};
export default Dashboard;
