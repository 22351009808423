// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TripsTable_select_container__Bo\\+dO {
  position: absolute;
  width: 150px;
  top: 0px;
  right: 0px;
  margin-right: 20px;
  margin-top: 15px;
}
.TripsTable_select_container__Bo\\+dO > div:first-child {
  height: 30px;
}
.TripsTable_select_container__Bo\\+dO > div:first-child > div {
  min-height: 30px !important;
}
.TripsTable_select_container__Bo\\+dO > div:first-child > div > div {
  padding: 0;
  padding-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/views/common/Partners/TripsTable/TripsTable.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,YAAA;EACA,QAAA;EACA,UAAA;EACA,kBAAA;EACA,gBAAA;AADF;AAGE;EACE,YAAA;AADJ;AAGI;EACE,2BAAA;AADN;AAGM;EACE,UAAA;EACA,iBAAA;AADR","sourcesContent":["@import \"styles/utils.scss\";\n\n.select_container {\n  position: absolute;\n  width: 150px;\n  top: 0px;\n  right: 0px;\n  margin-right: 20px;\n  margin-top: 15px;\n\n  & > div:first-child {\n    height: 30px;\n\n    & > div {\n      min-height: 30px !important;\n\n      & > div {\n        padding: 0;\n        padding-left: 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select_container": `TripsTable_select_container__Bo+dO`
};
export default ___CSS_LOADER_EXPORT___;
