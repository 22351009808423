import React from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import { Button } from "reactstrap";

import { useStartVehicleMutation, useStopVehicleMutation } from "app/vehicles/vehiclesApiSlice";

import { Battery, TableBadge } from "components";
import { calculateRemainingCapacity, errorHandler, GlobalStrings, GlobalVariables } from "utils";
import {
  ElectricityContainerStatus,
  StationStatus,
  TransportType,
  VehicleStatus,
} from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

import googleMapsImage from "assets/images/google_maps.svg";
import { adminRoutesPath } from "views/admin/routes";

const MarkerData = (props: {
  data: any;
  objectType: "vehicle" | "container" | "bus_stop" | "charging_station";
  refetch: Function;
}) => {
  const status = props?.data?.status as ElectricityContainerStatus;
  const stationStatus = props?.data?.status as StationStatus;
  const vehicleStatus = props?.data?.status as VehicleStatus;
  const type = props?.data?.type as TransportType;
  const [startVehicle, { isLoading: isLoadingStart }] = useStartVehicleMutation();
  const [stopVehicle, { isLoading: isLoadingStop }] = useStopVehicleMutation();

  const startVehicleHandler = async () => {
    try {
      await startVehicle(props.data?.id).unwrap();
      toast.success(GlobalStrings.partners.vehicleStarted);
      props.refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  const stopVehicleHandler = async () => {
    try {
      await stopVehicle(props.data?.id).unwrap();
      toast.success(GlobalStrings.partners.vehicleStoped);
      props.refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      {props.objectType === "vehicle" && (
        <div>
          <h4>{GlobalStrings.vehicleTypes[type]}</h4>
          <div className="maps_container">
            <p>#{props.data?.qrCode}</p>
            <Link
              to={`https://www.google.com/maps/dir/?api=1&destination=${props.data.position.latitude},${props.data.position.longitude}`}
              title={GlobalStrings.dashboard.showRoadToVehicle}
              target="_blank"
            >
              <ReactSVG src={googleMapsImage} />
            </Link>
          </div>
          <div className="mb-3">
            {
              <TableBadge
                text={"Status: " + GlobalStrings.vehicleStatus?.[vehicleStatus]}
                color={
                  props.data.status === VehicleStatus.ACTIVE
                    ? "green"
                    : props.data.status === VehicleStatus.IN_USE
                    ? "orange"
                    : "red"
                }
              />
            }
          </div>

          <p>
            <span>{GlobalStrings.formLabels.averageTravelTime}</span>
            <span>{props.data?.averageTravelTime || NO_VALUE_SYMBOL}</span>
          </p>
          <p>
            <span>{GlobalStrings.formLabels.averageTripsPerDay}</span>
            <span>{props.data?.averageTripsPerDay || NO_VALUE_SYMBOL}</span>
          </p>
          <p>
            <span>{GlobalStrings.formLabels.tripsSinceListCharge}</span>
            <span>{props.data?.tripsSinceListCharge || NO_VALUE_SYMBOL}</span>
          </p>
          <div className="d-flex gap-2 align-items-center justify-content-between">
            <Link
              to={adminRoutesPath.Partners.EditPartner.replace(
                ":partnerId",
                props.data.companyId.toString()
              )}
              className="mb-3 d-block"
            >
              {GlobalStrings.formLabels.detailsAboutPartner}
            </Link>
            <Link
              to={adminRoutesPath.Vehicles.Vehicle.replace(":vehicleId", props.data.id.toString())}
              className="mb-3 d-block"
            >
              {GlobalStrings.partners.vehicleDetails}
            </Link>
          </div>
          <div className="d-flex gap-1 align-items-center">
            {props.data.status === VehicleStatus.ACTIVE && (
              <Button
                color="success"
                outline
                size="sm"
                onClick={startVehicleHandler}
                disabled={isLoadingStart}
              >
                {GlobalStrings.start}
              </Button>
            )}
            {props.data.status === VehicleStatus.IN_USE && (
              <Button
                color="success"
                outline
                size="sm"
                onClick={stopVehicleHandler}
                disabled={isLoadingStop}
              >
                {GlobalStrings.stop}
              </Button>
            )}
            <Battery level={props.data?.batteryLevel} />
          </div>
        </div>
      )}
      {props.objectType === "container" && (
        <div>
          <h4>
            <Link
              to={adminRoutesPath.ContainersAndStations.EditContainer.replace(
                ":id",
                props.data.id.toString()
              )}
            >
              {GlobalStrings.containersAndStations.container}
            </Link>
          </h4>
          <p>
            <span>{GlobalStrings.formLabels.address}</span>
            <span>{props.data.address}</span>
          </p>
          <p>
            <span>{GlobalStrings.containersAndStations.totalCapacity}</span>
            <span>
              {isNaN(props.data.totalCapacity)
                ? NO_VALUE_SYMBOL
                : props.data.totalCapacity + " " + GlobalVariables.capacityUM}
            </span>
          </p>
          <p>
            <span>{GlobalStrings.containersAndStations.levelOfBattery}</span>
            <span>
              {isNaN(props.data.levelOfBattery)
                ? NO_VALUE_SYMBOL
                : props.data.levelOfBattery + ` %`}
            </span>
          </p>
          <p>
            <span>{GlobalStrings.containersAndStations.remainingEnergyCapacity}</span>
            <span>
              {isNaN(props.data.totalCapacity) || isNaN(props.data.levelOfBattery)
                ? NO_VALUE_SYMBOL
                : calculateRemainingCapacity(props.data.totalCapacity, props.data.levelOfBattery) +
                  ` ${GlobalVariables.capacityUM}`}
              {}
            </span>
          </p>
          <p>
            <span>{GlobalStrings.containersAndStations.numberOfDailyCharges}</span>
            <span>
              {isNaN(props.data.numberOfDailyCharges)
                ? NO_VALUE_SYMBOL
                : props.data.numberOfDailyCharges}
            </span>
          </p>
          <Link
            to={adminRoutesPath.ContainersAndStations.EditContainer.replace(
              ":id",
              props.data.id.toString()
            )}
            className="mb-3 d-block"
          >
            {GlobalStrings.formLabels.detailsAboutContainer}
          </Link>
          <div className="mt-4">
            {
              <TableBadge
                text={"Status: " + GlobalStrings.electricityContainerStatus?.[status]}
                color={
                  props.data.status === ElectricityContainerStatus.AVAILABLE
                    ? "green"
                    : props.data.status === ElectricityContainerStatus.CHARGING
                    ? "orange"
                    : "red"
                }
              />
            }
          </div>
        </div>
      )}
      {props.objectType === "charging_station" && (
        <div>
          <h4>
            <Link
              to={adminRoutesPath.ContainersAndStations.EditStation.replace(
                ":id",
                props.data.id.toString()
              )}
            >
              {props.data.name}
            </Link>
          </h4>
          <p>
            <span>{GlobalStrings.formLabels.address}</span>
            <span>{props.data.address}</span>
          </p>
          <p>
            <span>{GlobalStrings.formLabels.company}</span>
            <span>{props.data.companyName}</span>
          </p>
          <p>
            <span>{GlobalStrings.formLabels.power}</span>
            <span>{props.data.power} {GlobalVariables.amountAC}</span>
          </p>
          <div className="mt-4">
            {
              <TableBadge
                text={"Status: " + GlobalStrings.stationsStatus?.[stationStatus]}
                color={
                  props.data.status === StationStatus.ACTIVE
                    ? "green"
                    : props.data.status === StationStatus.OUTOFUSE
                    ? "orange"
                    : "red"
                }
              />
            }
          </div>
        </div>
      )}
      {props.objectType === "bus_stop" && (
        <div>
          <h4>{props.data?.name}</h4>
          <p>
            <span>{GlobalStrings.formLabels.latitude}</span>
            <span>{props.data?.latitude}</span>
          </p>
          <p>
            <span>{GlobalStrings.formLabels.longitude}</span>
            <span>{props.data?.longitude}</span>
          </p>
        </div>
      )}
    </>
  );
};

export default React.memo(MarkerData);
