import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Form, FormGroup, Input, InputGroupText, Label } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import { useUpdatePartnerMutation } from "app/partners/partnersApiSlice";

import { AlertComponent, PageHeader, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { errorHandler, GlobalStrings, Regex } from "utils";
import { TransportType } from "utils/enums";

import { I_PARTNER_FORM } from "views/common/Partners/PartnerForm/PartnerForm";

type I_FORM_DATA = {
  maxScooterBatteryLevelToPickUp: number;
  minScooterBatteryLevelToChargeTo: number;
  maxBicycleBatteryLevelToPickUp: number;
  minBicycleBatteryLevelToChargeTo: number;
};

const VehiclesBatteryLevels = (props: { data: I_PARTNER_FORM; refetch: Function }) => {
  const user = useSelector(selectCurrentUser);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<I_FORM_DATA>({
    defaultValues: {
      maxScooterBatteryLevelToPickUp: props.data.maxScooterBatteryLevelToPickUp || undefined,
      minScooterBatteryLevelToChargeTo: props.data.minScooterBatteryLevelToChargeTo || undefined,
      maxBicycleBatteryLevelToPickUp: props.data.maxBicycleBatteryLevelToPickUp || undefined,
      minBicycleBatteryLevelToChargeTo: props.data.minBicycleBatteryLevelToChargeTo || undefined,
    },
  });

  const [updatePartner, { isLoading }] = useUpdatePartnerMutation();
  const { serverMessage, setServerMessage } = useServerMessage();

  const onSubmit = async (data: I_FORM_DATA) => {
    try {
      const payload = {
        id: user.company.id,
        body: data,
      };
      await updatePartner(payload).unwrap();

      setServerMessage({
        type: "success",
        text: GlobalStrings.partners.partnerBatteryLevelsUpdated,
      });

      props.refetch();
    } catch (error: any) {
      errorHandler(error, true, setServerMessage);
    }
  };

  return (
    <section className="d-flex flex-column gap-2 mt-5">
      <PageHeader title={GlobalStrings.partners.batteryLevelJuicers} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        {props.data.individualTransportVehicles.includes(TransportType.SCOOTER) && (
          <>
            <h5>{GlobalStrings.vehicleTypes.scooter}</h5>
            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-100">
                <Controller
                  name="maxScooterBatteryLevelToPickUp"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.number,
                      message: GlobalStrings.formValidation.invalidNumber,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      placeholder={GlobalStrings.formLabels.maxScooterBatteryLevelToPickUp}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">%</InputGroupText>
                <Label for="maxScooterBatteryLevelToPickUp">
                  {GlobalStrings.formLabels.maxScooterBatteryLevelToPickUp}
                </Label>
              </FormGroup>

              <FormGroup floating className="w-100">
                <Controller
                  name="minScooterBatteryLevelToChargeTo"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.number,
                      message: GlobalStrings.formValidation.invalidNumber,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      placeholder={GlobalStrings.formLabels.minScooterBatteryLevelToChargeTo}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">%</InputGroupText>
                <Label for="minScooterBatteryLevelToChargeTo">
                  {GlobalStrings.formLabels.minScooterBatteryLevelToChargeTo}
                </Label>
              </FormGroup>
            </div>
          </>
        )}

        {props.data.individualTransportVehicles.includes(TransportType.BICYCLE) && (
          <>
            <h5>{GlobalStrings.vehicleTypes.bicycle}</h5>
            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-100">
                <Controller
                  name="maxBicycleBatteryLevelToPickUp"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.number,
                      message: GlobalStrings.formValidation.invalidNumber,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      placeholder={GlobalStrings.formLabels.maxScooterBatteryLevelToPickUp}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">%</InputGroupText>
                <Label for="maxBicycleBatteryLevelToPickUp">
                  {GlobalStrings.formLabels.maxScooterBatteryLevelToPickUp}
                </Label>
              </FormGroup>

              <FormGroup floating className="w-100">
                <Controller
                  name="minBicycleBatteryLevelToChargeTo"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.number,
                      message: GlobalStrings.formValidation.invalidNumber,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      placeholder={GlobalStrings.formLabels.minScooterBatteryLevelToChargeTo}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">%</InputGroupText>
                <Label for="minBicycleBatteryLevelToChargeTo">
                  {GlobalStrings.formLabels.minScooterBatteryLevelToChargeTo}
                </Label>
              </FormGroup>
            </div>
          </>
        )}

        <SubmitButton isLoading={isLoading} label={GlobalStrings.formLabels.save} />
      </Form>

      <AlertComponent errors={errors} serverMessage={serverMessage} />
    </section>
  );
};
export default VehiclesBatteryLevels;
