import React, { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Form, FormGroup, Input, Label } from "reactstrap";

import { selectMarketplaceIsUsedInApp } from "app/common/commonSlice";

import { useResponsive } from "ahooks";
import classNames from "classnames";
import { GlobalStrings } from "utils";

import Subcategories from "views/partner/MarketplacePartner/Subcategories/Subcategories";

import styles from "./Filters.module.scss";

const Filters = (props: {
  subcategories: any;
  attributes: {
    id: number;
    name: string;
    characteristics: {
      id: number;
      name: string;
    }[];
  }[];
  isActive: boolean;
  closeFilters: Function;
}) => {
  const responsive = useResponsive();
  const marketplaceIsUsedInApp = useSelector(selectMarketplaceIsUsedInApp);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (props.isActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [props.isActive]);

  const filtersHandler = (id: string) => {
    const characteristics = searchParams.getAll("characteristics");
    if (characteristics?.includes(id)) {
      const filteredCategories = characteristics?.filter((item: any) => item !== id);
      if (filteredCategories.length > 0) {
        searchParams.delete("characteristics");
        for (const filteredCategory of filteredCategories) {
          searchParams.append("characteristics", filteredCategory);
        }
        setSearchParams(searchParams);
      } else {
        searchParams.delete("characteristics");
        setSearchParams(searchParams);
      }
    } else {
      searchParams.append("characteristics", id);
      setSearchParams(searchParams);
    }
  };

  const resetFiltersHandler = () => {
    searchParams.delete("characteristics");
    searchParams.delete("subcategory");
    setSearchParams(searchParams);
  };

  return (
    <div
      className={classNames(styles.filters, {
        [styles.active]: props.isActive,
        [styles.used_for_app]: marketplaceIsUsedInApp,
      })}
    >
      <button className={styles.close_btn} onClick={() => props.closeFilters()} />
      {!responsive.md && (
        <button
          className="btn btn-link mb-3 d-flex align-items-center gap-1"
          onClick={resetFiltersHandler}
        >
          <AiOutlineClose /> {GlobalStrings.marketplace.resetFilters}
        </button>
      )}
      <Subcategories subcategories={props.subcategories} />
      <Form className={styles.attributes}>
        {props.attributes.map((item, index: number) => {
          return (
            <div key={index} className={classNames(styles.attribute_container, "card")}>
              <p className={styles.attribute_name}>{item.name}</p>
              <div className={styles.characteristics_container}>
                {item.characteristics.map((item, index: number) => {
                  return (
                    <FormGroup check key={index} className={styles.form_group}>
                      <Input
                        type="checkbox"
                        onClick={() => filtersHandler(item.id.toString())}
                        defaultChecked={
                          searchParams.getAll("characteristics").includes(item.id.toString()) ||
                          false
                        }
                      />
                      <Label check>{item.name}</Label>
                    </FormGroup>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Form>
    </div>
  );
};

export default Filters;
