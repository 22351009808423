import { apiSlice } from "../apiSlice";

type PublicTransportParteners = {
  id: number;
  name: string;
  phone: string;
  email: string;
  city: string;
  address: number;
  publicTransportVehicles: number;
  type: string;
};

export const publicTransportPartenersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPublicTransportPartners: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort }) =>
        `${process.env.REACT_APP_API_URL}/companies?pagination=true&page=${
          pageIndex + 1
        }&limit=${pageSize}${
          sort ? `&sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}` : ""
        }&type=public_transport`,
      keepUnusedDataFor: 3,
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const publicTransportParteners: PublicTransportParteners[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          publicTransportParteners.push({
            id: item.id,
            city: item?.city || "",
            address: item?.address || "",
            publicTransportVehicles: item?.publicTransportVehicles,
            name: item?.name || "",
            email: item?.email || "",
            phone: item?.phone || "",
            type: item?.type || "",
          });
        });
        return {
          publicTransportParteners,
          meta,
        };
      },
    }),

    getPublicTransportPartenersId: builder.query<any, any>({
      query: (id: number) => `${process.env.REACT_APP_API_URL}/companies/${id}`,
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),

    updatePublicTransportPartners: builder.mutation({
      query: (data: {
        body: {
          name: string;
          phone: string;
          email: string;
          city: string;
          publicTransportVehicles: string;
          address: string;
        };
        id?: string | number;
      }) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/${data.id}`,
        method: "PUT",
        body: { ...data.body },
      }),
    }),

    deletePublicTransportPartners: builder.mutation({
      query: (id: number) => ({
        url: `${process.env.REACT_APP_API_URL}/companies/${id}`,
        method: "DELETE",
      }),
    }),

    CreatePublicTransportParteners: builder.mutation<any, Partial<any>>({
      query: (newPublicTransportPartner) => ({
        url: `${process.env.REACT_APP_API_URL}/companies`,
        method: "POST",
        body: { ...newPublicTransportPartner, type: "public_transport" },
      }),
    }),
  }),
});

export const {
  useGetPublicTransportPartnersQuery,
  useGetPublicTransportPartenersIdQuery,
  useDeletePublicTransportPartnersMutation,
  useUpdatePublicTransportPartnersMutation,
  useCreatePublicTransportPartenersMutation,
} = publicTransportPartenersApiSlice;
