import { I_ACTIVE_SERVICES } from "utils/types";

import { apiSlice } from "../apiSlice";

export const activeServicesApiSlice = apiSlice.injectEndpoints({
  // TODO -> DELETE THIS FILE AND USE ONLY servicesApiSlice. Reason: DUPLICATED CODE.
  endpoints: (builder) => ({
    getActiveServices: builder.query<any, any>({
      query: ({ pageIndex, pageSize, sort, companyId }) =>
        `${process.env.REACT_APP_VEHICLE_API_URL}/services?pagination=true&page=${
          pageIndex + 1
        }&limit=${pageSize}${
          sort ? `&sort_direction=${sort.sort_direction}&sort_order=${sort.sort_order}` : ""
        }${companyId ? `&companyId=${companyId}` : ""}`,
      keepUnusedDataFor: 3,
      transformResponse: (response: any) => {
        const meta = {
          page: +response?.data?.page,
          numberOfPages: response?.data?.numberOfPages,
          pageSize: +response?.data?.pageSize,
          totalRows: +response?.data?.totalRows,
        };
        const activeServices: I_ACTIVE_SERVICES[] = [];
        response?.data?.pageData?.forEach((item: any) => {
          activeServices.push({
            id: item?.id,
            vehicleType: item?.vehicleType || "",
            serviceName: item?.serviceName || "",
            city: item?.city || "",
            coveredZone: item?.coveredZone || "",
            amountAC: item?.amountAC || "",
            amountDC: item?.amountDC || "",
          });
        });

        return {
          activeServices,
          meta,
        };
      },
    }),

    getActiveServicesId: builder.query<any, any>({
      query: (id: number) => `${process.env.REACT_APP_VEHICLE_API_URL}/services/${id}`,
      keepUnusedDataFor: 5,
      transformResponse: (response: any) => {
        return response?.data;
      },
    }),

    updateActiveServices: builder.mutation({
      query: (data: {
        body: {
          vehicleType: string;
          serviceName: string;
          city: string;
          coveredZone: string;
          amountAC: number;
          amountDC: number;
          companyId: number;
        };
        id?: string | number;
      }) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/services/${data.id}`,
        method: "PUT",
        body: { ...data.body },
      }),
    }),

    deleteActiveServices: builder.mutation({
      query: (id: number) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/services/${id}`,
        method: "DELETE",
      }),
    }),

    createActiveServices: builder.mutation<any, Partial<any>>({
      query: (newActiveServices) => ({
        url: `${process.env.REACT_APP_VEHICLE_API_URL}/services`,
        method: "POST",
        body: { ...newActiveServices },
      }),
    }),
  }),
});

export const {
  useGetActiveServicesQuery,
  useGetActiveServicesIdQuery,
  useUpdateActiveServicesMutation,
  useDeleteActiveServicesMutation,
  useCreateActiveServicesMutation,
} = activeServicesApiSlice;
