import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { TableBadge } from "components";
import { calculateRemainingCapacity, GlobalStrings, GlobalVariables } from "utils";
import { ElectricityContainerStatus } from "utils/enums";
import { NO_VALUE_SYMBOL } from "utils/globalStrings";

import { ElectricityContainers } from "../Containers/ContainersTable";
import greenPin from "assets/images/recharge_station_green.svg";
import { adminRoutesPath } from "views/admin/routes";

const ContainerMarker = (props: {
  clusterer: any;
  item: any;
  active: ElectricityContainers | undefined;
  setActive: any;
}) => {
  const google = window.google;
  const [MVCObject, setMVCObject] = useState(undefined);
  const handleOnLoad = useCallback((marker: any) => {
    setMVCObject(marker);
  }, []);

  return (
    <>
      <MarkerF
        onLoad={handleOnLoad}
        icon={{
          url: greenPin,
          scaledSize: new google.maps.Size(60, 60),
        }}
        position={{
          lat: +props.item.latitude,
          lng: +props.item.longitude,
        }}
        clusterer={props.clusterer}
        clickable
        onClick={() => props.setActive(props.item)}
      >
        {props.active && props.active?.id === props.item?.id && (
          <InfoWindowF
            anchor={MVCObject}
            onCloseClick={() => {
              setMVCObject(undefined);
              props.setActive(undefined);
            }}
            position={{
              lat: +props.item.latitude,
              lng: +props.item.longitude,
            }}
          >
            <div className="map_pin">
              <h4>
                <Link
                  to={adminRoutesPath.ContainersAndStations.EditContainer.replace(
                    ":id",
                    props.active.id.toString()
                  )}
                >
                  {props.active?.name || GlobalStrings.containersAndStations.container}
                </Link>
              </h4>
              <p>
                <span>{GlobalStrings.formLabels.address}</span>
                <span>
                  <Link
                    to={`https://www.google.com/maps/dir/?api=1&destination=${props.active.latitude},${props.active.longitude}`}
                    target="_blank"
                  >
                    {props.active.address}
                  </Link>
                </span>
              </p>
              <p>
                <span>{GlobalStrings.formLabels.company}</span>
                <span>{props.active.companyName}</span>
              </p>

              <p>
                <span>{GlobalStrings.containersAndStations.totalCapacity}</span>
                <span>
                  {!props.active.totalCapacity
                    ? NO_VALUE_SYMBOL
                    : props.active.totalCapacity + " " + GlobalVariables.capacityUM}
                </span>
              </p>
              <p>
                <span>{GlobalStrings.containersAndStations.levelOfBattery}</span>
                <span>
                  {!props.active.levelOfBattery
                    ? NO_VALUE_SYMBOL
                    : props.active.levelOfBattery + " %"}
                </span>
              </p>
              <p>
                <span>{GlobalStrings.containersAndStations.remainingEnergyCapacity}</span>
                <span>
                  {!props.active.totalCapacity || !props.active.levelOfBattery
                    ? NO_VALUE_SYMBOL
                    : calculateRemainingCapacity(
                        props.active.totalCapacity,
                        props.active.levelOfBattery
                      ) + ` ${GlobalVariables.capacityUM}`}
                </span>
              </p>
              <p>
                <span>{GlobalStrings.containersAndStations.numberOfDailyCharges}</span>
                <span>
                  {!props.active.numberOfDailyCharges
                    ? NO_VALUE_SYMBOL
                    : props.active.numberOfDailyCharges}
                </span>
              </p>
              <p>
                <span>{GlobalStrings.containersAndStations.stations}</span>
                <span>{props.active.stations?.length}</span>
              </p>
              <p>
                <span>{GlobalStrings.containersAndStations.connectors}</span>
                <span>{props.active.stations?.length}</span>
              </p>
              <div className="mt-4">
                {
                  <TableBadge
                    text={
                      "Status: " + GlobalStrings.electricityContainerStatus?.[props.active.status]
                    }
                    color={
                      props.active.status === ElectricityContainerStatus.AVAILABLE
                        ? "green"
                        : props.active.status === ElectricityContainerStatus.CHARGING
                        ? "orange"
                        : "red"
                    }
                  />
                }
              </div>
            </div>
          </InfoWindowF>
        )}
      </MarkerF>
    </>
  );
};

export default ContainerMarker;
