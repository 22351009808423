import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, FormGroup, Input, InputGroupText, Label } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import { useGetCitiesQuery } from "app/common/commonApiSlice";
import { useUpdateServiceMutation } from "app/services/servicesApiSlice";
import { useCreateActiveServicesMutation } from "app/suppliers/activeServicesApiSlice";

import { AlertComponent, CitiesSelect, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { GlobalVariables, Regex } from "utils";
import { Currency, ServiceType, TransportType } from "utils/enums";
import { errorHandler } from "utils/globalFunctions";
import GlobalStrings from "utils/globalStrings";
import { I_FORM_DATA_ACTIVE_SERVICES, I_SERVICE } from "utils/types";

import { supplierRoutesPath } from "views/supplier/routes";

const AddEditSupplierServiceForm = (props: { serviceData: I_SERVICE }) => {
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);

  const [createActiveServices, { isLoading }] = useCreateActiveServicesMutation();
  const [updateService, { isLoading: isLoadingUpdate }] = useUpdateServiceMutation();
  const { data: cities } = useGetCitiesQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const { serverMessage, setServerMessage } = useServerMessage();
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<I_FORM_DATA_ACTIVE_SERVICES>({
    defaultValues: {
      vehicleType: props.serviceData?.vehicleType || undefined,
      serviceName: props.serviceData?.serviceName || undefined,
      city: props.serviceData?.city || undefined,
      coveredZone: props.serviceData?.coveredZone || undefined,
      amountAC: props.serviceData?.amountAC?.toFixed(2) || "",
      amountDC: props.serviceData?.amountDC?.toFixed(2) || "",
      amount: props.serviceData?.amount?.toFixed(2) || "",
    },
  });

  const onSubmit = async (data: I_FORM_DATA_ACTIVE_SERVICES) => {
    try {
      const body: any = {
        vehicleType: data.vehicleType,
        serviceName: data.serviceName,
        city: data.city.value,
        coveredZone: data.coveredZone,
        companyId: user.company.id,
      };
      if (
        (data.vehicleType === TransportType.CAR ||
          data.vehicleType === TransportType.RIDESHARING) &&
        data.serviceName === ServiceType.RECHARGEBATTERY
      ) {
        body.amountAC = data.amountAC;
        body.amountDC = data.amountDC;
      } else {
        body.amount = data.amount;
      }

      if (!serviceId) {
        const newActiveServicesResponse = await createActiveServices(body).unwrap();
        toast.success(GlobalStrings.activeServices.serviceWasSaved);
        navigate(
          supplierRoutesPath.Services.Service.replace(
            ":serviceId",
            newActiveServicesResponse.data.id
          )
        );
      } else {
        await updateService({ body, id: props.serviceData.id }).unwrap();
        setServerMessage({
          text: GlobalStrings.activeServices.activeServicesWasSaved,
          type: "success",
        });
      }
    } catch (error) {
      errorHandler(error, true, setServerMessage);
    }
  };

  useEffect(() => {
    if (cities)
      setValue(
        "city",
        cities?.find((item: any) => item.value === props.serviceData?.city)
      );
  }, [props.serviceData?.city, cities]);

  return (
    <section className="d-flex flex-column gap-5">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="vehicleType"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.city}
                  type="select"
                  disabled={props.serviceData !== undefined}
                >
                  <option value="">-</option>
                  {GlobalVariables.vehicleTypes.map((item: any) => {
                    return (
                      <option key={item.id} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </Input>
              )}
            />
            <Label for="vehicleType">{GlobalStrings.servicesSuppliers.vehicleType}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <CitiesSelect control={control} name="city" />
          </FormGroup>
        </div>

        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="serviceName"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.city}
                  type="select"
                  disabled={props.serviceData !== undefined}
                >
                  <option value="">-</option>
                  {GlobalVariables.serviceType.map((item: any) => {
                    return (
                      <option key={item.id} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </Input>
              )}
            />
            <Label for="serviceName">{GlobalStrings.formLabels.serviceName}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="coveredZone"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.city}
                  type="text"
                />
              )}
            />
            <Label for="coveredZone">{GlobalStrings.formLabels.coveredZone}</Label>
          </FormGroup>
        </div>

        {(watch("vehicleType") === TransportType.CAR ||
          watch("vehicleType") === TransportType.RIDESHARING) &&
        watch("serviceName") === ServiceType.RECHARGEBATTERY ? (
          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-100">
              <Controller
                name="amountAC"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  pattern: {
                    value: Regex.price,
                    message: GlobalStrings.formValidation.wrongPriceFormat,
                  },
                  min: {
                    value: 0,
                    message: GlobalStrings.formValidation.amountNegative,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.amountAC}
                    type="text"
                    className="input-with-group-text"
                  />
                )}
              />
              <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
              <Label for="amountAC">{GlobalStrings.formLabels.amountAC}</Label>
            </FormGroup>

            <FormGroup floating className="w-100">
              <Controller
                name="amountDC"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                  pattern: {
                    value: Regex.price,
                    message: GlobalStrings.formValidation.wrongPriceFormat,
                  },
                  min: {
                    value: 0,
                    message: GlobalStrings.formValidation.amountNegative,
                  },
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.amountDC}
                    type="text"
                    className="input-with-group-text"
                  />
                )}
              />
              <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
              <Label for="amountDC">{GlobalStrings.formLabels.amountDC}</Label>
            </FormGroup>
          </div>
        ) : (
          watch("vehicleType") &&
          watch("serviceName") && (
            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-100 w-md-50">
                <Controller
                  name="amount"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.price,
                      message: GlobalStrings.formValidation.wrongPriceFormat,
                    },
                    min: {
                      value: 0,
                      message: GlobalStrings.formValidation.amountNegative,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      placeholder={
                        watch("vehicleType") === TransportType.BICYCLE
                          ? GlobalStrings.formLabels.amountBicycle
                          : watch("vehicleType") === TransportType.SCOOTER
                          ? GlobalStrings.formLabels.amountScooter
                          : GlobalStrings.formLabels.amountCar
                      }
                      type="text"
                      className="input-with-group-text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="amount">
                  {watch("vehicleType") === TransportType.BICYCLE
                    ? GlobalStrings.formLabels.amountBicycle
                    : watch("vehicleType") === TransportType.SCOOTER
                    ? GlobalStrings.formLabels.amountScooter
                    : GlobalStrings.formLabels.amountCar}
                </Label>
              </FormGroup>
            </div>
          )
        )}

        <SubmitButton
          isLoading={isLoading || isLoadingUpdate}
          label={
            serviceId !== undefined
              ? GlobalStrings.formLabels.editService
              : GlobalStrings.formLabels.addService
          }
          className="mt-3"
        />
      </Form>
      <AlertComponent errors={errors} serverMessage={serverMessage} />
    </section>
  );
};

export default AddEditSupplierServiceForm;
