import React from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { useGetCompaniesQuery } from "app/containersAndStations/containersAndStationsApiSlice";
import { useCreateStationMutation } from "app/containersAndStations/stationsApiSlice";

import classNames from "classnames";
import { SubmitButton } from "components";
import { GlobalStrings, GlobalVariables, Regex } from "utils";
import { errorHandler, returnFirstErrorMessage } from "utils/globalFunctions";
import { I_ELECTRICITY_CONTAINER } from "utils/types";

type FormData = {
  name: string;
  power: number;
  company: {
    label: string;
    value: string;
  };
};

const AddStation = (props: {
  toggle: () => void;
  isOpen: boolean;
  container: I_ELECTRICITY_CONTAINER;
  refetch: Function;
}) => {
  const animatedComponents = makeAnimated();
  const { data: companies } = useGetCompaniesQuery();
  const [createStation, { isLoading }] = useCreateStationMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    const body = {
      power: +data.power,
      name: data.name,
      address: props.container.address,
      latitude: props.container.lat,
      longitude: props.container.lng,
      containerId: props.container.id,
      companyId: data.company?.value,
      companyName: data.company?.label,
      identity: "",
    };

    try {
      await createStation(body).unwrap();
      toast.success(GlobalStrings.addElectricityContainer.stationAdded);
      props.refetch();
      props.toggle();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <section>
      <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
        <ModalHeader toggle={props.toggle}>
          {GlobalStrings.addElectricityContainer.addStation}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex gap-2 flex-column flex-md-row">
              <div className="w-100 d-flex flex-column">
                <FormGroup floating className="w-100">
                  <Controller
                    name="company"
                    control={control}
                    rules={{
                      required: GlobalStrings.formValidation.fieldsRequired,
                    }}
                    render={({ field }) => (
                      <Select
                        className={classNames("react_select", {
                          invalid: errors.company,
                        })}
                        name="company"
                        placeholder={GlobalStrings.formLabels.company}
                        value={field.value}
                        onChange={(selected) => {
                          field.onChange(selected as any);
                        }}
                        components={animatedComponents}
                        options={companies}
                        isSearchable={false}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup floating className="w-100">
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: GlobalStrings.formValidation.fieldsRequired,
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        {...field}
                        invalid={invalid}
                        placeholder={GlobalStrings.formLabels.name}
                        type="text"
                      />
                    )}
                  />
                  <Label for="name">{GlobalStrings.formLabels.name}</Label>
                </FormGroup>

                <FormGroup floating className="w-100">
                  <Controller
                    name="power"
                    control={control}
                    rules={{
                      required: GlobalStrings.formValidation.fieldsRequired,
                      pattern: {
                        value: Regex.floatNumber,
                        message: GlobalStrings.formValidation.invalidNumber,
                      },
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        {...field}
                        invalid={invalid}
                        placeholder={GlobalStrings.formLabels.power}
                        type="text"
                      />
                    )}
                  />
                  <InputGroupText className="input-group-text">
                    {GlobalVariables.amountAC}
                  </InputGroupText>
                  <Label for="power">{GlobalStrings.formLabels.power}</Label>
                </FormGroup>
              </div>
            </div>

            <SubmitButton
              isLoading={isLoading}
              label={GlobalStrings.formLabels.add}
              className="mb-3"
            />
          </Form>
          <Alert color="danger" fade={true} isOpen={Object.keys(errors).length > 0}>
            {returnFirstErrorMessage(errors) || GlobalStrings.formValidation.fieldsRequired}
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggle}>
            {GlobalStrings.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    </section>
  );
};

export default AddStation;
