import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import { I_STATION } from "app/containersAndStations/stationsApiSlice";

import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { TableBadge } from "components";
import { GlobalStrings, GlobalVariables } from "utils";
import { StationStatus } from "utils/enums";

import greenPin from "assets/images/recharge_station_green.svg";
import { adminRoutesPath } from "views/admin/routes";

const StationMarker = (props: {
  clusterer: any;
  item: any;
  index: number;
  active: I_STATION | undefined;
  setActive: any;
}) => {
  const google = window.google;
  const latLongDiff = 0.00001;
  const [MVCObject, setMVCObject] = useState(undefined);
  const handleOnLoad = useCallback((marker: any) => {
    setMVCObject(marker);
  }, []);

  return (
    <>
      <MarkerF
        onLoad={handleOnLoad}
        icon={{
          url: greenPin,
          scaledSize: new google.maps.Size(60, 60),
        }}
        position={{
          lat: +props.item.latitude + (props.index + 1) * latLongDiff,
          lng: +props.item.longitude + (props.index + 1) * latLongDiff,
        }}
        clusterer={props.clusterer}
        clickable
        onClick={() => props.setActive(props.item)}
      >
        {props.active && props.active?.id === props.item?.id && (
          <InfoWindowF
            anchor={MVCObject}
            onCloseClick={() => {
              setMVCObject(undefined);
              props.setActive(undefined);
            }}
            position={{
              lat: +props.item.latitude + (props.index + 1) * latLongDiff,
              lng: +props.item.longitude + (props.index + 1) * latLongDiff,
            }}
          >
            <div className="map_pin">
              <h4>
                <Link
                  to={adminRoutesPath.ContainersAndStations.EditStation.replace(
                    ":id",
                    props.active.id.toString()
                  )}
                >
                  {props.active?.name}
                </Link>
              </h4>
              <p>
                <span>{GlobalStrings.formLabels.address}</span>
                <span>
                  <Link
                    to={`https://www.google.com/maps/dir/?api=1&destination=${props.active.latitude},${props.active.longitude}`}
                    target="_blank"
                  >
                    {props.active.address}{" "}
                  </Link>
                </span>
              </p>
              <p>
                <span>{GlobalStrings.formLabels.company}</span>
                <span>{props.active.companyName}</span>
              </p>
              <p>
                <span>{GlobalStrings.formLabels.power}</span>
                <span>
                  {isNaN(props.active.power)
                    ? props.active.power
                    : `${props.active.power} ${GlobalVariables.amountAC}`}
                </span>
              </p>
              <p>
                <span>{GlobalStrings.formLabels.connectors}</span>
                <span>{props.active.connectors?.length}</span>
              </p>
              {props.active?.container?.id && (
                <p>
                  <span>{GlobalStrings.formLabels.container}</span>
                  <span>
                    <Link
                      to={adminRoutesPath.ContainersAndStations.EditContainer.replace(
                        ":id",
                        props.active.container.id.toString()
                      )}
                    >
                      {GlobalStrings.edit}
                    </Link>
                  </span>
                </p>
              )}

              <div className="mt-4">
                {
                  <TableBadge
                    text={"Status: " + GlobalStrings.stationsStatus?.[props.active.status]}
                    color={
                      props.active.status === StationStatus.ACTIVE
                        ? "green"
                        : props.active.status === StationStatus.MAINTENANCE
                        ? "orange"
                        : "red"
                    }
                  />
                }
              </div>
            </div>
          </InfoWindowF>
        )}
      </MarkerF>
    </>
  );
};

export default StationMarker;
