import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import {
  useCreateConnectorMutation,
  useUpdateConnectorMutation,
} from "app/containersAndStations/containersAndStationsApiSlice";

import { GlobalStrings, GlobalVariables, Regex } from "utils";
import { Connector, ConnectorStatus, Currency } from "utils/enums";
import { errorHandler, returnFirstErrorMessage } from "utils/globalFunctions";
import { I_CONNECTOR } from "utils/types";

type FormData = {
  type: Connector;
  status: ConnectorStatus;
  label: string;
  internalId: number;
  power: number;
  price: number;
};

const AddEditConector = (props: {
  toggle: () => void;
  isOpen: boolean;
  stationId?: number;
  containerId?: number;
  refetch: Function;
  data?: I_CONNECTOR;
}) => {
  const [addConnector] = useCreateConnectorMutation();
  const [updateConnector] = useUpdateConnectorMutation();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      type: undefined,
      status: undefined,
      label: "",
      power: 0,
      internalId: undefined,
    },
  });

  useEffect(() => {
    if (props.data) {
      setValue("type", props.data?.type);
      setValue("status", props.data?.status);
      setValue("label", props.data?.label);
      setValue("power", props.data?.power);
      setValue("price", props.data?.price);
      setValue("internalId", props.data?.internalId);
    } else {
      reset();
    }
  }, [props.data]);

  const onSubmit = async (data: FormData) => {
    if (props.data) {
      updateConnectorHandler(data);
    } else {
      createConnectorHandler(data);
    }
  };

  const updateConnectorHandler = async (data: FormData) => {
    if (props.data) {
      try {
        const body: any = {
          type: data.type,
          status: data.status || ConnectorStatus.AVAILABLE,
          label: data.label,
          power: data.power,
          price: data.price,
          internalId: data.internalId,
        };
        await updateConnector({ body, id: props.data.id }).unwrap();
        props.toggle();
        props.refetch();

        toast.success(GlobalStrings.addElectricityContainer.connectorUpdated);
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  const createConnectorHandler = async (data: FormData) => {
    try {
      const body: any = {
        type: data.type,
        status: ConnectorStatus.AVAILABLE,
        label: data.label,
        power: data.power,
        price: data.price,
        internalId: data.internalId,
      };
      if (props.stationId) {
        body.stationId = props.stationId;
      }
      if (props.containerId) {
        body.containerId = props.containerId;
      }
      await addConnector(body).unwrap();
      props.toggle();
      props.refetch();
      reset();
      toast.success(GlobalStrings.addElectricityContainer.connectorAdded);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <section>
      <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
        <ModalHeader toggle={props.toggle}>
          {GlobalStrings.addElectricityContainer.addConnector}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-100">
                <Controller
                  name="power"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.floatNumber,
                      message: GlobalStrings.formValidation.invalidNumber,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      placeholder={GlobalStrings.formLabels.power}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">
                  {GlobalVariables.amountAC}
                </InputGroupText>
                <Label for="power">{GlobalStrings.formLabels.power}</Label>
              </FormGroup>
            </div>

            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-100">
                <Controller
                  name="price"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.price,
                      message: GlobalStrings.formValidation.wrongPriceFormat,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      placeholder={GlobalStrings.formLabels.tarif}
                      type="text"
                    />
                  )}
                />
                <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
                <Label for="price">{GlobalStrings.formLabels.tarif}</Label>
              </FormGroup>
            </div>

            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-100">
                <Controller
                  name="type"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      placeholder={GlobalStrings.addElectricityContainer.selectConnectorType}
                      type="select"
                    >
                      <option value="">-</option>
                      {GlobalVariables.connectorType.map((item: any) => {
                        return (
                          <option key={item.id} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </Input>
                  )}
                />
                <Label for="type">
                  {GlobalStrings.addElectricityContainer.selectConnectorType}
                </Label>
              </FormGroup>
            </div>
            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-100">
                <Controller
                  name="label"
                  control={control}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      placeholder={GlobalStrings.formLabels.label}
                      type="text"
                    />
                  )}
                />
                <Label for="label">{GlobalStrings.formLabels.label}</Label>
              </FormGroup>
            </div>

            <div className="d-flex gap-2 flex-column flex-md-row">
              <FormGroup floating className="w-100">
                <Controller
                  name="internalId"
                  control={control}
                  rules={{
                    required: GlobalStrings.formValidation.fieldsRequired,
                    pattern: {
                      value: Regex.positiveNumber,
                      message: GlobalStrings.formValidation.positiveNumber,
                    },
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      placeholder={GlobalStrings.formLabels.internalId}
                      type="text"
                    />
                  )}
                />
                <Label for="internalId">{GlobalStrings.formLabels.internalId}</Label>
              </FormGroup>
            </div>

            {props.data && (
              <div className="d-flex gap-2 flex-column flex-md-row">
                <FormGroup floating className="w-100">
                  <Controller
                    name="status"
                    control={control}
                    rules={{
                      required: GlobalStrings.formValidation.fieldsRequired,
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                      <Input
                        {...field}
                        invalid={invalid}
                        placeholder={GlobalStrings.addElectricityContainer.selectConnectorStatus}
                        type="select"
                      >
                        {GlobalVariables.connectorStatus.map((item: any) => {
                          return (
                            <option key={item.id} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Input>
                    )}
                  />
                  <Label for="status">
                    {GlobalStrings.addElectricityContainer.selectConnectorStatus}
                  </Label>
                </FormGroup>
              </div>
            )}

            <div className="d-flex justify-content-start">
              <Button
                color="success"
                type="submit"
                className="d-flex align-item-center gap-2 mb-3 "
              >
                {GlobalStrings.formLabels.save}
              </Button>
            </div>

            <Alert color="danger" fade={true} isOpen={Object.keys(errors).length > 0}>
              {returnFirstErrorMessage(errors) || GlobalStrings.formValidation.fieldsRequired}
            </Alert>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              props.toggle();
              reset();
              clearErrors();
            }}
          >
            {GlobalStrings.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    </section>
  );
};

export default AddEditConector;
