import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { useGetValidateForgotPasswordTokenQuery, useLogoutMutation } from "app/auth/authApiSlice";
import { logOut, selectCurrentUser } from "app/auth/authSlice";

import { IsLoading, ResetPasswordForm } from "components";
import { errorHandler, GlobalStrings } from "utils";

import logo from "assets/logo/ecohop-logo.png";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [logoutBackend] = useLogoutMutation();
  const user = useSelector(selectCurrentUser);
  const { token } = useParams();
  const { data, isLoading } = useGetValidateForgotPasswordTokenQuery(token);

  useEffect(() => {
    if (user) {
      try {
        logoutBackend();
      } catch (error) {
        errorHandler(error);
      }
      dispatch(logOut());
    }
  }, [user]);

  return (
    <section className="d-flex align-items-center justify-content-center min-vh-100 py-5">
      <div className="form-card">
        {isLoading ? (
          <IsLoading />
        ) : data ? (
          <ResetPasswordForm />
        ) : (
          <div>
            <div className="signin_header">
              <h3 className="title">{GlobalStrings.auth.resetPasswordTitle}</h3>
              <div className="image_container">
                <img src={logo} alt="logo" />
              </div>
            </div>
            <h6 className="my-5">{GlobalStrings.forgotPassword.expiredToken}</h6>
            <Link to="/" className="btn btn-link">
              {GlobalStrings.auth.backToAuth}
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};
export default ResetPassword;
