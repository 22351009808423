import { apiSlice } from "../apiSlice";

export interface TrafficSegment {
    geometry: { lat: number; lon: number }[];
    averageSpeed: number;
}

export interface TrafficData {
    data: TrafficSegment[];
    meta: {
        serverTime: string;
        statusCode: number;
        message: string;
    };
}

export const aicontrollerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTraffic: builder.query<TrafficSegment[], void>({
      query: () => {
        console.log("making request")
        let url = `${process.env.REACT_APP_WORKERS_API_URL}/motiondata/traffic-within-radius/?lat=47.16420404064886&lon=27.5868285985697&radius=100000`;

        if (url.slice(-1) === "&") {
          url = url.slice(0, -1);
        }
        return url;
      },
      transformResponse: (response: TrafficData) => response.data,
    }),
  }),
});

export const { useGetTrafficQuery } = aicontrollerApiSlice;
