import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import { Form, FormGroup, FormText, Input, InputGroupText, Label } from "reactstrap";

import { selectCurrentUser } from "app/auth/authSlice";
import {
  useAddProductMutation,
  useGetAttributtesBySubcategoryQuery,
  useGetProductCategoriesQuery,
  useGetProductSubcategoriesQuery,
  useUpdateProductMutation,
} from "app/marketplace/marketplaceApiSlice";

import classNames from "classnames";
import { AlertComponent, FiltersBadge, ProductImagesSlider, SubmitButton } from "components";
import { useServerMessage } from "hooks/useServerMessage";
import { errorHandler, GlobalStrings, GlobalVariables, Regex } from "utils";
import { Currency, ProductStatus } from "utils/enums";
import { I_PRODUCT } from "utils/types";

import { supplierRoutesPath } from "views/supplier/routes";

import styles from "./AddEditProductForm.module.scss";

const animatedComponents = makeAnimated();

const AddEditProductFrom = (props: { productData: I_PRODUCT; refetch: Function }) => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const { productId } = useParams();
  const { data: categories } = useGetProductCategoriesQuery(null, {
    refetchOnMountOrArgChange: false,
  });
  const { data: subcategories } = useGetProductSubcategoriesQuery(null, {
    refetchOnMountOrArgChange: false,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      name: props.productData?.name || "",
      description: props.productData?.description || "",
      status: props.productData?.inStock
        ? GlobalVariables.productStatus[0].value
        : GlobalVariables.productStatus[1].value,
      price: props.productData?.price.toFixed(2) || "",
      unit: props.productData?.unit || GlobalVariables.unitsArray[0].value,
      categories: undefined,
      subcategory: props.productData?.subCategory?.id || undefined,
      attributtes: "no-value",
      characteristics: "no-value",
    },
  });

  const { data: attributtes } = useGetAttributtesBySubcategoryQuery(watch("subcategory"), {
    skip: !watch("subcategory"),
  });
  const [addProduct, { isLoading }] = useAddProductMutation();
  const [updateProduct, { isLoading: isLoadingUpdate }] = useUpdateProductMutation();
  const { serverMessage, setServerMessage } = useServerMessage();
  const [images, setImages] = useState<any>([]);
  const [selectedCharacteristics, setSelectedCharacteristics] = useState<any>({});

  useEffect(() => {
    if (watch("subcategory")) {
      setSelectedCharacteristics({});
    }
  }, [watch("subcategory")]);

  useEffect(() => {
    if (props.productData) {
      const characteristics: any = {};
      props.productData?.characteristics?.forEach((item) => {
        characteristics[item.attribute.id] = item.id;
      });
      setSelectedCharacteristics(characteristics);
      const categories: any = [];
      props.productData?.categories?.forEach((item) => {
        categories.push({
          id: item.id,
          name: item.name,
          label: item.name,
          value: item.id,
        });
      });
      setValue("categories", categories);
      setImages(props.productData.imagesKeys);
    }
  }, [props.productData]);

  const imagesHandler = (e: any) => {
    let canBeUploaded = true;
    const images = [...e.target.files];
    images.forEach((item: any) => {
      if (!item.type.includes("image")) {
        canBeUploaded = false;
      }
    });
    if (canBeUploaded) {
      setImages(e.target.files);
    } else {
      toast.error(GlobalStrings.marketplace.imageFormat);
      e.target.value = null;
    }
  };

  const characteristicsHandler = () => {
    const attributeId = watch("attributtes");
    const characteristicId = watch("characteristics");
    if (attributeId && characteristicId) {
      selectedCharacteristics[attributeId] = characteristicId;
      setSelectedCharacteristics({ ...selectedCharacteristics });
      setValue("attributtes", "no-value");
      setValue("characteristics", "no-value");
    }
  };

  const removeCharacteristicHandler = (id: string) => {
    delete selectedCharacteristics[id];
    setSelectedCharacteristics({ ...selectedCharacteristics });
  };

  const onSubmit = async ({
    name,
    description,
    status,
    price,
    unit,
    subcategory,
    categories,
  }: any) => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("price", price);
      formData.append("inStock", status === ProductStatus.IN_STOCK ? "true" : "false");
      formData.append("companyId", user?.company?.id.toString());
      formData.append("userId", user.id.toString());
      formData.append("description", description);

      formData.append("subCategoryId", subcategory);

      formData.append("unit", unit);
      formData.append("characteristicsIds", JSON.stringify(Object.values(selectedCharacteristics)));
      formData.append(
        "categoriesIds",
        JSON.stringify(Object.values(categories.map((item: any) => item.id)))
      );
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }

      if (productId) {
        await updateProduct({ body: formData, productId }).unwrap();
        setServerMessage({
          type: "success",
          text: GlobalStrings.marketplace.update200,
        });
        props.refetch();
      } else {
        const response = await addProduct(formData).unwrap();
        navigate(supplierRoutesPath.Products.Product.replace(":productId", response?.data?.id));
        toast.success(GlobalStrings.marketplace[200]);
      }
    } catch (error: any) {
      errorHandler(error, true, setServerMessage);
    }
  };

  return (
    <section className="d-flex flex-column gap-5">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100 w-md-50">
            <Controller
              name="name"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.productName}
                  type="text"
                />
              )}
            />
            <Label for="name">{GlobalStrings.formLabels.productName}</Label>
          </FormGroup>
        </div>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="description"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.description}
                  type="textarea"
                  style={{ minHeight: 120 }}
                />
              )}
            />
            <Label for="description">{GlobalStrings.formLabels.description}</Label>
          </FormGroup>
        </div>

        <div className="d-flex gap-2 flex-column flex-md-row">
          <FormGroup floating className="w-100">
            <Controller
              name="status"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.productStatus}
                  type="select"
                >
                  {GlobalVariables.productStatus.map((item: any) => {
                    return (
                      <option key={item.id} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              )}
            />
            <Label for="status">{GlobalStrings.formLabels.productStatus}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="price"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
                pattern: {
                  value: Regex.price,
                  message: GlobalStrings.formValidation.wrongPriceFormat,
                },
                min: {
                  value: 0,
                  message: GlobalStrings.formValidation.amountNegative,
                },
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.productPrice}
                  type="text"
                  className="input-with-group-text"
                />
              )}
            />
            <InputGroupText className="input-group-text">{Currency.LEI}</InputGroupText>
            <Label for="price">{GlobalStrings.formLabels.productPrice}</Label>
          </FormGroup>

          <FormGroup floating className="w-100">
            <Controller
              name="unit"
              control={control}
              rules={{
                required: GlobalStrings.formValidation.fieldsRequired,
              }}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder={GlobalStrings.formLabels.unit}
                  type="select"
                >
                  {GlobalVariables.unitsArray.map((item: any) => {
                    return (
                      <option key={item.id} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              )}
            />
            <Label for="unit">{GlobalStrings.formLabels.unit}</Label>
          </FormGroup>
        </div>
        <h5 className="mt-5">{GlobalStrings.marketplace.categoriesInfo}</h5>
        {categories?.length > 0 && (
          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-33">
              <Controller
                name="categories"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field }) => (
                  <Select
                    className={classNames("react_select", {
                      invalid: errors.categories,
                    })}
                    name="categories"
                    isMulti
                    placeholder={GlobalStrings.formLabels.categories}
                    value={field.value}
                    onChange={(selected) => field.onChange(selected as any)}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    options={categories}
                  />
                )}
              />
            </FormGroup>

            <FormGroup floating className="w-md-33 w-100">
              <Controller
                name="subcategory"
                control={control}
                rules={{
                  required: GlobalStrings.formValidation.fieldsRequired,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.subcategory}
                    type="select"
                  >
                    <option value="">-</option>
                    {subcategories?.map((item: any) => {
                      return (
                        <option key={item.id} value={item.value}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                )}
              />
              <Label for="subcategory">{GlobalStrings.formLabels.subcategory}</Label>
            </FormGroup>
          </div>
        )}

        {attributtes?.length > 0 && (
          <div className="mt-5">
            <h4>{GlobalStrings.marketplace.productCharacteristics}</h4>
            <p>{GlobalStrings.marketplace.productCharacteristicsInfo}</p>
          </div>
        )}

        {attributtes?.length > 0 && (
          <div className="d-flex gap-2 flex-column flex-md-row">
            <FormGroup floating className="w-md-33 w-100">
              <Controller
                name="attributtes"
                control={control}
                render={({ field, fieldState: { invalid } }) => (
                  <Input
                    {...field}
                    invalid={invalid}
                    placeholder={GlobalStrings.formLabels.attributtes}
                    type="select"
                  >
                    <option value="no-value">-</option>
                    {attributtes.map((item: any) => {
                      return (
                        <option
                          key={item.id}
                          value={item.value}
                          disabled={Object.keys(selectedCharacteristics).includes(
                            item.id.toString()
                          )}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                )}
              />
              <Label for="attributtes">{GlobalStrings.formLabels.attributtes}</Label>
            </FormGroup>

            {watch("attributtes") !== "no-value" && (
              <FormGroup floating className="w-md-33 w-100">
                <Controller
                  name="characteristics"
                  control={control}
                  render={({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={invalid}
                      placeholder={GlobalStrings.formLabels.characteristics}
                      type="select"
                    >
                      <option value="no-value">-</option>
                      {attributtes
                        ?.find((item: any) => item.id.toString() === getValues("attributtes"))
                        ?.characteristics?.map((item: any) => {
                          return (
                            <option key={item.id} value={item.value}>
                              {item.name}
                            </option>
                          );
                        })}
                    </Input>
                  )}
                />
                <Label for="characteristics">{GlobalStrings.formLabels.characteristics}</Label>
              </FormGroup>
            )}

            {watch("attributtes") !== "no-value" && watch("characteristics") !== "no-value" && (
              <button
                className="btn btn-success align-self-center mb-3"
                onClick={characteristicsHandler}
                type="button"
              >
                {GlobalStrings.add}
              </button>
            )}
          </div>
        )}

        <div className={styles.attributes}>
          {Object.keys(selectedCharacteristics)?.length > 0 &&
            Object.keys(selectedCharacteristics).map((item, index) => {
              const attribute = attributtes?.find(
                (attributeItem: any) => attributeItem.id.toString() === item.toString()
              );
              const characteristic = attribute?.characteristics?.find(
                (characteristicItem: any) =>
                  characteristicItem.id.toString() === selectedCharacteristics[item].toString()
              );
              return (
                <FiltersBadge
                  key={index}
                  attribute={attribute?.name}
                  characteristic={characteristic?.name}
                  action={() => removeCharacteristicHandler(attribute.id)}
                />
              );
            })}
        </div>

        <div className={styles.images_container}>
          <FormGroup className={styles.files_input}>
            <Label for="exampleFile">{GlobalStrings.formLabels.images}</Label>
            <Input
              type="file"
              name="images"
              id="images"
              multiple
              onChange={imagesHandler}
              files={images}
              accept="image/*"
            />

            <FormText color="muted">{GlobalStrings.marketplace.imageFormat}</FormText>
          </FormGroup>

          <ProductImagesSlider images={[...images]} />
        </div>
        <SubmitButton
          isLoading={isLoading || isLoadingUpdate}
          label={productId ? GlobalStrings.formLabels.save : GlobalStrings.marketplace.addProduct}
          className="mt-3"
        />
      </Form>
      <AlertComponent errors={errors} serverMessage={serverMessage} />
    </section>
  );
};
export default AddEditProductFrom;
